import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { logout } from '../../state/actions/auth-actions';
import { emptyApiData } from '../../state/actions/api-data-actions';
import { cleanBacktestData } from '../../state/actions/backtest-actions';

const Logout = props => {
  props.dispatch(logout());
  props.dispatch(emptyApiData());
  props.dispatch(cleanBacktestData());
  return <Redirect to="/login" />;
};

export default connect()(Logout);
