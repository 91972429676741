/* eslint-disable func-names */
import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import BitaSelect from '../../../BitaSelect/BitaSelect';
import styles from '../PerformanceAndRisk.module.scss';

const TableData = props => {
  const [featuresdate, setFeaturesDate] = useState({
    id: '2020-12-20',
    value: '2020-12-20 (reconstitution)',
  });

  const dataTableDummie = {
    Features: {
      benchmark: [
        { name: 'Business Ethics Score', date: '2020-12-20', value: 27.63 },
        { name: 'Capital Structures Score', date: '2020-12-20', value: 26.07 },
        { name: 'Comunity Relations Score', date: '2020-12-20', value: 17.83 },
        { name: 'Compensation Score', date: '2020-12-20', value: 12.62 },
        { name: 'Corporate governance score', date: '2020-12-20', value: 25.11 },
        { name: 'Diversity Score', date: '2020-12-20', value: 20.82 },
        { name: 'Emissions score', date: '2020-12-20', value: 20.38 },
        { name: 'Employment Quality Score', date: '2020-12-20', value: 46.52 },
        { name: 'Environmental Management Score', date: '2020-12-20', value: 12.97 },
        { name: 'Environmental Stewardship Score', date: '2020-12-20', value: 6.56 },
        { name: 'Forensic Accounting Score', date: '2020-12-20', value: 40.38 },
        { name: 'Human Rights Score', date: '2020-12-20', value: 7.19 },
        { name: 'Labour RIghts Score', date: '2020-12-20', value: 27.32 },
        { name: 'Occupational Health and Safety Score', date: '2020-12-20', value: 28.41 },
        { name: 'Product Access Score', date: '2020-12-20', value: 42.13 },
        { name: 'Product Quality and Safety Score', date: '2020-12-20', value: 6.7 },
        { name: 'Resource Use Score', date: '2020-12-20', value: 26.85 },
        { name: 'Training and Development Score', date: '2020-12-20', value: 8.15 },
        { name: 'Transparency Score', date: '2020-12-20', value: 21.84 },
        { name: 'Waste Score', date: '2020-12-20', value: 37.16 },
        { name: 'Water Score', date: '2020-12-20', value: 9.87 },
      ],
      index: [
        { name: 'Business Ethics Score', date: '2020-12-20', value: 3.41 },
        { name: 'Capital Structures Score', date: '2020-12-20', value: 3.43 },
        { name: 'Comunity Relations Score', date: '2020-12-20', value: 7.47 },
        { name: 'Compensation Score', date: '2020-12-20', value: 46 },
        { name: 'Corporate governance score', date: '2020-12-20', value: 37.85 },
        { name: 'Diversity Score', date: '2020-12-20', value: 1.55 },
        { name: 'Emissions score', date: '2020-12-20', value: 49.78 },
        { name: 'Employment Quality Score', date: '2020-12-20', value: 41.54 },
        { name: 'Environmental Management Score', date: '2020-12-20', value: 6.58 },
        { name: 'Environmental Stewardship Score', date: '2020-12-20', value: 10.25 },
        { name: 'Forensic Accounting Score', date: '2020-12-20', value: 35.28 },
        { name: 'Human Rights Score', date: '2020-12-20', value: 21.97 },
        { name: 'Labour RIghts Score', date: '2020-12-20', value: 22.67 },
        { name: 'Occupational Health and Safety Score', date: '2020-12-20', value: 35.42 },
        { name: 'Product Access Score', date: '2020-12-20', value: 24.29 },
        { name: 'Product Quality and Safety Score', date: '2020-12-20', value: 48.32 },
        { name: 'Resource Use Score', date: '2020-12-20', value: 10.48 },
        { name: 'Training and Development Score', date: '2020-12-20', value: 12.59 },
        { name: 'Transparency Score', date: '2020-12-20', value: 47.09 },
        { name: 'Waste Score', date: '2020-12-20', value: 19.18 },
        { name: 'Water Score', date: '2020-12-20', value: 38.6 },
      ],
    },
    'Sustainalytics ESG Risk Scores': {
      benchmark: [
        { name: 'ESG Risk Score', date: '2020-12-20', value: [27.63, 34.12, 12.67, 10.4] },
        { name: 'Overall Exposure Score', date: '2020-12-20', value: [36.07, 33.51, 21.58, 32.78] },
        { name: 'Overall Management Score', date: '2020-12-20', value: [17.83, 5.5, 18.33, 10.56] },
      ],
      index: [
        { name: 'ESG Risk Score', date: '2020-12-20', value: [5.41, 33.12, 62.19, 18.32] },
        { name: 'Overall Exposure Score', date: '2020-12-20', value: [13.43, 23.72, 19.82, 33.53] },
        {
          name: 'Overall Management Score',
          date: '2020-12-20',
          value: [8.47, 47.58, 13.43, 10.44],
        },
      ],
    },
    'Sustainalytics ESG Risk Issues': {
      benchmark: [
        { name: 'ESG Risk Score', date: '2020-12-20', value: [7.91, 3.76, 22.19, 23.72] },
        { name: 'Overall Exposure Score', date: '2020-12-20', value: [16.22, 18.56, 39.51, 27.63] },
        {
          name: 'Overall Management Score',
          date: '2020-12-20',
          value: [20.41, 34.99, 12.45, 33.19, 18.53],
        },
      ],
      index: [
        { name: 'ESG Risk Score', date: '2020-12-20', value: [13.41, 56.33, 19.45, 29.43] },
        { name: 'Overall Exposure Score', date: '2020-12-20', value: [8.43, 23.7, 19.8, 18.5] },
        { name: 'Overall Management Score', date: '2020-12-20', value: [22.7, 19.8, 12.4, 62.1] },
      ],
    },
  };

  const generateTables = (data, subHeader) => {
    const hash = {};
    const _index = data.index.filter(item => item.date === featuresdate.id);
    const _benchmark = data.benchmark.filter(item => item.date === featuresdate.id);
    const _dataDummie = _index.concat(_benchmark).filter(function(current) {
      const go = current.value !== undefined ? String(current.name) : String(current.name);
      const exists = !hash[go] || false;
      hash[go] = true;
      return exists;
    });

    return (
      <div className={styles.tableContainer}>
        <table className={styles.generatedTable}>
          <thead className={styles.generatedTableHeader}>
            <tr>
              <th colSpan="1" />
              <th
                colSpan={subHeader ? subHeader.length / 2 : 1}
                scope="col"
                className={subHeader ? styles.restTableHeaders : ''}
              >
                Index
              </th>
              <th
                colSpan={subHeader ? subHeader.length / 2 : 1}
                scope="col"
                className={subHeader ? styles.restTableHeaders : ''}
              >
                Benchmark
              </th>
            </tr>
            {subHeader ? (
              <tr>
                <th />
                {subHeader.map((item, key) => (
                  <th
                    className={
                      key === 0 || key === subHeader.length / 2 ? styles.restTableHeaders : ''
                    }
                    style={{ textAlign: 'center' }}
                  >
                    {item}
                  </th>
                ))}
              </tr>
            ) : (
              ''
            )}
          </thead>
          <tbody>
            {subHeader
              ? _dataDummie.map(item => {
                  const _indexItem = _index.filter(itemIndex => itemIndex.name === item.name)[0];
                  const _benchmarkItem = _benchmark.filter(
                    itembenchmar => itembenchmar.name === item.name,
                  )[0];
                  return (
                    <tr className={styles.generatedTableHeader}>
                      <td style={{ fontWeight: 'bold' }}>{item.name}</td>
                      {_indexItem?.value.map((_item, key) => (
                        <td
                          className={
                            key === 0 || key === subHeader.length / 2 ? styles.restTableHeaders : ''
                          }
                          style={{ textAlign: 'center' }}
                        >
                          {_item}
                        </td>
                      ))}

                      {_benchmarkItem?.value.map((_item, key) => (
                        <td
                          className={
                            key === 0 || key === subHeader.length / 2 ? styles.restTableHeaders : ''
                          }
                          style={{ textAlign: 'center' }}
                        >
                          {_item}
                        </td>
                      ))}
                    </tr>
                  );
                })
              : _dataDummie.map(itemdummie => {
                  const _indexItem = _index.filter(
                    itemIndex => itemIndex.name === itemdummie.name,
                  )[0];
                  const _benchmarkItem = _benchmark.filter(
                    itembenchmar => itembenchmar.name === itemdummie.name,
                  )[0];
                  return (
                    <tr className={styles.generatedTableHeader}>
                      <td style={{ fontWeight: 'bold' }}>{itemdummie.name}</td>
                      <td
                        className={styles.restTableHeaders}
                        style={{ width: '30%', textAlign: 'center' }}
                      >
                        {_indexItem?.value}
                      </td>
                      <td
                        className={styles.restTableHeaders}
                        style={{ width: '30%', textAlign: 'center' }}
                      >
                        {_benchmarkItem?.value}
                      </td>
                    </tr>
                  );
                })}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className={styles.tableContainer}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <span style={{ fontWeight: 'bold' }} />
        <BitaSelect
          data={props.timeframes}
          value={featuresdate}
          handleSelection={selected => {
            setFeaturesDate(selected);
          }}
        />
      </div>
      <div>{generateTables(dataTableDummie[props.data], props.subHeader)}</div>
    </div>
  );
};
export default TableData;
