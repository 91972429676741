import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as _ from 'lodash';
import { useHistory, useLocation } from 'react-router';
import BitaModal from '../../bitaComponents/BitaModal/BitaModal';
import { Icons } from '../../constants/icons';
import BitaButton from '../../bitaComponents/BitaButton/BitaButton';
import BitaCharts from '../../bitaComponents/BitaCharts/BitaCharts';
import { DownloadCenterModal } from '../BitaModal/DownloadCenterModal';
import BitaSelect from '../BitaSelect/BitaSelect';
import styles from './BacktestModal.module.scss';
import * as Action from '../../state/actions/backtest-actions';
import { setDataModal } from '../../state/actions/download-actions';
import BitaExitWorkflowModal from '../../bitaComponents/BitaModal/BitaExitWorkflowModal';

import { cleanCache } from '../../state/actions/summary-actions';
import * as SummaryConstants from '../../constants/summary-constants';
import chartData from './chartData';
import BaseDetails from './BaseDetails';

import { cleanProject } from '../../state/actions/project-actions';
import * as STATUS from '../../constants/backtest-status';

const SAVE_BACKTEST_CLICKED = 'SAVE_BACKTEST_CLICKED';
const DISCARD_CLICKED = 'DISCARD_CLICKED';
const NOTHING_CLICKED = 'NOTHING_CLICKED';
const SAVE_LOAD_FTP_CLICKED = 'SAVE_LOAD_FTP_CLICKED';

const BacktestCompletedSingleModal = ({ backtest }) => {
  const history = useHistory();
  const location = useLocation();
  const backTestData = backtest.data;
  const storeChartData = useSelector(state =>
    _.get(
      state,
      `chart.charts[${backtest.id}].dataAvailabilityChartData.total_data_availability.data`,
      null,
    ),
  );
  const [formatedChartData, setFormatedData] = useState(null);
  const [selectOptions, setSelectOptions] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isClicked, setClicked] = useState(NOTHING_CLICKED);
  const [showExitModal, setShowExitModal] = useState(false); // State for exit modal
  const [exitModalText, setExitModalText] = useState(''); // State for modal text
  const [cancelProjectType, setCancelProjectType] = useState(''); // State for action type (x-button or discard-button)

  const token = useSelector(state => state.auth.token);
  const userdata = useSelector(state => state.auth.userdata);
  const backtestDetails = useSelector(state => state.backtest.backtestDetails);
  const companyId = userdata.id_company;
  const apiData = useSelector(state => state.apiData.apiData);
  const dispatch = useDispatch();
  const changeSelectionDropdown = ({ value }) => {
    setSelectedOption(value);
  };
  const [backtestDataDetails, setBacktestDataDetails] = useState({
    universeSelected: {
      name: '',
      id: null,
    },
    methodologySelected: {
      name: '',
      id: null,
    },
  });

  const getBacktestDetails = () => {
    const BACKTEST_NAME = backTestData['INDEX NAME'] || backTestData['PORTFOLIO NAME'];
    const backtestObjData = backtestDetails.find(
      backtestObj => backtestObj?.name === BACKTEST_NAME,
    );

    setBacktestDataDetails({
      universeSelected: {
        name: backtestObjData?.universeSelected?.name,
        id: backtestObjData?.universeSelected?.id,
      },
      methodologySelected: {
        name: backtestObjData?.methodologySelected?.name,
        id: backtestObjData?.methodologySelected?.id,
      },
    });
  };

  useEffect(() => {
    getBacktestDetails();
  }, []);

  useEffect(() => {
    if (
      location.pathname.includes('step=Leveraged+Index') ||
      location.pathname.includes('step=Decrement+Index') ||
      location.pathname.includes('step=Risk+Control+Index')
    ) {
      setClicked(SAVE_BACKTEST_CLICKED);
      const payload = {
        backtest,
        history,
        apiData,
      };

      dispatch(
        Action.acceptBacktest({
          companyId,
          token,
          payload,
          universeIdSelected: backtestDataDetails.universeSelected.id,
          methodologyIdSelected: backtestDataDetails.methodologySelected.id,
        }),
      );
    }
  }, []);

  useEffect(() => {
    if (storeChartData) {
      const xAxisDates = _.keys(storeChartData); // son las fechas
      // dataFirstStoredDate debe ser {clave_data:valor} de la primera fecha, como todas traen la misma dataFirstStoredDate, basta
      // con revisar la primera
      const [, dataFirstStoredDate] = _.toPairs(storeChartData)[0];
      const labelCategories = Object.keys(dataFirstStoredDate); // son las categorias guardadas

      const dataByCategory = {};

      // eslint-disable-next-line no-restricted-syntax
      for (const date of xAxisDates) {
        // eslint-disable-next-line no-restricted-syntax
        for (const category of labelCategories) {
          const categValueForDate = _.get(storeChartData, [date, category], 0);
          dataByCategory[category] = [...(dataByCategory[category] || []), categValueForDate];
        }
      }

      setFormatedData({ xAxisDates, labelCategories, dataByCategory });

      if (!selectOptions)
        setSelectOptions(labelCategories.map((category, i) => ({ id: i, value: category })));
      setSelectedOption('Number of Constituents (Index)');
    }
  }, [storeChartData, selectOptions]);

  // todo, this must be set directly in redux, this approach is cumbersome
  let baseMethodology;

  if (_.get(apiData, 'methodologySelected.id')) {
    baseMethodology = _.get(apiData, 'Methodologies.data', [{}]).find(
      method => method.id === apiData.methodologySelected.id,
    );
  }

  const save = props => {
    const payload = {
      backtest,
      history,
      apiData,
      wasHide: backtest.wasHide,
    };
    dispatch(
      Action.acceptBacktest({
        companyId,
        token,
        payload,
        generateFTP: props,
        universeIdSelected: backtestDataDetails.universeSelected.id,
        methodologyIdSelected: backtestDataDetails.methodologySelected.id,
      }),
    );
  };

  const setBaseInfo = () => {
    const indexType = _.get(backTestData, ['type'], 'regular');
    switch (indexType) {
      case 'optimized_index':
        return [
          { title: 'BACKTEST NAME', content: backTestData['INDEX NAME'] },
          { title: 'PARENT INDEX', content: _.get(backTestData, ['parent_index'], 'N/A') },
          {
            title: 'PARENT INDEX UNIVERSE',
            content: _.get(apiData, 'universeSelected.name', 'no universe selected'),
          },
          {
            title: 'PARENT INDEX METHODOLOGY',
            content: _.get(baseMethodology, 'name', 'no base methodology selected'),
          },
        ];
      case 'rebalancing_module':
        return [
          {
            title: 'REBALANCING NAME',
            content: backTestData['INDEX NAME'] || backTestData['PORTFOLIO NAME'],
          },
          {
            title: 'BASE UNIVERSE',
            content: _.get(apiData, 'universeSelected.name', 'Universe Uploaded'),
          },
          {
            title: 'BASE METHODOLOGY',
            content: _.get(baseMethodology, 'name', 'no base methodology selected'),
          },
        ];
      case 'structured_index':
        if (backTestData.index_subtype) {
          return [
            { title: 'BACKTEST NAME', content: backTestData['INDEX NAME'] },
            {
              title: `PARENT ${_.get(
                backTestData,
                ['structured_instrument', 'Instrument type'],
                '',
              )}`,
              content:
                _.get(backTestData, ['structured_instrument', 'Instrument name'], null) ||
                _.get(backTestData, ['parent_index'], 'N/A'),
            },
            {
              title: 'BENCHMARK',
              content:
                _.get(backTestData, ['Benchmark', 'label'], null) ||
                _.get(backTestData, ['info', 'Benchmark', 'label'], 'N/A'),
            },
          ];
        }
        return [
          { title: 'BACKTEST NAME', content: backTestData['INDEX NAME'] },
          { title: 'NUMBER OF CONSTITUENTS', content: backTestData.structured_n_constituents },
          {
            title: 'BENCHMARK',
            content: _.get(backTestData, ['Benchmark', 'Instrument name'], 'N/A'),
          },
        ];
      case 'basket-index':
        return [
          { title: 'BACKTEST NAME', content: backTestData['INDEX NAME'] },
          {
            title: 'BASE PORTFOLIO',
            content: backTestData?.front_parameters?.PortfolioSelected?.value,
          },
        ];
      default:
        return [
          {
            title: 'BACKTEST NAME',
            content: backTestData['INDEX NAME'] || backTestData['PORTFOLIO NAME'],
          },
          {
            title: 'BASE UNIVERSE',
            content: backtestDataDetails.universeSelected.name ?? 'no universe selected',
          },
          {
            title: 'BASE METHODOLOGY',
            content: backtestDataDetails.methodologySelected.name ?? 'no base methodology selected',
          },
        ];
    }
  };

  const handleAcceptButton = () => {
    setClicked(SAVE_BACKTEST_CLICKED);
    const payload = {
      backtest,
      history,
      apiData,
      wasHide: backtest.wasHide,
    };

    dispatch(
      Action.acceptBacktest({
        companyId,
        token,
        payload,
        universeIdSelected: backtestDataDetails.universeSelected.id,
        methodologyIdSelected: backtestDataDetails.methodologySelected.id,
      }),
    );
  };

  const handleClose = () => {
    setExitModalText('The backtest is completed. Do you really want to cancel the backtest?');
    setCancelProjectType('x-button');
    setShowExitModal(true); // Show exit modal
  };

  const handleDiscard = () => {
    if (_.get(backTestData, ['type'], 'regular') !== 'rebalancing_module') {
      setExitModalText('The backtest is completed. Do you really want to discard the backtest?');
      setCancelProjectType('discard-button');
    }
    setShowExitModal(true); // Show exit modal
  };

  // Function to handle the actions within the exit modal
  const handleExitModalClose = ({ id }) => {
    setShowExitModal(false); // Hide exit modal

    if (id === 'cancel_backtest') {
      if (cancelProjectType === 'x-button') {
        dispatch(cleanCache(token));
        dispatch(cleanProject(token, true));
        dispatch({
          type: SummaryConstants.RESET,
        });
        if (location.pathname.includes('')) {
          history.push('/');
        }
      } else if (cancelProjectType === 'discard-button') {
        setClicked(DISCARD_CLICKED);
        if (location.pathname.includes('') && !backtest.wasHide) {
          history.push('/');
        }
      }

      dispatch(Action.changeBacktestStatus(backtest.id, STATUS.BACKTEST_CANCELLED));
    }

    if (id === 'return') {
      setShowExitModal(false); // Just close the modal
    }
  };

  return (
    <>
      {/* Added the exit workflow modal if user select X or discard buttons for confirmation */}
      <BitaExitWorkflowModal
        isModalOpen={showExitModal}
        popupCloseOptions={[
          { value: 'Yes', id: 'cancel_backtest' },
          { value: 'No', id: 'return' },
        ]}
        workflowSection="before_run"
        handleOptionsMenuCloseModal={handleExitModalClose}
        customText={exitModalText} // Pass the custom text
      />

      <BitaModal
        isModalOpen={
          backtest.status === STATUS.BACKTEST_COMPLETED &&
          !location.pathname.includes('step=Leveraged+Index') &&
          !location.pathname.includes('step=Decrement+Index') &&
          !location.pathname.includes('step=Risk+Control+Index')
        }
        setModalState={open => dispatch(Action.setBacktestModalMustDisplay(open))}
      >
        <div className={styles.contentContainer}>
          <div>
            <span className={styles.closeModalButton} onClick={handleClose}>
              &times;
            </span>
          </div>

          <div className={styles.headerContainer}>
            <Icons.Avanti />
            {_.get(backTestData, ['type'], 'regular') === 'rebalancing_module' ? (
              <span className={styles.headerText}>REBALANCING COMPLETED</span>
            ) : (
              <span className={styles.headerText}>BACKTEST COMPLETED</span>
            )}
          </div>
          <div className={styles.backtestDetailsContainer}>
            <div className={styles.detailsTitle}>
              {_.get(backTestData, ['type'], 'regular') === 'rebalancing_module' ? (
                <span className={styles.detailsTitleText}>REBALANCING DETAILS</span>
              ) : (
                <span className={styles.detailsTitleText}>BACKTEST DETAILS</span>
              )}
            </div>
            <div className={styles.indexDataContainer}>
              {setBaseInfo().map(base => (
                <BaseDetails {...base} />
              ))}
            </div>
          </div>
          {_.get(backTestData, ['type'], 'regular') !== 'rebalancing_module' && (
            <div className={styles.backtestDataContainer}>
              <div className={styles.dataTitleContainer}>
                <span className={styles.dataTitle}>BACKTEST DATA EVOLUTION</span>
              </div>
              <div className={`${styles.dataDropdownContainer} backtestcompletedropdown`}>
                <span className={styles.dataDropdownText}>
                  Review usage and availability of constituents and input data fields:{' '}
                </span>
                {selectedOption && selectOptions && (
                  <BitaSelect
                    label={selectedOption || selectOptions[selectOptions.length - 1]}
                    data={selectOptions}
                    handleSelection={changeSelectionDropdown}
                    style={{ fontSize: 13 }}
                  />
                )}
              </div>
              <span className={styles.dataChartTitle}>NUMBER OF CONSTITUENTS</span>
              <div className={styles.chartContainer}>
                {formatedChartData &&
                  formatedChartData.xAxisDates &&
                  formatedChartData.dataByCategory &&
                  selectedOption && (
                    <div key={selectedOption}>
                      <BitaCharts
                        containerProps={{ style: { height: '35vh' } }}
                        options={_.merge(
                          chartData(
                            formatedChartData.xAxisDates,
                            formatedChartData.dataByCategory[selectedOption],
                          ),
                          { yAxis: { title: false } },
                        )}
                      />
                    </div>
                  )}
              </div>
            </div>
          )}
          <div className={styles.buttonsContainer}>
            <BitaButton
              onClick={handleDiscard}
              primary
              disabled={isClicked !== NOTHING_CLICKED}
              style={{ marginRight: '20px' }}
            >
              {_.get(backTestData, ['type'], 'regular') !== 'rebalancing_module'
                ? 'Discard Backtest'
                : 'Discard Rebalancing'}
            </BitaButton>
            <BitaButton
              style={{ marginRight: '20px' }}
              disabled={isClicked === DISCARD_CLICKED}
              onClick={handleAcceptButton}
              primary
              loadingColor="#2962ff"
              loading={isClicked === SAVE_BACKTEST_CLICKED}
            >
              {_.get(backTestData, ['type'], 'regular') !== 'rebalancing_module'
                ? 'Accept Backtest'
                : 'Accept Rebalancing'}
            </BitaButton>

            <BitaButton
              primaryWhite
              onClick={() => {
                setClicked(SAVE_LOAD_FTP_CLICKED);

                dispatch(setDataModal({ open: true, file: 'File generation' }));
              }}
              loading={isClicked === SAVE_LOAD_FTP_CLICKED}
              loadingColor="#2962ff"
            >
              Save & Load to FTP
            </BitaButton>
          </div>
        </div>
        <DownloadCenterModal
          onClick={data => {
            save(data);
          }}
        />
      </BitaModal>
    </>
  );
};

const BacktestCompletedModal = () => {
  const allBacktests = useSelector(state => state.backtest.backtests);

  const backtests = useMemo(() => {
    return allBacktests
      ? Object.values(allBacktests).filter(backtst => backtst.status === STATUS.BACKTEST_COMPLETED)
      : [];
  }, [allBacktests]);

  return (
    <>
      {backtests && backtests.map(backtest => <BacktestCompletedSingleModal backtest={backtest} />)}
    </>
  );
};

export default BacktestCompletedModal;
