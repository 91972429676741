import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import styles from '../AttributionAnalyzer.module.scss';
import { setDecimal } from '../../../../utils/utils';
import BitaCharts from '../../../../bitaComponents/BitaCharts/BitaCharts';

export const StyleFactor = ({ styleFactorDate }) => {
  const apiData = useSelector(state => state.apiData.apiData);
  const [styleFactorStats, setStyleFactorStats] = useState();
  const [styleFactorBenchmarkStats, setStyleFactorBenchmarkStats] = useState();
  const validateNumber = value => {
    if (typeof value === 'number') {
      return Number(setDecimal(value));
    }
    return 0;
  };
  const heatmapOptions = {
    credits: {
      enabled: false,
    },
    legend: {
      x: 35,
    },
    chart: {
      type: 'heatmap',
      marginTop: 40,
      marginBottom: 80,
      backgroundColor: 'transparent',
      plotBorderWidth: 1,
      style: {
        fontFamily: 'Neue Haas Grotesk Display Pro',
      },
    },
    plotOptions: {
      heatmap: {
        borderColor: 'white',
      },
    },

    title: {
      text: '',
    },
    lang: {
      noData: 'Loading data',
    },
    noData: {
      style: {
        fontWeight: 'bold',
        fontSize: '12px',
        color: '#ffffff',
      },
    },
    xAxis: {
      labels: {
        style: { color: 'white', backgroundColor: '#2962FF' },
      },
      opposite: true,
      categories: ['Index', 'Benchmark'],
    },

    yAxis: {
      labels: {
        style: { color: 'white' },
      },
      categories: ['Momentum', 'Value', 'Size', 'Quality', 'Low Volatility', 'Liquidity', 'Yield'],
      title: null,
      reversed: true,
    },

    colorAxis: {
      min:
        styleFactorStats && styleFactorStats.momentum && styleFactorBenchmarkStats
          ? Object.entries(styleFactorStats)
              .concat(Object.entries(styleFactorBenchmarkStats))
              .map(obj => obj[1])
              .reduce((a, b) => {
                return Math.min(a, b);
              })
              .toFixed(2)
          : -3,
      gridLineColor: 'transparent',
      labels: {
        align: 'center',
        style: { color: '#FFF' },
      },
      nullColor: '#2962FF',
      minColor: '#FA5400',
      maxColor: '#2962FF',
      stops: [
        [0, '#FA5400'],
        [0.5, '#C7C7C7'],
        [0.9, '#2962FF'],
      ],
      minorTickInterval: 25,
    },

    series:
      styleFactorStats && styleFactorBenchmarkStats
        ? [
            {
              name: 'Sales per employee',
              borderWidth: 1,
              data: [
                [0, 0, styleFactorStats && validateNumber(styleFactorStats.momentum)],
                [0, 1, styleFactorStats && validateNumber(styleFactorStats.value)],
                [0, 2, styleFactorStats && validateNumber(styleFactorStats.size)],
                [0, 3, styleFactorStats && validateNumber(styleFactorStats.quality)],
                [0, 4, styleFactorStats && validateNumber(styleFactorStats.low_volatility)],
                [0, 5, styleFactorStats && validateNumber(styleFactorStats.liquidity)],
                [0, 6, styleFactorStats && validateNumber(styleFactorStats.yield)],
                [
                  1,
                  0,
                  styleFactorBenchmarkStats && validateNumber(styleFactorBenchmarkStats.momentum),
                ],
                [
                  1,
                  1,
                  styleFactorBenchmarkStats && validateNumber(styleFactorBenchmarkStats.value),
                ],
                [1, 2, styleFactorBenchmarkStats && validateNumber(styleFactorBenchmarkStats.size)],
                [
                  1,
                  3,
                  styleFactorBenchmarkStats && validateNumber(styleFactorBenchmarkStats.quality),
                ],
                [
                  1,
                  4,
                  styleFactorBenchmarkStats &&
                    validateNumber(styleFactorBenchmarkStats.low_volatility),
                ],
                [
                  1,
                  5,
                  styleFactorBenchmarkStats && validateNumber(styleFactorBenchmarkStats.liquidity),
                ],
                [
                  1,
                  6,
                  styleFactorBenchmarkStats && validateNumber(styleFactorBenchmarkStats.yield),
                ],
              ],
              dataLabels: {
                enabled: true,
                color: '#FFF',
                style: {
                  textOutline: '0px',
                  backgroundColor: '#2962FF',
                },
              },
            },
          ]
        : [
            {
              name: 'Sales per employee',
              borderWidth: 1,
              data: [
                [0, 0, 0],
                [0, 1, 0],
                [0, 2, 0],
                [0, 3, 0],
                [0, 4, 0],
                [0, 5, 0],
                [0, 6, 0],
                [1, 0, 0],
                [1, 1, 0],
                [1, 2, 0],
                [1, 3, 0],
                [1, 4, 0],
                [1, 5, 0],
                [1, 6, 0],
              ],
              dataLabels: {
                enabled: true,
                color: '#FFF',
                style: {
                  textOutline: '0px',
                  backgroundColor: '#2962FF',
                },
              },
            },
          ],
  };

  useEffect(() => {
    if (apiData && apiData['Index Stats'] && styleFactorDate) {
      const data = _.get(apiData, ['Index Stats', 'data', '57', 'value'], false);
      if (data) {
        const res = data.find(d => {
          return d.timestamp.includes(styleFactorDate);
        });
        const value = _.get(res, 'value', []);

        setStyleFactorStats(value);
      }
    }
  }, [apiData['Index Stats'], styleFactorDate]);

  useEffect(() => {
    if (apiData && apiData['Benchmark Card Stats'] && styleFactorDate) {
      const data = _.get(apiData, ['Benchmark Card Stats', 'data', '57', 'value'], false);
      if (data) {
        const res = data.find(d => {
          return d.timestamp.includes(styleFactorDate);
        });
        const value = _.get(res, 'value', []);
        setStyleFactorBenchmarkStats(value);
      }
    }
  }, [apiData['Benchmark Card Stats'], styleFactorDate]);

  return (
    <BitaCharts
      key="StyleFactorTiltsHeatmap"
      options={heatmapOptions}
      theme={{}}
      containerProps={{ style: { maxHeight: '90%', width: '90%' } }}
    />
  );
};

export default StyleFactor;
