import React from 'react';
import styles from '../CharacteristicsAnalyzer.module.scss';

const percentageValue = value => {
  return value ? `${(parseFloat(value) * 100).toFixed(2)}%` : 'N/A';
};

const weightingConstants = [
  {
    title: 'Median',
    key: 'median_weight',
  },
  {
    title: 'Max',
    key: 'max_weight',
  },
  {
    title: 'Min',
    key: 'min_weight',
  },
];

const IndexCharacteristicsConstituents = ({ pcfInfo }) => {
  return (
    <div className={styles.listContainer}>
      <ul className={styles.listContent}>
        <li className={styles.listTitleContainer}>
          <span className={styles.listTitle}>Nº of Constituents</span>
        </li>
        <li className={styles.listValue}>
          <span className={styles.oneValue}>{pcfInfo.number_of_constituents || 'N/A'}</span>
        </li>
        <li className={styles.listTitleContainer}>
          <span className={styles.listTitle}>Constituents Weighting Profile %</span>
        </li>
        {weightingConstants.map(weight => (
          <li className={styles.listValue}>
            <span className={styles.rightText}>{weight.title}</span>
            <span className={styles.leftText}>{percentageValue(pcfInfo[weight.key])}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default IndexCharacteristicsConstituents;
