/* eslint-disable radix */
import axios from '../Axios';
import { TYPE_CONSTANTS } from './type-constants';
import { URL_CONSTANTS } from './url-constants';

const getDataComponents = [
  {
    key: 'ESG Topics',
    title: 'ESG Provider',
    type: TYPE_CONSTANTS.DROPDOWN,
    getUrlData(id, idUniverse, token) {
      let url;
      if (idUniverse) {
        // const url = URL_CONSTANTS.ESG_TOPICS_GET_URL.replace('{{ID}}', `${id}`);
        url = URL_CONSTANTS.ESG_TOPICS_GET_URL.replace('{{ID}}', Number(id) + 6).replace(
          '{{UNIVERSE}}',
          `${idUniverse}`,
        ); // TODO Provider hardcodeado
      } else {
        // const url = URL_CONSTANTS.ESG_TOPICS_GET_URL.replace('{{ID}}', `${id}`);
        url = URL_CONSTANTS.ESG_TOPICS_GET_URL_WHITE_CANVAS.replace('{{ID}}', Number(id) + 6); // TODO Provider hardcodeado
      }
      return axios
        .get(url, { headers: { Authorization: `Bearer ${token}` } })
        .then(res => ({ key: this.title, data: res.data.data }));
    },
    formatData(data) {
      return data.map(entry => ({ id: entry.id, option: entry.name, value: entry.count }));
    },
  },
  {
    key: 'Risk Model Bita',
    title: 'Risk Model Bita',
    type: TYPE_CONSTANTS.DROPDOWN,
    getUrlData() {
      const url = URL_CONSTANTS.RISKMODELS_GET_URL;
      return axios.get(url).then(res => ({ key: this.title, data: res.data.data }));
    },
    formatData(data) {
      return data.map(entry => ({ id: entry.name, option: entry.name, value: entry.name }));
    },
  },
  {
    key: 'Benchmark',
    title: 'Benchmark',
    type: TYPE_CONSTANTS.DROPDOWN,
    getUrlData(token) {
      return axios
        .get(URL_CONSTANTS.INSTRUMENTSELECTION_GET_URL.etfs_list, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then(resETF => ({ key: this.title, data: resETF.data.data }));
    },
    formatData(data) {
      return data.map(entry => ({
        id: entry.id,
        name: entry.name,
        value: entry.name,
        index_type: entry.type ? 'ETF' : 'BENCHMARK',
        type: entry.type,
        author: entry.autor,
      }));
    },
  },
  {
    key: 'Themes Dropdown',
    title: 'Pureness',
    type: TYPE_CONSTANTS.SELECTIONLIST,
    getUrlData(id, idUniverse, token) {
      const url = type => {
        let apiUrl = URL_CONSTANTS.THEMES_GET_URL;
        if (!idUniverse) {
          apiUrl = apiUrl.replace('universe={{UNIVERSE}}&', '');
        }
        apiUrl = `${apiUrl}&provider=iClima`;
        switch (type) {
          case '0':
            return apiUrl.replace('{{UNIVERSE}}', `${idUniverse}`).replace('{{TYPE}}', `All`);
          case '1':
            return apiUrl.replace('{{UNIVERSE}}', `${idUniverse}`).replace('{{TYPE}}', `All`);
          case '2':
            return apiUrl.replace('{{UNIVERSE}}', `${idUniverse}`).replace('{{TYPE}}', `All`);
          default:
            return '';
        }
      };
      return axios
        .get(url(id), {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then(res => {
          let dataThemes = [];
          res.data.data.forEach(element => {
            dataThemes = [...dataThemes, ...element.data];
          });
          return {
            key: this.title,
            data: dataThemes || [],
          };
        });
    },
    formatData(data) {
      const result = {};
      data.forEach(item => {
        const { category } = item;
        if (!result[category ?? '']) {
          result[category ?? ''] = [];
        }
        result[category ?? ''].push({
          id: item.id,
          option: item.name,
          value: item.count,
        });
      });
      const finalResult = {};
      // eslint-disable-next-line no-restricted-syntax, guard-for-in
      for (const category in result) {
        finalResult[category] = result[category];
      }
      return finalResult;
    },
  },
  {
    key: 'Exchanges SearchBar',
    title: 'List of Exchanges',
    type: TYPE_CONSTANTS.SEARCHBAR,
    getUrlData() {
      const url = URL_CONSTANTS.EXCHANGES_GET_URL;
      return axios.get(url).then(res => ({
        key: this.title,
        data: res.data.data.map(entry => ({ Name: entry.name, MIC: entry.mic })),
      }));
    },
    formatData(data) {
      return data.map(entry => ({ option: entry.name, value: entry.count }));
    },
  },
  {
    key: 'Sectors Dropdown',
    title: 'Sectors List',
    type: TYPE_CONSTANTS.DROPDOWN,
    getUrlData(id, idUniverse, token) {
      let url;
      // eslint-disable-next-line
      if (idUniverse) {
        url = URL_CONSTANTS.SECTORS_GET_URL.replace('{{ID}}', `${parseInt(id) + 1}`).replace(
          '{{UNIVERSE}}',
          `${idUniverse}`,
        );
      } else {
        url = URL_CONSTANTS.SECTORS_GET_URL_WHITE_CANVAS.replace('{{ID}}', `${parseInt(id) + 1}`);
      }
      return axios
        .get(url, { headers: { Authorization: `Bearer ${token}` } })
        .then(res => ({ key: this.title, data: res.data.data }));
    },
    formatData(data) {
      return Object.entries(data).map(([key, value]) => {
        if (Object.keys(value)[0] === 'components') {
          const arr = value.components.map(comp => ({
            option: comp.name,
            value: parseInt(comp.count, 10),
            id: comp.id,
          }));
          return {
            option: key,
            value: arr.reduce((obj, itm, idx) => ({ ...obj, [idx]: itm }), {}),
          };
        }
        return { option: key, value: value.count, id: value.id };
      });
    },
  },
  {
    key: 'Securities SearchBar',
    title: 'List of Instruments',
    type: TYPE_CONSTANTS.SEARCHBAR,
    getUrlData(id, cancelToken, value) {
      const url = id
        ? URL_CONSTANTS.SECURITIES_GET_URL.replace('{{UNIVERSE}}', `${id}`)
        : URL_CONSTANTS.SECURITIES_GET_URL.replace('?universe={{UNIVERSE}}', '');
      const data = { value };
      return axios
        .post(url, data, { cancelToken: cancelToken.token })
        .then(res => ({ key: this.title, data: res.data.data }));
    },
    formatData(data) {
      return data.map(entry => ({ option: entry.name, value: entry.count }));
    },
  },
  {
    key: 'Geography Dropdown',
    title: 'Classification',
    type: TYPE_CONSTANTS.DROPDOWN,
    getUrlData(option, idUniverse, token, groupBy, value, countries) {
      function url(opt) {
        if (idUniverse) {
          switch (opt) {
            case '0':
              return URL_CONSTANTS.GEOGRAPHY_GET_URL.primary_list.replace(
                '{{ID}}',
                `${idUniverse}`,
              );

            case '1': {
              return URL_CONSTANTS.GEOGRAPHY_GET_URL.country_list.replace(
                '{{ID}}',
                `${idUniverse}`,
              );
            }
            case '2': {
              return URL_CONSTANTS.GEOGRAPHY_GET_URL.country_headquarter.replace(
                '{{ID}}',
                `${idUniverse}`,
              );
            }
            case '3': {
              return URL_CONSTANTS.GEOGRAPHY_GET_URL.country_bita.replace(
                '{{ID}}',
                `${idUniverse}`,
              );
            }
            case '5': {
              return URL_CONSTANTS.GEOGRAPHY_GET_URL.country_bita_group_by_liquidity
                .replace('{{ID}}', `${idUniverse}`)
                .replace('{{LIQUIDITY}}', `${groupBy}`)
                .replace('{{COUNTRIES}}', `${countries}`)
                .replace('{{VALUE}}', `${value}`);
            }
            case '6': {
              return URL_CONSTANTS.GEOGRAPHY_GET_URL.country_bita_group_by_size
                .replace('{{ID}}', `${idUniverse}`)
                .replace('{{SIZE}}', `${groupBy}`)
                .replace('{{VALUE}}', `${value}`)
                .replace('{{COUNTRIES}}', `${countries}`);
            }
            case '7': {
              return URL_CONSTANTS.GEOGRAPHY_GET_URL.primary_list_group_by_liquidity
                .replace('{{ID}}', `${idUniverse}`)
                .replace('{{LIQUIDITY}}', `${groupBy}`)
                .replace('{{VALUE}}', `${value}`)
                .replace('{{COUNTRIES}}', `${countries}`);
            }
            case '8': {
              return URL_CONSTANTS.GEOGRAPHY_GET_URL.primary_list_group_by_size
                .replace('{{ID}}', `${idUniverse}`)
                .replace('{{SIZE}}', `${groupBy}`)
                .replace('{{VALUE}}', `${value}`)
                .replace('{{COUNTRIES}}', `${countries}`);
            }
            case '9': {
              return URL_CONSTANTS.GEOGRAPHY_GET_URL.country_list_group_by_liquidity
                .replace('{{ID}}', `${idUniverse}`)
                .replace('{{LIQUIDITY}}', `${groupBy}`)
                .replace('{{VALUE}}', `${value}`)
                .replace('{{COUNTRIES}}', `${countries}`);
            }
            case '10': {
              return URL_CONSTANTS.GEOGRAPHY_GET_URL.country_list_group_by_size
                .replace('{{ID}}', `${idUniverse}`)
                .replace('{{SIZE}}', `${groupBy}`)
                .replace('{{VALUE}}', `${value}`)
                .replace('{{COUNTRIES}}', `${countries}`);
            }
            case '11': {
              return URL_CONSTANTS.GEOGRAPHY_GET_URL.country_headquarter_group_by_liquidity
                .replace('{{ID}}', `${idUniverse}`)
                .replace('{{LIQUIDITY}}', `${groupBy}`)
                .replace('{{VALUE}}', `${value}`)
                .replace('{{COUNTRIES}}', `${countries}`);
            }
            case '12': {
              return URL_CONSTANTS.GEOGRAPHY_GET_URL.country_headquarter_group_by_size
                .replace('{{ID}}', `${idUniverse}`)
                .replace('{{SIZE}}', `${groupBy}`)
                .replace('{{VALUE}}', `${value}`)
                .replace('{{COUNTRIES}}', `${countries}`);
            }
            default:
              // Este codigo es sensible a los options de select
              return URL_CONSTANTS.GEOGRAPHY_GET_URL.exchanges_list.replace(
                '{{ID}}',
                `${idUniverse}`,
              );
          }
        } else {
          switch (opt) {
            case '0':
              return URL_CONSTANTS.GEOGRAPHY_GET_URL_WHITE_CANVAS.primary_list;
            case '1': {
              return URL_CONSTANTS.GEOGRAPHY_GET_URL_WHITE_CANVAS.country_list;
            }
            case '2': {
              return URL_CONSTANTS.GEOGRAPHY_GET_URL_WHITE_CANVAS.country_headquarter;
            }
            case '3': {
              return URL_CONSTANTS.GEOGRAPHY_GET_URL_WHITE_CANVAS.country_bita;
            }
            case '5': {
              return URL_CONSTANTS.GEOGRAPHY_GET_URL_WHITE_CANVAS.country_bita_group_by_liquidity
                .replace('{{LIQUIDITY}}', `${groupBy}`)
                .replace('{{COUNTRIES}}', `${countries}`)
                .replace('{{VALUE}}', `${value}`);
            }
            case '6': {
              return URL_CONSTANTS.GEOGRAPHY_GET_URL_WHITE_CANVAS.country_bita_group_by_size
                .replace('{{SIZE}}', `${groupBy}`)
                .replace('{{COUNTRIES}}', `${countries}`)
                .replace('{{VALUE}}', `${value}`);
            }
            case '7': {
              return URL_CONSTANTS.GEOGRAPHY_GET_URL_WHITE_CANVAS.primary_list_group_by_liquidity
                .replace('{{ID}}', `${idUniverse}`)
                .replace('{{LIQUIDITY}}', `${groupBy}`)
                .replace('{{VALUE}}', `${value}`)
                .replace('{{COUNTRIES}}', `${countries}`);
            }
            case '8': {
              return URL_CONSTANTS.GEOGRAPHY_GET_URL_WHITE_CANVAS.primary_list_group_by_size
                .replace('{{ID}}', `${idUniverse}`)
                .replace('{{SIZE}}', `${groupBy}`)
                .replace('{{VALUE}}', `${value}`)
                .replace('{{COUNTRIES}}', `${countries}`);
            }
            case '9': {
              return URL_CONSTANTS.GEOGRAPHY_GET_URL_WHITE_CANVAS.country_list_group_by_liquidity
                .replace('{{ID}}', `${idUniverse}`)
                .replace('{{LIQUIDITY}}', `${groupBy}`)
                .replace('{{VALUE}}', `${value}`)
                .replace('{{COUNTRIES}}', `${countries}`);
            }
            case '10': {
              return URL_CONSTANTS.GEOGRAPHY_GET_URL_WHITE_CANVAS.country_list_group_by_size
                .replace('{{ID}}', `${idUniverse}`)
                .replace('{{SIZE}}', `${groupBy}`)
                .replace('{{VALUE}}', `${value}`)
                .replace('{{COUNTRIES}}', `${countries}`);
            }
            case '11': {
              return URL_CONSTANTS.GEOGRAPHY_GET_URL_WHITE_CANVAS.country_headquarter_group_by_liquidity
                .replace('{{ID}}', `${idUniverse}`)
                .replace('{{LIQUIDITY}}', `${groupBy}`)
                .replace('{{VALUE}}', `${value}`)
                .replace('{{COUNTRIES}}', `${countries}`);
            }
            case '12': {
              return URL_CONSTANTS.GEOGRAPHY_GET_URL_WHITE_CANVAS.country_headquarter_group_by_size
                .replace('{{ID}}', `${idUniverse}`)
                .replace('{{SIZE}}', `${groupBy}`)
                .replace('{{VALUE}}', `${value}`)
                .replace('{{COUNTRIES}}', `${countries}`);
            }
            default:
              // Este codigo es sensible a los options de select
              return URL_CONSTANTS.GEOGRAPHY_GET_URL_WHITE_CANVAS.exchanges_list;
          }
        }
      }
      return axios
        .get(url(option), { headers: { Authorization: `Bearer ${token}` } })
        .then(res => ({ key: this.title, data: res.data.data }));
    },
    formatData(data) {
      // eslint-disable-next-line
      return data.map(entry => {
        return {
          option: entry.name,
          value: entry.count,
          classification: entry.clasification,
          id: entry.id,
        };
      });
    },
  },
  {
    key: 'UserManager',
    title: 'User management',
    type: TYPE_CONSTANTS.USERMANAGER,
    getUrlData(token) {
      const url = URL_CONSTANTS.USERMANAGER_GET_URL;
      return axios
        .get(url, { headers: { Authorization: `Bearer ${token}` } })
        .then(res => ({ key: this.title, data: res.data }))
        .catch(res => ({ message: res.message, data: res.data }));
    },
    formatData(data) {
      return data.map(entry => ({ option: entry.name, value: entry.count }));
    },
  },
  {
    key: 'list_of_instruments_structured_products',
    title: 'List of Instruments',
    type: TYPE_CONSTANTS.SEARCHBAR,
    getUrlData(token) {
      return axios
        .get(URL_CONSTANTS.INSTRUMENTSELECTION_GET_URL.etfs_list, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then(resETF => {
          const dataETF = resETF.data.data.map(row => {
            return {
              'Instrument name': row.name,
              'Instrument type': row.type === 'Equity' ? 'ETF' : 'BENCHMARK',
              id: row.id,
            };
          });
          return axios
            .get(URL_CONSTANTS.INSTRUMENTSELECTION_GET_URL.indexes_list, {
              headers: { Authorization: `Bearer ${token}` },
            })
            .then(resIndexes => {
              const dataIndexes = resIndexes.data.data.map(row => {
                return {
                  'Instrument name': row.name,
                  'Instrument type': row.index_type
                    .replace('regular', 'INDEX')
                    .replace('structured_index', 'STRUCTURED PRODUCT')
                    .replace('optimized_index', 'OPTIMIZED PRODUCT'),
                  id: row.id,
                };
              });
              return { key: this.title, data: [...dataETF, ...dataIndexes] };
            })
            .catch(res => ({ message: res.message, data: res.data }));
        })
        .catch(res => ({ message: res.message, data: res.data }));
    },
    formatData(data) {
      return data.map(entry => ({ option: entry.name, value: entry.count }));
    },
  },
  {
    key: 'Create User',
    title: 'Confirm',
    type: TYPE_CONSTANTS.BUTTON,
    getUrlData(data, token) {
      const url = URL_CONSTANTS.CREATE_USER_GET_URL;
      const user = {
        email: data['Business Email'],
        user: data['Name & Surname'],
        password: '909090',
        charge: '5da7617ccd0db3250ca7d01c',
        type_user: 'READ',
      };
      return axios.post(url, user, { headers: { Authorization: `Bearer ${token}` } });
    },
  },
  {
    key: 'UniverseFilter',
    title: 'UniverseFilter',
    type: TYPE_CONSTANTS.UNIVERSEFILTER,
    getUrlData(data, token) {
      const url = URL_CONSTANTS.UNIVERSE_FILTER_GET_URL;
      return axios.post(url, data, { headers: { Authorization: `Bearer ${token}` } }).then(res => {
        return res.data;
      });
    },
  },
  {
    key: 'UniverseAllFilters',
    title: 'UniverseAllFilters',
    type: TYPE_CONSTANTS.UNIVERSEFILTER,
    getUrlData(data, token, cancelToken) {
      const url = URL_CONSTANTS.UNIVERSE_ALL_FILTERS_GET_URL;
      return axios
        .post(url, data, {
          headers: { Authorization: `Bearer ${token}` },
          cancelToken: cancelToken.token,
        })
        .then(res => {
          return res.data;
        });
    },
  },
  {
    key: 'saveUniverse',
    title: 'saveUniverse',
    type: TYPE_CONSTANTS.UNIVERSEFILTER,
    getUrlData(data, token) {
      const url = URL_CONSTANTS.SAVE_UNIVERSE_URL;
      return axios.post(url, data, { headers: { Authorization: `Bearer ${token}` } });
    },
  },

  {
    key: 'saveDataField',
    title: 'saveDataField',
    type: TYPE_CONSTANTS.DATAFIELD,
    getUrlData(data, token) {
      const url = URL_CONSTANTS.SAVE_DATE_FIELD;
      return axios.post(url, data, { headers: { Authorization: `Bearer ${token}` } });
    },
  },
  {
    key: 'Constituents_tab',
    title: 'CONSTITUENTS',
    type: TYPE_CONSTANTS.PREVIEWVALIDATE,
    getUrlData(token, data) {
      const url = URL_CONSTANTS.CONSTITUENTS_TAB_URL;
      return axios.post(url, data, { headers: { Authorization: `Bearer ${token}` } }).then(res => {
        return res.data;
      });
    },
    formatData(data) {
      return data.map(entry => ({
        Name: entry?.security_name ?? '',
        FIGI: entry.figi,
        ISIN: entry.isin,
        Ticker: entry.ticker_symbol ? entry.ticker_symbol : ' ',
        'Free Float Marketcap': `${parseFloat(entry.free_float_marketcap, 10).toFixed(2)} M`,
        // 'Free Float Marketcap in USD Mn.': Number(
        //   parseFloat(entry.free_float_marketcap, 10).toFixed(2),
        // ),
        'Market Cap': `${parseFloat(entry.marketcap, 10).toFixed(2)} M`,
        FigiName: entry.figi_name,
      }));
    },
  },
  {
    key: 'runBacktest',
    title: 'runBacktest',
    type: 'runBacktest',
    getUrlData(data, token) {
      const url = URL_CONSTANTS.RUN_BACKTEST_URL;
      return axios.post(url, data, { headers: { Authorization: `Bearer ${token}` } });
    },
  },
  {
    key: 'runOptimizedBacktest',
    title: 'runOptimizedBacktest',
    type: 'runOptimizedBacktest',
    getUrlData(data, token) {
      const url = URL_CONSTANTS.RUN_OPTIMIZED_BACKTEST_URL;
      return axios.post(url, data, { headers: { Authorization: `Bearer ${token}` } });
    },
  },
  {
    key: 'runStructureIndexBacktest',
    title: 'runStructureIndexBacktest',
    type: 'runStructureIndexBacktest',
    getUrlData(data, token) {
      const url = URL_CONSTANTS.RUN_STRUCTURED_INDEX_BACKTEST_URL;
      return axios.post(url, data, { headers: { Authorization: `Bearer ${token}` } });
    },
  },
  {
    key: 'runBasketIndexBacktest',
    title: 'runBasketIndexBacktest',
    type: 'runBasketIndexBacktest',
    getUrlData(data, token) {
      const url = URL_CONSTANTS.RUN_BASKET_INDEX_URL;
      return axios.post(url, data, { headers: { Authorization: `Bearer ${token}` } });
    },
  },
  {
    key: 'runFactsheet',
    title: 'runFactsheet',
    type: 'runFactsheet',
    getUrlData(data, token) {
      const url = URL_CONSTANTS.RUN_FACTSHEET;
      return axios.post(url, data, { headers: { Authorization: `Bearer ${token}` } });
    },
  },
  {
    key: 'saveFactsheet',
    title: 'saveFactsheet',
    type: 'saveFactsheet',
    getUrlData(data, token) {
      const url = URL_CONSTANTS.SAVE_FACTSHEET;
      return axios.post(url, data, { headers: { Authorization: `Bearer ${token}` } });
    },
  },
  {
    key: 'runRebalancingIndexBacktest',
    title: 'runRebalancingIndexBacktest',
    type: 'runRebalancingIndexBacktest',
    getUrlData(data, token) {
      const url = URL_CONSTANTS.RUN_REBALANCING_BACKTEST_URL;
      return axios.post(url, data, { headers: { Authorization: `Bearer ${token}` } });
    },
  },
  {
    key: 'universeSelected',
    title: 'universeSelected',
    type: 'universeSelected',
    getUrlData(id, token) {
      const url = URL_CONSTANTS.UNIVERSE_SELECTED_URL.replace('{{UNIVERSE}}', `${id}`);
      return axios.get(url, { headers: { Authorization: `Bearer ${token}` } }).then(res => {
        return res.data;
      });
    },
  },
  {
    key: 'universeSelectedFirstRevision',
    title: 'universeSelectedFirstRevision',
    type: 'universeSelectedFirstRevision',
    getUrlData(id, token) {
      const url = URL_CONSTANTS.UNIVERSE_SELECTED_FIRST_REVISION_URL.replace(
        '{{UNIVERSE}}',
        `${id}`,
      );
      return axios.get(url, { headers: { Authorization: `Bearer ${token}` } }).then(res => {
        return res.data;
      });
    },
  },
  {
    key: 'universeRevisionDate',
    title: 'universeRevisionDate',
    type: 'universeRevisionDate',
    getUrlData(data, token) {
      const url = URL_CONSTANTS.UNIVERSE_REVISION_DATE;
      return axios.post(url, data, { headers: { Authorization: `Bearer ${token}` } }).then(res => {
        return res.data;
      });
    },
  },
  {
    key: 'Security Type',
    title: 'Security SelectionList',
    type: TYPE_CONSTANTS.SELECTIONLIST,
    getUrlData(id, token) {
      const url = id
        ? URL_CONSTANTS.SECURITY_TYPE_GET_URL.replace('{{UNIVERSE}}', `${id}`)
        : URL_CONSTANTS.SECURITY_TYPE_GET_URL.replace('?universe={{UNIVERSE}}', '');
      return axios.get(url, { headers: { Authorization: `Bearer ${token}` } }).then(res => {
        return res.data;
      });
    },
    formatData(data) {
      return data.map(entry => ({
        id: entry.security_type,
        option: entry.security_type,
        value: entry.count,
      }));
    },
  },
];

// this connect the response of some request to redux for display loading buttons, so when you click
// a button and must show some loading indicator while makes the http request this button must check the
// status in api-data-reducer.statusRequest
function enableLoadingDisplayForRequest(axiosCall) {}

export { getDataComponents };
