import React from 'react';
import styles from '../PerformanceAndRisk.module.scss';

const Table = props => {
  return (
    <table className={styles.generatedTable} style={{ fontSize: '12px' }}>
      <thead className={styles.generatedTableHeader}>
        <tr>
          <th>{props?.text}</th>
          {props.years &&
            props.years.map((year, i) => (
              <th className={i === 0 ? styles.restTableHeaders : ''} style={{ textAlign: 'left' }}>
                {year
                  .replace('(', '')
                  .replace(')', '')
                  .replace(',', '-')}
              </th>
            ))}
        </tr>
      </thead>
      <tbody>
        {props.data &&
          Object.values(props.data).length > 0 &&
          Object.values(props.data).map(
            item =>
              item.data.length > 0 && (
                <tr className={styles.generatedTableHeader}>
                  <td style={{ fontWeight: 'bold', minWidth: '130px' }}>{item.name}</td>
                  {props.years.map((year, i) => (
                    <td className={i === 0 ? styles.restTableHeaders : ''}>
                      {item.data.find(value => value.timestamp.includes(year))?.value !== undefined
                        ? props.type === 'percentage'
                          ? `${Number(
                              item.data.find(value => value.timestamp.includes(year))?.value,
                            )?.toFixed(2)}%`
                          : Number(
                              item.data.find(value => value.timestamp.includes(year))?.value,
                            )?.toFixed(2)
                        : 'N/A'}
                    </td>
                  ))}
                </tr>
              ),
          )}
      </tbody>
    </table>
  );
};
export default Table;
