const DATA_LIST_DROPDOWN = [
  { value: 'Long only Basket', id: 'basket', disabled: false },
  // { value: 'Bottom-Up Basket', id: 'global', isDisabled: true },
  { value: 'Long/Short', id: 'long/short', disabled: true },
];

const BASKET_BUTTON_BULK = {
  'Long only Basket': [
    {
      type: 'Button',
      label: 'Submission with Weights',
      url: 'divisor_basket_input_with_open_weight',
    },
    {
      type: 'Button',
      label: 'Submission with Shares',
      url: 'divisor_basket_input_with_shares_and_without_custom_prices',
    },
    {
      type: 'Button',
      label: 'Submission with Notionals',
      url: 'divisor_basket_input_with_divisor_and_notional',
    },
  ],
  'Bottom-Up Basket': [{ type: 'Button', label: 'Global Template', url: 'global_input' }],
  'Long/Short': [{ type: 'Button', label: 'Long/short Template', url: 'long_short_input' }],
};

const basketButton = {
  'Long only Basket': [
    {
      type: 'Button',
      label: 'Submission with Weights',
      url: 'divisor_basket_input_with_open_weight',
    },
    {
      type: 'Button',
      label: 'Submission with Shares',
      url: 'divisor_basket_input_with_shares_and_without_custom_prices',
    },
    {
      type: 'Button',
      label: 'Submission with Notionals',
      url: 'divisor_basket_input_with_divisor_and_notional',
    },
  ],
  'Bottom-Up Basket': [{ type: 'Button', label: 'Global Template', url: 'global_input' }],
  'Long/Short': [{ type: 'Button', label: 'Long/short Template', url: 'long_short_input' }],
};

const CURRENCIES_ARRAY = [
  'AUD',
  'BRL',
  'CAD',
  'CHF',
  'CLP',
  'CNH',
  'CNY',
  'COP',
  'DKK',
  'EUR',
  'GBP',
  'HKD',
  'ILA',
  'ILS',
  'JPY',
  'KRW',
  'MXN',
  'NGN',
  'NOK',
  'PLN',
  'RUB',
  'SEK',
  'SGD',
  'TRY',
  'TWD',
  'USD',
  'XAG',
  'XAL',
  'XAU',
  'XCU',
  'XNI',
  'XPB',
  'XPD',
  'XPT',
  'XZN',
];

const BUSINESS_DAYS = {
  0: 'Monday',
  1: 'Tuesday',
  2: 'Wednesday',
  3: 'Thursday',
  4: 'Friday',
  5: 'Saturday',
  6: 'Sunday',
};

const DATA_APPLICATION_POINT = [
  {
    value: 'At market close',
    id: 'AT_MARKET_CLOSE',
  },
  {
    value: 'At market open',
    id: 'AT_MARKET_OPEN',
  },
];

const DATA_CASH_DISTRIBUTION = [
  {
    value: 'Reinvest distributions across basket',
    id: 'DISTRIBUTION_ACROSS_BASKET',
  },
  {
    value: 'Reinvest distributions on paying security',
    id: 'DISTRIBUTION_ON_PAYING_SECURITY',
  },
  { value: 'Add distributions to cash', id: 'ADD_DISTRIBUTION_TO_CASH' },
];

export {
  DATA_LIST_DROPDOWN,
  BASKET_BUTTON_BULK,
  CURRENCIES_ARRAY,
  BUSINESS_DAYS,
  DATA_APPLICATION_POINT,
  DATA_CASH_DISTRIBUTION,
};
