import { applyMiddleware, createStore, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import authReducer from '../reducers/auth-reducer';
// import { sampleMiddleware } from '../../sample-middleware';
import indexOperationsReducer from '../reducers/index-operations-reducer';
import universeOperationsReducer from '../reducers/universe-operations-reducer';
import sidebarReducer from '../reducers/sidebar-reducer';
import summaryReducer from '../reducers/summary-reducer';
import backtestReducer from '../reducers/backtest-reducer/backtest-reducer';
import commonReducer from '../reducers/common-reducer';
import apiDataReducer from '../reducers/api-data-reducer';
import universeReducer from '../reducers/universe-reducer';
import optimizedIndexReducer from '../reducers/optimized-index-reducer';
import moduleSelectionReducer from '../reducers/module-selection-reducer';
import moduleUpload from '../reducers/module-upload-reducer';
import chartReducer from '../reducers/chart-reducer';
import websocketReducer from '../reducers/websocket-reducer';
import downloadReducer from '../reducers/download-reducer';

const store = createStore(
  combineReducers({
    auth: authReducer,
    websocket: websocketReducer,
    indexOperations: indexOperationsReducer,
    universeBuilderOperations: universeOperationsReducer,
    summary: summaryReducer,
    common: commonReducer,
    universeSelected: universeReducer,
    optimizedIndex: optimizedIndexReducer,
    moduleSelection: moduleSelectionReducer,
    apiData: apiDataReducer,
    backtest: backtestReducer,
    sidebar: sidebarReducer,
    upload: moduleUpload,
    chart: chartReducer,
    download_center: downloadReducer,
  }),
  composeWithDevTools(applyMiddleware(thunk /* sampleMiddleware */)),
);
export default store;
