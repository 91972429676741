import React, { useState, useLayoutEffect } from 'react';
import styles from './AnalyzerComponent.module.scss';
import BitaButton from '../../bitaComponents/BitaButton/BitaButton';
/**
 * Casos de paginacion
 * 1) 1 2 3 4 5 ... total
 * 2) 1 ... c -1, c, c+1 ... total
 * 3) 1 ... t4 t3 t2 t1 total
 * El Caso 2 ocurre cuando se llega a 4.
 * Caso 3 ocurre cuando se llega a T3
 * Minimo valor para N_buttons es 7 sino hay que alterar el diseno
 */

const PaginationComponent = ({ totalPages, onClick }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [buttons, setButtons] = useState([]);
  const setPage = value => {
    setCurrentPage(value);
    onClick(value);
  };

  const n_buttons = 7;
  const isOdd = n_buttons % 2 === 1;
  const display_middleButton_left = Math.trunc(n_buttons / 4) + 1;
  const display_middleButton_right = isOdd
    ? Math.trunc(n_buttons / 4)
    : Math.trunc(n_buttons / 4) + 1;
  const displayLast = isOdd
    ? totalPages - display_middleButton_right
    : totalPages - display_middleButton_right;
  const displayFirst = isOdd ? Math.round(n_buttons / 2) : n_buttons / 2;
  const isCaseOne = current => {
    return current < displayFirst;
  };
  const isCaseTwo = current => {
    return current >= displayFirst && displayLast > current + display_middleButton_right;
  };
  const isCaseThree = current => {
    return current + display_middleButton_right >= displayLast;
  };

  useLayoutEffect(() => {
    const pagButtons = [];
    for (let i = 1; totalPages >= i; i += 1) {
      pagButtons.push(i);
    }
    setButtons(pagButtons);
  }, [totalPages]);

  return (
    <div className={styles.paginationNumbers}>
      <BitaButton
        width="90px"
        primaryWhite
        onClick={() => {
          if (currentPage - 1 > 0) setPage(currentPage - 1);
        }}
        style={{ opacity: currentPage === 1 ? 0.5 : 1 }}
        disabled={currentPage === 1}
      >
        Previous
      </BitaButton>

      {totalPages > n_buttons ? (
        <>
          {isCaseOne(currentPage) && (
            <>
              {buttons.slice(0, n_buttons - 2).map((comp, index) => (
                <div
                  key={index}
                  style={comp.index === currentPage ? { backgroundColor: '#2962ff' } : {}}
                >
                  <button
                    className={styles.paginationButtons}
                    style={currentPage === comp ? { background: '#2962ff' } : {}}
                    onClick={() => setPage(comp)}
                  >
                    {comp}
                  </button>
                </div>
              ))}
            </>
          )}
          {!isCaseOne(currentPage) && (
            <>
              <button
                className={styles.paginationButtons}
                style={currentPage === 1 ? { background: '#2962ff' } : {}}
                onClick={() => setPage(1)}
              >
                1
              </button>
              <button className={styles.paginationButtons}>...</button>
            </>
          )}
          {isCaseTwo(currentPage) &&
            buttons
              .slice(
                currentPage - display_middleButton_left,
                currentPage + display_middleButton_right,
              )
              .map((comp, index) => (
                <button
                  className={styles.paginationButtons}
                  style={currentPage === comp ? { background: '#2962ff' } : {}}
                  onClick={() => setPage(comp)}
                >
                  {comp}
                </button>
              ))}
          {isCaseThree(currentPage) && (
            <>
              {buttons.slice(totalPages - (n_buttons - 2), totalPages).map((comp, index) => (
                <div style={comp.index === currentPage ? { backgroundColor: '#2962ff' } : {}}>
                  <button
                    className={styles.paginationButtons}
                    style={currentPage === comp ? { background: '#2962ff' } : {}}
                    onClick={() => setPage(comp)}
                  >
                    {comp}
                  </button>
                </div>
              ))}
            </>
          )}

          {!isCaseThree(currentPage) && (
            <>
              <button className={styles.paginationButtons}>...</button>
              <button className={styles.paginationButtons} onClick={() => setPage(totalPages)}>
                {totalPages}
              </button>
            </>
          )}
        </>
      ) : (
        buttons.map((comp, index) => (
          <div>
            {' '}
            <button
              className={styles.paginationButtons}
              style={currentPage === comp ? { background: '#2962ff' } : {}}
              onClick={() => setPage(comp)}
            >
              {comp}
            </button>
          </div>
        ))
      )}

      <BitaButton
        width="90px"
        primaryWhite
        style={{ marginLeft: '4px', opacity: currentPage === totalPages ? 0.5 : 1 }}
        onClick={() => {
          if (currentPage + 1 <= totalPages) setPage(currentPage + 1);
        }}
        disabled={currentPage === totalPages}
      >
        Next
      </BitaButton>
    </div>
  );
};

export default PaginationComponent;
