/* eslint-disable import/no-cycle */
import React, { useState, forwardRef, useImperativeHandle } from 'react';
import ConfigYAML from '../../../config/ConfigYAML';
import WorkflowModal from '../../../pages/structures-product/WorkflowModal/WorkflowModal';
import './stylesBasketCalculation.css';

const ModalBasketCalculation = forwardRef((props, ref) => {
  const [initialWorkFlow, setInitialWorkflow] = useState(null);
  const [modalOpened, setModalOpened] = useState(false);
  const setWorkflow = slides => {
    setInitialWorkflow(slides);
  };

  useImperativeHandle(ref, () => ({
    runModal: () => {
      const structuredProducts = ConfigYAML.jsonPath('$.analyzer_basket_calculation');
      const sections = Object.values(structuredProducts.sections).map(s => {
        const section = { ...s };
        if (section.widgets) {
          section.widgets = Object.values(section.widgets);
        } else if (section.components) {
          section.components = Object.values(section.components);
        }
        return section;
      });
      setModalOpened(true);
      setWorkflow(sections.find(({ type }) => type === 'Slides'));
    },
  }));

  const closeModal = (value, stayAtWorkFlow) => {
    setModalOpened(value);
  };

  return (
    <div className="style-basket-calculation">
      {initialWorkFlow && modalOpened && (
        <WorkflowModal
          {...initialWorkFlow}
          isModalOpen={modalOpened}
          setModalState={closeModal}
          setComponent={setWorkflow}
          runBacktestUbication={props.ubication}
          backtestShowData={!!props.showData}
          disable_title={props.disable_title}
        />
      )}
    </div>
  );
});

export default ModalBasketCalculation;
