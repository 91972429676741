/*
this is a wrapper around the sideNav, you dont use the customized lib directly, instead use SideNavBar from
this file
 */

import React from 'react';
import PropTypes from 'prop-types';
import { SideNav } from 'react-sidenav';
import NavArrow from './lib/NavArrow';

const SideNavbar = React.memo(props => {
  const { selectedId } = props;
  return (
    <SideNav
      defaultSelectedPath={selectedId}
      childrenToggleMode="click"
      collapseAutomatically
      childrenToggleIndicator={NavArrow}
    >
      {props.children}
    </SideNav>
  );
});

SideNavbar.propTypes = {
  selectedId: PropTypes.string,
};

SideNavbar.defaultProps = {
  selectedId: '1',
};

export default SideNavbar;
