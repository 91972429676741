/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { toast } from 'react-toastify';
import axios from 'axios';
import styles from '../Summary.module.scss';
// import ConfigYAML from '../../../config/ConfigYAML';
import BitaButton from '../../../bitaComponents/BitaButton/BitaButton';
import SaveUniverse from './SaveUniverse';
import GraphComponent from './GraphComponent';
import ConstituentsComponent from './ConstituentsComponent';
import FiltersImpactComponent from './FiltersImpactComponent';
import { getDataComponents } from '../../../api-data-mapping/dataMap';
import { ReactComponent as IconsU } from '../../../static/img/Universe_icon.svg';
import { loaderDataObjectDecorator } from '../../../utils/loading_status_tools';
import { toastInterceptor } from '../../../utils/utils';
// Componente tabs

const TabsComponent = props => {
  // Controla en el estado del componente la pestaña activa
  const [activeTab, setActiveTab] = React.useState(0);
  const [constituents, setConstituents] = React.useState([]);
  const [constituentsgraph, setConstituentsgraph] = React.useState({});
  const [selectedDate, setSelectedDate] = React.useState();
  const [listColumnConstituents, setListColumnConstituents] = React.useState();
  const [options, setOptions] = React.useState([]);
  const [constituentsgraphfiltered, setConstituentsgraphFiltered] = React.useState({});
  const token = useSelector(state => state.auth.token);
  const [isLoading, setLoading] = React.useState(false);
  const filterGroups = useSelector(state => state.summary['Universe Builder']);
  const filterGroupState = useSelector(state => state.summary.universeBuilderOperations);
  const dispatch = useDispatch();
  const cancelToken = axios.CancelToken.source();
  const onSelectDate = value => {
    setLoading(true);
    setConstituents([]);
    setSelectedDate({ id: value?.id, value: value?.value });
    // Obtenemos los datos para la pestaña de CONSTITUENTS
    const dataComponent = getDataComponents.filter(
      v =>
        v.key === 'Constituents_tab' && v.type === 'PreviewValidate' && v.title === 'CONSTITUENTS',
    )[0];
    if (typeof dataComponent !== 'undefined') {
      const promise = loaderDataObjectDecorator(dataComponent, dispatch).getUrlData(token, {
        date: value?.value,
      });
      promise
        .then(res => {
          setConstituents(dataComponent.formatData(res.data));
          setLoading(false);
        })
        .catch(err => {
          setLoading(false);
        });
    }
  };

  React.useEffect(() => {
    if (filterGroups) {
      const summaryTransformed = filterGroups;
      const filterGroupsdata = Object.keys(filterGroups).map(filters => {
        const filtersData = {};
        Object.keys(summaryTransformed[filters]).forEach(filterKey => {
          if (
            summaryTransformed[filters][filterKey][filterKey] &&
            Array.isArray(summaryTransformed[filters][filterKey][filterKey])
          ) {
            const filterData = summaryTransformed[filters][filterKey][filterKey].map(row => {
              const fieldData = {};
              Object.keys(row).forEach(field => {
                if (row[field]?.value && (row[field]?.id || row[field]?.id === 0)) {
                  fieldData[field] =
                    typeof row[field].id === 'number' ? row[field].id : row[field].value;
                } else {
                  fieldData[field] = row[field];
                }
              });
              return fieldData;
            });
            filtersData[filterKey] = filterData;
          } else {
            const fieldData = {};
            Object.keys(summaryTransformed[filters][filterKey]).forEach(field => {
              if (
                summaryTransformed[filters][filterKey][field]?.value &&
                (summaryTransformed?.[filters]?.[filterKey]?.[field]?.id ||
                  summaryTransformed?.[filters]?.[filterKey]?.[field]?.id === 0)
              ) {
                fieldData[field] =
                  typeof summaryTransformed[filters][filterKey][field].id === 'number'
                    ? summaryTransformed[filters][filterKey][field].id
                    : summaryTransformed[filters][filterKey][field].value;
              } else if (Array.isArray(summaryTransformed[filters][filterKey][field])) {
                fieldData[field] = summaryTransformed[filters][filterKey][field].map(val =>
                  val.id || val.id === 0 ? val.id : val,
                );
              } else {
                fieldData[field] = summaryTransformed[filters][filterKey][field];
              }
            });
            filtersData[filterKey] = fieldData;
          }
        });
        // TODO ESG provider hardcoded
        if (filtersData['ESG Issues']) {
          const { provider } = filtersData['ESG Issues'];
          filtersData['ESG Issues'].provider = provider === 'Sustainalytics' ? 7 : 6;
        }
        if (filtersData['ESG Ratings']) {
          filtersData['ESG Ratings'].forEach((i, index) => {
            const ratingProvider = filtersData['ESG Ratings'][index].provider;
            switch (ratingProvider) {
              case 'Sustainalytics':
                filtersData['ESG Ratings'][index].provider = '7';
                break;
              case 'Bita':
                filtersData['ESG Ratings'][index].provider = '8';
                break;
              default:
                filtersData['ESG Ratings'][index].provider = '6';
                break;
            }
          });
        }

        if (filtersData.Liquidity) {
          filtersData.Liquidity.forEach((item, index) => {
            if (item.indicator === 152 && (item.operator === '>=' || item.operator === '<=')) {
              item.value = (item.value / 100).toString();
            }
          });
        }
        if (filtersData.Geography) {
          filtersData.Geography.forEach((item, index) => {
            item.selection = item.selection.map(itemSel => {
              return itemSel.id;
            });
            if (item.value) {
              item.value /= 100;
            }
          });
        }
        if (filtersData['Single Instrument']) {
          if (
            filtersData['Single Instrument'].operator_type === 'Add' &&
            filterGroupState?.universe?.['Universe Name'] === 'WHITE CANVAS' &&
            filterGroupState?.constituents > 0
          ) {
            filtersData['Single Instrument'].operator_type = 'Add-in';
          }
        }

        const filterGroup = {
          delete: false,
          filters: {
            ...filtersData,
          },
        };
        return filterGroup;
      });
      let name = '';
      let id = 0;
      if (filterGroupState) {
        name = filterGroupState.universe ? filterGroupState.universe.name : '';
        id = filterGroupState.universe && filterGroupState.universe.id;
      }
      const _filterType = Object.values(filterGroupsdata[0].filters).filter(
        e => e.operator_type || (e[0] ? e[0].operator_type : null),
      );
      const filterType =
        _filterType.length === 0
          ? false
          : typeof _filterType[_filterType.length - 1] === 'object'
          ? _filterType[_filterType.length - 1]
          : _filterType[_filterType.length - 1][0];

      if (filterType && !filterType.operator_type && filterType[0].operator_type) {
        filterType.operator_type = filterType[0].operator_type;
      }
      if (filterGroupsdata[0].filters['iClima Themes']) {
        filterGroupsdata[0].filters = {
          ...filterGroupsdata[0].filters,
          Themes: filterGroupsdata[0].filters['iClima Themes'],
        };

        delete filterGroupsdata[0].filters['iClima Themes'];
      }
      if (filterGroupsdata[0].filters.Themes) {
        filterGroupsdata[0].filters.Themes.exposure = (
          Number(filterGroupsdata[0].filters.Themes.exposure) / 100
        ).toString();
      }
      const dataFilter = {
        base_universe: name,
        base_universe_id: id,
        type: typeof name !== 'undefined' ? 'exclusion' : 'inclusion',
        filter_groups:
          filterGroupsdata.filter(item => Object.values(item.filters).length > 0).length > 0
            ? filterGroupsdata.filter(item => Object.values(item.filters).length > 0)
            : [{ delete: true, filters: {} }],
      };
      const dataComponent = getDataComponents.filter(
        v =>
          v.key === 'UniverseAllFilters' &&
          v.type === 'UniverseFilter' &&
          v.title === 'UniverseAllFilters',
      )[0];
      if (typeof dataComponent !== 'undefined') {
        const promise = loaderDataObjectDecorator(dataComponent, dispatch).getUrlData(
          dataFilter,
          token,
          cancelToken,
        );
        promise
          .then(res => {
            props.setDisabled(false);
            if (res.data && res.data[res.data.length - 1].filter_impact) {
              setConstituentsgraph(res.data[res.data.length - 1].filter_impact);
              setConstituentsgraphFiltered(res.data[res.data.length - 1].filter_impact);
              const _options = Object.keys(res.data[res.data.length - 1].filter_impact)
                .slice()
                .reverse()
                .map(value => ({
                  id: value,
                  value,
                }))
                .sort((a, b) => {
                  const currentDate = new Date(a.value);
                  const nextDate = new Date(b.value);
                  if (currentDate < nextDate) {
                    return 1;
                  }
                  if (currentDate > nextDate) {
                    return -1;
                  }
                  return 0;
                });
              setOptions(_options);
              onSelectDate(_options && _options[0]);
            }
          })
          .catch(e => {
            if (e?.response?.status === 502) {
              toast.danger(
                'The filter took too long. Please try again or change the selected filters.',
              );
              props.toggleModal(prev => !prev);
            } else if (e.message !== 'user_cancel') {
              toastInterceptor({
                message:
                  'Timeout error. The filtering process was too heavy to be processed under 5 minutes.',
              });
              props.toggleModal(prev => !prev);
            }
          });
      }
    }
    // eslint-disable-next-line
  }, []);
  const close = () => {
    cancelToken.cancel('user_cancel');
    props.toggleModal(prev => !prev);
  };

  const filterYears = year => {
    const dates = Object.keys(constituentsgraph)
      .slice()
      .reverse();
    const minYear = year === -1 ? moment().year() : moment(dates && dates[0]).year() - year;
    const filteredData = {};
    Object.keys(constituentsgraph).forEach(constituent => {
      if (moment(constituent).year() >= minYear) {
        filteredData[constituent] = constituentsgraph[constituent];
      }
    });
    setConstituentsgraphFiltered(filteredData);
  };
  const getActiveTab = () => {
    switch (activeTab) {
      case 0: {
        return (
          <FiltersImpactComponent
            universe={filterGroupState ? filterGroupState.universe : {}}
            firstImpact={filterGroupState ? filterGroupState.Filters : []}
          />
        );
      }
      case 1: {
        return (
          <ConstituentsComponent
            options={options}
            selectedDate={selectedDate}
            onSelectDate={onSelectDate}
            isLoading={isLoading}
            constituents={constituents}
          />
        );
      }
      case 2: {
        return (
          <GraphComponent constituentsgraph={constituentsgraphfiltered} filterYears={filterYears} />
        );
      }
      default:
        return null;
    }
  };
  return (
    <div className={styles.tabsComponentContainer}>
      <div className="close" onClick={close}>
        &times;
      </div>
      <div className={styles.previewModalTitleContainer}>
        <div className={styles.previewModalTitle}>
          {' '}
          <IconsU /> {'Universe Completed'.toUpperCase()}
        </div>
        {/* Renderizado de pestañas en base a datos recibidos por la prop data */}
        <div className={styles.tabsContainer}>
          {props.data.map((tabTitle, index) => (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events
            <div
              className={`${styles.tabTitleButton} ${
                index === activeTab ? styles.tabTitleButtonActive : styles.tabTitleButtonInactive
              }`}
              onClick={() => setActiveTab(index)}
            >
              {tabTitle.title}
            </div>
          ))}
        </div>
        <div className={styles.dividerModal} />
      </div>
      {/* Renderizado del contenido de la tab, recibido por la propiedad content de la prop data */}
      <div className={styles.tabContentContainer}>{getActiveTab()}</div>
    </div>
  );
};

// Datos para generar las pestañas y contenidos de la misma, en la propiedad content iria el componente a renderizar como contenido

const data = [
  { title: 'FILTERS IMPACT' },
  { title: 'TOTAL CONSTITUENTS' },
  { title: 'CONSTITUENTS EVOLUTION' },
];

// Componente a incluir como hijo en el modal
const PreviewValidateModal = props => {
  const [UniverseSavepen, setUniverseSavepen] = React.useState(false);
  const [isDisabled, setDisabled] = React.useState(true);
  const buttonValidateRef = React.useRef('buttonValidateRef');

  //  const universebuilderModalsave = ConfigYAML.jsonPath('$.universe_builder').sections[2];
  const close = () => {
    setUniverseSavepen(!UniverseSavepen);
    props.toggleModal(prev => !prev);
  };

  const closeSaveUniverse = () => {
    setUniverseSavepen(!UniverseSavepen);
  };

  React.useEffect(() => {
    window.addEventListener('keydown', e => {
      if (e.key === 'Enter' && !isDisabled && buttonValidateRef.current) {
        buttonValidateRef.current.focus();
      }
    });
  }, [isDisabled]);

  return (
    <div className={`${styles.previewModalContainer} previewModalContainer`}>
      <div className={styles.previewModalChartContainer}>
        <TabsComponent
          data={data}
          universeSelected={props.universeSelected}
          toggleModal={props.toggleModal}
          setDisabled={setDisabled}
        />
      </div>
      <div className={styles.previewModalButtonContainer}>
        <BitaButton
          onClick={() => {
            if (props.selectUniverse) {
              props.selectUniverse(props.universeSelected);
            }
            setUniverseSavepen(true);
          }}
          primary
          width="229px"
          height="32px"
          textLoading="Filtering Universe"
          loading={isDisabled}
          autofocusfixed
          disabled={isDisabled}
          style={{ opacity: '1', position: 'static', zIndex: '9999999' }}
          Ref={buttonValidateRef}
        >
          Validate &amp; Save Universe
        </BitaButton>
      </div>
      <SaveUniverse
        viewModal={UniverseSavepen}
        closeModalPreview={close}
        closeSaveUniverse={closeSaveUniverse}
      />
    </div>
  );
};

export default PreviewValidateModal;
