import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import 'simplebar'; // or "import SimpleBar from 'simplebar';" if you want to use it manually.
import 'simplebar/dist/simplebar.css';
import styles from './Layout.module.scss';
import NotFound from '../../pages/NotFound';
import ProjectDashboard from '../../pages/dashboard/ProjectDashboard';
import UniverseBuilderModal from '../../pages/universe-builder/UniverseBuilderModal';
import IndexBaseUniverse from '../../pages/index-builders/IndexBaseUniverse';
// import AdditionalRules from '../../pages/index-builders/parametrization/AdditionalRules';
// import FastExitEntry from '../../pages/index-builders/parametrization/FastExitEntry';
// import GeneralRules from '../../pages/index-builders/parametrization/GeneralRules';
// import RebalancingReconstitution from '../../pages/index-builders/parametrization/RebalancingReconstitution';
import OptimizedIndex from '../../pages/optimized-index/OptimizedIndex';
import SpecifyConstraints from '../../pages/optimized-index/SpecifyConstraints';
import UniverseIndex from '../../pages/optimized-index/UniverseIndex';
import StructuresProduct from '../../pages/structures-product/StructuresProduct';
import PortfolioBasketCalculatio from '../../pages/portfolio-bastek-calculation/PortfolioBasketCalculation';
import LiveCalculatioBasket from '../../pages/live-calculation-basket/LiveCalculationBasket';
import FactsheetBuilder from '../../pages/factsheet-builder/FactsheetBuilder';
import RebalancingReconstitutionPage from '../../pages/rebalancing-reconstitution/RebalancingReconstitutionPage';
import IndexAnalyzer from '../../pages/index-analyzer/IndexAnalyzer';
import Performance from '../../pages/index-analyzer/Performance';
import Characteristics from '../../pages/index-analyzer/Characteristics';
import RiskAttribution from '../../pages/index-analyzer/RiskAttribution';
import Ratings from '../../pages/index-analyzer/Ratings';
import Controversy from '../../pages/index-analyzer/Controversy';
import Waste from '../../pages/index-analyzer/Waste';
import Emission from '../../pages/index-analyzer/Emission';
import ExpertChat from '../../pages/user-profile/ExpertChat';
import AccountProfile from '../../pages/user-profile/AccountProfile';
import Parameters from '../../pages/user-profile/Parameters';
import ProtectedRoute from '../Router/ProtectedRoute';
import IndexBuilderGenerator from '../../pages/index-builders/IndexBuilderGenerator';
import Settings from '../../pages/settings/Settings';
import Tutorials from '../../pages/tutorials/Tutorials';
import CustomerService from '../../pages/customer-service/Customer-service';
import RunBacktestModal from '../../pages/runBacktestModal';
import { BitaInformativeModal } from '../BitaModal/BitaModal';

const PagesContainer = () => {
  /* eslint-disable  */
  // Start of Tawk.to Script-->
  /*var Tawk_API = Tawk_API || {};
  const Tawk_LoadStart = new Date();
  (function() {
    const s1 = document.createElement('script');
    const s0 = document.getElementsByTagName('script')[0];
    s1.async = true;
    s1.src = 'https://embed.tawk.to/5e4fd54e298c395d1ce91a40/default';
    s1.charset = 'UTF-8';
    s1.setAttribute('crossorigin', '*');
    s0.parentNode.insertBefore(s1, s0);
  })();
  // End of Tawk.to Script-->*/
  /* eslint-enable   */

  return (
    <div className={styles.pagePanel} data-simplebar>
      <div>
        <Switch>
          {/* Dashboard */}
          <ProtectedRoute exact path="/" component={ProjectDashboard} />

          {/* Universe Builder */}
          <ProtectedRoute
            path="/universe-builder/universe-builder"
            component={UniverseBuilderModal}
          />

          <ProtectedRoute path="/run-backtest" component={RunBacktestModal} />

          {/* <ProtectedRoute path="/universe-builder/new" component={UniverseBuilderModal} /> */}
          {/* <ProtectedRoute path="/universe-builder/base-universe" component={BaseUniverse} /> */}
          {/* <ProtectedRoute path="/universe-builder/filters/esg-ratings" component={ESGRatings} /> */}
          {/* <ProtectedRoute path="/universe-builder/filters/esg-topics" component={ESGTopics} /> */}
          {/* <ProtectedRoute path="/universe-builder/filters/factors" component={Factors} /> */}
          {/* <ProtectedRoute path="/universe-builder/filters/fundamentals" component={Fundamentals} /> */}
          {/* <ProtectedRoute path="/universe-builder/filters/geography" component={Geography} /> */}
          {/* <ProtectedRoute path="/universe-builder/filters/liquidity" component={Liquidity} /> */}
          {/* <ProtectedRoute path="/universe-builder/filters/sector" component={Sector} /> */}
          {/* <ProtectedRoute path="/universe-builder/filters/size" component={Size} /> */}
          {/* <ProtectedRoute path="/universe-builder/filters/themes" component={Themes} /> */}

          {/* Index Builders */}
          <ProtectedRoute
            path="/index-builders/index-builders/:filter?"
            component={IndexBuilderGenerator}
          />
          {/* <ProtectedRoute path="/index-builders/index-builders/" component={IndexBuilderGenerator} /> */}

          <ProtectedRoute path="/index-builders/base-universe" component={IndexBaseUniverse} />
          <ProtectedRoute
            path="/index-builders/parametrization/additional-rules"
            component={IndexBuilderGenerator}
          />
          <ProtectedRoute
            path="/index-builders/parametrization/fast-exit-entry"
            component={IndexBuilderGenerator}
          />
          <ProtectedRoute
            path="/index-builders/parametrization/general-rules"
            component={IndexBuilderGenerator}
          />

          <ProtectedRoute
            path="/index-builders/parametrization/rebalacing-reconstitution"
            component={IndexBuilderGenerator}
          />
          <ProtectedRoute
            path="/index-builders/parametrization/caps-floors-buffer"
            component={IndexBuilderGenerator}
          />

          {/* Optimized Index */}
          <ProtectedRoute path="/optimized-index/optimized-index" component={OptimizedIndex} />
          <ProtectedRoute
            path="/optimized-index/universe-index-selection"
            component={SpecifyConstraints}
          />
          <ProtectedRoute path="/optimized-index/optimized-index" component={SpecifyConstraints} />
          <ProtectedRoute path="/optimized-index/specify-contraints" component={UniverseIndex} />

          {/* Structures Product  */}
          <ProtectedRoute
            path="/structures-product/structures-product"
            component={StructuresProduct}
          />
          <ProtectedRoute
            path="/structures-product/structures-product/step=Decrement+Index"
            component={StructuresProduct}
          />

          <ProtectedRoute
            path="/structures-product/structures-product/step=Leverage+Index"
            component={StructuresProduct}
          />

          {/* Portfolio */}

          <ProtectedRoute
            path="/portfolio-basket-calculation/portfolio-basket-calculation"
            component={PortfolioBasketCalculatio}
          />

          <ProtectedRoute path="/live-calculation/basket" component={LiveCalculatioBasket} />

          <ProtectedRoute path="/optimized-index/structured-index">
            <OptimizedIndex getFlow="structured" />
          </ProtectedRoute>
          <ProtectedRoute
            path="/factsheet-builder/factsheet-builder"
            component={FactsheetBuilder}
          />
          <ProtectedRoute path="/live-calculation/basket" component={LiveCalculatioBasket} />
          <ProtectedRoute
            path="/rebalancing-reconstitution/rebalancing-reconstitution"
            component={RebalancingReconstitutionPage}
          />
          <ProtectedRoute
            path="/index-builders/rebalancing-reconstitution"
            component={IndexBuilderGenerator}
          />
          {/* <ProtectedRoute path="/structures-product/build-structured" component={BuildStructured} /> */}

          {/* Index Analyzer  */}
          <ProtectedRoute path="/index-analyzer/analyzer" component={IndexAnalyzer} />
          <ProtectedRoute path="/index-analyzer/performance/:indexId?" component={Performance} />
          <ProtectedRoute path="/index-analyzer/risk-attribution" component={RiskAttribution} />
          <ProtectedRoute
            path="/index-analyzer/characteristics/:indexId?"
            component={Characteristics}
          />
          <ProtectedRoute path="/index-analyzer/ratings" component={Ratings} />
          <ProtectedRoute path="/index-analyzer/esg-issues" component={Controversy} />
          <ProtectedRoute path="/index-analyzer/waste" component={Waste} />
          <ProtectedRoute path="/index-analyzer/emission" component={Emission} />

          {/* Setting */}
          <ProtectedRoute path="/settings" component={Settings} />
          <ProtectedRoute path="/tutorials" component={Tutorials} />

          {/* Customer Service */}
          <ProtectedRoute path="/customer-service" component={CustomerService} />

          {/* User profile  */}
          <ProtectedRoute path="/user-profile/expert-chat" component={ExpertChat} />
          <ProtectedRoute path="/user-profile/account-preferences" component={AccountProfile} />
          <ProtectedRoute path="/user-profile/parameters" component={Parameters} />

          <ProtectedRoute path="/404" component={NotFound} />
          <Redirect from="*" to="/404" />
        </Switch>
      </div>
    </div>
  );
};

export default PagesContainer;
