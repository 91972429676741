import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import PageLayout from '../../components/Layout/PageLayout';
import CharacteristicsAnalyzer from '../../components/Analyzer/CharacteristicsAnalyzer/CharacteristicsAnalyzer';

const Characteristics = () => {
  const apiData = useSelector(state => state.apiData.apiData);
  const [benchmarkList, setBenchmarkList] = useState([]);

  useEffect(() => {
    const getDataFor = ['Benchmark', 'Indexes'];
    let instrumentList = [];
    // eslint-disable-next-line array-callback-return
    getDataFor.map(dataName => {
      const list = _.get(apiData, dataName, {});
      if (list.data) {
        let dataList = list.data;
        if (dataName === 'Benchmark') {
          dataList = list.data.Benchmark;
        }
        instrumentList = instrumentList.concat(
          dataList.map(row => {
            return {
              label: `${row.name} - ${
                row.index_type
                  ? row.index_type
                      .replace('regular', 'INDEX')
                      .replace('structured_index', 'STRUCTURED PRODUCT')
                      .replace('optimized_index', 'OPTIMIZED PRODUCT')
                  : 'ETF'
              }`,
              value: row.name,
              id: row.id,
              'Instrument type': row.index_type
                ? row.index_type
                    .replace('regular', 'INDEX')
                    .replace('structured_index', 'STRUCTURED PRODUCT')
                    .replace('optimized_index', 'OPTIMIZED PRODUCT')
                : 'ETF',
            };
          }),
        );
      }
    });
    setBenchmarkList(instrumentList);
  }, [apiData.Benchmark, apiData.Indexes]);

  return (
    <PageLayout>
      <CharacteristicsAnalyzer benchmarkList={benchmarkList} />
    </PageLayout>
  );
};

export default Characteristics;
