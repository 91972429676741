/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import { withRouter } from 'react-router';
import 'react-sweet-progress/lib/style.css';
import { Components, ComponentGenerator } from './components/Components';
import './builder.css';

const Builder = withRouter(props => {
  if (typeof Components[props.type] !== 'undefined') {
    return <ComponentGenerator {...props} />;
  }

  return `Not implemented "${props.type}"`;
});

export default Builder;
