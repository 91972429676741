import React from 'react';
import _ from 'lodash';
import 'simplebar'; // or "import SimpleBar from 'simplebar';" if you want to use it manually.
import 'simplebar/dist/simplebar.css';
import styles from '../EmissionsWaste.module.scss';
import { thousandFormat } from '../../../../utils/utils';

const numberWithCommas = n => {
  const auxFloat = n.indexOf('.');
  const aux = n.replace(/M/g, '').replace(/,/g, '');
  const parts = aux.toString().split('.');
  return (
    parts[0].replace(/[^0-9]/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
    (parts[1] ? `.${parts[1].replace(/[^0-9]/g, '')}` : auxFloat > 0 ? '.' : '')
  );
};

const abbreviateNumber = (auxValue, decimals) => {
  const value = auxValue.replace(/,/g, '');
  const n = value;
  if (n < 1e6) return `${thousandFormat(auxValue)}${decimals}`;
  if (n >= 1e6) return `${numberWithCommas((n / 1e6).toFixed(1))}M`;
  return 'N/A';
};

const isPercentageValue = (value, dataUnit, exception = false) => {
  const _value =
    dataUnit === '%' || exception === true
      ? `${Number(value).toFixed(2)}`
      : dataUnit === 'ratio'
      ? Number(Number(value).toFixed(2))
      : Number(Number(value).toFixed(2));
  const splitedValue = _value.toString().split('.');

  return abbreviateNumber(splitedValue[0], splitedValue[1] ? `.${splitedValue[1]}` : '');
};
export const TableDatesGenerator = (categories, data, isPercentage, typeIndex) => (
  <table className={styles.generatedTable} style={{ fontSize: '10px', width: '100%' }}>
    <thead className={styles.generatedTableHeader}>
      <tr>
        <th />
        {categories?.map(timeframe => {
          return <th className={styles.restTableHeaders}>{timeframe}</th>;
        })}
      </tr>
    </thead>
    <tbody>
      {data?.map(item => (
        <tr className={styles.generatedTableHeader}>
          {' '}
          <td style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>
            {item.name === 'RECYCLING RATIO* (RHS)'
              ? 'RECYCLING RATIO*'
              : typeIndex === 'rebalancing_module' && item.name === 'Index'
              ? 'Portfolio'
              : item.name}
          </td>{' '}
          {item.data.length > 0 &&
            item.data.map(value => {
              let exception = false;
              if (item.name === 'RECYCLING RATIO* (RHS)' && value % 1 === 0) {
                exception = true;
              }
              return (
                <td className={styles.restTableHeaders}>
                  {Number(value)
                    ? `${isPercentageValue(value, isPercentage, exception)}${
                        isPercentage !== 'ratio'
                          ? isPercentage
                          : item.name.toUpperCase() === 'RECYCLING RATIO* (RHS)'
                          ? '%'
                          : ''
                      }`
                    : 'N/A'}
                </td>
              );
            })}
          {item.data.length === 0 && <td className={styles.restTableHeaders}>N/A</td>}
        </tr>
      ))}
    </tbody>
  </table>
);

const StatsTable = ({
  title,
  categories,
  series,
  yAxisLabel = '%',
  disclaimer = false,
  typeIndex,
}) => {
  return (
    <div className={styles.tableContainer}>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
        <span className={styles.styleFactorTitle} style={{ fontSize: '14px', fontWeight: 'bold' }}>
          {title}
        </span>
      </div>
      <div data-simplebar>{TableDatesGenerator(categories, series, yAxisLabel, typeIndex)}</div>
      {disclaimer && <span style={{ fontSize: '8px' }}>{disclaimer}</span>}
    </div>
  );
};

export default StatsTable;
