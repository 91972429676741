// TODO KAREM, luego de corregirlo se debe poder deshabilitar esto
// eslint-disable camelcase
// eslint-disable react-hooks/rules-of-hooks
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { toast } from 'react-toastify';
import axios from '../../../Axios';
import { getDataComponents } from '../../../api-data-mapping';
import { setApiData } from '../../../state/actions/api-data-actions';
import styles from '../../builder.module.scss';
import BitaSelect from '../../../components/BitaSelect/BitaSelect';
// eslint-disable-next-line import/no-cycle
import { ComponentGenerator } from '../Components';
import { loaderDataObjectDecorator } from '../../../utils/loading_status_tools';
import { setBacktest, setRiskModel } from '../../../state/actions/backtest-actions';
import { remove_option, hasValidBitaDomain, hasValidIdBNP } from '../../../utils/utils';
import { aryIanaTimeZones } from '../../../constants/timezome-list-constants';

export const BuilderDropdownComponent = ({
  title,
  id,
  external_title,
  text = '',
  width,
  onChange,
  data_set_key = '',
  label,
  path = '',
  data_key,
  parentKey = '',
  parent,
  options = [],
  setterFunction = Function.prototype,
  default_option_selected_position,
  post_text = '',
  onclick = null,
  onclicksecond = null,
  data_fields,
  data_fields_type,
  widgetData,
  default_selected,
  operationTypeSelection = '',
  justify,
  historyAddibleData,
  defineaddiblerow,
  defineaddiblerowDataRemove,
  search_id = false,
  display_none = false,
  stepData,
  addibleData,
  ...props
  // components = [],
}) => {
  const [selected, setSelected] = React.useState(default_selected && default_selected);
  const apiData = useSelector(state => state.apiData.apiData);
  const dataSummary = useSelector(state => state.summary);
  const [components, setComponents] = useState([]);
  const [newStep, setNewStep] = useState({});
  const [optionsCaps, setOptionsCaps] = useState([]);
  const universeSelected = useSelector(state => state.summary);
  const auth = useSelector(state => state.auth);
  let idUniverse = false;
  const subThemesPSA = auth?.userdata
    ? hasValidBitaDomain(auth?.userdata?.email) ||
      auth?.userdata?.id_company === process.env.REACT_APP_PACER_ID ||
      auth?.userdata?.id_company === process.env.REACT_APP_DEMO_COMPANY_ID
    : false;
  const idOptionsSpecial = [
    'CapType',
    'SectorType',
    'SelectedCountry',
    'SelectedSector',
    'floorCountry',
    'floorSector',
  ];
  if (
    universeSelected &&
    universeSelected.universeBuilderOperations &&
    universeSelected.universeBuilderOperations.universe.id
  ) {
    idUniverse = universeSelected.universeBuilderOperations.universe.id;
  }
  let localPath = `${path}${id}`;
  if (path !== '' && id) {
    localPath = `${path}${id}`;
  } else if (external_title && id !== 'risk_model_id') {
    localPath = external_title;
  }
  if (
    options &&
    id === 'operator' &&
    parent === 'size' &&
    historyAddibleData?.fundamental?.value === 'Free Float Percent - Instrument'
  ) {
    options = Object.values(options).filter(
      e => !(e?.value?.includes('Top') || e?.value?.includes('Bottom')),
    );
  }
  if (options && id === 'operator_type' && Object.values(options).indexOf('Add') > -1) {
    if (idUniverse) {
      if (parentKey === 'Single Instrument') {
        options = Object.values(options);
      } else {
        options = Object.values(options).filter(e => e !== 'Add');
      }
    } else if (
      universeSelected &&
      universeSelected['Universe Builder'] &&
      universeSelected['Universe Builder']['Filter Group 1'] &&
      Object.values(universeSelected['Universe Builder']['Filter Group 1']).length > 0
    ) {
      if (parentKey === 'Single Instrument') {
        options = Object.values(options);
      } else {
        options = Object.values(options).filter(e => e !== 'Add');
      }
    } else if (parent === 'geography' && localPath !== '[0].operator_type') {
      options = Object.values(options).map(item => {
        if (item === 'Keep') return item;
        return { value: item, disable: true };
      });
    } else {
      options = Object.values(options).map(item => {
        if (item === 'Add') return item;
        return { value: item, disable: true };
      });
    }
  }

  const dispatch = useDispatch();

  const data = useMemo(() => {
    const storeFields = {
      ..._.get(apiData, ['data_fields', 'data'], {}),
      ..._.get(apiData, ['Available Data Fields', 'data'], {}),
      ..._.get(apiData, ['client_data_fields', 'data'], {}),
      ..._.get(apiData, ['risk_models_filter', 'data'], {}),
      ..._.get(apiData, ['risk_models', 'data'], {}),
      ..._.get(apiData, ['risk_models_autor', 'data'], {}),
      ..._.get(apiData, ['Benchmark', 'data'], {}),
      'universe - size': [
        ..._.get(apiData, ['Universes', 'data'], []),
        ..._.get(apiData, ['EditUniverses', 'data'], []),
      ],
      'Number Constituents Custom Data Fields': [
        ..._.get(apiData, ['Custom Data Fields', 'data'], []),
        ..._.get(apiData, ['CustomDataFields', 'CustomdataFields'], []),
      ],
      timezone: aryIanaTimeZones.sort().map(item => {
        return { id: item, name: item };
      }),
    };
    // TODO linea que me daba error
    if (data_fields === 'Size - Instrument' || data_fields === 'Size - Instrument - traditional') {
      const sizeData = storeFields?.['Size - Instrument']?.filter(
        item => item.name !== 'Foreign Inclusion Factor Adjusted Free Float Marketcap',
      );
      const dataNewSizeFilters =
        data_fields === 'Size - Instrument - traditional' || id === 'rank_by'
          ? sizeData
          : [...sizeData, storeFields['Size Filters']?.find(item => item.id === 153)];
      return dataNewSizeFilters
        .map(field => ({
          id: field.id,
          value: field.name,
        }))
        .sort((a, b) => {
          if (a.value < b.value) {
            return -1;
          }
          if (a.value > b.value) {
            return 1;
          }
          return 0;
        });
    }

    if (data_fields === 'Liquidity' || data_fields === 'Liquidity - Rank by') {
      let liquidityData = storeFields?.Liquidity?.filter(item => item.name !== 'Foreign Room');
      if (data_fields === 'Liquidity - Rank by') {
        liquidityData = liquidityData.filter(item => item.name.includes('ADTV '));
      }
      return liquidityData
        .map(field => ({
          id: field.id,
          value: field.name,
        }))
        .sort((a, b) => {
          if (a.value < b.value) {
            return -1;
          }
          if (a.value > b.value) {
            return 1;
          }
          return 0;
        });
    }

    if (
      data_fields === 'Size - Instrument - geography' ||
      data_fields === 'Size - Instrument - buffer'
    ) {
      return [
        ...storeFields['Size - Instrument'].filter(
          item =>
            item.name === 'Free Float Market Cap' ||
            item.name === 'Market Capitalization - Instrument',
        ),
      ]
        .map(field => ({
          id: field.id,
          value: field.name,
        }))
        .sort((a, b) => {
          if (a.value < b.value) {
            return -1;
          }
          if (a.value > b.value) {
            return 1;
          }
          return 0;
        });
    }

    if (data_fields === 'Liquidity - geography') {
      return [
        ...storeFields?.Liquidity?.filter(
          item => item.name === 'ADTV 1 Month' || item.name === 'ADTV 3 Month',
        ),
      ]
        .map(field => ({
          id: field.id,
          value: field.name,
        }))
        .sort((a, b) => {
          if (a.value < b.value) {
            return -1;
          }
          if (a.value > b.value) {
            return 1;
          }
          return 0;
        });
    }

    if (data_fields && storeFields && _.isArray(storeFields[data_fields])) {
      let dataDatafields = storeFields[data_fields];
      if (
        hasValidIdBNP(auth?.userdata?.id_company) &&
        (id === 'risk_model' || id === 'risk_model_id')
      ) {
        dataDatafields = storeFields[data_fields].filter(
          item => item.name === 'Bita Global Risk Model',
        );
      }

      return dataDatafields
        .map(field => ({
          id: field.id,
          value: field.name,
        }))
        .sort((a, b) => {
          if (a.value < b.value) {
            return -1;
          }
          if (a.value > b.value) {
            return 1;
          }
          return 0;
        });
    }

    if (
      (data_fields === 'Available Data Fields' || data_fields === 'Bita ESG') &&
      data_fields_type
    ) {
      return storeFields[data_fields][data_fields_type]
        .map(field => ({
          id: field.id,
          value: field.name,
        }))
        .sort((a, b) => {
          if (a.value < b.value) {
            return -1;
          }
          if (a.value > b.value) {
            return 1;
          }
          return 0;
        });
    }
    if (
      id === 'category_type' &&
      Object.values(options).find(item => item.value === 'Subthemes') &&
      !subThemesPSA
    ) {
      return Object.entries(options)
        .filter(([key, _value]) => remove_option(!_value.disable))
        .filter(([key, _value]) => _value.value !== 'Subthemes' && _value.value !== 'PSA')
        .map(([_id, value]) => {
          if (_id === default_option_selected_position) {
            setSelected({ id: _id, value, disabled: value.disable ? value.disable : false });
          }
          if (typeof value === 'string' || typeof value === 'number') {
            return { id: _id, value, disabled: value.disable ? value.disable : false };
          }
          return { id: _id, value: value.value, disabled: value.disable ? value.disable : false };
        });
    }
    if (
      data_key === 'Geography Dropdown_Country of Primary Listing' ||
      data_key === 'Geography Dropdown_BITA Assigned Country' ||
      data_key === 'Geography Dropdown_Country of Incorporation' ||
      data_key === 'Geography Dropdown_Country of Headquarters'
    ) {
      const dataIncludesKey = Object.keys(apiData)
        .filter(key => key?.includes(data_key))
        .reduce((result, key) => {
          result[data_key] = apiData[key];
          return result;
        }, {});
      return (apiData[`${data_key}_${path}`] ?? dataIncludesKey?.[data_key] ?? [])
        .map(field => ({
          id: field.id,
          value: field.option,
        }))
        .sort((a, b) => {
          if (a.value < b.value) {
            return -1;
          }
          if (a.value > b.value) {
            return 1;
          }
          return 0;
        });
    }
    if (data_key === 'Sectors Dropdown_Sectors List') {
      let dataSector = apiData?.[data_key];
      if (!apiData?.[data_key]?.[0]?.id) {
        const listArray = (apiData?.[data_key] ?? []).map(item => {
          return Object.values(item.value);
        });
        dataSector = listArray.reduce((acc, val) => acc.concat(val), []);
      }
      return (dataSector ?? [])
        .map(field => ({
          id: field.id,
          value: field.option,
        }))
        .sort((a, b) => {
          if (a.value < b.value) {
            return -1;
          }
          if (a.value > b.value) {
            return 1;
          }
          return 0;
        });
    }
    let optionsNew = false;
    if (
      (addibleData ?? []).filter(item => item?.[id])?.[0]?.[id] &&
      (id === 'CapType' || id === 'SectorType')
    ) {
      optionsNew = true;
    }

    const validateOption = value => {
      if (!optionsNew) {
        return true;
      }
      if (id === 'CapType' || id === 'SectorType') {
        return value !== (addibleData ?? []).filter(item => item?.[id])?.[0]?.[id]?.value;
      }
      return value === (addibleData ?? []).filter(item => item?.[id])?.[0]?.[id]?.value;
    };

    return Object.entries(options)
      .filter(([key, _value]) => remove_option(!_value.disable) && validateOption(_value.value))
      .map(([_id, value]) => {
        if (_id === default_option_selected_position) {
          setSelected({ id: _id, value, disabled: value.disable ? value.disable : false });
        }
        if (typeof value === 'string' || typeof value === 'number') {
          return { id: _id, value, disabled: value.disable ? value.disable : false };
        }
        return { id: _id, value: value.value, disabled: value.disable ? value.disable : false };
      });
    // eslint-disable-next-line
  }, [options]);

  const onChangeLocal = value => {
    setComponents([]);
    if (value) {
      setComponents([]);
      if (id === 'risk_model_id') {
        // debugger;
        dispatch(setRiskModel(value));
      }
      if (setterFunction) setterFunction(prev => ({ ...prev, [title]: value }));
      setSelected(value);

      if (onclick) {
        if (id === 'fundamental' && value.value === 'Dividend Yield') {
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < Object.values(onclick[0].onclick[0].options).length; i++) {
            const operationOptions = Object.values(onclick[0].onclick[0].options)[i].value;
            if (operationOptions === '>=' || operationOptions === '<=') {
              onclick[0].onclick[0].options[i].onclick[0].placeholder = 'Enter %';
            }
          }
          setComponents(Object.values(onclick));
        } else if (onclicksecond && value.value === 'Free Float Percent - Instrument') {
          setComponents(Object.values(onclicksecond));
        } else {
          setComponents(Object.values(onclick));
        }
      }
      if (options[value.id] && options[value.id].onclick) {
        setComponents(Object.values(options[value.id].onclick));
        Object.values(options[value.id].onclick).map(subComponents => {
          if (subComponents.type !== 'Input') {
            onChange(`${path}${subComponents.id}`);
          } else {
            onChange(`${path}${subComponents.id}`);
          }
          return true;
        });
      }
      if (onChange) onChange(localPath, value, data);
    } else {
      setSelected(value);
      if (onChange) onChange(localPath, ' ', data);
    }
    if (onclicksecond && value.value === 'Free Float Percent - Instrument') {
      setComponents(Object.values(onclicksecond));
    }
  };
  useEffect(() => {
    if (options && id === 'operator_type' && Object.values(options).indexOf('Add') > -1) {
      if (
        universeSelected &&
        universeSelected['Universe Builder'] &&
        universeSelected['Universe Builder']['Filter Group 1'] &&
        Object.values(universeSelected['Universe Builder']['Filter Group 1']).length < 2 &&
        selected?.value !== 'Add'
      ) {
        setSelected(default_selected);
      }
    }
  }, [universeSelected]);
  useEffect(() => {
    if (widgetData && widgetData[id]) {
      if (typeof widgetData[id] === 'object') {
        onChangeLocal(widgetData[id]);
      } else {
        const seldata = data.filter(row => row.value === widgetData[id])[0];
        if (typeof seldata !== 'undefined') {
          onChangeLocal(seldata);
        }
      }
    }
    // eslint-disable-next-line
  }, []);

  const validateMatchCapsFloors = () => {
    let result = false;
    if (
      id === 'floorCountry' ||
      id === 'SelectedCountry' ||
      id === 'floorSector' ||
      id === 'SelectedSector'
    ) {
      setOptionsCaps(data);
      const capsKey = id.includes('Country') ? 'Country' : 'Sector';
      const capsType = id.includes('Selected') ? 'Floors' : 'Caps';
      const capsData =
        dataSummary?.['Index Builder']?.['Rebalancing & Reconstitution']?.[capsType]?.[capsType];
      if (capsData && historyAddibleData?.Selected?.value === capsKey) {
        const filteredCap = capsData.filter(row => row?.Selected?.value === capsKey);
        const selectedKey = capsType === 'Caps' ? `Selected${capsKey}` : `floor${capsKey}`;
        result = filteredCap[0]?.[selectedKey];
      }
    }
    return result;
  };

  useEffect(() => {
    if (id === 'CapType' || id === 'SectorType') {
      let optionsNew = false;
      if ((addibleData ?? []).filter(item => item?.[id])?.[0]?.[id]) {
        optionsNew = true;
      }

      const validateOption = value => {
        if (!optionsNew) {
          return true;
        }
        return value !== (addibleData ?? []).filter(item => item?.[id])?.[0]?.[id]?.value;
      };

      const dataTemp = Object.entries(options)
        .filter(([key, _value]) => remove_option(!_value.disable) && validateOption(_value.value))
        .map(([_id, value]) => {
          if (_id === default_option_selected_position) {
            setSelected({ id: _id, value, disabled: value.disable ? value.disable : false });
          }
          if (typeof value === 'string' || typeof value === 'number') {
            return { id: _id, value, disabled: value.disable ? value.disable : false };
          }
          return { id: _id, value: value.value, disabled: value.disable ? value.disable : false };
        });
      setOptionsCaps(dataTemp);
    }
    if (id === 'SelectedCountry' || id === 'SelectedSector') {
      if (
        addibleData.filter(item => item?.Selected?.value === id.replace('Selected', '')).length ===
          2 &&
        Number(path.replace('[', '').replace('].', '')) !==
          (addibleData ?? []).findIndex(item => item?.[id])
      ) {
        setOptionsCaps(
          data.filter(
            option => option.id === (addibleData ?? []).find(item => item?.[id])?.[id].id,
          ),
        );
      } else {
        setOptionsCaps(data);
        const validtateCapsFloors = validateMatchCapsFloors();
        if (validtateCapsFloors) {
          setOptionsCaps([validtateCapsFloors]);
        }
      }
    }

    if (
      (id === 'SelectedCountry' || id === 'SelectedSector') &&
      selected !== (addibleData ?? []).find(item => item?.[id])?.[id] &&
      Number(path.replace('[', '').replace('].', '')) !==
        (addibleData ?? []).findIndex(item => item?.[id])
    ) {
      onChangeLocal((addibleData ?? []).find(item => item?.[id])?.[id]);
    }

    // eslint-disable-next-line
  }, [addibleData]);

  useEffect(() => {
    if (_.has(apiData, ['indexBacktest', 'Index Backtest', 'risk_model_id'])) {
      const risk = _.get(apiData, ['indexBacktest', 'Index Backtest', 'risk_model_id'], {});
      const getRisk = data.filter(r => r.value === risk.name);
      setSelected(getRisk[0] || data[default_option_selected_position]);
      onChangeLocal(getRisk[0] || data[default_option_selected_position]);
    } else if (default_option_selected_position || default_option_selected_position === 0) {
      const defaultAux = data[default_option_selected_position]
        ? data[default_option_selected_position]
        : false;
      if (defaultAux) {
        setSelected(defaultAux);
        // Activo el Onchange para guardar en la data que se enviara con el click al run backtest, pero no funciona
        onChangeLocal(defaultAux);
      }
    }
  }, [data]);

  useEffect(() => {
    setNewStep(stepData);
  }, [stepData]);

  const arr = path.split('.');
  const childrenPath = arr.filter((v, i) => i !== arr.length - 1).join('.');

  // eslint-disable-next-line
  // eslint-disable-next-line consistent-return
  React.useEffect(() => {
    if (data_set_key && data_set_key !== '') {
      if (selected) {
        const source = axios.CancelToken.source();
        // eslint-disable-next-line
        (async function() {
          const dataComponent = getDataComponents.filter(
            v =>
              v.key === (data_set_key || parentKey) &&
              (v.title === (title || label) || label === 'Select Themes category'),
          )[0];
          if ((data_set_key || parentKey) !== 'Themes Dropdown') {
            dispatch(
              setApiData({
                title: `${data_set_key || parentKey}_${dataComponent?.title}`,
                data: [],
              }),
            );
          }
          if (typeof dataComponent !== 'undefined') {
            let res = {};
            if (
              (data_set_key || parentKey) === 'Geography Dropdown' ||
              (data_set_key || parentKey) === 'Sectors Dropdown' ||
              (data_set_key || parentKey) === 'ESG Topics' ||
              (data_set_key || parentKey) === 'Themes Dropdown'
            ) {
              if (
                !(
                  (data_set_key || parentKey) === 'Themes Dropdown' &&
                  selected.id.toString() === '3'
                )
              ) {
                if ((data_set_key || parentKey) === 'Sectors Dropdown' && search_id) {
                  res = await loaderDataObjectDecorator(dataComponent, dispatch).getUrlData(
                    Number(search_id) + Number(selected.id),
                    idUniverse,
                    auth.token,
                  );
                } else {
                  res = await loaderDataObjectDecorator(dataComponent, dispatch).getUrlData(
                    selected.id,
                    idUniverse,
                    auth.token,
                  );
                }
                if ((data_set_key || parentKey) === 'Geography Dropdown') {
                  dispatch(
                    setApiData({
                      title: `${data_set_key || parentKey}_${selected.value}_${path}`,
                      data: dataComponent.formatData(res.data || {}),
                    }),
                  );
                } else {
                  dispatch(
                    setApiData({
                      title: `${data_set_key || parentKey}_${dataComponent.title}`,
                      data: dataComponent.formatData(res.data || {}),
                    }),
                  );
                }
              }
            } else {
              res = await dataComponent.getUrlData(selected.id, auth.token);
              dispatch(
                setApiData({
                  title: `${data_set_key || parentKey}_${dataComponent.title}`,
                  data: dataComponent.formatData(res.data || {}),
                }),
              );
            }
          }
        })();
        return () => {
          source.cancel();
        };
      }
      const dataComponent = getDataComponents.filter(
        v => v.key === (data_set_key || parentKey) && v.title === (title || label),
      )[0];
      if (typeof dataComponent !== 'undefined') {
        dispatch(
          setApiData({
            title: `${data_set_key || parentKey}_${dataComponent.title}`,
            data: [],
          }),
        );
      }
    }
    // eslint-disable-next-line
  }, [selected]);

  useEffect(() => {
    const validtateCapsFloors = validateMatchCapsFloors();
    if (validtateCapsFloors) {
      if (selected && selected.value !== validtateCapsFloors.value) {
        const capsKey = id.includes('Country') ? 'Country' : 'Sector';
        const type = id.includes('Selected') ? 'Caps' : 'Floors';
        const countertype = !id.includes('Selected') ? 'caps' : 'floors';
        toast(`${capsKey} type changed in ${type} to match with ${countertype} type`);
        onChangeLocal({});
        onChangeLocal(validtateCapsFloors);
      }
      setOptionsCaps([validtateCapsFloors]);
    }
  }, [dataSummary]);

  return !display_none ? (
    <div className={`${styles.dropdownLine} dropdownLine id-${id}`} key={path}>
      <span className={styles.dropdownText} style={{ marginRight: text ? '10px' : '0px' }}>
        {text}
      </span>
      <div
        className={`${styles.dropdownField} dropdownField`}
        style={id === 'sector_provider' || id === 'esg_score_provider' ? { width: '100%' } : {}}
      >
        {(external_title && external_title !== 'Apply a Leverage of'
          ? external_title.toUpperCase()
          : external_title) ||
          (title && title.toUpperCase())}
        <BitaSelect
          key={`${title}`}
          label={label || title}
          value={selected}
          selected={default_option_selected_position}
          data={
            idOptionsSpecial.includes(id)
              ? optionsCaps
              : defineaddiblerow && defineaddiblerowDataRemove?.id === id
              ? data.filter(item => !defineaddiblerowDataRemove.data.includes(item.value || item))
              : data
          }
          width={width}
          handleSelection={onChangeLocal}
          selectClassname={styles.builderSelect}
        />
      </div>
      <span className={styles.dropdownPostText} style={{ marginRight: post_text ? '10px' : '0px' }}>
        {post_text}
      </span>
      {components.map(comp => (
        <ComponentGenerator
          path={path}
          key={`${comp.id}-${comp.data_fields}-${selected?.value}`}
          {...comp}
          onChange={onChange}
          widgetData={widgetData}
          operationTypeSelection={operationTypeSelection}
          historyAddibleData={historyAddibleData}
          addibleData={addibleData}
        />
      ))}
    </div>
  ) : (
    <div style={{ width: '48%' }} />
  );
};
