import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import ConfigYAML from '../../config/ConfigYAML';
import { useSummary } from '../../bitaHooks';
import { selectUniverse } from '../../state/actions/universe-operations-actions';
import WorkflowModal from '../structures-product/WorkflowModal/WorkflowModal';
import FiltersMainPage from './FiltersMainPage';

const UniverseBuilderModal = () => {
  const [addToSummary] = useSummary('universeBuilderOperations', 'universe', 'universeselected');
  const [initialWorkFlow, setInitialWorkflow] = useState(null);
  const [modalOpened, setModalOpened] = useState(true);
  const history = useHistory();
  const location = useLocation();
  const closeModal = (value, stayAtWorkFlow) => {
    setModalOpened(value);
    const path = `/`;
    if (!stayAtWorkFlow) {
      history.push(path);
    }
  };

  useEffect(() => {
    document.title = `${process.env.REACT_APP_SITE_TITLE} | Universe Builder`;
    const structuredProducts = ConfigYAML.jsonPath('$.universe_builder');
    const sections = Object.values(structuredProducts.sections).map(s => {
      const section = { ...s };
      if (section.widgets) {
        section.widgets = Object.values(section.widgets);
      } else if (section.components) {
        section.components = Object.values(section.components);
      }
      return section;
    });

    setInitialWorkflow(sections.find(({ type }) => type === 'Slides'));
  }, []);
  return (
    <>
      {initialWorkFlow && (
        <WorkflowModal
          {...initialWorkFlow}
          isModalOpen={modalOpened || location.pathname.includes('/step=')}
          setModalState={closeModal}
          setSelectedRow={universe => addToSummary({ ...universe })}
        />
      )}
      {!modalOpened ? (
        <>
          <Switch>
            <Route
              exact
              path="/universe-builder/universe-builder/filtergroup-:filtergroupindex"
              component={FiltersMainPage}
            />
            <Route
              path="/universe-builder/universe-builder/filtergroup-:filtergroupindex/filter-:filterindex"
              component={FiltersMainPage}
            />
            <Route path="/universe-builder/universe-builder" component={FiltersMainPage} />
          </Switch>
        </>
      ) : null}
    </>
  );
};

const mapDispatchToProps = dispatch => ({
  setSelectedUniverse: universe => dispatch(selectUniverse(universe)),
});

export default connect(Function.prototype, mapDispatchToProps)(UniverseBuilderModal);
