import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'simplebar';
import 'simplebar/dist/simplebar.css';
import { post, get } from 'axios';
import { useHistory } from 'react-router';

import BitaCard from '../../components/BitaCard/BitaCard';
import BitaButton from '../../bitaComponents/BitaButton/BitaButton';
import BitaSelect from '../../components/BitaSelect/BitaSelect';
import BitaModal from '../../bitaComponents/BitaModal/BitaModal';
import { BitaCardSelectedContent } from '../structures-product/WorkflowModal/WorkflowComponents/BitaCardSelectedContent';

import { ReactComponent as Logo } from '../../static/img/Icons/BITA.svg';
import { IconsModules } from '../../constants/icons-module';

import { toastInterceptor } from '../../utils/utils';
import { URL_CONSTANTS } from '../../api-data-mapping/url-constants';
import './Portfolio.css';
import BitaTable from '../../bitaComponents/BitaTable/BitaTable';
import { GenerateTable } from '../structures-product/WorkflowModal/WorkflowComponents/GenerateTable';

import { isFloatWithMoreThanTwoDecimals } from './utils';
import { Icons } from '../../constants/icons';
import style from './update-basket.module.scss';
import styles from './PortfolioBasketCalculation.module.scss';
import tableStyles from './table.module.scss';
import modalStyles from './select-methodology-modal.module.css';

// const updateBasket = (tokenUser, data) => {
//   return post(URL_CONSTANTS.UPDATELIVEBASKET, data, {
//     headers: { Authorization: `Bearer ${tokenUser}` },
//   });
// };

const getBasket = (tokenUser, data) => {
  return get(URL_CONSTANTS.GETLIVEBASKET, {
    headers: { Authorization: `Bearer ${tokenUser}` },
  });
};

const styleClose = {
  position: 'absolute',
  fontSize: '30px',
  fontWeight: 'bold',
  top: '-10px',
  right: '0',
  cursor: 'pointer',
  padding: '10px',
  boxSizing: 'border-box',
};

const dataDummieMethodology = {
  description: 'Lorem ipsum dolor sit amet, consectetur.',
  calculation_type: 'Net Total Return',
  metadata: {
    awf: {
      CH0025238863: 1,
      DE0005552004: 1,
    },
    iwf: {
      CH0025238863: 1,
      DE0005552004: 1,
    },
    shares: {
      CH0025238863: 17233.575252327573,
      DE0005552004: 482228.15641475335,
    },
    mic: {
      CH0025238863: 'XNYS',
      DE0005552004: 'XNYS',
    },
    divisor: 25000.000000000004,
  },
  open_hour: '00:00:00',
  close_hour: '00:00:00',
  timezone: 'UTC',
};

const RANDOM_NUMBER = Math.floor(Math.random() * (999 - 111 + 1)) + 111;

const BasketToIndex = () => {
  const token = useSelector(state => state.auth.token);
  const [showModal, setShowModal] = useState(false);
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [currentView] = React.useState(null);
  const [data, setData] = useState();
  const [dataMethodology, setDataMethodology] = useState();
  const [applicationPoint, setApplicationPoint] = useState();
  const [idIndex, setIdIndex] = useState();
  const [selectBasket, setSelectBasket] = useState();
  const [selectMethodology, setSelectMethodology] = useState();
  const [listBasket, setListBasket] = useState([]);
  const history = useHistory();
  const [showSelectBaseMethodologyModal, setShowSelectBaseMethodologyModal] = useState(false);
  const [loadingSubmitChanges, setLoadingSubmitChanges] = useState(false);

  // Add a custom row to remove the U icon in the first column
  const CustomRow = ({ value }) => {
    return (
      <div>
        <p>{value}</p>
      </div>
    );
  };

  const getColumns = metadata => {
    if (metadata?.exchange) {
      metadata.mic = metadata.exchange;
      delete metadata.exchange; // Rename exchange key for mic
    }

    const columns = Object.keys(metadata ?? {});
    columns.unshift('constituents');

    return columns;
  };

  const renderRows = metadata => {
    if (metadata?.exchange) {
      metadata.mic = metadata.exchange;
      delete metadata.exchange; // Rename exchange key for mic
    }

    const constituents = Object.keys(metadata?.awf ?? {});

    const rows = constituents.map(constituent => {
      const rowData = {
        constituents: constituent,
      };

      Object.keys(metadata).forEach(key => {
        rowData[key] =
          key === 'divisor'
            ? isFloatWithMoreThanTwoDecimals(metadata[key]) || metadata[key]
            : isFloatWithMoreThanTwoDecimals(metadata[key]?.[constituent]) ||
              metadata[key]?.[constituent];
      });

      return rowData;
    });

    return rows;
  };

  useEffect(() => {
    getBasket(token)
      .then(rsp => {
        setListBasket(Object.values(rsp?.data?.data));
      })
      .catch(error => {
        toastInterceptor(error);
      });
  }, []);

  useEffect(() => {
    setData(selectBasket?.date);
  }, [selectBasket]);

  useEffect(() => {
    setDataMethodology(dataDummieMethodology);
  }, [selectBasket]);

  const resetBasketData = () => {
    setData(null);
    setSelectBasket(null);
  };

  // const handleApplyButton = () => {
  //   updateBasket(token, { basket_id: selectBasket.id, ...data })
  //     .then(rsp => {
  //       toast('Your Basket has been processed successfully');
  //       resetBasketData(); // Reset the state after successfully processed the basket
  //       setShowModal(true);
  //       setIdIndex(rsp?.data?.data?.parent_index?.index_id);
  //     })
  //     .catch(error => {
  //       toastInterceptor(error);
  //     });
  // };

  const handleSubmitButton = () => {
    setLoadingSubmitChanges(true);
    setTimeout(() => {
      setShowModalConfirm(false);
      setShowModal(true);
    }, 3000);
  };

  const dataTable = {
    universe: {
      apikey: 'EditUniverses',
      header: ['name', 'constituents', 'revision_type', 'created_at', 'updated_at', 'author'],
    },
    methodology: {
      apikey: 'Methodologies',
      header: ['name', 'caps', 'floors', 'rebalancing', 'reconstitution'],
    },
  };

  console.info('applicationPoint ', applicationPoint);

  return (
    <>
      {/* Launch Index */}
      <div className={style.compositionPortfolio}>
        <div className={style.compositionPortfolioTitle}>Launch Index</div>
        <div className={styles.contentDataUpload}>
          {/* Basket selection */}
          <div>
            <div className={style.container}>
              <div className={style.dataUpload} style={{ flexDirection: 'row' }}>
                <div className={style.contentTemplate}>
                  <div className={style.subtitle}>Basket</div>

                  <BitaSelect
                    key="Baskets"
                    label="Live Basket(s)"
                    width="200px"
                    style={{ marginRight: ' 15px' }}
                    value={selectBasket}
                    data={listBasket?.map(item => {
                      return { value: item?.symbol, id: item?.id, name: item?.symbol, date: item };
                    })}
                    selected={selectBasket?.value}
                    handleSelection={selected => setSelectBasket(selected)}
                  />
                </div>
              </div>
            </div>
          </div>

          {data && (
            <div>
              <div className={tableStyles.tableTitle}>Basket Data Preview</div>
              <BitaTable
                data={renderRows(data?.metadata)}
                search={false}
                showFields={getColumns(data?.metadata)}
                selectAction={() => {}}
                CustomRowComponent={CustomRow}
              />
            </div>
          )}
        </div>
      </div>

      {/* Index Methodology */}
      <div className={style.compositionPortfolio}>
        <div className={style.compositionPortfolioTitle}>Index Methodology</div>
        <div className={styles.contentDataUpload}>
          {/* Methodology selection */}
          <div style={{ width: '300px' }}>
            <div className={style.container} style={{ width: '300px' }}>
              <div className={style.dataUpload} style={{ flexDirection: 'row' }}>
                <div className={style.contentTemplate}>
                  <BitaCard
                    className={styles.cardComponent}
                    title="Base Methodology"
                    icon="baseMethodology"
                    withSelected={
                      selectMethodology ? (
                        <BitaCardSelectedContent
                          icon="baseMethodology"
                          name={selectMethodology.name}
                          author={selectMethodology.author}
                          rebalancing={selectMethodology.rebalancing}
                          reconstitution={selectMethodology.reconstitution}
                          created_at={selectMethodology.created_at}
                          caps={selectMethodology.caps}
                          floors={selectMethodology.floors}
                        />
                      ) : null
                    }
                    onClick={() => {
                      setShowSelectBaseMethodologyModal(true);
                    }}
                  />
                </div>

                <div className={style.applicationPointContainer}>
                  <div className={style.subtitle} style={{ paddingBottom: 20 }}>
                    Application Point
                  </div>

                  <BitaSelect
                    key="Application Point"
                    label="Application Point"
                    width="200px"
                    style={{ marginRight: '15px' }}
                    value={applicationPoint}
                    data={[
                      { value: 'NEXT EOD', label: 'NEXT EOD' },
                      { value: 'Next Rebalancing', label: 'Next Rebalancing' },
                    ]}
                    selected={selectBasket?.value}
                    handleSelection={selected => setApplicationPoint(selected)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Apply Button */}
      <div className={style.applyButton}>
        <BitaButton
          onClick={() => setShowModalConfirm(true)}
          disabled={!(selectBasket?.value && selectMethodology && applicationPoint)}
          style={{ margin: 'auto' }}
          primary
        >
          Apply
        </BitaButton>
      </div>

      {/* Select Methodology Modal */}
      <BitaModal
        isModalOpen={showSelectBaseMethodologyModal}
        setModalState={() => setShowSelectBaseMethodologyModal(false)}
      >
        <div className={`${modalStyles.modalContent} closable_box`}>
          <>
            <div className="close_modal_x">
              <span
                className={modalStyles.closeModalButton}
                onClick={() => {
                  setShowSelectBaseMethodologyModal(false);
                }}
              >
                &times;
              </span>
            </div>

            <header
              className={modalStyles.headerpaths}
              style={{ alignItems: 'center', justifyContent: 'space-between' }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <IconsModules.MethodologyModule />
              </div>
              <Icons.Avanti style={{ marginRight: '40px', transform: 'scale(1.5)' }} />
            </header>
          </>

          <section className={`${modalStyles.workflowCurrentComponent}`}>
            <h2 className={modalStyles.componentText}>Select a methodology to use as a base:</h2>

            <GenerateTable
              key="Methodologies"
              apiDataKey="Methodologies"
              headers={['name', 'caps', 'floors', 'rebalancing', 'reconstitution']}
              filterAuthor={false}
              onSelect={row => {
                setSelectMethodology(row);
                setShowSelectBaseMethodologyModal(false);
              }}
              height="250px"
            />
          </section>
        </div>
      </BitaModal>

      {/* Confirm Modal */}
      <BitaModal isModalOpen={showModalConfirm} setModalState={() => setShowModalConfirm(false)}>
        <div style={{ width: '600px ', minHeight: '340px' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              borderBottom: '1px solid #eee',
              padding: '10px 0',
            }}
          >
            <Logo style={{ width: '30px', height: '30px', marginRight: '20px' }} />
            <h2
              style={{
                margin: '0',
                padding: '0',
                fontSize: '20px',
                textTransform: 'uppercase',
                textAlign: 'center',
                marginLeft: '-20px',
                width: '100%',
              }}
            >
              BASKET TO INDEX
            </h2>
            <span onClick={() => setShowModalConfirm(false)} style={styleClose}>
              &times;
            </span>
          </div>
          <div>
            <div style={{ margin: '20px 0', textAlign: 'center' }}>
              Are you sure you want to submit these changes?
            </div>

            <div data-simplebar style={{ height: '340px' }}>
              <div
                className={tableStyles.tableContainer}
                style={{ width: '100%', flexDirection: 'column' }}
              >
                <div className={style.contentNoMetadata} style={{ width: '100%', padding: 10 }}>
                  <div className={style.tableNoMetadata}>
                    <div className={tableStyles.tableTitle}>Base Information</div>
                    <div style={{ display: 'flex' }}>
                      <div>Ticker: </div> <div>{selectBasket?.value}</div>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <div>Open hour: </div> <div>00:00:00</div>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <div>Close hour: </div> <div>23:59:59</div>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <div>Timezone: </div> <div>Europe/Berlin</div>
                    </div>
                  </div>
                </div>

                {/* <div className={tableStyles.tableTitle} style={{ padding: 10 }}>
                  Application
                </div>
                <div
                  className={style.contentNoMetadata}
                  style={{
                    width: '100%',
                    paddingBottom: 20,
                    padding: '0px 10px 10px 10px',
                    marginBottom: '20px',
                  }}
                >
                  {' '}
                  <div style={{ fontSize: '10px' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <div style={{ textTransform: 'uppercase' }}>Application Point: </div>{' '}
                      <div>{applicationPoint?.value}</div>
                    </div>
                  </div>
                </div> */}

                <div className={style.contentNoMetadata} style={{ width: '100%', padding: 10 }}>
                  <div className={style.tableNoMetadata}>
                    <div className={tableStyles.tableTitle}>Application</div>
                    <div style={{ display: 'flex' }}>
                      <div>Application Point: </div> <div>{applicationPoint?.value}</div>
                    </div>
                  </div>
                </div>

                <div
                  className={tableStyles.tableTitle}
                  style={{ paddingLeft: '10px', paddingTop: 20 }}
                >
                  Methodology Preview
                </div>
                <div className={style.contentNoMetadata} style={{ width: '100%', padding: 10 }}>
                  <div className={style.tableNoMetadata}>
                    <div style={{ display: 'flex' }}>
                      <div>Name: </div> <div>{selectMethodology?.name}</div>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <div>Author: </div> <div>{selectMethodology?.author}</div>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <div>Rebalancing: </div> <div>{selectMethodology?.rebalancing}</div>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <div>Reconstitution: </div> <div>{selectMethodology?.reconstitution}</div>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <div>Created at: </div> <div>{selectMethodology?.created_at}</div>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <div>Caps: </div> <div>{selectMethodology?.caps}</div>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <div>Floors: </div> <div>{selectMethodology?.floors}</div>
                    </div>
                  </div>
                </div>

                <div style={{ width: '100%' }}>
                  {data && (
                    <div>
                      <div className={tableStyles.tableTitle}>Metadata Preview</div>
                      <BitaTable
                        data={renderRows(data?.metadata)}
                        search={false}
                        showFields={getColumns(data?.metadata)}
                        selectAction={() => {}}
                        CustomRowComponent={CustomRow}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
              <BitaButton
                onClick={handleSubmitButton}
                width="150px"
                height="30px"
                style={{ margin: '5px' }}
                primary
                autofocus
                loading={loadingSubmitChanges}
              >
                Submit
              </BitaButton>

              <BitaButton
                onClick={() => setShowModalConfirm(false)}
                width="150px"
                height="30px"
                style={{ margin: '5px' }}
                primary
                disabled={loadingSubmitChanges}
              >
                Cancel
              </BitaButton>
            </div>
          </div>
        </div>
      </BitaModal>

      {/* Success Modal */}
      <BitaModal isModalOpen={showModal} setModalState={() => setShowModal(false)}>
        <div style={{ width: ' 600px ', minHeight: '300px' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: '10px 0',
            }}
          >
            <span onClick={() => setShowModal(false)} style={styleClose}>
              &times;
            </span>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <div className={style.modalTextTitle}>BMR Submission successfully completed!</div>
            <div style={{ display: 'flex', margin: '5%', fontSize: '20px' }}>
              <Logo style={{ width: '100px', height: '100px', marginRight: '20px' }} />
              <div>
                <p> Submission ID: {RANDOM_NUMBER}</p>
                <p> Submission Status: Queued </p>
                <p> Ticker: {selectBasket?.value} </p>
                <p> Methodology: {selectMethodology?.name} </p>
              </div>
            </div>
            <div className={style.smallLetters}>
              Once the BMR/Compliance committee approves the submission, you will receive an e-mail
              with the confirmation and documentation associated with the index.{' '}
            </div>
          </div>
          <div
            style={{ display: 'flex', justifyContent: 'center', gap: '10px', marginTop: '20px' }}
          >
            <BitaButton primaryWhite onClick={() => history.push('/')} height="35px" width="200px">
              Go to Home
            </BitaButton>
          </div>
        </div>
      </BitaModal>
    </>
  );
};

export default BasketToIndex;
