import React, { useEffect, useState } from 'react';
import 'simplebar'; // or "import SimpleBar from 'simplebar';" if you want to use it manually.
import 'simplebar/dist/simplebar.css';
import { useSelector, useDispatch } from 'react-redux';
import BitaButton from '../../../bitaComponents/BitaButton/BitaButton';

import * as ApiDataActions from '../../../state/actions/api-data-actions';

import '../ComponentsSettings.css';

const CustomFieldData = ({ search }) => {
  const token = useSelector(state => state.auth.token);
  const data = useSelector(state => state.apiData.apiData.CustomDataFields);
  const [displayList, setDisplayList] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      if (search && search !== '') {
        setDisplayList(
          data?.CustomdataFields.filter(
            df =>
              df.name.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
              df.author.toLowerCase().indexOf(search.toLowerCase()) > -1,
          ),
        );
      } else {
        setDisplayList(data.CustomdataFields);
      }
    }, 500);
    return () => clearTimeout(timeOutId);
  }, [data, search]);

  return (
    <div
      className="contentFields "
      style={{
        height: 'calc(100% - 120px)',
        paddingRight: '20px',
        marginTop: '20px',
        marginBottom: '20px',
      }}
      data-simplebar
    >
      <div>
        {' '}
        {displayList.length > 0 &&
          displayList.map(row => (
            <div className="itemField" key={`${row.id}-${row.name}`}>
              <div
                style={{ display: 'flex', width: 'calc(100% - 220px)' }}
                className="contentColumn"
              >
                <div style={{ width: '40%' }}>
                  <div className="titleItemField">
                    <b>FIELD NAME</b>
                  </div>{' '}
                  <div> {row.name}</div>
                </div>

                <div style={{ width: '25%' }}>
                  <div className="titleItemField">
                    <b>CREATED BY</b>
                  </div>{' '}
                  <div> {row.author}</div>
                </div>

                <div style={{ width: '25%' }}>
                  <div className="titleItemField">
                    <b>CREATED ON</b>
                  </div>{' '}
                  <div> {row.create_date.split('T')[0]}</div>
                </div>
              </div>
              <BitaButton onClick={() => {}} primaryWhite width="100px" disabled>
                Hide
              </BitaButton>
              <BitaButton
                onClick={() => {
                  dispatch(ApiDataActions.deleteEntity(row.id, token));
                }}
                primaryWhite
                width="100px"
              >
                Delete
              </BitaButton>
            </div>
          ))}
      </div>
    </div>
  );
};

export default CustomFieldData;
