/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import style from './BitaPreviewVideo.module.scss';

const BitaPreviewVideo = props => {
  return (
    <div className={style.videosItem} onClick={() => props.onClick(props.video)}>
      <div className={style?.videosImage}>
        <img src={props?.video?.thumbnails?.default?.url} />
      </div>
      <div className={style.videosDescription}>
        <p>{props?.video?.title}</p>
      </div>
    </div>
  );
};

export default BitaPreviewVideo;
