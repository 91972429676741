import 'react-image-crop/dist/ReactCrop.css';
import React, { useState } from 'react';
import ReactCrop from 'react-image-crop';
import { getCroppedImg } from './cropImage';
import styles from './BitaBrandingModal.module.scss';

const ImageCropper = ({ getBlob, inputImg }) => {
  const [cropRectangle, setCrop] = useState({
    unit: 'px',
    width: 340,
    height: 210,
  });
  const [imageRef, setImageRef] = useState();
  const makeClientCrop = async crop => {
    if (imageRef && crop.width && crop.height) {
      const croppedImageUrl = await getCroppedImg(imageRef, crop);
      getBlob(croppedImageUrl);
    }
  };
  const onCropComplete = crop => {
    makeClientCrop(crop);
  };
  const onImageLoaded = image => {
    setImageRef(image);
  };
  const onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    setCrop(crop);
  };

  return (
    <div>
      <div className={styles.logoPreviewContainer}>
        <ReactCrop
          src={inputImg}
          crop={cropRectangle}
          ruleOfThirds
          onImageLoaded={onImageLoaded}
          onComplete={onCropComplete}
          onChange={onCropChange}
        />
      </div>
    </div>
  );
};

export default ImageCropper;
