import React from 'react';
import styles from './BitaModal.module.scss';
import { Icons } from '../../constants/icons';
import BitaButton from '../BitaButton/BitaButton';
import BitaModal from './BitaModal';

const styleClose = {
  position: 'absolute',
  fontSize: '30px',
  fontWeight: 'bold',
  top: '-10px',
  right: '0',
  cursor: 'pointer',
  padding: '20px',
  boxSizing: 'border-box',
};

const BitaExitWorkflowModal = ({
  isModalOpen,
  popupCloseOptions,
  workflowSection,
  handleOptionsMenuCloseModal = () => {},
  customText,
}) => {
  const optionButtons = popupCloseOptions.map((option, i) => (
    <BitaButton
      key={option?.value}
      onClick={() => {
        handleOptionsMenuCloseModal({ id: option.id });
      }}
      width="90px"
      height="30px"
      style={{ margin: '5px', width: '280px' }}
      primary
      autofocus={i === 0}
    >
      {option?.value}
    </BitaButton>
  ));

  // esto se debe a que la opción en handleOptionsMenuCloseModal para solo cerrar el modal
  // es { value: 'Return to options', id: 'return' }
  const closeModal = () => {
    handleOptionsMenuCloseModal({ id: 'return' });
  };

  // TODO improve this because all the options are the same
  const defaultText =
    workflowSection === 'running'
      ? 'Are you sure you want to cancel the current project?'
      : workflowSection === 'cancel'
      ? 'Are you sure you want to cancel the current project? '
      : 'Are you sure you want to cancel the current project?';

  const text = customText || defaultText;

  const buttonAreaWidth = workflowSection === 'running' ? '65%' : '50%';

  return (
    <BitaModal isModalOpen={isModalOpen} setModalState={closeModal} typeModal="abandoneModal">
      <div
        className={styles.pupUpTitleContainer}
        style={{
          display: 'flex',
          borderBottom: '1px solid #eee',
          alignItems: 'center',
          padding: '10px 0',
        }}
      >
        <Icons.Avanti style={{ marginRight: '20px' }} />
        <h2 style={{ margin: '0', padding: '0', fontSize: '22px', textTransform: 'uppercase' }}>
          Abandon Project
        </h2>
      </div>
      <div style={{ margin: '20px 0' }}>{text}</div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          float: 'right',
          width: buttonAreaWidth,
        }}
      >
        {optionButtons}
      </div>
      <div />
    </BitaModal>
  );
};

// Set default props
BitaExitWorkflowModal.defaultProps = {
  customText: '', // Default to an empty string
};

export default BitaExitWorkflowModal;
