import * as ACTIONS from '../../constants/chart-constants';

const initialState = {
  charts: {},
};

const chartReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.STORE_CHART: {
      const { backtestId, chartName, data } = action.payload;
      const chartsUpdated = { ...state.charts };
      chartsUpdated[backtestId] = { [chartName]: data };

      return { ...state, charts: chartsUpdated };
    }

    default: {
      return state;
    }
  }
};

export default chartReducer;
