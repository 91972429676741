export const getAttributionIndex = timeframe => {
  switch (timeframe.toUpperCase()) {
    case 'YTD':
      return 75;
    case '3M':
      return 73;
    case '1Y':
      return 74;
    case '3Y':
      return 76;
    case '5Y':
      return 77;
    case 'MAX':
      return 78;
    default:
      return 74;
  }
};
