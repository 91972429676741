import React, { useState } from 'react';
import Slider from 'react-input-slider';
import './BitaRangeSlider.scss';

const BitaSlider = ({ initialValue, maxValue, stepValue, onFinalDrag, onChange }) => {
  const [sliderState, setState] = useState(initialValue);
  const stylesSlider = {
    track: {
      backgroundImage: 'linear-gradient(270deg, #2962FF 0%, #0039CB 100%)',
    },
    active: {
      backgroundColor: 'transparent',
    },
    thumb: {
      height: 20,
      width: 20,
      borderRadius: `50%`,
      backgroundImage: `radial-gradient(#265FFC 20%,#ffffff 30%,#ffffff)`,
      display: 'flex',
      justifyContent: 'center',
    },
  };

  const rangeLabel = () => {
    const rangeValue = sliderState.x;
    switch (true) {
      case rangeValue < 3:
        return 'Low';
      case rangeValue === 3:
        return 'Mid';
      case rangeValue > 3:
        return 'High';
      default:
        return 'Mid';
    }
  };

  return (
    <div className="containerRange">
      <Slider
        styles={stylesSlider}
        axis="x"
        x={sliderState.x}
        xmin={1}
        xmax={maxValue}
        xstep={stepValue}
        onChange={({ x }) => {
          setState(oldState => ({ ...oldState, x }));
          onFinalDrag(x);
        }}
        onMouseUp={() => {
          onFinalDrag(sliderState.x);
        }}
      />
      <label>{rangeLabel()}</label>
    </div>
  );
};

export default BitaSlider;
