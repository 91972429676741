import React, { useState, useEffect, useRef, createContext, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as ACTION from '../../state/actions/websocket-actions-dispatcher';

const WebSocketContext = createContext(null);

export function restartWebSocket(socketRef, initializeWebsocket) {
  if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
    socketRef.current.close();
    initializeWebsocket();
  }
}

const MAX_RETRY_ATTEMPTS = 5;
const RETRY_INTERVAL = 5000; // Retry every 5 seconds

export function useWebSocket() {
  return useContext(WebSocketContext);
}

const Websocket = props => {
  const userToken = useSelector(state => state.auth.token);
  const socketRef = useRef(null);
  const dispatch = useDispatch();

  let numMessages = 0;
  let retryAttempts = 0;

  const onmessage = evt => {
    if (evt.data === 'ping' && socketRef.current?.readyState === 1) {
      console.info('Received PING');
      socketRef.current.send('pong');
      return;
    }

    if (socketRef.current?.readyState !== 1) return;

    if (JSON.parse(evt.data)?.action === 'Logout') {
      console.info('RECEIVED SESSION LOGOUT BUT WS IS JUST STARTED, IGNORING MESSAGE');
      return;
    }

    const d = new Date();
    console.info(
      `Received WebSocket data at ${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}:${d.getMilliseconds()}`,
    );
    numMessages += 1;
    console.info(`WebSocket [x]: new DATA ${new Date()} ${numMessages}*\n`);
    dispatch(ACTION.newWebsocketReceivedMessage(evt.data));
  };

  const onclose = () => {
    console.info('WS CONNECTION CLOSE');
    dispatch(ACTION.websocketChangeStatus({ connected: false }));

    // disable eslint for this line because it's a known issue
    // eslint-disable-next-line
    retryWebSocketConnection();
  };

  const onopen = () => {
    dispatch(ACTION.websocketChangeStatus({ connected: true }));
    socketRef.current = new WebSocket(
      `${process.env.REACT_APP_WEBSOCKET_PATH}?api_key=${userToken}`,
    );
    socketRef.current.addEventListener('message', onmessage);
    socketRef.current.addEventListener('close', onclose);
    console.info('WebSocket connection realized');
  };

  const onerror = error => {
    console.error('WEBSOCKET ERROR: ', error.message);
  };

  const initializeWebsocket = () => {
    console.info('WS: trying to connect ', process.env.REACT_APP_WEBSOCKET_PATH);
    const socket = new WebSocket(`${process.env.REACT_APP_WEBSOCKET_PATH}?api_key=${userToken}`);
    socket.addEventListener('open', onopen);
    socket.addEventListener('error', onerror);
  };

  const retryWebSocketConnection = () => {
    if (socketRef.current?.readyState !== 1 && retryAttempts < MAX_RETRY_ATTEMPTS) {
      console.info('WS not connected...retrying');
      setTimeout(() => {
        retryAttempts += 1;
        initializeWebsocket();
      }, RETRY_INTERVAL);
    } else if (socketRef.current?.readyState === 1) {
      console.info('WS connected');
      retryAttempts = 0;
    } else {
      console.info('WS connection attempts exhausted');
    }
  };

  useEffect(() => {
    if (userToken) {
      initializeWebsocket();
    }
  }, [userToken]);

  return (
    <WebSocketContext.Provider
      value={{
        send: message => socketRef.current?.send(message),
        restartWebSocket: () => restartWebSocket(socketRef, initializeWebsocket),
      }}
    >
      {props.children || null}
    </WebSocketContext.Provider>
  );
};

export default Websocket;
