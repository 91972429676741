import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ConfigYAML from '../../config/ConfigYAML';
import WorkflowModal from '../structures-product/WorkflowModal/WorkflowModal';

const RebalancingReconstitutionPage = () => {
  const [initialWorkFlow, setInitialWorkflow] = useState(null);
  const [modalOpened, setModalOpened] = useState(true);
  const history = useHistory();

  const setWorkflow = slides => {
    setInitialWorkflow(slides);
  };

  const closeModal = (value, stayAtWorkFlow) => {
    setModalOpened(value);
    const path = `/`;
    if (!stayAtWorkFlow) {
      history.push(path);
    }
  };

  useEffect(() => {
    document.title = `${process.env.REACT_APP_SITE_TITLE} | Index Builder`;
  }, []);

  useEffect(() => {
    const structuredProducts = ConfigYAML.jsonPath('$.rebalancing_reconstitution');
    const sections = Object.values(structuredProducts.sections).map(s => {
      const section = { ...s };
      if (section.widgets) {
        section.widgets = Object.values(section.widgets);
      } else if (section.components) {
        section.components = Object.values(section.components);
      }
      return section;
    });

    setWorkflow(sections.find(({ type }) => type === 'Slides'));
  }, []);
  return (
    <>
      {initialWorkFlow && (
        <WorkflowModal
          {...initialWorkFlow}
          isModalOpen={modalOpened}
          setModalState={closeModal}
          setComponent={setWorkflow}
        />
      )}
    </>
  );
};

export default RebalancingReconstitutionPage;
