import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash';
import FileSaver from 'file-saver';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { Icons } from '../../../../constants/icons';
import styles from '../workflow-modal.module.css';
import { GenerateTable } from './GenerateTable';
import BitaButton from '../../../../bitaComponents/BitaButton/BitaButton';
import Switcher from '../../../../bitaComponents/Switcher/Switcher';
import { toastInterceptor } from '../../../../utils/utils';

const uploadFileUniverse = (tokenUser, file) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/upload-file`, file, {
    headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${tokenUser}` },
  });
};

export const RebalacingReconstitutionSelectUniverses = props => {
  const [firstOptionSelect, setFirstOptionSelect] = React.useState(true);
  const [data, setData] = React.useState();
  const [files, setFiles] = React.useState('');
  const token = useSelector(state => state.auth.token);
  const history = useHistory();
  const dataTable = {
    universe: {
      apikey: 'EditUniverses',
      header: ['name', 'constituents', 'revision_type', 'created_at', 'updated_at', 'author'],
    },
    methodology: {
      apikey: 'Methodologies',
      header: ['name', 'caps', 'floors', 'rebalancing', 'reconstitution'],
    },
  };
  return (
    <Fragment>
      <div className="content-Widget">
        <div className={styles.rebalancingselectedTitle}>
          <div>
            <Icons.parameters />
            <div className={styles.optionTitle}>OPTION 1: UPLOAD YOUR {props.type}</div>
          </div>
          <div>
            {props.type === 'universe' ? (
              <Switcher.Switch
                className={styles.customRadio}
                onChange={option => setFirstOptionSelect(option)}
                checked={firstOptionSelect}
              />
            ) : (
              <BitaButton
                primaryWhite
                onClick={() => {
                  console.info(props.onclickMethodology);
                  props.onclickMethodology();
                  history.push('/index-builders/rebalancing-reconstitution');
                }}
                style={{ width: '200px' }}
                className="runBacktestStructured"
              >
                Go to Methodology Builder
              </BitaButton>
            )}
          </div>
        </div>
        {firstOptionSelect && props.type === 'universe' && (
          <div className={styles.contentFirstOption}>
            <div style={{ marginRight: '50px' }}>
              <div>
                <span>1 º STEP:</span>
                UNIVERSE TEMPLATE DOWNLOAD
              </div>
              <BitaButton
                primaryWhite
                onClick={() => {
                  FileSaver.saveAs(
                    `${process.env.PUBLIC_URL}/files/Eligible_Universe_Template.xlsx`,
                    `Eligible_Universe_Template.xlsx`,
                  );
                }}
                style={{ width: '200px' }}
                className="runBacktestStructured"
              >
                Eligible Universe Template
              </BitaButton>
            </div>
            <div>
              <div style={{ marginBottom: '10px' }}>
                <span>2º STEP:</span>
                UNIVERSE UPLOAD
              </div>
              <div className={styles.contentUpload}>
                <input
                  type="file"
                  // eslint-disable-next-line no-return-assign
                  onClick={e => (e.target.value = null)}
                  onChange={event => {
                    setFiles(event.target.files[0]);
                    const formData = new FormData();
                    formData.append('template', event.target.files[0]);
                    formData.append('type', 'universe-rebalancing');
                    uploadFileUniverse(token, formData)
                      .then(rsp => {
                        toast(`${rsp.data.message}`);
                        setData(rsp.data);
                      })
                      .catch(error => {
                        setFiles('');
                        toastInterceptor(error);
                      });
                  }}
                  name="file"
                  id="file"
                  className={styles.inputfile}
                />
                <label htmlFor="file">Upload File</label>
                {files && (
                  <p className={styles.nameFile}>
                    <strong>Uploaded File: </strong>
                    {files.name}
                  </p>
                )}
                {files && (
                  <BitaButton
                    primaryWhite
                    onClick={() => {
                      setFiles('');
                    }}
                    style={{ width: '100px', marginTop: '0px' }}
                  >
                    Delete
                  </BitaButton>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="content-Widget rruniverse">
        <div className={styles.rebalancingselectedTitle}>
          <div>
            <Icons.parameters />
            <div className={styles.optionTitle}>
              OPTION 2: SELECT {props.type === 'universe' ? 'AN UNIVERSE' : 'METHODOLOGY'} SAVED IN
              BITACORE
            </div>
          </div>
          <div>
            <Switcher.Switch
              className={styles.customRadio}
              onChange={option => setFirstOptionSelect(!option)}
              checked={!firstOptionSelect}
            />
          </div>
        </div>
        {!firstOptionSelect && (
          <GenerateTable
            key={dataTable[props.type].apikey}
            apiDataKey={dataTable[props.type].apikey}
            headers={dataTable[props.type].header}
            filterAuthor={false}
            search={false}
            onSelect={row => {
              props.onSelect(row);
            }}
            height="250px"
            rebalancing={props.type === 'universe'}
          />
        )}
      </div>
      {firstOptionSelect && props.type === 'universe' && (
        <BitaButton
          primary
          onClick={() => {
            props.onSelect({ File_name: files.name, ...data });
          }}
          style={{
            position: 'absolute',
            marginLeft: '35%',
            marginRight: '35%',
          }}
          disabled={props.type === 'methodology' || !(files?.name && data?.type)}
          className="runBacktestStructured"
        >
          Apply Rebalancing Universe
        </BitaButton>
      )}
    </Fragment>
  );
};
