/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as _ from 'lodash';
import BitaSpinner from '../../components/BitaSpinner/BitaSpinner';
import style from './BitaButton.module.scss';

const BitaButton = props => {
  if (props.primary && props.highlighted) {
    throw new Error(
      'the bitaButton component must have a single style attribute primary or highlighted)',
    );
  }

  const [clickedState, setClickedState] = useState(true);

  const {
    width,
    height,
    className,
    disabled,
    loading,
    textLoading,
    primary,
    primaryWhite,
    highlighted,
    loadingColor,
    autofocus,
    autofocusfixed,
    Ref,
    ...rest
  } = props;
  // if pass primary as a prop apply style.primary
  // if pass highlighted as a prop appy style.highlighted
  // in other case apply style default
  // eslint-disable-next-line no-nested-ternary
  const bitaComponenteStyle = primary
    ? style.primary
    : highlighted
    ? style.highlighted
    : primaryWhite
    ? style.primaryWhite
    : style.default;
  return (
    <button
      {...rest}
      type="button"
      autoFocus={autofocus}
      ref={Ref}
      onClick={props.onClick}
      className={`${
        props.children !== 'Download Factsheet' ? 'bitabutton' : 'whiteButton'
      } ${bitaComponenteStyle} ${style.text} ${className || ''} ${
        disabled ? 'disabled-component' : ''
      } ${autofocusfixed ? 'autofocusfixed' : ''}`}
      style={{
        width,
        height,
        cursor: loading && 'wait',
        padding: loading ? '0px 10px' : ' ',
        ...rest.style,
      }}
    >
      {loading ? (
        textLoading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              cursor: `${loading ? 'wait' : 'pointer'}`,
            }}
          >
            {' '}
            {textLoading || 'please wait'}{' '}
            <BitaSpinner
              color={loadingColor}
              pointers
              loading={loading}
              style={{ marginTop: '2px' }}
            />{' '}
          </div>
        ) : (
          <BitaSpinner color={loadingColor} pointers loading={loading} />
        )
      ) : (
        props.children
      )}
    </button>
  );
};

BitaButton.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  primary: PropTypes.bool,
  primaryWhite: PropTypes.bool,
  highlighted: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  loading: PropTypes.bool,
  loadingColor: PropTypes.string,
  autofocusfixed: PropTypes.bool,
};

BitaButton.defaultProps = {
  width: '25%',
  height: '27px',
  primary: false,
  primaryWhite: false,
  highlighted: false,
  disabled: false,
  loading: false,
  autofocusfixed: false,
  className: '',
  loadingColor: '#fff',
};

export default BitaButton;
