import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ConfigYAML from '../../config/ConfigYAML';
import WorkflowModal from '../structures-product/WorkflowModal/WorkflowModal';
import IndexBuilder from './IndexBuilder';

// import IndexPageBuilder from './IndexPageBuilder';

const IndexBuilderGenerator = () => {
  const [initialWorkFlow, setInitialWorkflow] = useState(null);
  const [parametrization, setParametrization] = useState(null);
  const summary = useSelector(state => state.summary);
  const [modalOpened, setModalOpened] = useState(true);
  const history = useHistory();
  const location = useLocation();

  const setWorkflow = slides => {
    setInitialWorkflow(slides);
  };

  const closeModal = (value, stayAtWorkFlow) => {
    setModalOpened(value);
    const path = `/`;
    if (!stayAtWorkFlow) {
      history.push(path);
    }
  };

  useEffect(() => {
    document.title = `${process.env.REACT_APP_SITE_TITLE} | Index Builder`;
    if (location.pathname.includes('/index-builders/rebalancing-reconstitution')) {
      if (summary?.['Index Builder']?.['Type Methodology']) {
        setModalOpened(false);
      } else {
        history.push('/index-builders/index-builders');
      }
    }
  }, []);

  useEffect(() => {
    const structuredProducts = ConfigYAML.jsonPath('$.index_builder');
    const sections = Object.values(structuredProducts.sections).map(s => {
      const section = { ...s };
      if (section.widgets) {
        section.widgets = Object.values(section.widgets);
      } else if (section.components) {
        section.components = Object.values(section.components);
      }
      return section;
    });

    setWorkflow(sections.find(({ type }) => type === 'Slides'));
    // setParametrization(sections.find(({ type }) => type === 'Default'));
    const defaultC = sections.find(({ type }) => type === 'Default');
    setParametrization({
      ...defaultC,
      components: defaultC.components.filter(
        item =>
          (item?.title !== 'Rebalancing & reconstitution' &&
            location.pathname.includes('/index-builders/rebalancing-reconstitution')) ||
          (item?.title !== 'Caps, Floors & Buffer' &&
            !location.pathname.includes('/index-builders/rebalancing-reconstitution')),
      ),
    });
  }, []);
  return (
    <>
      {initialWorkFlow && (
        <WorkflowModal
          {...initialWorkFlow}
          isModalOpen={modalOpened}
          setModalState={closeModal}
          setComponent={setWorkflow}
        />
      )}
      {!modalOpened && <IndexBuilder parametrization={parametrization} />}
    </>
  );
};

export default IndexBuilderGenerator;
