/* eslint-disable react/no-this-in-sfc */
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import BitaCharts from '../../../../bitaComponents/BitaCharts/BitaCharts';
import { TotalRecicledTheme } from '../../../../bitaComponents/BitaCharts/Themes';
import styles from '../EmissionsWaste.module.scss';
import StatsTable from './StatsTable';

const orderbyDate = (a, b, key) => {
  return new Date(a[key]) - new Date(b[key]);
};

const filterByDate = (item, key) => {
  return new Date(item[key]) >= new Date('2014-01-01');
};

export const TableDatesGenerator = (categories, data) => (
  <table className={styles.generatedTable} style={{ fontSize: '10px' }}>
    <thead className={styles.generatedTableHeader}>
      <tr>
        <th />
        {categories.map(timeframe => {
          return <th className={styles.restTableHeaders}>{timeframe}</th>;
        })}
      </tr>
    </thead>
    <tbody>
      {data.map(item => (
        <tr className={styles.generatedTableHeader}>
          {' '}
          <td style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>{item.name}</td>{' '}
          {item.data.length > 0 &&
            item.data.map(value => (
              <td className={styles.restTableHeaders}>{Number(value).toFixed(4) || 'N/A'}</td>
            ))}
          {item.data.length === 0 && <td className={styles.restTableHeaders}>N/A</td>}
        </tr>
      ))}
    </tbody>
  </table>
);

const MetricTonChar = ({ title, stats, tableTitle = 'Stats' }) => {
  const [categories, setCategories] = useState([]);
  const [series, setSeries] = useState([]);

  useEffect(() => {
    const _serie = [];
    if (Object.keys(stats).length > 0) {
      const scopes = ['Waste-to-Energy', 'Composting', 'Reused'];
      if (_.has(stats, [scopes[0]])) {
        setCategories(
          Object.values(stats[scopes[0]])
            .filter(i => filterByDate(i, 'timestamp'))
            .sort((a, b) => orderbyDate(a, b, 'timestamp'))
            .map(x => moment(x.timestamp).year()),
        );
      } else if (_.has(stats, [scopes[1]])) {
        setCategories(
          Object.values(stats[scopes[1]])
            .filter(i => filterByDate(i, 'timestamp'))
            .sort((a, b) => orderbyDate(a, b, 'timestamp'))
            .map(x => moment(x.timestamp).year()),
        );
      } else if (_.has(stats, [scopes[2]])) {
        setCategories(
          Object.values(stats[scopes[2]])
            .filter(i => filterByDate(i, 'timestamp'))
            .sort((a, b) => orderbyDate(a, b, 'timestamp'))
            .map(x => moment(x.timestamp).year()),
        );
      }
      // else if (_.has(stats, [scopes[3]])) {
      //   setCategories(
      //     Object.values(stats[scopes[3]])
      //       .filter(i => filterByDate(i, 'timestamp'))
      //       .sort((a, b) => orderbyDate(a, b, 'timestamp'))
      //       .map(x => moment(x.timestamp).year()),
      //   );
      // }
      scopes.forEach(key => {
        _serie.push({
          name: key.toUpperCase(),
          data: Object.values(stats[key])
            .filter(i => filterByDate(i, 'timestamp'))
            .sort((a, b) => orderbyDate(a, b, 'timestamp'))
            .map(x => Number((Number(x.value) * 100).toFixed(2))),
        });
      });
      setSeries(_serie);
    }
  }, [stats]);

  return (
    <>
      <div className={styles.tableContainer} style={{ padding: '0px' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '10px',
            marginRight: '10px',
          }}
        >
          <span className={styles.subtitleSize} style={{ fontWeight: 'bold', marginLeft: '10px' }}>
            {title}
          </span>
        </div>

        <BitaCharts
          key="totalRecycledMetricTons"
          options={{
            ...{
              xAxis: {
                categories,
              },

              series,
            },
          }}
          theme={TotalRecicledTheme}
          containerProps={{
            style: {
              padding: '5px 20px 0px 20px',
              minHeight: '300px',
              height: '100%',
              width: '100%',
            },
          }}
        />
      </div>
      <StatsTable
        title={tableTitle}
        categories={categories}
        series={series}
        yAxisLabel=""
        disclaimer="*The sum of Recycled, Reused, Composting and Waste-to-Energy refer to Total Recycled metric."
      />
    </>
  );
};

export default MetricTonChar;
