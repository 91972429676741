/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { useDispatch } from 'react-redux';
import FileSaver from 'file-saver';
import PageLayout from '../../components/Layout/PageLayout';
import * as CommonActions from '../../state/actions/common-actions';
import ConfigYAML from '../../config/ConfigYAML';
import BitaButton from '../../bitaComponents/BitaButton/BitaButton';
import { iconF } from '../../builder/components/lib/generatorIcons';
import './customer-service.scss';

const ImagenIcon = IconsModules => {
  const Imagen = iconF(IconsModules);
  return <Imagen />;
};

const CustomerService = () => {
  const dispatch = useDispatch();
  dispatch(CommonActions.resetSubheader());
  dispatch(CommonActions.appendSubheader('Customer Service'));
  const customer = ConfigYAML.jsonPath('$.customer_service').sections[0].widgets;
  const cardDownload =
    customer[1].components[0] &&
    customer[1].components[0].type === 'CardDownload' &&
    customer[1].components[0].components
      ? Object.values(customer[1].components[0].components)
      : false;

  React.useEffect(() => {
    document.title = `${process.env.REACT_APP_SITE_TITLE} | Customer Service`;
  }, []);

  return (
    <PageLayout>
      <div
        className="content customerService"
        style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}
      >
        {customer[0] && (
          <div className="content-Widget " style={{ width: 'calc( 50% - 10px)', height: '480px' }}>
            <div className="title">
              {customer[0].icon && ImagenIcon(customer[0].icon)}
              {customer[0].title && customer[0].title}
            </div>
            <div>{customer[0].text && customer[0].text}</div>
            {customer[0].components[0] && customer[0].components[0].text && (
              <div style={{ marginTop: '10px' }}> {customer[0].components[0].text}</div>
            )}

            <div className="clientSupport" style={{ display: 'flex', justifyContent: 'center' }}>
              <div>
                {ImagenIcon('mail')}
                <div>
                  Send an email to
                  <a href="mailto: bitacore@bitadata.com"> bitacore@bitadata.com </a>
                  to receive more information.
                </div>
              </div>
            </div>
          </div>
        )}
        {customer[1] && (
          <div className="content-Widget " style={{ width: 'calc( 50% - 10px)', height: '480px' }}>
            <div className="title">
              {customer[1].icon && ImagenIcon(customer[1].icon)}
              {customer[1].title && customer[1].title}
            </div>
            <div>{customer[1].text && customer[1].text}</div>
            <div className="contentCardDownload" style={{ display: 'flex' }}>
              {cardDownload &&
                cardDownload.map(card => (
                  <div
                    onClick={() => {
                      if (card.download) {
                        FileSaver.saveAs(
                          `${process.env.PUBLIC_URL}/files/${card.download}.pdf`,
                          `${card.download}.pdf`,
                        );
                      }
                    }}
                    style={{
                      opacity: card.disabled ? '0.6' : '1',
                      pointerEvents: card.disabled ? 'none' : ' ',
                    }}
                  >
                    <div>
                      {ImagenIcon('resource')} <div> {card.title} </div>
                    </div>
                    <div className="version"> {card.text} </div>
                  </div>
                ))}
            </div>
          </div>
        )}
        {customer[2] && (
          <div className="content-Widget " style={{ width: '100%' }}>
            <div className="title">
              {customer[2].icon && ImagenIcon(customer[2].icon)}
              {customer[2].title && customer[2].title}
            </div>
            <div>{customer[2].text && customer[2].text}</div>

            <a HREF="mailto:demo@bitadata.com?subject=Demo%20/%20Training%20Request">
              <BitaButton primary>Book Session</BitaButton>
            </a>
          </div>
        )}
      </div>
    </PageLayout>
  );
};

export default CustomerService;
