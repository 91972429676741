export const excludeUser = {
  organization_demo: {
    'optimized index builder': 'hidden',
    'optimized index': 'hidden',
    'structured index': 'hidden',
    'structured index builder': 'hidden',
    'factsheet builder': 'hidden',
  },
  demo_company: {
    'optimized index builder': 'hidden',
    'optimized index': 'hidden',
  },
  UBS: {
    'basket to index conversion': 'hidden',
  },
  'UBS APAC': {
    'basket to index conversion': 'hidden',
  },
  BNP: {
    'factsheet builder': 'hidden',
    'basket to index conversion': 'hidden',
  },
  Ultumus: {
    'basket to index conversion': 'hidden',
  },
  'Ultumus UAT': {
    'basket to index conversion': 'hidden',
  },
};
