export const defaultActiveApplyUniverse = {
  Geography: 'selection',
  Sectors: 'selection',
  Themes: 'selection',
  'ESG Issues': 'selection',
  'ESG Ratings': 'operator',
  Factors: 'operator',
  Liquidity: 'operator',
  Size: 'operator',
  Fundamentals: 'operator',
  'Single Instrument': 'securities',
  'Security Type': 'selection',
  'Sentiment & Alternative Data': 'operator_type',
  'Custom Filter': 'operator',
  'Additional Filter': 'Period',
  'iClima Themes': 'selection',
};
