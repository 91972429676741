import React from 'react';
import * as _ from 'lodash';
import moment from 'moment';
import { useLocation } from 'react-router';
import styles from './PerformanceAndRisk/PerformanceAndRisk.module.scss';

const IndexDescription = ({
  indexType,
  universe,
  methodology,
  start,
  end,
  author,
  benchmark,
  risk,
  parentIndex,
  frontParameters,
}) => {
  const location = useLocation();
  const today = moment();
  const adjustedDate = today.subtract(1, 'days');
  const formattedDate = adjustedDate.format('YYYY-MM-DD');

  const index_type = () => {
    if (_.has(frontParameters, ['Decrement Index'])) {
      return 'DECREMENT INDEX';
    }
    if (_.has(frontParameters, ['Leveraged Index'])) {
      return 'LEVERAGED INDEX';
    }
    if (_.has(frontParameters, ['Risk Control Index'])) {
      return 'RISK CONTROL INDEX';
    }
    if (_.has(frontParameters, ['New Structured Product'])) {
      return 'STRUCTURED INDEX';
    }
    return 'STRUCTURED INDEX';
  };

  return (
    <div className={styles.baseNamesContainer}>
      {indexType === 'structured_index' && (
        <div className={styles.baseContainer}>
          <span className={styles.baseTitle}>TYPE OF INDEX</span>
          <span className={styles.baseDescription}> {index_type()} </span>
        </div>
      )}
      {indexType === 'optimized_index' && (
        <div className={styles.baseContainer}>
          <span className={styles.baseTitle}>PARENT INDEX</span>
          <span className={styles.baseDescription}> {parentIndex} </span>
        </div>
      )}
      {indexType === 'rebalancing_module' && (
        <div className={styles.baseContainer}>
          <span className={styles.baseTitle}>
            {indexType !== 'optimized_index' ? 'BASE UNIVERSE' : 'PARENT INDEX UNIVERSE'}
          </span>
          {universe.length === 0 ? (
            <span className={styles.baseDescription}>Universe Uploaded</span>
          ) : (
            <span className={styles.baseDescription}> {universe} </span>
          )}
        </div>
      )}
      {indexType !== 'structured_index' &&
        indexType !== 'basket_index' &&
        indexType !== 'rebalancing_module' && (
          <div className={styles.baseContainer}>
            <span className={styles.baseTitle}>
              {indexType !== 'optimized_index' ? 'BASE UNIVERSE' : 'PARENT INDEX UNIVERSE'}
            </span>
            <span className={styles.baseDescription}> {universe} </span>
          </div>
        )}
      {indexType === 'basket_index' && (
        <div className={styles.baseContainer}>
          <span className={styles.baseTitle}>BASE PORTFOLIO</span>
          <span className={styles.baseDescription}>
            {' '}
            {frontParameters?.PortfolioSelected?.value}{' '}
          </span>
        </div>
      )}
      {indexType !== 'structured_index' && indexType !== 'basket_index' && (
        <div className={styles.baseContainer}>
          <span className={styles.baseTitle}>
            {indexType !== 'optimized_index' ? 'BASE METHODOLOGY' : 'PARENT INDEX METHODOLOGY'}
          </span>{' '}
          <span className={styles.baseDescription}> {methodology || ''} </span>
        </div>
      )}
      <div className={styles.datesDescription}>
        <div className={styles.baseContainer}>
          <span className={styles.baseTitle}>START DATE</span>
          <span className={styles.baseDescription}> {start} </span>
        </div>
        <div className={styles.baseContainer}>
          <span className={styles.baseTitle}>END DATE</span>
          <span className={styles.baseDescription}>
            {location.search.includes('live_index') ? formattedDate : end}
          </span>
        </div>
      </div>
      <div className={styles.baseContainer}>
        <span className={styles.baseTitle}>AUTHOR</span>
        <span className={styles.baseDescription}> {author} </span>
      </div>
      <div className={styles.datesDescription}>
        <div className={styles.baseContainer}>
          <span className={styles.baseTitle}>CREATED WITH BENCHMARK</span>
          <span className={styles.baseDescription}> {benchmark} </span>
        </div>
        <div className={styles.baseContainer}>
          <span className={styles.baseTitle}>RISK MODEL</span>
          <span className={styles.baseDescription}> {risk} </span>
        </div>
      </div>
    </div>
  );
};

export default IndexDescription;
