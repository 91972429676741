/* eslint-disable consistent-return */
/* eslint-disable no-case-declarations */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import styles from '../Summary.module.scss';
import { summaryFormat } from './summaryFormat';

export const SUMMARY_ENTRY_TYPES = {
  NUMBER_OF_CONSTITUENTS: 'NUMBER_OF_CONSTITUENTS',
  WEIGHTING_MECHANISM: 'WEIGHTING_MECHANISM',
  SINGLE_PORCENGATE_VALUE: 'SINGLE_PORCENGATE_VALUE',
  EX_DATE_WITH_PROFORMA: 'EX_DATE_WITH_PROFORMA',
  FAST_ENTRY: 'FAST_ENTRY',
  DELETION_REPLACEMENT_RULES: 'DELETION_REPLACEMENT_RULES',
  SECURITIES: 'SECURITIES',
  ESGTOPICS_FILTERS: 'ESGTOPICS_FILTERS',
  THEMES_FILTERS: 'THEMES_FILTERS',
  SECTORS_FILTERS: 'SECTORS_FILTERS',
  CURRENCY: 'CURRENCY',
  RETURN_CALCULATION: 'RETURN_CALCULATION',
  STRUCTURED_CURRENCY: 'STRUCTURED_CURRENCY',
  STRUCTURED_WEIGHTING: 'STRUCTURED_WEIGHTING',
  STRUCTURED_REBALANCING_RECONSTITUTION: 'STRUCTURED_REBALANCING_RECONSTITUTION',
  STRUCTURED_RISK: 'STRUCTURED_RISK',
  UNIVERSE_SELECTED: 'UNIVERSE_SELECTED',
  RECONSTITUTION_CAPS: 'RECONSTITUTION_CAPS',
  RECONSTITUTION_FLOORS: 'RECONSTITUTION_FLOORS',
  ARRAY_DATA_TYPE: 'ArrayDataType',
};
// Esto simula una factory de summary entries
const SummaryEntry = props => {
  const { data } = props;
  let indexEntry = 0;
  const summaryFormatPostText = summaryFormat();
  return (
    <>
      {Array.isArray(data) &&
        data.map(entry => (
          <div className={styles.widgetContainer}>
            {Object.values(entry).map((entryValue, entryIndex) => {
              let innerValue;
              const isLastItem = Object.values(entry).length - 1 === entryIndex;
              if (typeof entryValue === 'object') {
                if (isLastItem) {
                  innerValue = `[${entryValue.value} ${summaryFormatPostText[entryIndex]}]`;
                } else innerValue = entryValue.value;
                return <span>{innerValue}</span>;
              }
              if (isLastItem) {
                innerValue = `[${entryValue} ${summaryFormatPostText[entryIndex]}]`;
              } else innerValue = entryValue;
              return <span>{innerValue}</span>;
            })}
            <div className={styles.separator} />
          </div>
        ))}
      {typeof data === 'object' && props.type && props.type === 'Rebalancing-Reconstitution'
        ? Object.entries({ simple: '' }).map(([fieldKey, fieldValue], ind) => {
            return (
              <div key={ind}>
                <div>{data.Reconstitution && data.Reconstitution}</div>
                <div>{data.Rebalancing || data.rebalancing || ''}</div>
                <div>
                  {data.Reconstitution !== 'Same as Rebalancing' && data['Ex-Date'] && 'Ex-Date'}
                </div>
                <div>
                  {(data.Reconstitution !== 'Same as Rebalancing' &&
                    data.Reconstitution !== 'Custom Signal' &&
                    `${(data['Ex-Date'] && data['Ex-Date'].value) || ''}, ${(data.Day &&
                      data.Day.value) ||
                      ''}, ${(data.Months && data.Months.value) || ''}`) ||
                    (data['Custom Signal Dropdown'] && data['Custom Signal Dropdown'].value) ||
                    ''}
                </div>
                <div>
                  {(data.Reconstitution !== 'Same as Rebalancing' && data['Determination date'] ? (
                    <div>Determination: {data['Determination date'].value} days before ex-date</div>
                  ) : (
                    ''
                  )) ||
                    (data['Signal Selection Dropdown'] &&
                      data['Signal Selection Dropdown'].value) ||
                    ''}
                </div>
              </div>
            );
          })
        : typeof data === 'object' && props.type === 'securities-considerations'
        ? Object.entries({ simple: '' }).map(([fieldKey, fieldValue], ind) => {
            return (
              <div>
                {data?.Rule?.value === 'Highest Ranked' ? (
                  <>
                    <div>{data?.adrs || ' '}</div>
                    <div>{data.ipo_rule || ' '}</div>
                    <div>{data.Period?.value || ''}</div>
                    <div>{`[Shareclass Consideration rule]: ${data?.Rule?.value}, [Rank by]: ${data?.rank_by?.value}, [Ranking type]:  ${data?.ranking_type?.value}`}</div>
                    <div>{data.Rule?.value || ''}</div>
                  </>
                ) : (
                  <>
                    <div>{data?.adrs || ' '}</div>
                    <div>{data.ipo_rule || ' '}</div>
                    <div>{data.Period?.value || ''}</div>
                    <div>{data.share_class_rule || ' '}</div>
                    <div>{data.Rule?.value || ''}</div>
                  </>
                )}
                <div>
                  {data.minimum_foreign_ownership_limit || ' '}
                  {data['Only include instruments whose Foreign Ownership Limit is more than']
                    ? ` [${data['Only include instruments whose Foreign Ownership Limit is more than']}%]`
                    : ''}
                </div>

                <div>
                  {data.minimum_foreign_room || ' '}
                  {data['Only include instruments whose Foreign Room is more than']
                    ? ` [${data['Only include instruments whose Foreign Room is more than']}%] `
                    : ''}
                </div>
                <div>
                  {data.minimum_free_float_available || ' '}
                  {data[
                    'Only include instruments whose Free Float Available to Foreign Investors is more than'
                  ]
                    ? ` [${data['Only include instruments whose Free Float Available to Foreign Investors is more than']}%]`
                    : ''}
                </div>
                <div>
                  {data.minimum_free_float_percentage || ' '}
                  {data['Only include instruments whose Free Float Market Cap is more than']
                    ? ` [${data['Only include instruments whose Free Float Market Cap is more than']}%]`
                    : ''}
                </div>
              </div>
            );
          })
        : data &&
          Object.entries(data).map(([valuekey, valueEntry], ind) => {
            if (valueEntry) indexEntry += 1;
            if (Array.isArray(valueEntry)) {
              return (
                <div key={ind}>
                  {/* eslint-disable-next-line consistent-return */}
                  {valueEntry.map((field, index) => {
                    if (index < 10) {
                      if (typeof field !== 'object') {
                        return `${field}${valueEntry.length - 1 === index ? '' : ', '}`;
                      }
                      if (field.id && field.value) {
                        return `${field.value}${valueEntry.length - 1 === index ? '' : ', '}`;
                      }
                      return (
                        <div
                          className={
                            Object.entries(field).length > 2
                              ? data.user_defined_weight
                                ? styles.rowWidget
                                : styles.widgetContainer
                              : styles.rowWidget
                          }
                        >
                          {Object.entries(field).map(([fieldKey, fieldValue], fieldIndex) => (
                            <div key={fieldKey + fieldValue + fieldIndex}>
                              {fieldKey === 'selection' && Array.isArray(fieldValue)
                                ? fieldValue.map(itemSel => `${itemSel.value},`)
                                : fieldValue &&
                                  (fieldValue?.value
                                    ? fieldValue.value
                                    : ` [${fieldValue}   ${
                                        summaryFormatPostText[fieldKey]
                                          ? summaryFormatPostText[fieldKey]
                                          : field?.operator?.value === 'Bottom (%)' ||
                                            field?.operator?.value === 'Top (%)'
                                          ? '%'
                                          : ''
                                      }]${
                                        Object.entries(field).length - 1 === fieldIndex ? '' : ', '
                                      }`)}
                            </div>
                          ))}
                          <div className={styles.separator} />
                        </div>
                      );
                    }
                  })}
                  {valueEntry.length > 10 ? `+ ${valueEntry.length - 10} More` : null}
                </div>
              );
            }
            if (typeof valueEntry !== 'object') {
              if (indexEntry === 1) {
                return (
                  <span key={valueEntry} style={{ textTransform: 'uppercase' }}>
                    {valueEntry}
                    {summaryFormatPostText[Object.keys(data)?.[0]]
                      ? summaryFormatPostText[Object.keys(data)?.[0]]
                      : ''}
                  </span>
                );
              }
              if (
                valueEntry !== 'undefined' &&
                indexEntry === Object.values(data).length &&
                indexEntry > 2
              ) {
                return (
                  <span key={valueEntry}>
                    [{valueEntry}
                    {summaryFormatPostText[valuekey] ? summaryFormatPostText[valuekey] : ''}]
                  </span>
                );
              }
              return <span key={valueEntry}>{valueEntry}</span>;
            }
            if (indexEntry === 1) {
              return (
                <span key={valueEntry.value} style={{ textTransform: 'uppercase' }}>
                  {valueEntry.value}
                </span>
              );
            }
            if (
              valueEntry !== 'undefined' &&
              indexEntry === Object.values(data).length &&
              indexEntry > 2 &&
              !Array.isArray(data)
            ) {
              return <span key={valueEntry.value}>[{valueEntry.value}]</span>;
            }
            return <span key={valueEntry.value}>{valueEntry.value}</span>;
          })}
    </>
  );
};

export default SummaryEntry;
