import React, { useEffect, useRef } from 'react';

const VideoPlayer = ({ videoId }) => {
  const playerRef = useRef(null);

  useEffect(() => {
    const cleanUpPlayer = () => {
      if (playerRef.current && playerRef.current.destroy) {
        playerRef.current.destroy();
      }
    };

    if (window.YT && window.YT.Player) {
      cleanUpPlayer();
      playerRef.current = new window.YT.Player('youtube-player', {
        height: '360',
        width: '640',
        videoId: `${videoId}?rel=0`,
        playerVars: {
          loop: 1,
          autoplay: 1,
          rel: 0,
          modestbranding: 1,
          showinfo: 0,
        },
        events: {
          onStateChange(event) {
            if (event.data === 0) {
              playerRef.current.stopVideo();
            }
          },
        },
      });
    }

    return () => {
      cleanUpPlayer();
    };
  }, [videoId]);

  return <div id="youtube-player" />;
};

export default VideoPlayer;
