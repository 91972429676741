import React, { useState } from 'react';
import { Icons } from '../../../../constants/icons';
import PageBuilder from '../../PageBuilder/PageBuilder';

export const BitaContentWidget = props => {
  const { widget } = props;
  const [stepData, setStepData] = useState({});

  return (
    <div data-simplebar className="content_parameters scrollParameters">
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Icons.account style={{ marginRight: '10px' }} />
        {widget.title}
      </div>
      {Object.values(widget.components).map(component => (
        <PageBuilder {...component} stepData={stepData} setStepData={setStepData} kind="widget" />
      ))}
    </div>
  );
};
