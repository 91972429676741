import { useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import { useCurrentModule } from '../../../bitaHooks';
import BitaCard from '../../../components/BitaCard/BitaCard';
import styles from '../../builder.module.scss';
import { iconF } from '../lib/generatorIcons';

export const BuilderCardComponent = ({
  icon,
  onclick,
  setterFunction = Function.prototype,
  description = '',
  title,
  historyPlan = {},
  disabled = false,
}) => {
  let status = 'ACTIVE';
  const userPlan = {};

  const moduls = useSelector(state => state.auth.plan.modules);
  const summary = useSelector(state => state.summary?.['Universe Builder']?.['Filter Group 1']);
  if (moduls) {
    moduls.forEach(module => {
      userPlan[module.name] = module;
    });
  }

  const currentModule = useCurrentModule;

  if (!historyPlan.module && Object.values(userPlan).length > 0) {
    // eslint-disable-next-line no-param-reassign
    historyPlan.module = currentModule()[0].toUpperCase();
    if (userPlan[historyPlan.module]?.functionalities?.length > 0) {
      userPlan[historyPlan.module].functionalities.forEach(functionality => {
        if (functionality?.name?.toUpperCase() === title.toUpperCase()) {
          status = functionality.status_plan;
        }
      });
    }
    if (userPlan?.[historyPlan?.module]?.sub_modules?.length > 0) {
      userPlan[historyPlan.module].sub_modules.forEach(subModule => {
        if (subModule.name.toUpperCase() === title.toUpperCase()) {
          status = subModule.status_plan;
        }
      });
    }
  }

  if (
    (summary?.Themes?.operator_type && title === 'iClima Themes') ||
    (summary?.['iClima Themes']?.operator_type && title === 'Themes')
  ) {
    status = 'INACTIVE';
  }

  if (disabled) {
    status = 'INACTIVE';
  }

  if (status === 'ACTIVE' || status === 'INACTIVE') {
    return (
      <BitaCard
        text={description}
        className={styles.builderCard}
        title={title}
        cardType={BitaCard.FILTER}
        icon={iconF(icon)}
        status={status}
        styles={{
          opacity: status === 'ACTIVE' ? '1' : '0.5',
          pointerEvents: status === 'ACTIVE' ? 'auto' : 'none',
        }}
        onClick={() => {
          setterFunction(onclick, title);
        }}
      />
    );
  }

  return <></>;
};
