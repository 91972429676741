import * as ACTIONS from '../../constants/sidebar-constants';

const initialState = {
  activatedModules: {
    'UNIVERSE BUILDER': {
      showModule: true,
      showChildren: true,
    },
    'INDEX BUILDER': {
      showModule: true,
      showChildren: true,
    },
    'OPTIMIZED INDEX': {
      showModule: true,
      showChildren: true,
    },
    'STRUCTURED INDEX': {
      showModule: true,
      showChildren: true,
    },
    ANALYZER: {
      showModule: true,
      showChildren: false,
    },
  },
};

const sidebarReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SET_MODULE_STATE:
      return {
        ...state,
        activatedModules: {
          ...state.activatedModules,
          [action.payload.moduleName]: {
            ...state[action.payload.moduleName],
            showModule: action.payload.moduleState,
          },
        },
      };
    case ACTIONS.ACTIVATE_MODULE:
      return {
        ...state,
        activatedModules: {
          ...state.activatedModules,
          [action.payload.moduleName]: {
            ...state.activatedModules[action.payload.moduleName],
            showModule: true,
          },
        },
      };
    case ACTIONS.DEACTIVATE_MODULE:
      return {
        ...state,
        activatedModules: {
          ...state.activatedModules,
          [action.payload.moduleName]: {
            ...state.activatedModules[action.payload.moduleName],
            showModule: false,
          },
        },
      };
    case ACTIONS.SHOW_CHILDREN:
      return {
        ...state,
        activatedModules: {
          ...state.activatedModules,
          [action.payload.moduleName]: {
            ...state.activatedModules[action.payload.moduleName],
            showChildren: true,
          },
        },
      };
    case ACTIONS.HIDE_CHILDREN:
      return {
        ...state,
        activatedModules: {
          ...state.activatedModules,
          [action.payload.moduleName]: {
            ...state.activatedModules[action.payload.moduleName],
            showChildren: false,
          },
        },
      };
    default:
      return state;
  }
};

export default sidebarReducer;
