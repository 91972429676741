import * as _ from 'lodash';
import * as ACTIONS from '../../../constants/backtest-constants';
import processMessage from './backtest-metrics-helper';
import { LocalStorage } from '../../../localStorage';
import * as STATUS from '../../../constants/backtest-status';

const baseBacktest = {
  status: STATUS.BACKTEST_RUNNING,
  popUpData: null,
  backtestIndexData: {},
  backtestSaveStats: {
    SINGLE: [],
    JSON: [],
    TIMESERIES: [],
    'JSON TIMESERIES': [],
  },
  infoMessage: null,
  timestamp: null,
  id: null,
  data: null, // data va a incluir runBacktestForm
};

const initialState = {
  /* displayBacktestModal: false, // this is a bit confusing, this modal is the final modal that shows the chart
  displayRunningBacktest: { backtestinbackground: false, popUpData: null },
  backtestCompleted: false,
  backtestError: false,
  popUpOpen: false,
  popUpData: null,
  backtestIndexData: {},
  backtestSaveStats: {},
  infoMessage: null, */
  idDisplayedRunningBacktest: null,
  idCompletedRunningBacktest: null, // realmente esta propiedad no se usa...solo idDisplayedRunningBacktest se eliminará en un futuro
  backtests: {},
  pdfs: [],
  cancelledBacktests: LocalStorage.load('cancelledBacktests') || [],
  backtestsWError: [],
  runBacktestForm: {
    riskModelSelected: null,
    type: null,
    formData: null,
    parent_index_id: null,
    parent_index_startdate: null,
    parent_index: null, // <- por favor definir la estructura de redux desde un inicio de esta forma  y no introducir nuevas propiedades de forma dinámica, dado que hace
    // complicado luego entender y refactorizar el código
  },
  backtestDetails: [],
};

const backtestReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SET_BACKTEST: {
      const backtestID = action.payload.data.id;
      return {
        ...state,
        idDisplayedRunningBacktest: backtestID,
        backtests: {
          ...state.backtests,
          [backtestID]: {
            ...baseBacktest,
            timestamp: Date.now(),
            id: backtestID,
            data: {
              ...action.payload.data.data,
              ...state.runBacktestForm,
            },
            popUpData: action.payload.data.popUpData,
          },
        },
      };
    }

    case ACTIONS.DISPLAY_RUNNING_BACKTEST_WITH_ID: {
      const id = state.idDisplayedRunningBacktest === action.payload ? null : action.payload;

      return { ...state, idDisplayedRunningBacktest: id };
    }

    case ACTIONS.DISPLAY_COMPLETE_BACKTEST_WITH_ID: {
      const id = state.idCompletedRunningBacktest === action.payload ? null : action.payload;
      return { ...state, idCompletedRunningBacktest: id };
    }

    case ACTIONS.SET_FORM_DATA: {
      return { ...state, runBacktestForm: { ...state.runBacktestForm, formData: action.payload } };
    }

    case ACTIONS.SET_RISK_MODEL: {
      return {
        ...state,
        runBacktestForm: { ...state.runBacktestForm, riskModelSelected: action.payload },
      };
    }

    case ACTIONS.STORE_TYPE: {
      return { ...state, runBacktestForm: { ...state.runBacktestForm, type: action.payload } };
    }
    case ACTIONS.STORE_FRONT_PARAMETERS: {
      return {
        ...state,
        runBacktestForm: { ...state.runBacktestForm, front_parameters: action.payload },
      };
    }

    case ACTIONS.BACKTEST_STATUS_CHANGE: {
      const { id, newStatus } = action.payload;

      /* const stateNew = { ...state };
      stateNew.backtests[id].status = newStatus;

      if (id === state.idDisplayedRunningBacktest && newStatus === STATUS.BACKTEST_CANCELLED) {
        stateNew.idDisplayedRunningBacktest = null;
      }

      return stateNew; */

      const isCancelledStatus = newStatus === STATUS.BACKTEST_CANCELLED;
      const isFaillingBacktest = newStatus === STATUS.BACKTEST_WITH_ERROR;

      // si el backtest es EL cancelado oculta el modal de running backtest (id === nill)
      // si backtest es con error muestra el modal (id = id)
      // en otro caso queda igual
      const idDisplayedBacktest = () => {
        if (isCancelledStatus && id === state.idDisplayedRunningBacktest) return null;
        if (isFaillingBacktest) return id;
        return state.idDisplayedRunningBacktest;
      };

      const newState = {
        ...state,
        backtestsWError: [...state.backtestsWError, ...(isFaillingBacktest ? [id] : [])],
        cancelledBacktests: [...state.cancelledBacktests, ...(isCancelledStatus ? [id] : [])],
        idCompletedRunningBacktest:
          id === state.idCompletedRunningBacktest && isCancelledStatus
            ? null
            : state.idCompletedRunningBacktest,
        idDisplayedRunningBacktest: idDisplayedBacktest(),
        backtests: { ...state.backtests, [id]: { ...state.backtests[id], status: newStatus } },
      };

      // guardamos en localStorage
      if (isCancelledStatus) LocalStorage.save('cancelledBacktests', newState.cancelledBacktests);

      return newState;
    }

    case ACTIONS.SET_BACKTEST_COMPLETED: {
      const id = action.payload;
      const backtest = state.backtests[id];

      if (backtest?.status === STATUS.BACKTEST_CANCELLED || !backtest?.popUpData) {
        return state;
      }

      return {
        ...state,
        idDisplayedRunningBacktest:
          id === state.idDisplayedRunningBacktest ? null : state.idDisplayedRunningBacktest,
        idCompletedRunningBacktest: action.payload,
        backtests: {
          ...state.backtests,
          [id]: {
            ...state.backtests[id],
            status: STATUS.BACKTEST_COMPLETED,
          },
        },
      };
    }

    case ACTIONS.STORE_INFO_MESSAGE: {
      /* console.info('updating REDUX message ', action.payload, ' old status is ', state.infoMessage);
      const newState = { ...state };
      const backtest = newState.backtests[action.payload.id];

      if (backtest && backtest.status !== STATUS.BACKTEST_CANCELLED) {
        backtest.infoMessage = action.payload.message;
      }

      return newState; */

      /* const a = {
        type: 'BACKTEST/STORE_INFO_MESSAGE',
        payload: { id: 2324, message: 'hola' },
      }; */

      const { id } = action.payload;
      if (state.cancelledBacktests.includes(id)) {
        return state;
      }

      return {
        ...state,
        backtests: {
          ...state.backtests,
          [id]: { ...state.backtests[id], infoMessage: action.payload.message },
        },
      };
    }

    case ACTIONS.HIDE_BACKTEST: {
      const { hide } = action.payload;
      const hideId = state.idDisplayedRunningBacktest;
      const backtests = { ...state.backtests };
      backtests[hideId].wasHide = hide;

      return { ...state, idDisplayedRunningBacktest: hide ? null : hideId, backtests };
    }

    case ACTIONS.STORE_PARENT_INDEX_ID: {
      return {
        ...state,
        runBacktestForm: { ...state.runBacktestForm, parent_index_id: action.payload },
      };
    }
    case ACTIONS.STORE_PARENT_INDEX: {
      return {
        ...state,
        runBacktestForm: { ...state.runBacktestForm, parent_index: action.payload },
      };
    }

    case ACTIONS.STORE_PARENT_INDEX_STARTDATE: {
      return {
        ...state,
        runBacktestForm: { ...state.runBacktestForm, parent_index_startdate: action.payload },
      };
    }

    case ACTIONS.STORE_BACKTEST_ERROR: {
      const backtests = { ...state.backtests };
      backtests[action.payload].status = STATUS.BACKTEST_WITH_ERROR;

      return {
        ...state,
        backtests,
        idDisplayedRunningBacktest: action.payload,
        backtestsWError: [...state.backtestsWError, action.payload],
      };
    }

    // ===end changes to multibacktest

    case ACTIONS.CLEAN_BACKTESTS: {
      return initialState;
    }

    case ACTIONS.RUN_BACKTEST_IN_BACKGROUND: {
      const newState = {
        ...state,
        displayRunningBacktest: {
          ...state.displayRunningBacktest,
          ..._.omitBy(action.payload, _.isNil),
        },
      };
      return newState;
    }

    case ACTIONS.SET_RUNNING_BACKTEST_MODAL_DISPLAY: {
      return { ...state, displayRunningBacktest: action.payload };
    }

    case ACTIONS.STORE_BACKTEST_METRIC: {
      const updatedBacktestWithMetrics = processMessage(action.payload, state);
      return { ...state, ...updatedBacktestWithMetrics };
    }

    case ACTIONS.STORE_PDF: {
      return { ...state, pdfs: action.payload };
    }
    case ACTIONS.STORE_ID: {
      return { ...state, id: action.payload };
    }
    /* case ACTIONS.STORE_TYPE: {  TODO me parece va a dar error con esto...tendré que revisar donde se está llamando
      return { ...state, type: action.payload };
    } */

    case ACTIONS.STORE_STRUCTURED_INSTRUMENT: {
      // @deprecated
      return { ...state, structured_instrument: action.payload };
    }
    case ACTIONS.STORE_SI_N_CONSTITUENTS: {
      // @deprecated
      return { ...state, structured_n_constituents: action.payload };
    }
    case ACTIONS.STORE_INDEX_SUBTYPE: {
      // @deprecated
      return { ...state, index_subtype: action.payload };
    }

    case ACTIONS.BACKTEST_TIME: {
      return { ...state, timestamp: new Date().getTime() };
    }
    case ACTIONS.SET_BACKTEST_MODAL_DISPLAY: {
      return { ...state, displayBacktestModal: action.payload };
    }

    case ACTIONS.DISCARD_BACKTEST: {
      // const infoMessage = action.payload.removeRunningBacktest ? null : state.infoMessage;

      return initialState;
    }

    case ACTIONS.ADD_BACKTEST_DETAILS: {
      const newBacktestDetails = [...state.backtestDetails, action.payload];
      return {
        ...state,
        backtestDetails: newBacktestDetails,
      };
    }

    case ACTIONS.GET_BACKTEST_DETAILS: {
      return state.backtestDetails;
    }

    default: {
      return state;
    }
  }
};

export default backtestReducer;
