import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import * as SummaryConstants from '../constants/summary-constants';

function useSummary(module, widget) {
  const summary = useSelector(state => state.summary);
  const dispatch = useDispatch();
  // const path = `${module}.${view}.${widget.toLowerCase()}`;

  const addToSummary = value => {
    dispatch({
      type: SummaryConstants.ADD_ENTRY,
      payload: { module, widget, data: value },
    });
  };
  const removeFromSummary = value => {
    dispatch({
      type: SummaryConstants.REMOVE_ENTRY,
      payload: { module, widget, entry: value },
    });
  };

  const resetSummary = () => {
    dispatch({
      type: SummaryConstants.REMOVE_ALL_ENTRIES,
      payload: { module },
    });
  };

  const summaryModuleData = modulePath => {
    // Think refactor

    return Object.values(
      Object.keys(summary)
        .filter(key => key.includes(modulePath))
        .map(value => ({ [`${value.split('.')[1]}.${value.split('.')[2]}`]: summary[value] })),
    ).reduce((acc, curr) => ({ ...acc, ...curr }), {});
  };

  return [addToSummary, removeFromSummary, resetSummary, summaryModuleData];
}

function useCurrentModule() {
  const location = useLocation();
  const currentModuleUrl = location.pathname.split('/')[1];
  const currentViewUrl = location.pathname.split('/')[2];

  let currentModule = '';
  switch (currentModuleUrl) {
    case 'universe-builder':
      currentModule = 'Universe Builder';
      break;
    case 'index-builders':
      currentModule = 'Index Builder';
      break;
    case 'optimized-index':
      currentModule = 'Optimized Index';
      break;
    case 'structures-product':
      currentModule = 'Structured Index';
      break;
    case 'index-analyzer':
      currentModule = 'Analyzer';
      break;
    case 'portfolio-basket-calculation':
      currentModule = 'Portfolio';
      break;
    default:
      break;
  }
  return [currentModule, currentViewUrl];
}

export { useSummary, useCurrentModule };
