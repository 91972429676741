/* eslint-disable camelcase */
// when receive websocket messages these are parsed and can be distributed to another actions and stores
import { useSelector } from 'react-redux';
import * as BACKTEST_ACTIONS from '../../constants/backtest-constants';
import { messageMaintenance } from '../../constants/maintenance-message-constants';
import { storeBacktestError, storeInformationMessage } from './backtest-actions';
import * as ApiDataActions from './api-data-actions';
import { storeChart } from './chart-actions';
import { WEBSOCKET_CHANGE_STATUS } from '../../constants/websocket-constants';
import { downloadService } from './download-actions';
import { logout } from './auth-actions';
import { LocalStorage } from '../../localStorage';
import store from '../store';

export const websocketChangeStatus = ({ connected }) => ({
  type: WEBSOCKET_CHANGE_STATUS,
  payload: connected,
});

export const backtestSaveMetric = payload => ({
  type: BACKTEST_ACTIONS.STORE_BACKTEST_METRIC,
  payload,
});

export const setBacktestCompleted = id => ({
  type: BACKTEST_ACTIONS.SET_BACKTEST_COMPLETED,
  payload: id,
});

export const savePDF = pdf => ({
  type: BACKTEST_ACTIONS.STORE_PDF,
  payload: pdf,
});

export const saveID = id => ({
  type: BACKTEST_ACTIONS.STORE_ID,
  payload: id,
});
const files = {};

// CENTRAL DISPATCHER, ALL JSON MESSAGES RECEIVED FOR THE WEBSOCKET
// ARE HANDLED BY THIS FUNCTION WHO DISPATCH TO THE REQUIRED ACTIONS

const cancelledStored = LocalStorage.load('cancelledBacktests') || [];

const dispatchJSONMessages = ({
  grouped_data,
  headers,
  action,
  data,
  maintenance_type,
  time,
}) => dispatch => {
  window.store = store;
  const s = store.getState()?.backtest?.cancelledBacktests;
  const cancelledBacktest = [...s, ...cancelledStored];
  if (action === 'Logout') {
    dispatch(logout('Session initialized in another device.'));
  }
  if (maintenance_type === 'URGENT') {
    dispatch(
      ApiDataActions.setApiData({
        title: 'modal',
        data: {
          open: true,
          text: messageMaintenance('URGENT'),
        },
      }),
    );
    dispatch(logout());
  }

  if (maintenance_type === 'PLANNED') {
    console.info('Logout in', time);
    dispatch(
      ApiDataActions.setApiData({
        title: 'modal',
        data: {
          open: true,
          text: messageMaintenance('PLANNED', time),
        },
      }),
    );
    dispatch(
      ApiDataActions.setApiData({
        title: 'maintenance',
        data: {
          time,
        },
      }),
    );
  }

  if (headers?.error) {
    dispatch(storeBacktestError(headers.id));
  }

  if (headers?.id && cancelledBacktest.includes(headers.id)) {
    return;
  }

  if (headers && headers.informative) {
    // debugger;
    setTimeout(() => {
      dispatch(storeInformationMessage(headers.id, headers.information));
    }, 0);
  }

  if (action && (action === 'PDF completed' || action === 'Excel completed')) {
    const pdfs = data?.key;

    if (!pdfs) console.error('received empty data for PDFS ', data);
    else if (data.request_download) {
      dispatch(downloadService(pdfs[0].fileData?.name));
    } else {
      pdfs.forEach(file => {
        switch (file.fileData?.type) {
          case 'price-series':
            files.price = file.fileData.name;
            break;
          case 'constituents':
            files.constituents = file.fileData.name;
            break;
          case 'constituents-pcf':
            downloadService(pdfs[0].fileData?.name);
            files.pcf_constituents = file.fileData.name;
            break;
          case 'index_metadata':
            downloadService(pdfs[0].fileData?.name);
            files.index_metadata = file.fileData.name;
            break;
          case 'factsheet':
            files.factsheet = file.fileData.name;
            break;
          case 'guidebook':
            files.guidebook = file.fileData.name;
            break;
          case 'report':
            files.report = file.fileData.name;
            break;
          default:
            break;
        }
      });

      const savePdf = {
        status: 'done',
        files,
      };
      dispatch(
        ApiDataActions.setApiData({
          title: 'Download Center',
          data: savePdf,
        }),
      );
      dispatch(saveID(data.index_id));
      dispatch(savePDF(savePdf));
    }
  }

  if (headers && headers.chart_data) {
    dispatch(
      storeChart({ backtestId: headers.id, chartName: headers.chart_data, data: grouped_data }),
    );
  }

  if (headers && headers.completed) {
    const d = new Date();
    console.info(
      `Se completo el backtest ${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}:${d.getMilliseconds()}`,
    );
    setTimeout(() => {
      // debugger;
      dispatch(setBacktestCompleted(headers.id));
    }, 2 * 1000);
  }
  // debugger;
  if (grouped_data) {
    // debugger;
    dispatch(backtestSaveMetric({ grouped_data, headers }));
  }
};

export const newWebsocketReceivedMessage = payload => dispatch => {
  try {
    console.info('Procesando mensaje del websocket');
    const data = JSON.parse(payload);
    dispatch(dispatchJSONMessages(data));
  } catch (err) {
    console.info(`WS: unable to process "${payload} "\nunknown message\n${err}`);
  }
};
/* eslint-enable camelcase */
