import React, { useRef, useLayoutEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { SideNav } from 'react-sidenav';
import { motion } from 'framer-motion';
import NavArrow from '../../bitaComponents/SideNavbar/lib/NavArrow';

/*
SIDENAVWRAPPER WRAPS THE SIDEBAR AND ALLOW THAT THE SIDEBAR SHOWS OPTIONS,COLLAPSE AND
DESCOLLAPSE ACCORDING TO THE URL

this has two ways differents to work, because the universe builder and index builder has 
different requirements:

if you pass a id as a props then the filter only descollapse when that exactly path match
with the url, also you can pass a props showFilter which is useful for instance, when even the
url match with the filter but you don't want to show (i.e because it's empty in the summary)

 you can pass a defaultBasePath instead of an id, which is less restrictive
 TODO: probably we can unify id and defaultBasePath in a single prop and simplify the code...
 */
const SideNavWrapper = props => {
  const showRef = useRef(false);
  const [showState, setShowState] = useState(false);

  const variants = {
    open: { opacity: 1, height: 'auto' },
    close: { opacity: 0, height: '0px', overflow: 'hidden' },
  };

  const location = useLocation();
  const path = location.pathname;
  // eslint-disable-next-line prefer-const
  let [navItem, body] = (Array.isArray(props.children) && props.children) || [];
  // it's only active when id is the same as the url or if you don't pass id as props then when the url
  // has filtergroup...in the first way is more restrictive and is used by universe builder
  const isFilterActive =
    (props.id === undefined && /filtergroup-?\d?$/.test(path)) || path === props.id;
  // if the url contains the word parametrization
  const isParametrizationActive = path.includes('parametrization') || /filter-\d*$/.test(path);
  const defaultBasePath = props.defaultPath.match(/\/[A-Za-z-]+/);

  // if you set props showFilter as false this will ignore if the url match and doesn't show
  // the filter content and even the "Filter" word (the nav Item)
  const showFilter = props.hideFilter === undefined ? true : !props.hideFilter;

  const mustShowContent =
    defaultBasePath &&
    defaultBasePath[0] &&
    path.includes(defaultBasePath[0]) &&
    (isFilterActive || isParametrizationActive);

  /*eslint-disable */
  useLayoutEffect(() => {
    const _newState = props.id ? path.includes(props.id) && mustShowContent : mustShowContent;
    if (_newState !== showRef.current) {
      showRef.current = _newState;
      setShowState(_newState);
    }

    return () => {
      // console.info('DESMONTANDO SIDENAV');
    };
  }, [path]);
  /* eslint-enable */

  navItem =
    navItem &&
    React.cloneElement(navItem, {
      selected: navItem.props.to === path || (isFilterActive && path.includes(props.defaultPath)),
      to: navItem.props.to !== path ? navItem.props.to : props.defaultPath,
      ...props,
    });

  return (
    <div>
      {showFilter && (
        <SideNav childrenToggleIndicator={NavArrow}>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <div style={{ position: 'relative' }}>
            {navItem}
            <NavArrow collapsed="collapsed" />
          </div>
          {/* if the user pass props.id then we need check the content, if not then only we need check mustShowContent */}
          {
            <motion.div variants={variants} animate={showState ? 'open' : 'close'} initial="close">
              {body}
            </motion.div>
          }
        </SideNav>
      )}
    </div>
  );
};

export default SideNavWrapper;
