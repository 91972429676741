import React, { useState } from 'react';
import Modal from 'react-modal';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
// eslint-disable-next-line import/no-cycle
import FileSaver from 'file-saver';
import styles from '../builderUploadPortfolio.module.scss';
import { Icons } from '../../../constants/icons';
import BitaButton from '../../../bitaComponents/BitaButton/BitaButton';
import * as ApiDataActions from '../../../state/actions/api-data-actions';
import { toastInterceptor } from '../../../utils/utils';

const uploadFileUniverse = (tokenUser, file) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/upload-file`, file, {
    headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${tokenUser}` },
  });
};

export const BuilderUploadPortfolioComponent = props => {
  const [files, setFiles] = React.useState('');
  const apiData = useSelector(state => state.apiData.apiData);
  const token = useSelector(state => state.auth.token);
  const dispatch = useDispatch();

  if (
    apiData?.methodologySelected?.parameters?.data?.exit_buffer_percentage !== '' ||
    apiData?.methodologySelected?.parameters?.data?.operations_name.filter(
      item => item === 'filter_buffer_fast' || item === 'filter_ranked_buffer',
    ).length > 0
  ) {
    return (
      <div className={styles.UploadPortfolio}>
        <div className={styles.externalTitle}>{props.title}</div>
        <div>
          <div style={{ display: 'flex' }}>
            <div className={styles.title}>1º STEP: </div>
            <div>DOWNLOAD CURRENT PORTFOLIO TEMPLATE</div>
          </div>
          <BitaButton
            height="28px"
            width="100px"
            primaryWhite
            onClick={() => {
              FileSaver.saveAs(
                `${process.env.PUBLIC_URL}/files/Current_Portfolio_Template.xlsx`,
                `Current_Portfolio_Template.xlsx`,
              );
            }}
          >
            Template
          </BitaButton>
          <div style={{ display: 'flex', marginBottom: '15px' }}>
            <div className={styles.title}>2º STEP: </div>
            <div>UPLOAD TEMPLATE</div>
          </div>
          <input
            type="file"
            // eslint-disable-next-line no-return-assign
            onClick={e => (e.target.value = null)}
            onChange={event => {
              setFiles(event.target.files[0]);
              const formData = new FormData();
              formData.append('template', event.target.files[0]);
              formData.append('type', 'universe-rebalancing');
              uploadFileUniverse(token, formData)
                .then(rsp => {
                  toast(`${rsp.data.message}`);
                  dispatch(
                    ApiDataActions.setApiData({
                      title: 'previousUniverseBacktest',
                      data: rsp.data,
                    }),
                  );
                })
                .catch(error => {
                  setFiles('');
                  toastInterceptor(error);
                });
            }}
            name="file"
            id="file"
            className={styles.inputfile}
          />
          <label htmlFor="file">Upload</label>
        </div>
      </div>
    );
  }
  return <div />;
};

export default BuilderUploadPortfolioComponent;
