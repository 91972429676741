import React, { useState, useLayoutEffect } from 'react';
import { uniqueId } from 'lodash';
import styles from '../../builder.module.scss';

export const BuilderRBMultipleComponent = ({
  options = [],
  onChange,
  path = '',
  default_option = false,
  id,
  option_text,
  exclusive_options = [],
  props,
}) => {
  const [activeOption, setActiveOption] = useState([default_option] || []);
  const [uniqueName] = useState(uniqueId('bitacheckbox-'));
  const [data, setData] = useState([]);
  let localPath = `${path}${id}`;
  if (path !== '' && id) {
    localPath = `${path}${id}`;
  }
  useLayoutEffect(() => {
    const storeOptions = Object.values(options).map(option => ({
      name: option,
      checked: activeOption.indexOf(option) > -1,
    }));
    setData(storeOptions);
    if (onChange) onChange(localPath, activeOption);
  }, [activeOption]);

  const addOption = (option, add) => {
    let newActiveOption;
    if (add) {
      if (Object.values(exclusive_options).indexOf(option) > -1) {
        setActiveOption([option]);
      } else {
        newActiveOption = activeOption.filter(x => x !== 'Normal');
        newActiveOption.push(option);
        setActiveOption(newActiveOption);
      }
    } else {
      newActiveOption = activeOption.filter(x => x !== option);
      setActiveOption(newActiveOption);
    }
  };

  return (
    <div
      className={styles.cfd_type_container}
      style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', width: 300 }}
    >
      {data.map(option => (
        <div style={props?.style} className={`${props?.className}`}>
          <input
            type="checkbox"
            className="css-checkbox"
            id={`radio-${uniqueName}-${option.name}`}
            style={{ marginRight: 5, borderColor: 'black' }}
            onChange={() => {
              if (option.checked) {
                addOption(option.name, false);
              } else {
                addOption(option.name, true);
              }
            }}
            checked={option.checked}
          />
          <label className="css-label" htmlFor={`radio-${uniqueName}-${option.name}`}>
            {option.name}
          </label>
        </div>
      ))}
    </div>
  );
};
