import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { get } from 'axios';
import { DatePickerInput } from 'rc-datepicker';
import { BiSolidError } from 'react-icons/bi';
import { toast } from 'react-toastify';
import BitaModal from '../../../bitaComponents/BitaModal/BitaModal';
import { ReactComponent as Logo } from '../../../static/img/Icons/BITA.svg';
import VisualizationTable from './tables/VisualizationTable';
import SingleTemplateTable from './tables/SingleTemplateTable';
import BitaButton from '../../../bitaComponents/BitaButton/BitaButton';
import EditTableTaxes from './tables/EditTableTaxes';
import FileUploader from './FileUploader';
import BitaInput from '../../../components/BitaInput/BitaInput';
import BitaCheckbox from '../../../components/BitaCheckbox/BitaCheckbox';
import style from '../PortfolioBasketCalculation.module.scss';
import { URL_CONSTANTS } from '../../../api-data-mapping/url-constants';
import { toastInterceptor } from '../../../utils/utils';
import { createTax, updateTax } from '../utils/api';
import './styleVisualizationModal.css';

const styleClose = {
  fontSize: '30px',
  fontWeight: 'bold',
  cursor: 'pointer',
  padding: '0 0 0 10px',
  boxSizing: 'border-box',
};

const VisualizationModal = ({
  isModalOpen,
  setModalState,
  templateData,
  select,
  updateTemplate,
}) => {
  const user = useSelector(state => state.auth);
  const { token } = user;
  const [tableToShow, setTableToShow] = useState('listTemplates');
  const [templateToEdit, setTemplateToEdit] = useState('');
  const [singleTemplateData, setSingleTemplateData] = useState({ values: [] });
  const [rowsToDelete, setRowsToDelete] = useState([]);

  useEffect(() => {
    setTableToShow('listTemplates');
  }, [isModalOpen]);

  const getSingleTemplate = async id => {
    const req = await get(`${URL_CONSTANTS.WITHHOLDING_TAX}/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    setSingleTemplateData(req?.data?.data[0]);
  };

  const transformedData = (templateData ?? [])?.map(template => {
    return {
      'WHT Table Name': template?.withholding_tax_name,
      'created by': template?.author_id,
      operations: 'listTemplates',
      'created at': new Date(template?.create_at ?? new Date()).toISOString().split('T')[0],
      id: template?.id,
    };
  });

  const dataRows = transformedData ?? [];
  const columns = Object.keys(transformedData?.[0] ?? {}).filter(item => item !== 'id');

  const transformedSingleTemplateData = singleTemplateData?.values?.map(template => {
    const transformedValue = {
      ...template,
      date_from: template?.date_from ?? template?.date,
    };
    delete transformedValue?.date;
    return transformedValue;
  });
  const singleTemplateDataRows = transformedSingleTemplateData ?? [];
  const singleTemplateColumns =
    Object.keys(transformedSingleTemplateData?.[0] ?? {}).filter(item => item !== 'id') ?? [];

  const handleChange = (key, id, value) => {
    setSingleTemplateData(prevState => {
      const updatedValues = [...prevState.values];

      updatedValues.forEach(item => {
        if (item.id === id) {
          item[key] = value;
        }
      });

      return {
        ...prevState,
        values: updatedValues,
      };
    });
  };

  const createWithholdingTax = () => {
    if (singleTemplateData?.withholding_tax_name?.toUpperCase() === 'BITA WITHHOLDING TAXES') {
      toast.error(`The name is already in use, please select a different name.`);
      return;
    }
    const body = {
      withholding_tax_name: singleTemplateData.withholding_tax_name,
      values: singleTemplateData.values.map(item => {
        return {
          value: parseFloat(
            typeof item?.value === 'string' ? item?.value?.replace(',', '.') : item?.value,
          ),
          date: item.date,
          iso_code: item?.['ISO Code'] ?? item?.iso_code,
        };
      }),
    };
    createTax(token, body)
      .then(rsp => {
        toast(`The WHT template ${singleTemplateData.withholding_tax_name} was saved successfully`);
        setTableToShow('listTemplates');
        getSingleTemplate(rsp.data.data);
        setSingleTemplateData({});
        select(rsp.data.data);
        setModalState(!isModalOpen);
      })
      .catch(error => {
        toastInterceptor(error);
      });
  };

  const updateWithholdingTax = () => {
    if (singleTemplateData?.withholding_tax_name?.toUpperCase() === 'BITA WITHHOLDING TAXES') {
      toast.error(`The name is already in use, please select a different name.`);
      return;
    }
    const body = {
      ...singleTemplateData,
      withholding_tax_id: singleTemplateData.id,
      values: singleTemplateData.values.map(item => {
        return {
          value: parseFloat(
            typeof item?.value === 'string' ? item?.value?.replace(',', '.') : item?.value,
          ),
          date: item.date,
          iso_code: item?.['ISO Code'] ?? item?.iso_code,
        };
      }),
    };
    updateTax(token, body)
      .then(rsp => {
        toast(
          `The WHT template ${singleTemplateData.withholding_tax_name} was updated successfully`,
        );
        setTableToShow('listTemplates');
        setSingleTemplateData({});
        updateTemplate();
      })
      .catch(error => {
        toastInterceptor(error);
      });
  };

  const thereAreBadValues = () => {
    const badValues = [];
    // eslint-disable-next-line no-unused-expressions
    singleTemplateData?.values?.forEach(val => {
      if (val?.value < 0 || val?.value > 100) {
        badValues.push(val?.value);
      }
    });
    return badValues?.length > 0;
  };

  return (
    <>
      <BitaModal
        isModalOpen={isModalOpen}
        setModalState={setModalState}
        style={{ minWidth: '80%' }}
      >
        <div style={{ Width: '100%', minHeight: '380px' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              borderBottom: '1px solid #eee',
              padding: '10px 0',
            }}
          >
            <Logo style={{ width: '30px', height: '30px', marginRight: '20px' }} />
            <h2
              className="contentBreadcrumbs"
              style={{
                margin: '0',
                padding: '0',
                fontSize: '16px',
                textTransform: 'uppercase',
                textAlign: 'left',
                display: 'flex',
                marginLeft: '-10px',
                width: '100%',
              }}
            >
              <span
                onClick={() => setTableToShow('listTemplates')}
                style={{
                  cursor: tableToShow !== 'listTemplates' ? 'pointer' : 'auto',
                }}
              >
                {tableToShow === 'listTemplates'
                  ? 'Withholding Taxes Create or Edit'
                  : 'Withholding Tax Template - Preview'}
              </span>
              {tableToShow === 'visualize' && <span>Selected Template: {templateToEdit}</span>}
              {tableToShow === 'edit' && <span>Edit</span>}
              {tableToShow === 'create' && <span>Create</span>}
            </h2>
            <span onClick={() => setModalState(!isModalOpen)} style={styleClose}>
              &times;
            </span>
          </div>
          <div>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '20px',
              }}
            >
              {(tableToShow === 'edit' || tableToShow === 'create') && (
                <div style={{ marginTop: '80px', width: '100%' }}>
                  <p style={{ marginBottom: '5px', fontSize: '12px', fontWeight: '500' }}>
                    WHT Template Name
                  </p>
                  <BitaInput
                    id="taxTemplateName"
                    inputStyle={style.taxNameInput}
                    value={singleTemplateData?.withholding_tax_name}
                    type="text"
                    title="Name"
                    placeholder="Name"
                    justify="flex-start"
                    handleChange={value => {
                      setSingleTemplateData({ ...singleTemplateData, withholding_tax_name: value });
                    }}
                  />
                  <FileUploader setSingleTemplateData={value => setSingleTemplateData(value)} />
                </div>
              )}
              <div style={{ marginTop: tableToShow === 'listTemplates' ? '30px' : '40px' }}>
                {tableToShow === 'listTemplates' && (
                  <h1
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginBottom: '10px',
                    }}
                  >
                    Existing Withholding Taxes Templates
                    <BitaButton
                      onClick={() => {
                        setTableToShow('create');
                        setSingleTemplateData({});
                      }}
                    >
                      Create
                    </BitaButton>
                  </h1>
                )}
                {(tableToShow === 'visualize' || tableToShow === 'edit') && (
                  <h1 style={{ display: 'flex', justifyContent: 'space-between' }}>
                    Withholding Tax Table - Preview{' '}
                  </h1>
                )}
                {(tableToShow === 'edit' || tableToShow === 'create') && (
                  <>
                    <EditTableTaxes
                      search
                      pagination
                      data={singleTemplateDataRows ?? []}
                      showFields={
                        (singleTemplateColumns ?? []).filter(
                          item => item !== 'id' && item !== 'date_from',
                        ) ?? []
                      }
                      showOnly={10}
                      equalColumnsWidth
                      selectAction={() => {}}
                      CustomRowComponent={({ data: dataValue, entryKey, value }) => {
                        // if (entryKey === 'country_name') {
                        //   return (
                        //     <div style={{ display: 'flex', gap: '5px' }}>
                        //       <BitaCheckbox
                        //         checked={rowsToDelete.includes(dataValue?.id)}
                        //         handleChange={checked => {
                        //           if (checked) {
                        //             setRowsToDelete(prevRows => [...prevRows, dataValue?.id]);
                        //           } else {
                        //             setRowsToDelete(prevRow =>
                        //               prevRow.filter(item => item !== dataValue?.id),
                        //             );
                        //           }
                        //         }}
                        //       />
                        //       {value}
                        //     </div>
                        //   );
                        // }

                        if (entryKey === 'date_from') {
                          return (
                            <DatePickerInput
                              onChange={val => {
                                const formattedDate = new Date(val).toISOString().split('T')[0];
                                handleChange(entryKey, dataValue?.id, formattedDate);
                              }}
                              displayFormat="YYYY/MM/DD"
                              returnFormat="YYYY-MM-DD"
                              showOnInputClick
                              value={value}
                              className="bita-calendar-date"
                            />
                          );
                        }

                        if (entryKey === 'value') {
                          return (
                            <BitaInput
                              value={value || 0}
                              type="number"
                              inputStyle={
                                Number(value) > 100 || Number(value) < 0
                                  ? style.needValidationWithholding
                                  : style.editTableTaxesInput
                              }
                              justify="flex-start"
                              tooltip="Please indicate a value between 0 and 100"
                              handleChange={val => {
                                handleChange(entryKey, dataValue?.id, Number(val));
                                handleChange('date', dataValue?.id, new Date());
                              }}
                            />
                          );
                        }

                        return value;
                      }}
                    />
                    {/* <BitaButton
                      onClick={() => {
                        const updatedValues = singleTemplateData?.values?.filter(
                          row => !rowsToDelete.includes(row?.id),
                        );
                        setSingleTemplateData({
                          ...singleTemplateData,
                          values: [...updatedValues],
                        });
                      }}
                    >
                      Delete Rows
                    </BitaButton> */}
                    {/* <BitaButton
                      onClick={() => {
                        setSingleTemplateData({
                          ...singleTemplateData,
                          values: [
                            ...singleTemplateData?.values,
                            {
                              id: Math.floor(Math.random() * (1000000 - 0 + 1)) + 1000000,
                              country_name: 'select',
                              iso_code: 'select',
                              value: '0',
                              date_from: new Date().toISOString().split('T')[0],
                            },
                          ],
                        });
                      }}
                    >
                      Add Row
                    </BitaButton> */}
                    <div style={{ display: 'flex', gap: '15px' }}>
                      <BitaButton
                        disabled={
                          !(
                            singleTemplateData?.withholding_tax_name &&
                            singleTemplateData?.values?.length > 0 &&
                            !thereAreBadValues()
                          )
                        }
                        onClick={() => {
                          if (tableToShow === 'create') {
                            createWithholdingTax();
                          }
                          if (tableToShow === 'edit') {
                            updateWithholdingTax();
                          }
                        }}
                      >
                        Save
                      </BitaButton>
                      {thereAreBadValues() && (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginLeft: '10px',
                            gap: '7px',
                          }}
                        >
                          <BiSolidError className="icon-error" />
                          <div>
                            Input Error: One or more values are outside the valid range (0-100).
                            Please review and provide correct values.
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                )}
                {tableToShow === 'visualize' && (
                  <SingleTemplateTable
                    search
                    id="basket"
                    pagination
                    data={singleTemplateDataRows ?? []}
                    showFields={(singleTemplateColumns ?? []).filter(item => item !== 'id') ?? []}
                    showOnly={10}
                    equalColumnsWidth
                    selectAction={() => {}}
                    CustomRowComponent={({ data: dataValue, entryKey, value }) => (
                      <div>{value}</div>
                    )}
                  />
                )}
                {tableToShow === 'listTemplates' && (
                  <VisualizationTable
                    id="basket"
                    equalColumnsWidth
                    pagination
                    data={dataRows ?? []}
                    search
                    showFields={columns ?? []}
                    showOnly={10}
                    selectAction={() => {}}
                    CustomRowComponent={({ data: dataValue, entryKey, value }) => (
                      <div>
                        {entryKey === 'operations' ? (
                          <div>
                            <BitaButton
                              width="auto"
                              style={{ padding: '5px 10px' }}
                              disabled={dataValue.id === 'default'}
                              onClick={() => {
                                setTableToShow('edit');
                                setTemplateToEdit(dataValue?.['WHT Table Name']);
                                getSingleTemplate(dataValue?.id);
                              }}
                            >
                              Edit
                            </BitaButton>
                            <BitaButton
                              style={{ marginLeft: '10px', padding: '5px 10px' }}
                              width="auto"
                              onClick={() => {
                                setTableToShow('visualize');
                                setTemplateToEdit(dataValue?.['WHT Table Name']);
                                getSingleTemplate(dataValue?.id);
                              }}
                            >
                              View
                            </BitaButton>
                          </div>
                        ) : (
                          value
                        )}
                      </div>
                    )}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </BitaModal>
    </>
  );
};

export default VisualizationModal;
