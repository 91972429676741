import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { useSummary } from '../../../../bitaHooks';
import { Icons } from '../../../../constants/icons';
import BitaButton from '../../../../bitaComponents/BitaButton/BitaButton';
import styles from '../workflow-modal.module.css';
import { RadioGroup } from './RadioGroup';
import BitaSearchBar from '../../../../bitaComponents/BitaSearchBar/BitaSearchBar';
import Builder from '../../../../builder/Builder';
import * as SummaryConstants from '../../../../constants/summary-constants';
import { setApiData } from '../../../../state/actions/api-data-actions';

// eslint-disable-next-line import/no-cycle
import RunBacktestModal from '../../../runBacktestModal';
import { getDataComponents } from '../../../../api-data-mapping';

export const BitaInstrumentSelectOnly = props => {
  const { widget, onChange = Function.prototype } = props;
  const [selectedRows, setSelectedRows] = useState([]);
  const [optionSelected, setOptionSelected] = useState();
  const [filterSelected, setFilterSelected] = useState(widget.selected_default || '');
  const [stepData, setStepData] = useState({});
  const modalRef = useRef();
  const [instruments, setInstruments] = useState([]);
  const auth = useSelector(state => state.auth);
  const apiData = useSelector(state => state.apiData.apiData);
  const summary = useSelector(state => state.summary);
  const dispatch = useDispatch();
  const [disableButton, setDisableButton] = useState(false);

  useEffect(() => {
    if (widget.data_api_key && apiData) {
      const getDataFor = widget.data_api_key.replace(/\s/g, '').split('&');
      let instrumentList = [];
      // eslint-disable-next-line array-callback-return
      getDataFor.map(dataName => {
        const list = _.get(apiData, dataName, {});
        if (list.data) {
          let dataList = list.data;
          if (dataName === 'Benchmark') {
            dataList = list.data.Benchmark;
          }
          instrumentList = instrumentList.concat(
            dataList.map(row => {
              return {
                'Instrument name': row.name,
                'Instrument type': row.index_type
                  ? row.index_type
                      .replace('regular', 'INDEX')
                      .replace('structured_index', 'STRUCTURED INDEX')
                      .replace('optimized_index', 'OPTIMIZED INDEX')
                  : 'ETF',
                id: row.id,
              };
            }),
          );
        }
      });
      setInstruments(instrumentList);
    } else {
      setInstruments(
        Object.values(widget.data[0].values).reduce((result, extra, index) => {
          const row = {};
          Object.values(widget.data).forEach(column => {
            row[column.field_name] = column.values[index];
          });
          return result.concat(row);
        }, []),
      );
    }
  }, [apiData.Benchmark, apiData.Indexes, auth.token]);

  const rows = useMemo(
    () =>
      instruments.filter(
        row =>
          !selectedRows.some(selected => row['Instrument name'] === selected['Instrument name']) &&
          (filterSelected === '' || row['Instrument type'] === filterSelected.toUpperCase()),
      ),
    [instruments, selectedRows, filterSelected],
  );

  const searchOptions = useMemo(() => {
    if (!rows || !rows.length) {
      return [];
    }
    return rows.map(row => ({
      label: `${row['Instrument name']} - ${row['Instrument type']}`,
      value: row['Instrument name'],
      id: row.id,
    }));
  }, [rows]);

  const onSearchChange = useCallback(
    selected => {
      setSelectedRows(
        selectedRows.concat(rows.find(row => row['Instrument name'] === selected.value)),
      );
      setOptionSelected(selected);
      dispatch({
        type: SummaryConstants.ADD_ENTRY,
        payload: {
          module: 'Structured Index',
          widget: 'Instrument Selected',
          data: [instruments.find(row => row.id === selected.id)],
        },
      });
    },
    [setSelectedRows, rows, setOptionSelected],
  );

  const onChangeSelected = option => {
    setFilterSelected(option);
  };

  useEffect(() => {
    onChange(selectedRows);
  }, [selectedRows, onChange, optionSelected]);

  const siType = () => {
    if (props.widget.product) {
      return props.widget.product;
    }
    return 'New Structured Product';
  };

  const requiredWidgets = () => {
    const type = siType();
    if (Object.keys(_.get(summary, ['Structured Index', type], {})).length === 0) {
      return true;
    }
    if (type === 'Risk Control Index') {
      const interest_rate = _.get(summary, ['Structured Index', type, 'Type of Interest Rate'], {});
      if (interest_rate.interest_rate === 'Custom') {
        if (!interest_rate.daily_interest) {
          return !interest_rate.daily_interest;
        }
      }
      if (Object.keys(_.get(summary, ['Structured Index', type], {})).length === 9) {
        return false;
      }
      return true;
    }
    if (type === 'Leveraged Index' || type === 'Decrement Index') {
      if (Object.keys(_.get(summary, ['Structured Index', type], {})).length === 4) {
        return false;
      }
      return true;
    }
    return false;
  };

  useEffect(() => {
    setDisableButton(requiredWidgets());
  }, [summary['Structured Index']]);

  return (
    <Fragment key={widget.title}>
      <div>
        <h2 className="instrument_selection_titles">
          <div>
            {' '}
            <Icons.parameters />
            Instrument Selection
          </div>
        </h2>
        <div className={`${styles.searchTop} content-instrument_selection`}>
          {widget.filter ? (
            <div className={`${styles.searchFilterOptions} searchFilterOptions`}>
              <RadioGroup
                options={Object.values(widget.filter_options)}
                optionSelected={filterSelected}
                onChange={onChangeSelected}
              />
            </div>
          ) : null}
          <div className={styles.searchInput}>
            <BitaSearchBar
              searchOptions={searchOptions}
              value={optionSelected}
              onChange={onSearchChange}
            />
          </div>
        </div>
        <h2
          className="instrument_selection_titles"
          style={{ opacity: optionSelected ? '1' : '0.5' }}
        >
          <div>
            <Icons.account />
            {props.widget.subtitle}
          </div>
        </h2>
        {optionSelected ? (
          <div
            data-simplebar
            className="content_parameters"
            style={{ height: '300px', fontSize: '13px' }}
          >
            {Object.values(widget.parameters).map(parameter => (
              <Builder
                key={parameter.title}
                onApply={props.onApply}
                currentView={siType()}
                universe="Structured Index"
                setStepData={setStepData}
                stepData={stepData}
                {...parameter}
              />
            ))}
          </div>
        ) : null}
        <RunBacktestModal ref={modalRef} showData ubication="structured" disable_title={false} />
        <BitaButton
          primary
          onClick={() => {
            dispatch(
              setApiData({
                title: 'indexBacktest',
                data: {
                  'Index Backtest': {
                    'BASE VALUE': 1000,
                    Benchmark: {
                      ...optionSelected,
                      ...{ 'Instrument type': filterSelected.toUpperCase() },
                    },
                  },
                },
              }),
            );
            modalRef.current.runModal();
          }}
          style={{
            position: 'absolute',
            marginTop: '35px',
            marginLeft: '35%',
            marginRight: '35%',
          }}
          className="runBacktestStructured"
          disabled={!optionSelected || disableButton}
        >
          Run Backtest{' '}
        </BitaButton>
      </div>
    </Fragment>
  );
};
