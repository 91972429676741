import * as ACTIONS from '../../constants/module-selection-constants';

const initialState = {
  methodologySelected: null,
  universeSelected: null,
};

const moduleSelectionReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.UPDATE_METHODOLOGY:
      return {
        ...state,
        methodologySelected: { ...action.payload },
      };
    case ACTIONS.UPDATE_UNIVERSE:
      return {
        ...state,
        universeSelected: { ...action.payload },
      };
    case ACTIONS.REMOVE_METHODOLOGY:
      return {
        ...state,
        methodologySelected: null,
      };
    case ACTIONS.REMOVE_UNIVERSE:
      return {
        ...state,
        universeSelected: null,
      };
    case ACTIONS.RESET_ALL:
      return {
        ...state,
        methodologySelected: null,
        universeSelected: null,
      };
    default:
      return state;
  }
};

export default moduleSelectionReducer;
