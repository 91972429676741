import { useHistory } from 'react-router-dom';
import React from 'react';

export const BuilderImageComponent = ({ width, source, redirect = null }) => {
  const history = useHistory();
  return (
    // eslint-disable-next-line
    <div
      style={{ display: 'flex', justifyContent: 'center' }}
      className="image"
      onClick={() => {
        if (redirect) {
          history.push(redirect);
        }
        // eslint-disable-next-line no-unused-expressions
      }}
    >
      <img
        style={{ width, margin: 'auto' }}
        src={`${process.env.PUBLIC_URL}/modal_images/${source}`}
        alt=""
      />
    </div>
  );
};
