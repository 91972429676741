/* eslint-disable react/no-this-in-sfc */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import styles from '../AttributionAnalyzer.module.scss';
import {
  attributionLeftTheme,
  attributionRightTheme,
} from '../../../../bitaComponents/BitaCharts/Themes';
import BitaCharts from '../../../../bitaComponents/BitaCharts/BitaCharts';

export const AttributionPerformance = ({ pointInTime }) => {
  const PITKeyMap = {
    YTD: {
      'Attribution Effect': 69,
      'Relative Weight': 63,
    },
    '1Y': {
      'Attribution Effect': 68,
      'Relative Weight': 62,
    },
    '3Y': {
      'Attribution Effect': 70,
      'Relative Weight': 64,
    },
    '5Y': {
      'Attribution Effect': 71,
      'Relative Weight': 65,
    },
    Max: {
      'Attribution Effect': 72,
      'Relative Weight': 66,
    },
  };
  const apiData = useSelector(state => state.apiData.apiData);
  const [relativeWeightValues, setRelativeWeightValues] = useState();
  const [attributionEffectsDataValues, setAttributionEffectsDataValues] = useState();

  useEffect(() => {
    if (
      pointInTime &&
      _.has(apiData, [
        'Index Stats',
        'data',
        `${PITKeyMap[pointInTime]['Relative Weight']}`,
        'value',
      ])
    ) {
      const relativeWeightData = _.get(
        apiData,
        ['Index Stats', 'data', `${PITKeyMap[pointInTime]['Relative Weight']}`, 'value'],
        {},
      );
      const relativeWeightDataValuesBody = {
        name: 'Return in Portfolio',
        data: Object.values(_.get(relativeWeightData, 'return_in_portfolio', [])),
      };
      const relativeAllocation = {
        name: 'Relative Allocation',
        data: Object.values(_.get(relativeWeightData, 'relative_allocation', [])),
      };
      setRelativeWeightValues([relativeWeightDataValuesBody, relativeAllocation]);
    }

    if (
      pointInTime &&
      _.has(apiData, [
        'Index Stats',
        'data',
        `${PITKeyMap[pointInTime]['Attribution Effect']}`,
        'value',
      ])
    ) {
      const attributionEffectData = _.get(
        apiData,
        ['Index Stats', 'data', `${PITKeyMap[pointInTime]['Attribution Effect']}`, 'value'],
        {},
      );
      const attributionSelectionEffectBody = {
        name: 'Selection',
        data: Object.values(_.get(attributionEffectData, 'selection_effect', [])),
      };

      const attributionAllocationEffectBody = {
        name: 'Allocation',
        data: Object.values(_.get(attributionEffectData, 'allocation_effect', [])),
      };

      setAttributionEffectsDataValues([
        attributionSelectionEffectBody,
        attributionAllocationEffectBody,
      ]);
    }
  }, [apiData['Index Stats'], pointInTime]);

  const attributionRelativeWeightOptions = {
    xAxis: {
      categories: pointInTime
        ? Object.keys(
            _.get(
              apiData,
              [
                'Index Stats',
                'data',
                `${PITKeyMap[pointInTime]['Relative Weight']}`,
                'value',
                'return_in_portfolio',
              ],
              {},
            ),
          )
        : [],
    },

    series: relativeWeightValues || [],
  };

  const attributionEffectsOption = {
    xAxis: {
      categories: pointInTime
        ? Object.keys(
            _.get(
              apiData,
              [
                'Index Stats',
                'data',
                `${PITKeyMap[pointInTime]['Relative Weight']}`,
                'value',
                'return_in_portfolio',
              ],
              {},
            ),
          )
        : [],
    },
    series: attributionEffectsDataValues || [],
  };

  function getRelativeWeightFormatter() {
    return {
      tooltip: {
        formatter() {
          return `
          ${this.x}<br/>
          ${this.points[0].series.name} : ${Number(this.points[0].y).toFixed(3)}<br/>
          ${this.points[1].series.name} : ${Number(this.points[1].y).toFixed(3)}`;
        },
      },
    };
  }
  function getAttributionFormatter() {
    return {
      tooltip: {
        formatter() {
          return `
          ${this.x}<br/>
          ${this.points[0].series.name} : ${Number(this.points[0].y).toFixed(3)}<br/>
          ${this.points[1].series.name} : ${Number(this.points[1].y).toFixed(3)}`;
        },
      },
    };
  }
  return (
    <div className={styles.attributionChartsContainer}>
      <BitaCharts
        key="attributionRelativeWeightOptions"
        options={{
          ...attributionRelativeWeightOptions,
          ...getRelativeWeightFormatter(),
        }}
        theme={attributionLeftTheme}
        containerProps={{ style: { maxHeight: '100%', width: '55%' } }}
      />
      <BitaCharts
        key="attributionEffectsOption"
        options={{ ...attributionEffectsOption, ...getAttributionFormatter() }}
        theme={attributionRightTheme}
        containerProps={{ style: { maxHeight: '100%', width: '45%' } }}
      />
    </div>
  );
};

export default AttributionPerformance;
