import axios from '../../Axios';

export const getIndexBenchmark = (indexId, benchmarkId, token) => {
  const headers = { Authorization: `Bearer ${token}` };
  const url = `${process.env.REACT_APP_API_URL}/stats-benchmark/${indexId}?benchmark=${benchmarkId}`;
  return axios
    .get(url, { headers: { Authorization: `Bearer ${token}` } })
    .then(res => res)
    .catch(error => {
      console.info('Error', error);
      return {};
    });
};

export const getIndexPCFInfo = (indexId, date, token) => {
  const headers = { Authorization: `Bearer ${token}` };
  const url = `${process.env.REACT_APP_API_URL}/pcf-info`;
  const body = {
    id: indexId,
    date,
  };
  return axios.post(url, body, { headers: { Authorization: `Bearer ${token}` } }).then(res => res);
};
