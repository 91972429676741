import * as ACTIONS from '../../constants/websocket-constants';

const initialState = { connected: false };

const websocketReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.WEBSOCKET_CHANGE_STATUS: {
      return { ...state, connected: action.payload };
    }

    case ACTIONS.WEBSOCKET_NEW_RECEIVED_MESSAGE: {
      console.info('received message ', action.payload);
      return state;
    }

    default: {
      return state;
    }
  }
};

export default websocketReducer;
