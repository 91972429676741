import * as SummaryConstants from '../../constants/summary-constants';
import { discardBacktest } from './backtest-actions';
import * as IndexConstants from '../../constants/index-operations-constants';
import { cleanCache } from './summary-actions';

export const cleanProject = (token, removeRunningBacktest = false) => dispatch => {
  dispatch({
    type: SummaryConstants.RESET,
  });
  dispatch(discardBacktest(removeRunningBacktest));
  dispatch({
    type: IndexConstants.RESET,
  });
  dispatch(cleanCache(token));
};
