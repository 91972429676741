import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import lodash from 'lodash';
import { toast } from 'react-toastify';
import styles from '../../pages/structures-product/WorkflowModal/workflow-modal.module.css';
import { Icons } from '../../constants/icons';
import Builder from '../../builder/Builder';
import BitaModal from '../../bitaComponents/BitaModal/BitaModal';
import {
  changeBacktestStatus,
  discardBacktest,
  hideRunningBacktest,
} from '../../state/actions/backtest-actions';
import * as STATUS from '../../constants/backtest-status';
import { cleanProject } from '../../state/actions/project-actions';
import { cleanCache } from '../../state/actions/summary-actions';
import * as ApiDataActions from '../../state/actions/api-data-actions';
import { getDataComponents } from '../../api-data-mapping';
import { loaderDataObjectDecorator } from '../../utils/loading_status_tools';
import { toastInterceptor } from '../../utils/utils';

import BitaExitWorkflowModal from '../../bitaComponents/BitaModal/BitaExitWorkflowModal';

const BacktestInProgressModalB = () => {
  const json = window.ConfigYAML.jsonPath([
    'run_backtest',
    'sections',
    "components[?(@.type == 'Button')]",
    'onclick',
  ]);

  const jsonFactsheet = window.ConfigYAML.jsonPath([
    'factsheet_builder',
    'sections',
    "widgets[?(@.type == 'FactsheetBuilderComponent')]",
    'onclick',
  ]);

  const backtestID = useSelector(state => state.backtest.idDisplayedRunningBacktest);
  const token = useSelector(state => state.auth.token);
  const backtest = useSelector(state => state.backtest.backtests[backtestID]);
  const { popUpData } = backtest || { popUpData: null };
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [showModalExit, setShowModalExit] = useState(false);
  const [popupIsOpen, setPopupIsOpen] = useState(false);
  const [components, setComponenets] = useState(json);

  useEffect(() => {
    if (
      backtestID &&
      // !showModalExit &&
      // backtest?.status !== 'BACKTEST_WITH_ERROR' &&
      [STATUS.BACKTEST_RUNNING, STATUS.BACKTEST_WITH_ERROR].includes(backtest?.status)
    ) {
      setPopupIsOpen(true);
    } else {
      setPopupIsOpen(false);
    }
  }, [backtestID, backtest?.status, showModalExit]);

  useEffect(() => {
    if (location.pathname.includes('/rebalancing-reconstitution/')) {
      const copyJson = JSON.parse(JSON.stringify(json));
      delete copyJson.components['5'];
      copyJson.components['4'].title = 'Cancel Rebalancing';
      copyJson.components['0'].text =
        'Your rebalancing is currently running. Follow the progress below.';
      setComponenets(copyJson);
    } else if (location.pathname.includes('/factsheet-builder/')) {
      setComponenets(jsonFactsheet);
    } else {
      setComponenets(json);
    }
  }, [backtestID, location]);

  useEffect(() => {
    if (
      popUpData &&
      popUpData.title === 'Backtest in Progress' &&
      popupIsOpen &&
      backtest?.wasHide
    ) {
      dispatch(hideRunningBacktest(false));
    }
  }, [popupIsOpen]);

  const popupCloseOptions = lodash.compact([
    ...(popUpData && popUpData.title === 'Backtest in Progress'
      ? location.pathname.includes('/rebalancing-reconstitution/')
        ? [{ value: 'Cancel Rebalancing', id: 'cancel_backtest' }]
        : [
            { value: 'Continue in Background', id: 'continue' },
            { value: 'Cancel Backtest', id: 'cancel_backtest' },
          ]
      : [
          { value: 'Yes', id: 'cancel_project' },
          { value: 'No', id: 'return' },
        ]),
  ]);

  const popupCancelOptions = lodash.compact([
    ...(popUpData && popUpData.title === 'Backtest in Progress'
      ? [
          { value: 'Yes', id: 'cancel_backtest' },
          { value: 'No', id: 'return' },
        ]
      : [
          { value: 'Yes', id: 'cancel_project' },
          { value: 'No', id: 'return' },
        ]),
  ]);

  const handleBackground = () => {
    // debugger;
    dispatch(hideRunningBacktest(true));

    const urls = [
      '/index-builders/index-builders/',
      '/structures-product/structures-product',
      '/optimized-index/optimized-index',
      'portfolio-basket-calculation/portfolio-basket-calculation',
    ];

    if (urls.find(url => location.pathname.includes(url))) {
      history.push('/');
    }
    return false;
  };

  const downloadFactsheet = () => {
    const copyJson = JSON.parse(JSON.stringify(jsonFactsheet));
    copyJson.components['3'].loading = 'download_factsheet';
    setComponenets(copyJson);
    const body = { id_backtest: backtestID };
    const dataComponent = getDataComponents.filter(
      v => v.key === 'saveFactsheet' && v.title === 'saveFactsheet',
    )[0];
    const backtestPromise = loaderDataObjectDecorator(dataComponent, dispatch).getUrlData(
      body,
      token,
    );
    backtestPromise.then(res => {
      toast('The factsheet data was successfully received. The PDF will be generated shortly.');
      setTimeout(() => {
        setShowModalExit(false);
        dispatch(cleanProject(token));
        dispatch(changeBacktestStatus(backtestID), STATUS.BACKTEST_CANCELLED);
        history.push('/');
      }, 3000);
    });
  };

  const handleOptionsMenuCloseModal = ({ id }) => {
    setShowModalExit(false);

    if (id === 'cancel_project') {
      dispatch(cleanProject(token));
      dispatch(changeBacktestStatus(backtestID), STATUS.BACKTEST_CANCELLED);
      history.push('/');
    }

    if (id === 'cancel_backtest') {
      dispatch(cleanProject(token, true));
      dispatch(changeBacktestStatus(backtestID, STATUS.BACKTEST_CANCELLED));
      dispatch(cleanCache(token));
      dispatch(
        ApiDataActions.setApiData({
          title: 'universeSelected',
          data: {},
        }),
      );
      dispatch(
        ApiDataActions.setApiData({
          title: 'methodologySelected',
          data: {},
        }),
      );
      dispatch(
        ApiDataActions.setApiData({
          title: 'universeSelectedCard',
          data: {},
        }),
      );
      dispatch(
        ApiDataActions.setApiData({
          title: 'methodologySelectedCard',
          data: {},
        }),
      );

      // dispatch(discardBacktest());
      if (location.pathname === '/optimized-index/optimized-index') {
        history.push('/optimized-index/optimized-index');
      } else if (!location.pathname.includes('analyzer')) {
        history.push('/');
      }
    }
    if (id === 'continue') {
      history.push('/');
      dispatch(hideRunningBacktest(true));
    }
  };

  //  debugger;
  return (
    <>
      <BitaExitWorkflowModal
        isModalOpen={showModalExit}
        popupCloseOptions={
          showModalExit === 'cancel backtest' ? popupCancelOptions : popupCloseOptions
        }
        workflowSection={
          showModalExit === 'cancel backtest' ? 'cancel' : popUpData ? 'running' : 'before_run'
        }
        handleOptionsMenuCloseModal={handleOptionsMenuCloseModal}
      />
      {popupIsOpen && (
        <BitaModal
          animateOnUrlChange={false}
          isModalOpen={popupIsOpen}
          customStyle={{
            backgroundColor: location.pathname.includes('/factsheet-builder/')
              ? '#2962ff'
              : 'rgb(42, 45, 49)',
          }}
        >
          <div>
            <div style={{ width: '589px' }}>
              <div className={`${styles.popUpContainer} popUpContainer`}>
                {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                <div className="close_modal_x" onClick={() => setShowModalExit(true)}>
                  <span
                    className={`${styles.closeModalButton} closebutton`}
                    // rol="button"
                  >
                    &times;
                  </span>
                </div>
                <header className={styles.headerpaths}>
                  <div>
                    <span style={{ fontSize: '20px', marginLeft: '0px' }}>
                      {location.pathname.includes('/factsheet-builder/')
                        ? 'PREPARING FACTSHEET FOR DOWNLOAD'
                        : popUpData?.title === 'Backtest in Progress'
                        ? location.pathname.includes('/rebalancing-reconstitution/')
                          ? 'Rebalancing in Progress'
                          : popUpData?.title
                        : backtest?.popUpData?.['INDEX NAME']}
                    </span>
                  </div>
                  <Icons.Avanti style={{ marginRight: '20px', transform: 'scale(1.3)' }} />
                </header>
                <Builder
                  handlePopupClose={() => {
                    if (backtest.status === STATUS.BACKTEST_WITH_ERROR) {
                      handleOptionsMenuCloseModal({ id: 'cancel_backtest' });
                    } else {
                      setShowModalExit(true);
                    }
                  }}
                  popupHandleOnClick={
                    location.pathname.includes('/factsheet-builder/')
                      ? downloadFactsheet
                      : handleBackground
                  }
                  {...components}
                />
              </div>
            </div>
          </div>
        </BitaModal>
      )}
    </>
  );
};

export default BacktestInProgressModalB;
