import React, { useEffect } from 'react';
import { toast } from 'react-toastify';

import { useDispatch, useSelector } from 'react-redux';
import 'simplebar'; // or "import SimpleBar from 'simplebar';" if you want to use it manually.
import 'simplebar/dist/simplebar.css';
import { months } from 'moment';
import ConfigYAML from '../../config/ConfigYAML';
import BitaModal from '../../bitaComponents/BitaModal/BitaModal';
import { Icons } from '../../constants/icons';
import styles from './universe-builder.module.scss';
import Builder from '../../builder/Builder';
import BitaButton from '../../bitaComponents/BitaButton/BitaButton';
import { getDataComponents } from '../../api-data-mapping';
import { setApiData } from '../../state/actions/api-data-actions';

const styleClose = {
  position: 'absolute',
  fontSize: '30px',
  fontWeight: 'bold',
  top: '-10px',
  right: '0',
  cursor: 'pointer',
  boxSizing: 'border-box',
};

const UniverseRevisionDatesModal = props => {
  const dispatch = useDispatch();
  const settings = ConfigYAML.jsonPath('$.universe_revision_dates');
  const reviewDate = useSelector(state => state.apiData.apiData['Review Date']);
  const [data, setData] = React.useState();
  const [requestData, setRequest] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const components =
    props.universeSelected &&
    props.universeSelected.universe &&
    props.universeSelected.universe.name
      ? settings.components_universe
      : settings.components_white_canvas;
  const token = useSelector(state => state.auth.token);

  useEffect(() => {
    if (reviewDate && Object.keys(reviewDate).length > 0) {
      setData(reviewDate);
    }
  }, [reviewDate]);

  const onChange = (item, value) => {
    let currentState = data;
    if (value['Universe data history']) {
      currentState['Universe data history'] = value['Universe data history'];
    } else {
      currentState = { ...data, ...value };
    }
    const dataAux = {
      days: currentState['Revision Periodicity']?.Day?.value || 'Friday',
      weeks: currentState['Revision Periodicity']?.['Ex-Date']?.value || '1st',
      months:
        currentState['Revision Periodicity']?.Months?.value || 'March-June-September-December',
      years:
        currentState['Universe data history']?.universe_data_history
          ?.split(' ')?.[0]
          ?.toUpperCase() || '15',
    };

    setRequest(dataAux);
    setData(currentState);
  };

  const dataComponent = getDataComponents.filter(v => v.key === 'universeRevisionDate')[0];

  const Apply = () => {
    setLoading(true);
    const promise = dataComponent.getUrlData(requestData, token);
    promise.then(res => {
      if (res.dates) {
        setLoading(false);
        props.close();
        dispatch(setApiData({ title: 'Review Date', data }));
      }
    });
  };
  return (
    <BitaModal
      isModalOpen={props.visibility}
      setModalState={() => {
        props.close();
      }}
    >
      <div style={{ width: '1100px', height: '640px' }}>
        <div
          className={styles.pupUpTitleContainer}
          style={{
            display: 'flex',
            borderBottom: '1px solid #eee',
            alignItems: 'center',
            padding: '10px 0',
          }}
        >
          <Icons.Avanti style={{ marginRight: '20px' }} />
          <h2 style={{ margin: '0', padding: '0', fontSize: '22px', textTransform: 'uppercase' }}>
            {settings.title}
          </h2>
          <span onClick={() => props.close()} style={styleClose}>
            &times;
          </span>
        </div>
        <div
          style={{
            marginTop: '30px',
            fontFamily: " 'Neue Haas Grotesk Display Pro', sans-serif",
            height: '480px',
            fontSize: '13px',
          }}
          className="content modalRevisionUniverse"
          data-simplebar
        >
          {data && reviewDate && (
            <div>
              {Object.values(components).map(component => {
                if (component.action === 'closeModal') {
                  return (
                    <div style={{ width: '48%' }}>
                      <Builder
                        {...component}
                        onChange={onChange}
                        initWidgetData={{ [component.title]: data[component.title] }}
                        widgetData={data}
                      />
                    </div>
                  );
                }

                return (
                  <Builder
                    {...component}
                    onChange={onChange}
                    initWidgetData={{ [component.title]: data[component.title] }}
                    widgetData={data}
                  />
                );
              })}
            </div>
          )}
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
          <BitaButton
            primary
            onClick={Apply}
            disabled={
              !(
                data?.['Revision Periodicity']?.Day &&
                data?.['Revision Periodicity']?.Months &&
                data?.['Revision Periodicity']?.periodicity &&
                data?.['Revision Periodicity']?.['Ex-Date'] &&
                data?.['Universe data history']?.universe_data_history
              )
            }
            loading={loading}
          >
            Apply
          </BitaButton>
        </div>
      </div>
    </BitaModal>
  );
};

export default UniverseRevisionDatesModal;
