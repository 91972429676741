import React, { useEffect, useState } from 'react';
import styles from '../EmissionsWaste.module.scss';
import { thousandFormat } from '../../../../utils/utils';

const TableIndexBenchmarkByYear = ({ data, columns, year, rows, isPercentage = '' }) => {
  const [currentData, setCurrentData] = useState({});
  useEffect(() => {
    if (data[year]) {
      setCurrentData(data[year]);
    } else {
      setCurrentData({});
    }
  }, [data, year]);

  const isPercentageValue = (value, dataUnit) => {
    const _value =
      dataUnit === '%' ? `${Number(Number(value).toFixed(2))}` : Number(Number(value).toFixed(2));
    const splitedValue = _value.toString().split('.');
    return `${thousandFormat(splitedValue[0])}${splitedValue[1] ? `.${splitedValue[1]}` : ''}`;
  };

  return (
    <table className={styles.generatedTable} style={{ fontSize: '10px' }}>
      <thead className={styles.generatedTableHeader}>
        <tr>
          <th />
          {columns.map(c => {
            return (
              <th className={styles.restTableHeaders} style={{ textAlign: 'center' }}>
                {c}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {rows.map(rName => {
          return (
            <tr className={styles.generatedTableHeader}>
              <td style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>{rName}</td>
              {columns.map(col => {
                return (
                  <td className={styles.restTableHeaders} style={{ textAlign: 'center' }}>
                    {currentData[`${rName.replace('TOTAL', col.toUpperCase())}`]
                      ? isPercentageValue(
                          currentData[`${rName.replace('TOTAL', col.toUpperCase())}`],
                          isPercentage,
                        )
                      : currentData[`${col.toUpperCase()} ${rName}`]
                      ? isPercentageValue(
                          currentData[`${col.toUpperCase()} ${rName}`],
                          isPercentage,
                        )
                      : 'N/A'}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default TableIndexBenchmarkByYear;
