/* eslint-disable */
import React from 'react';
import style from './BitaCardChart.module.scss';

const BitaCardRebalancing = React.memo(({ title, data, width }) => {
  const isEmptyData = () => _.isEmpty(data);
  const waitingCardStyle = {
    filter: 'grayscale(36)',
    cursor: 'wait',
  };

  return (
    <div
      className={style.cardContainerRebalancing}
      style={{
        ...{ width, height: '150px', cursor: 'pointer', paddingTop: '40px' },
        ...(isEmptyData() ? waitingCardStyle : {}),
      }}
    >
      <span className={style.cardTitle}>{title}</span>
      {_.isEmpty(data) ? 'LOADING...PLEASE WAIT' : <div />}
      {data?.length > 0 && (
        <div className={style.buttonContainer}>
          <div className={style.firstButton} style={{ width: 'calc(100% - 20px)' }}>
            <span className={style.firstText}>Last Update</span>
            <span className={style.firstValue}>{data?.length > 0 && data[0]?.date}</span>
          </div>
        </div>
      )}
    </div>
  );
});

export default BitaCardRebalancing;
