import lodash from 'lodash';
import * as ACTIONS from '../../constants/optimized-index-constants';

const initialState = {};

const optimizedIndexReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.GENERIC_UPDATE: {
      const { subgroup, operation, data } = action.payload;
      return {
        ...state,
        [subgroup]: {
          ...state[subgroup],
          [operation]: {
            ...state[subgroup][operation],
            ...data,
          },
        },
      };
    }

    case ACTIONS.ALL_UPDATE: {
      return {
        ...state,
        ...lodash.merge(state, action.payload),
      };
    }

    default: {
      return state;
    }
  }
};

export default optimizedIndexReducer;
