import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect, useSelector, useDispatch } from 'react-redux';
import { withRouter, useLocation } from 'react-router';
import { LocalStorage } from '../../localStorage';
import { LOCALSTORAGE_USERDATA } from '../../constants/global-constants';
import { getUserFromStorage } from '../../state/actions/auth-actions';

const mapStateToProps = state => {
  return {
    token: state.auth.token,
  };
};

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const token = useSelector(state => state.auth.token);
  const dispatch = useDispatch();
  const location = useLocation();
  let _token = token;

  // si no tenemos el token en el state, lo buscamos en el localsotarge y actualizamos en caso de existir
  if (!token) {
    const userdata = LocalStorage.load(LOCALSTORAGE_USERDATA);
    if (userdata) {
      _token = userdata.token;
      dispatch(getUserFromStorage(userdata));
    }
  }

  return (
    <Route
      {...rest}
      render={props =>
        _token ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default ProtectedRoute;
