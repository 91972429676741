import React, { useEffect } from 'react';
import styles from '../EmissionsWaste.module.scss';
import BitaTable from '../../../../bitaComponents/BitaTable/BitaTable';

const TopConstituentTable = ({ data, year, labels }) => {
  return (
    <div className={`${styles.tableContainerGenerated} tableCharacteristics`}>
      <BitaTable
        data={data[year] || []}
        showFields={labels}
        search={false}
        showOnly={10}
        selectAction={() => {}}
      />
    </div>
  );
};
export default TopConstituentTable;
