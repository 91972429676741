/* eslint-disable func-names */
import axios from '../../Axios';
import { setApiData } from './api-data-actions';
import * as ACTIONS from '../../constants/summary-constants';
// eslint-disable-next-line import/no-cycle
import { toastInterceptor } from '../../utils/utils';

export const addEntryToSummary = (module, widget, data) => {
  return { type: ACTIONS.ADD_ENTRY, payload: { module, widget, data } };
};

export const removeEntryFromSummary = (module, widget, entry) => {
  // 0 must be treated as 0 and not as false
  if (entry || typeof entry === 'number') {
    return { type: ACTIONS.REMOVE_ENTRY, payload: { module, widget, entry } };
  }
  return { type: ACTIONS.REMOVE_ALL_ENTRIES, payload: { module, widget } };
};

export const resetSummary = () => {
  return { type: ACTIONS.RESET };
};

// eslint-disable-next-line no-unused-vars
export const cleanCache = token => dispatch => {
  axios
    .post(`${process.env.REACT_APP_API_URL}/clean-cache`, null, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(rsp => {
      console.log(rsp.data);
    })
    .catch(rsp => {
      console.log(rsp.message);
    });
};

export const saveMethodology = (sumary, token) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}/methodology`;
  return axios
    .post(url, sumary, { headers: { Authorization: `Bearer ${token}` } })
    .then(rsp => {
      dispatch(
        setApiData({
          title: 'modal',
          data: { open: true, text: 'Methodology created successfully' },
        }),
      );
      dispatch(cleanCache(token));
      dispatch(resetSummary());
      return Promise.resolve(rsp);
    })
    .catch(error => {
      toastInterceptor('Error saving methodology. Please try again');
      console.error('AXIOS ERROR SAVE METHODOLOGY [x] ', error);
    });
};
