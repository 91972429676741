import React from 'react';
import styles from '../PerformanceAndRisk.module.scss';

const RadioButtons = props => {
  const GeneralStatsPITButtons = [
    { name: '1 Year', value: '1y' },
    { name: '3 Years', value: '3y' },
    { name: '5 Years', value: '5y' },
  ];

  const onChange = value => {
    if (props.setSelected) {
      props.setSelected(value);
    }
  };

  return (
    <div
      className={styles.radioButtonsContainer}
      style={{
        justifyContent: 'flex-end',
        fontSize: '11px',
      }}
    >
      {GeneralStatsPITButtons.map(button => (
        <div>
          <input
            id={button.value}
            type="radio"
            name="radio-filtertables"
            onClick={() => onChange(button.value)}
            checked={props.selected === button.value}
          />
          <label htmlFor={button.value}>{button.name}</label>
        </div>
      ))}
    </div>
  );
};

export default RadioButtons;
