import lodash from 'lodash';
import { toast } from 'react-toastify';
import moment from 'moment';
import { getDataComponents } from '../../../api-data-mapping';
import {
  setBacktest,
  setBacktestType,
  setSiInstrument,
  setBacktestFrontParameters,
} from '../../../state/actions/backtest-actions';
import { setApiData } from '../../../state/actions/api-data-actions';
import { loaderDataObjectDecorator } from '../../../utils/loading_status_tools';
import { getStructureIndexBacktestBody } from './backtestStructureIndexApiBody';
import { getBasketIndexBacktestBody } from './backtestBasketsApiBody';
import { getOptimizedBacktestBody } from './backtestApiBody';
import axios from '../../../Axios';
import { hasValidBitaDomain } from '../../../utils/utils';

const getEndDate = async token => {
  const url = `${process.env.REACT_APP_API_URL}/end-date`;
  const res = await axios.get(url, { headers: { Authorization: `Bearer ${token}` } });
  return res.data.data.config['end-date'];
};

export const runBacktest = ({
  dispatch,
  stateApiData,
  popUpData,
  setPopUpData,
  stateSummary,
  token,
  userData,
}) => async (_data, onclick) => {
  let data = { ..._data };
  const preloadBacktest = lodash.get(stateApiData, 'indexBacktest', {});
  const optimizedState = lodash.get(stateApiData, 'Optimized Index', {});
  const structureIndexState = lodash.get(stateSummary, 'Structured Index', {});
  const basketState = lodash.get(stateSummary, 'Portfolio', {});
  const defaultStartDate = lodash.get(stateApiData, 'first_revision_universe_global_default');
  const riskmodels = stateApiData?.risk_models?.data?.['Risk Model Bita'];
  const buffer =
    stateApiData?.methodologySelected?.parameters?.data?.exit_buffer_percentage ||
    stateApiData?.methodologySelected?.parameters?.data?.operations_name.filter(
      item => item === 'filter_buffer_fast' || item === 'filter_ranked_buffer',
    ).length > 0;
  const methodologyState = lodash.get(stateApiData, 'methodologySelected.front_parameters', {});
  const optimizedData = getOptimizedBacktestBody(optimizedState, methodologyState);
  const backtestInfo = data && { ...preloadBacktest['Index Backtest'], ...data };
  const companyId = userData.userdata.id_company;

  if (!backtestInfo.risk_model_id) {
    backtestInfo.risk_model_id = riskmodels?.[1];
  }
  if (!backtestInfo['BASE VALUE']) {
    // hardcoded
    // If you change this default value, you should also change it in the index_builder.yaml and run_backtest_popup.yaml
    backtestInfo['BASE VALUE'] = 1000;
  }
  const backtestType = optimizedData.operations_name
    ? 'runOptimizedBacktest'
    : stateSummary['Structured Index']
    ? 'runStructureIndexBacktest'
    : stateSummary.Portfolio
    ? 'runBasketIndexBacktest'
    : popUpData.title === 'REBALANCING & RECONSTITUTION'
    ? 'runRebalancingIndexBacktest'
    : 'runBacktest';

  dispatch(
    setBacktestType(
      optimizedData.operations_name
        ? 'optimized_index'
        : stateSummary['Structured Index']
        ? 'structured_index'
        : stateSummary.Portfolio
        ? 'basket_index'
        : popUpData.title === 'REBALANCING & RECONSTITUTION'
        ? 'rebalancing_module'
        : 'regular',
    ),
  );
  switch (backtestType) {
    case 'runOptimizedBacktest':
      dispatch(setBacktestFrontParameters(optimizedState));
      break;
    case 'runStructureIndexBacktest':
      dispatch(setBacktestFrontParameters(structureIndexState));
      break;
    case 'runBasketIndexBacktest':
      dispatch(setBacktestFrontParameters(basketState));
      break;
    default:
      dispatch(setBacktestFrontParameters({}));
      break;
  }
  if (
    popUpData.title === 'Index Backtest' &&
    !(
      backtestInfo &&
      backtestInfo['INDEX NAME'] &&
      backtestInfo.Benchmark &&
      backtestInfo.Benchmark.id &&
      backtestInfo.risk_model_id &&
      backtestInfo['BASE VALUE']
    )
  ) {
    toast.error('All fields are required ');
    return false;
  }
  if (
    popUpData.title === 'REBALANCING & RECONSTITUTION' &&
    !(
      backtestInfo &&
      backtestInfo['PORTFOLIO NAME'] &&
      backtestInfo.Benchmark &&
      backtestInfo.Benchmark.id &&
      backtestInfo.risk_model_id &&
      backtestInfo['Determination Date']
    )
  ) {
    toast.error('All fields are required ');
    return false;
  }
  if (
    popUpData.title === 'REBALANCING & RECONSTITUTION' &&
    !!buffer &&
    !stateApiData?.previousUniverseBacktest?.constituents
  ) {
    toast.error('Upload of current portfolio template is required');
    return false;
  }
  if (!backtestInfo['Start Date']) {
    backtestInfo['Start Date'] = defaultStartDate;
  }
  backtestInfo['Start Date'] = moment(backtestInfo['Start Date']).format('YYYY-MM-DD');
  backtestInfo['End Date'] = moment(backtestInfo['End Date'] || (await getEndDate(token))).format(
    'YYYY-MM-DD',
  );

  if (popUpData.title === 'REBALANCING & RECONSTITUTION') {
    if (moment(backtestInfo['Determination Date']).format('YYYY-MM-DD') === '2009-01-05') {
      backtestInfo['Determination Date'] = backtestInfo['End Date'];
    }
    backtestInfo['Inception Date'] = backtestInfo['Determination Date'] || backtestInfo['End Date'];
  }

  backtestInfo['Inception Date'] = moment(backtestInfo['Inception Date']).format('YYYY-MM-DD');

  // debugger;
  const runBacktestApiBody = {
    universe:
      stateApiData?.universeSelected?.id || stateApiData?.universeSelected?.constituents || '',
    object_backtest: {
      calculate: true,
      api_parameters: lodash.get(stateApiData, 'methodologySelected.api_parameters', {}),
      parameters: lodash.get(stateApiData, 'methodologySelected.parameters', {}),
    },
    companyId,
  };

  if (
    popUpData.title === 'REBALANCING & RECONSTITUTION' &&
    stateApiData?.previousUniverseBacktest
  ) {
    runBacktestApiBody.previous_universe = stateApiData?.previousUniverseBacktest.constituents;
  }

  // TODO hardcoded
  runBacktestApiBody.object_backtest.parameters.operations = {
    run_index: {
      operation_name: 'run_index',
    },
    constitute: {
      operation_name: 'constitute',
    },
    performance_metrics: {
      operation_name: 'performance_metrics',
    },
  };

  // BODY initial_index_value
  if (lodash.has(runBacktestApiBody, 'object_backtest.parameters.data')) {
    runBacktestApiBody.object_backtest.parameters.data.initial_index_value =
      (backtestInfo && backtestInfo['BASE VALUE']) || 0;
  }

  // BODY inception_value
  if (lodash.has(runBacktestApiBody, 'object_backtest.parameters.data')) {
    runBacktestApiBody.object_backtest.parameters.data.inception_value =
      (backtestInfo && backtestInfo['BASE VALUE']) || 0;
  }

  // BODY inception_date
  if (lodash.has(runBacktestApiBody, 'object_backtest.api_parameters')) {
    runBacktestApiBody.object_backtest.api_parameters.inception_date = backtestInfo[
      'Inception Date'
    ]
      ? backtestInfo['Inception Date']
      : backtestInfo['Start Date'];
  }

  // debugger;
  // BODY start_date
  if (lodash.has(runBacktestApiBody, 'object_backtest.api_parameters')) {
    runBacktestApiBody.object_backtest.api_parameters.start_date = backtestInfo['Start Date'];
  }
  console.info('BacktestInfo', backtestInfo);
  if (lodash.has(runBacktestApiBody, 'object_backtest.parameters.data')) {
    runBacktestApiBody.object_backtest.parameters.data.start_date = backtestInfo['Start Date'];
    runBacktestApiBody.object_backtest.parameters.data.end_date = backtestInfo['End Date'];
    runBacktestApiBody.object_backtest.parameters.data.inception_date =
      backtestInfo['Inception Date'] || backtestInfo['Start Date'];
    runBacktestApiBody.object_backtest.parameters.data.sector_provider = backtestInfo[
      'Sector Classification'
    ]?.value
      ? `${backtestInfo['Sector Classification'].value.toLowerCase()}_sector`
      : 'gics_sector';
  }
  // BODY end_date
  if (lodash.has(runBacktestApiBody, 'object_backtest.api_parameters')) {
    runBacktestApiBody.object_backtest.api_parameters.end_date = backtestInfo['End Date'];
  }

  // BODY Risk MOdel
  if (lodash.has(runBacktestApiBody, 'object_backtest')) {
    runBacktestApiBody.object_backtest.risk_model_id =
      optimizedData.risk_model_id || backtestInfo?.risk_model_id?.id;
  }

  // Body Benchmark
  if (lodash.has(runBacktestApiBody, 'object_backtest')) {
    runBacktestApiBody.object_backtest.benchmark = {
      type: backtestInfo.Benchmark['Instrument type'],
      id: backtestInfo?.Benchmark?.id,
    };
  }
  if (
    backtestType === 'runRebalancingIndexBacktest' &&
    hasValidBitaDomain(userData?.userdata?.email)
  ) {
    runBacktestApiBody.object_backtest.parameters.data.rebalancing_file_path =
      backtestInfo['PORTFOLIO NAME'];
  }

  const dataComponent = getDataComponents.filter(
    v => v.key === backtestType && v.title === backtestType,
  )[0];

  // we need set and format  some data before post
  // convert initial_index_value to number
  const pathInitialValue = 'object_backtest.parameters.data.initial_index_value';
  const initialValue = lodash.get(runBacktestApiBody, pathInitialValue);

  if (initialValue) {
    lodash.set(runBacktestApiBody, pathInitialValue, Number(initialValue));
  }

  const pathInceptionValue = 'object_backtest.parameters.data.inception_value';
  const inceptionValue = lodash.get(runBacktestApiBody, pathInitialValue);

  if (initialValue) {
    lodash.set(runBacktestApiBody, pathInceptionValue, Number(inceptionValue));
  }

  // convert index_len to number if exists
  const pathIndexLen = 'object_backtest.parameters.data.index_len';
  const indexLenValue = lodash.get(runBacktestApiBody, pathIndexLen);

  if (indexLenValue) {
    lodash.set(runBacktestApiBody, pathIndexLen, Number(indexLenValue));
  }

  // add some operations_names to the array
  const pathOperationNames = 'object_backtest.parameters.data.operations_name';
  let operations = lodash.get(runBacktestApiBody, pathOperationNames, []);
  // operations = lodash.pull(operations, 'fast_entry_exit');
  if (!operations.includes('fast_entry_exit')) operations.push('fast_entry_exit');
  if (optimizedData.operations_name) {
    operations = [];
    // eslint-disable-next-line array-callback-return
    optimizedData.operations_name.map(op => {
      if (!operations.includes(op)) {
        operations.push(op);
      }
    });
  }
  if (!operations.includes('awf')) operations.push('awf');

  if (optimizedData && optimizedData.parameters) {
    runBacktestApiBody.object_backtest.parameters.data = {
      ...runBacktestApiBody.object_backtest.parameters.data,
      ...optimizedData.parameters.data,
    };
  }

  lodash.set(runBacktestApiBody, pathOperationNames, operations);

  // we get type fundamental or set it as an empty arr
  const pathTypeFundamental = 'object_backtest.parameters.data.type_fundamentals';
  const typeFundamentalArr = lodash.get(runBacktestApiBody, pathTypeFundamental, []);
  lodash.set(
    runBacktestApiBody,
    'object_backtest.parameters.data.type_fundamentals',
    typeFundamentalArr,
  );

  // rebalancing format
  const rebalancingPath = 'object_backtest.api_parameters.rebalancing.Rebalancing';

  const rebalancing = lodash.get(runBacktestApiBody, rebalancingPath);
  if (lodash.isObject(rebalancing)) {
    // fix issue when Rebalancing comes in this format
    // "Rebalancing": {
    //           "Annually": "Annually"
    //         }
    const newValue = lodash.isArray(rebalancing) ? rebalancing[0] : Object.values(rebalancing)[0];
    lodash.set(runBacktestApiBody, rebalancingPath, newValue);
  }

  //= ===== VALIDA QUE FAST POSITION Y CHECKING FACTOR SEA UN ARREGLO DENTRO DE UN ARREGLO

  const fastEntryPath = 'object_backtest.api_parameters.fast_positions';
  const fastEntryData = lodash.get(runBacktestApiBody, fastEntryPath, false);
  if (fastEntryData && fastEntryData.length > 0 && !lodash.isArray(fastEntryData[0])) {
    lodash.set(runBacktestApiBody, fastEntryPath, [fastEntryData]);
  }

  const checkingFactorPath = 'object_backtest.api_parameters.checking_factors';
  const checkingFactorData = lodash.get(runBacktestApiBody, checkingFactorPath, false);
  if (
    checkingFactorData &&
    checkingFactorData.length > 0 &&
    !lodash.isArray(checkingFactorData[0])
  ) {
    lodash.set(runBacktestApiBody, checkingFactorPath, [checkingFactorData]);
  }

  console.info('body to api to run backtest:\n', runBacktestApiBody);
  const d = new Date();
  console.info(
    `Se envio la solicitud del backtest a las ${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}:${d.getMilliseconds()}`,
  );

  if (typeof dataComponent !== 'undefined') {
    let backtestPromise;
    if (stateSummary['Structured Index']) {
      // dispatch(setSiConstituents(numberOfConstituents));
      data.structured_n_constituents =
        stateSummary?.['Structured Index']?.['Instrument Selected']?.length || 'N/A';
      let subType = false;
      if (lodash.has(stateSummary, ['Structured Index', 'Leveraged Index'])) {
        subType = 'leverage_index';
      }
      if (lodash.has(stateSummary, ['Structured Index', 'Decrement Index'])) {
        subType = 'decrement_index';
      }
      if (subType) {
        // dispatch(setIndexSubtype(subType));
        data.index_subtype = subType;
        // dispatch(setSiInstrument(stateSummary?.['Structured Index']?.['Instrument Selected'][0]));
        data.structured_instrument = stateSummary?.['Structured Index']?.['Instrument Selected'][0];
      }

      const runBacktestStructure = getStructureIndexBacktestBody(
        stateSummary,
        backtestInfo,
        await getEndDate(token),
      );
      backtestPromise = loaderDataObjectDecorator(dataComponent, dispatch).getUrlData(
        runBacktestStructure,
        token,
      );
    } else if (stateSummary.Portfolio) {
      const runBacktestBasket = getBasketIndexBacktestBody(
        stateSummary,
        backtestInfo,
        await getEndDate(token),
      );
      backtestPromise = loaderDataObjectDecorator(dataComponent, dispatch).getUrlData(
        runBacktestBasket,
        token,
      );
    } else {
      backtestPromise = loaderDataObjectDecorator(dataComponent, dispatch).getUrlData(
        runBacktestApiBody,
        token,
      );
    }

    data = {
      ...data,
      ...backtestInfo,
      parent_index_id: optimizedData?.parameters?.data?.parent_index_id,
    };

    backtestPromise
      .then(res => {
        setPopUpData(onclick);
        dispatch(setBacktest({ id: res?.data?.data?.headers?.id, data, popUpData: onclick }));
      })
      .finally(() =>
        dispatch(
          setApiData({
            title: 'indexBacktest',
            data: {},
          }),
        ),
      );
  }

  return true;
};
