import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import jsonpath from 'jsonpath';
import lodash from 'lodash';
import { motion } from 'framer-motion';
import { toast } from 'react-toastify';
import PageLayout from '../../components/Layout/PageLayout';
import ConfigYAML from '../../config/ConfigYAML';
import { defaultActiveApplyUniverse } from '../../constants/universe-active-apply-constants';
import BitaModal from '../../bitaComponents/BitaModal/BitaModal';
import PreviewValidateModal from '../../components/Summary/lib/PreviewValidateModal';
import * as CommonActions from '../../state/actions/common-actions';
import BitaButton from '../../bitaComponents/BitaButton/BitaButton';
import { createNewFilterGroup } from '../../state/actions/universe-operations-actions';
import { useSummary } from '../../bitaHooks';
import styles from './universe-builder.module.scss';
import { Icons } from '../../constants/icons';
import { IconsModules } from '../../constants/icons-module';
import Builder from '../../builder/Builder';
import { setBy } from '../../builder/helpers/deepSet';
import { getDataComponents } from '../../api-data-mapping/dataMap';
import UniverseRevisionDatesModal from './UniverseRevisionDatesModal';

const getUniverseBuilderCards = () =>
  ConfigYAML.jsonPath([
    'universe_builder',
    "sections[?(@.name == 'Available Filters')]",
    'components',
    'components',
  ]);
const addibleTabs = ConfigYAML.jsonPath([
  'universe_builder',
  "sections[?(@.name == 'Available Filters')]",
]);

const FiltersMainPage = props => {
  const {
    filterGroups,
    createFilterGroup,
    history,
    match: { params },
  } = props;

  const dispatch = useDispatch();
  const location = useLocation();

  const selectedFilterGroupIndex = useMemo(() => {
    if (!params.filtergroupindex) {
      return 0;
    }
    return +params.filtergroupindex;
  }, [params]);

  const [addUniverse] = useSummary('universeBuilderOperations', 'filterUniverse');
  const [key, setKey] = useState('');
  const [applyEnabled, setApplyEnabled] = useState(false);
  const [addToSummary] = useSummary(
    'Universe Builder',
    `Filter Group ${selectedFilterGroupIndex + 1}.${key}`,
  );
  // eslint-disable-next-line
  const [_, removeFromSummary] = useSummary(
    'Universe Builder',
    `Filter Group ${selectedFilterGroupIndex + 1}`,
  );
  const [addFilters] = useSummary('universeBuilderOperations', 'Filters');
  const [addConstituents] = useSummary('universeBuilderOperations', 'constituents');
  const [addFilteringDate] = useSummary('universeBuilderOperations', 'fintering_date');

  const universeState = useSelector(state => state.summary.universeBuilderOperations);
  const summaryState = useSelector(state => state.summary['Universe Builder']);
  const token = useSelector(state => state.auth.token);
  const [isDelete, setIsDelete] = useState({});
  const [component, setComponent] = useState(null);
  const [filterState, setFilterState] = useState([]);
  const [previewModalOpen, setPreviewModalOpen] = useState(false);
  const [visibleConfirmation, setVisibleConfirmation] = React.useState(null);
  const universeSelected = useSelector(state => state.summary);
  const [activeApply, setActiveApply] = useState(false);

  const Imagen = IconsModules.UniverseModule;
  const setFilter = () => {
    const { constituents } = universeState;
    addConstituents('N/A');
    // const summaryTransformed = transform(summaryState);
    const summaryTransformed = summaryState;
    const filterGroupsdata = Object.keys(summaryState).map(filters => {
      const filtersData = {};
      Object.keys(summaryTransformed[filters]).forEach(filterKey => {
        if (
          summaryTransformed[filters][filterKey][filterKey] &&
          Array.isArray(summaryTransformed[filters][filterKey][filterKey])
        ) {
          filtersData[filterKey] = summaryTransformed[filters][filterKey][filterKey].map(row => {
            const fieldData = {};
            Object.keys(row).forEach(field => {
              if (row[field]?.value && (row[field].id || row[field].id === 0)) {
                fieldData[field] =
                  typeof row[field].id === 'number' ? row[field].id : row[field].value;
              } else {
                fieldData[field] = row[field];
              }
            });
            return fieldData;
          });
        } else {
          const fieldData = {};
          Object.keys(summaryTransformed[filters][filterKey]).forEach(field => {
            if (
              summaryTransformed?.[filters]?.[filterKey]?.[field]?.value &&
              (summaryTransformed?.[filters]?.[filterKey]?.[field]?.id ||
                summaryTransformed?.[filters]?.[filterKey]?.[field]?.id === 0)
            ) {
              fieldData[field] =
                typeof summaryTransformed[filters][filterKey][field].id === 'number'
                  ? summaryTransformed[filters][filterKey][field].id
                  : summaryTransformed[filters][filterKey][field].value;
            } else if (Array.isArray(summaryTransformed[filters][filterKey][field])) {
              fieldData[field] = summaryTransformed[filters][filterKey][field].map(val =>
                val.id || val.id === 0 ? val.id : val,
              );
            } else {
              fieldData[field] = summaryTransformed[filters][filterKey][field];
            }
            // eslint-disable-next-line
            scrollY;
          });
          filtersData[filterKey] = fieldData;
        }
      });
      // TODO ESG provider hardcoded
      if (filtersData['ESG Issues']) {
        filtersData['ESG Issues'] = filtersData['ESG Issues'];
        const { provider } = filtersData['ESG Issues'];
        filtersData['ESG Issues'].provider = provider === 'Sustainalytics' ? 7 : 6;
      }
      if (filtersData['ESG Ratings']) {
        filtersData['ESG Ratings'].forEach((i, index) => {
          const ratingProvider = filtersData['ESG Ratings'][index].provider;
          switch (ratingProvider) {
            case 'Sustainalytics':
              filtersData['ESG Ratings'][index].provider = '7';
              break;
            case 'Bita':
              filtersData['ESG Ratings'][index].provider = '8';
              break;
            default:
              filtersData['ESG Ratings'][index].provider = '6';
              break;
          }
        });
      }
      if (filtersData.Liquidity) {
        filtersData.Liquidity.forEach((item, index) => {
          if (item.indicator === 152 && (item.operator === '>=' || item.operator === '<=')) {
            item.value = (item.value / 100).toString();
          }
        });
      }
      if (filtersData.Geography) {
        filtersData.Geography.forEach((item, index) => {
          if (item?.division === 'No Apply Filter by Group') {
            delete item?.value;
            delete item?.group_by;
            delete item?.indicator;
          }
          item.selection = item?.selection?.map(itemSel => {
            return itemSel?.id;
          });
          if (item.value) {
            item.value /= 100;
          }
        });
      }
      if (filtersData['Single Instrument']) {
        if (
          filtersData['Single Instrument'].operator_type === 'Add' &&
          universeState?.universe?.['Universe Name'] === 'WHITE CANVAS' &&
          universeState?.constituents > 0
        ) {
          filtersData['Single Instrument'].operator_type = 'Add-in';
        }
      }
      const filterGroup = {
        delete: isDelete,
        filters: {
          ...filtersData,
        },
      };
      return filterGroup;
    });
    let name = '';
    let id = 0;
    if (universeState) {
      name = universeState.universe ? universeState.universe.name : '';
      id = universeState.universe && universeState.universe.id;
    }
    const _filterType = Object.values(filterGroupsdata[0].filters).filter(
      e => e.operator_type || (e[0] ? e[0].operator_type : null),
    );
    const filterType =
      _filterType.length === 0
        ? false
        : typeof _filterType[_filterType.length - 1] === 'object'
        ? _filterType[_filterType.length - 1]
        : _filterType[_filterType.length - 1][0];
    if (filterType && !filterType?.operator_type && filterType[0]?.operator_type) {
      filterType.operator_type = filterType[0].operator_type;
    }

    if (filterGroupsdata[0].filters['iClima Themes']) {
      filterGroupsdata[0].filters = {
        ...filterGroupsdata[0].filters,
        Themes: filterGroupsdata[0].filters['iClima Themes'],
      };

      delete filterGroupsdata[0].filters['iClima Themes'];
    }
    if (filterGroupsdata[0].filters.Themes) {
      filterGroupsdata[0].filters.Themes.exposure = (
        Number(filterGroupsdata[0].filters.Themes.exposure) / 100
      ).toString();
    }
    const dataFilter = {
      base_universe: name,
      base_universe_id: id,
      type: typeof name !== 'undefined' ? 'exclusion' : 'inclusion',
      filter_groups: filterGroupsdata,
    };
    const dataComponent = getDataComponents.filter(
      v =>
        v.key === 'UniverseFilter' && v.type === 'UniverseFilter' && v.title === 'UniverseFilter',
    )[0];
    if (typeof dataComponent !== 'undefined') {
      const promise = dataComponent.getUrlData(dataFilter, token);
      promise
        .then(res => {
          const filterPath = `[${selectedFilterGroupIndex}].elements`;
          addUniverse(dataFilter);
          const elements = parseInt(res.elements, 10);
          addFilters(setBy(universeState ? universeState.Filters || {} : {}, filterPath, elements));
          addConstituents(res.elements);
          addFilteringDate(res.filtering_date);
          setIsDelete({});
        })
        .catch(err => {
          toast.error('Something went wrong, filter not applied.');
          addConstituents(constituents);
          removeFromSummary(key);
          setIsDelete({});
        });
    }
  };
  useEffect(() => {
    if (typeof isDelete === 'boolean') {
      setFilter();
    }
    // eslint-disable-next-line
  }, [isDelete]);
  const onClickApply = () => {
    setIsDelete(false);
    addToSummary(
      lodash.get(filterState, `Filter Group ${selectedFilterGroupIndex + 1}.${key}`, {}),
    );
  };

  const validateAddibleRow = () => {
    const addibleRowList = lodash.get(
      filterState,
      [`Filter Group ${selectedFilterGroupIndex + 1}`, key, key],
      [],
    );
    const validationList = [];
    addibleRowList.forEach(ad => {
      if (ad.value && ad.value !== '' && Number(ad.operator?.id) < 6) {
        validationList.push(1);
      } else if (Number(ad.operator?.id) > 5) {
        delete ad.value;
        validationList.push(1);
      }
    });
    return validationList.length > 0 && validationList.length === addibleRowList.length;
  };
  const validateSingleInstrumentFilter = () => {
    let activeSingleInstrument = false;
    const siItem = lodash.get(
      filterState,
      [`Filter Group ${selectedFilterGroupIndex + 1}`, key],
      false,
    );
    if (siItem && siItem.operator_type?.value && siItem.securities?.length > 0) {
      activeSingleInstrument = true;
    }
    return activeSingleInstrument;
  };

  const validateSizeFilter = () => {
    const sizeItem = lodash.get(
      filterState,
      [`Filter Group ${selectedFilterGroupIndex + 1}`, key],
      false,
    );
    let active = false;
    if (sizeItem.operator?.value === 'Between') {
      if (
        sizeItem &&
        sizeItem.value?.[0] &&
        sizeItem.value?.[0] !== '' &&
        sizeItem.value?.[0] !== undefined &&
        sizeItem.value?.[1] &&
        sizeItem.value?.[1] !== undefined &&
        sizeItem.value?.[1] !== ''
      ) {
        active = true;
      }
    } else if (sizeItem && sizeItem.value && sizeItem.value !== '') {
      active = true;
    }
    return active;
  };
  useEffect(() => {
    const newPath = `$..${defaultActiveApplyUniverse[key]}`;
    if (key === 'Size') {
      setActiveApply(validateSizeFilter());
    } else if (key === 'Single Instrument') {
      setActiveApply(validateSingleInstrumentFilter());
    } else if (
      key === 'ESG Ratings' ||
      key === 'Factors' ||
      key === 'Liquidity' ||
      key === 'Custom Filter' ||
      key === 'Fundamentals'
    ) {
      setActiveApply(validateAddibleRow());
    } else if (
      defaultActiveApplyUniverse?.[key] &&
      jsonpath.nodes(filterState, newPath)?.[0] &&
      jsonpath.nodes(filterState, newPath)?.[0]?.value
    ) {
      if (Object.values(jsonpath.nodes(filterState, newPath)?.[0]?.value)?.length > 0) {
        setActiveApply(true);
      } else {
        setActiveApply(false);
      }
    } else {
      if (!defaultActiveApplyUniverse[key]) {
        setActiveApply(true);
      }
      setActiveApply(false);
    }
  }, [summaryState, filterState]);

  const selectedFilterIndex = useMemo(() => {
    if (!params.filterindex) {
      return null;
    }
    return +params.filterindex;
  }, [params]);

  const onFilterChange = (path, value) => {
    let data = null;
    data =
      path === 'Geography'
        ? value.map((item, index) => {
            if (item?.division === 'No Apply Filter by Group') {
              delete item?.value;
              delete item?.group_by;
              delete item?.indicator;
            }
            return item;
          })
        : value;
    if (Array.isArray(data) && data[0] && typeof data[0] === 'object' && !path.includes('.')) {
      data = setBy({}, path, data || value);
    } else {
      data = setBy({}, path, data || value)[component.title];
    }
    if (
      Array.isArray(value) &&
      value.length !==
        lodash.get(
          filterState,
          `Filter Group ${selectedFilterGroupIndex + 1}.${component.title}.${component.title}`,
          [],
        ).length &&
      lodash.get(
        filterState,
        `Filter Group ${selectedFilterGroupIndex + 1}.${component.title}.${component.title}`,
        [],
      ).length !== 0
    ) {
      setApplyEnabled(true);
    } else {
      setApplyEnabled(false);
    }
    if (typeof data === 'object' && Array.isArray(data) && data.length === 0) {
      setFilterState(old => ({
        ...old,
        [`Filter Group ${selectedFilterGroupIndex + 1}`]: {
          ...old[[`Filter Group ${selectedFilterGroupIndex + 1}`]],
          [component.title]: {
            ...data,
          },
        },
      }));
    } else if (
      lodash.has(summaryState, [`Filter Group ${selectedFilterGroupIndex + 1}`, component.title])
    ) {
      setFilterState(old => ({
        ...old,
        [`Filter Group ${selectedFilterGroupIndex + 1}`]: {
          ...old[[`Filter Group ${selectedFilterGroupIndex + 1}`]],
          [component.title]: {
            ...lodash.get(
              summaryState,
              `Filter Group ${selectedFilterGroupIndex + 1}.${component.title}`,
              {},
            ),
            ...data,
          },
        },
      }));
    } else {
      setFilterState(old => ({
        ...old,
        [`Filter Group ${selectedFilterGroupIndex + 1}`]: {
          ...old[[`Filter Group ${selectedFilterGroupIndex + 1}`]],
          [component.title]: {
            ...lodash.get(
              old,
              `Filter Group ${selectedFilterGroupIndex + 1}.${component.title}`,
              {},
            ),
            ...data,
          },
        },
      }));
    }
  };
  const onClickDelete = () => {
    const summaryKeys =
      summaryState['Filter Group 1'] && Object.keys(summaryState['Filter Group 1']);
    if (
      !lodash.has(universeState, ['universe', 'id']) &&
      summaryKeys.length > 1 &&
      summaryKeys.indexOf(key) === 0
    ) {
      toast('Please, remove all the other filters before removing this one.', {
        toastId: 'delete_summary',
      });
      return;
    }
    setIsDelete(true);
    removeFromSummary(key);
  };
  useEffect(() => {
    if (
      location.pathname === '/universe-builder/universe-builder' ||
      location.pathname ===
        `/universe-builder/universe-builder/filtergroup-${selectedFilterGroupIndex}`
    ) {
      setComponent(addibleTabs);
    }
    if (
      location.pathname ===
      `/universe-builder/universe-builder/filtergroup-${selectedFilterGroupIndex}/filter-${selectedFilterIndex}`
    ) {
      const cards = getUniverseBuilderCards();
      setComponent(cards[selectedFilterIndex].onclick);
    }
  }, [location, selectedFilterIndex, selectedFilterGroupIndex]);
  useEffect(() => {
    setComponent(addibleTabs);
  }, []);
  useEffect(() => {
    dispatch(CommonActions.resetSubheader());

    setKey(component && component.title);
    setFilterState({});
    if (component && component.header_path) {
      Object.values(component.header_path).forEach(path =>
        dispatch(
          CommonActions.appendSubheader(
            path.replace(/{groupindex}/gi, selectedFilterGroupIndex + 1),
          ),
        ),
      );
    }
  }, [component, selectedFilterGroupIndex]);

  const compSetter = comp => {
    const cards = Object.values(component.components);
    if (cards && comp.title) {
      const index = Object.values(cards[0].components).findIndex(
        ({ title }) => title === comp.title,
      );
      if (index !== -1) {
        history.push(
          `/universe-builder/universe-builder/filtergroup-${selectedFilterGroupIndex}/filter-${index}`,
        );
        setComponent(comp);
      }
    }
  };
  return (
    <PageLayout>
      <div className="content">
        <div className={styles.pageHeading}>
          <div className={styles.pageTitle}>
            <Imagen />
            {universeState && universeState.universe && universeState.universe.name
              ? universeState.universe.name
              : 'Untitled Universe'}
          </div>
          <div
            onClick={() => {
              setVisibleConfirmation(true);
            }}
          >
            <Icons.account style={{ width: '20px', height: '20px' }} />
          </div>
        </div>
        {universeSelected &&
          universeSelected.universeBuilderOperations &&
          universeSelected.universeBuilderOperations.universe.id &&
          filterGroups.map((filterGroup, index) => (
            <motion.div
              key={`motion-${index}`}
              style={{ display: 'inline-block' }}
              initial={{ opacity: 0, transform: 'scale(0.3)' }}
              animate={{ opacity: 1, transform: 'scale(1)' }}
            >
              <BitaButton
                key={index}
                primary
                onClick={() => {
                  history.push(`/universe-builder/universe-builder/filtergroup-${index}`);
                }}
                className={classnames(styles.filterGroupButton, {
                  [styles.filterGroupButtonActive]: selectedFilterGroupIndex === index,
                })}
              >
                FILTER GROUP #{index + 1}
              </BitaButton>
            </motion.div>
          ))}
        {universeSelected &&
        universeSelected.universeBuilderOperations &&
        universeSelected.universeBuilderOperations.universe.id &&
        filterGroups.length < -1 ? (
          <BitaButton
            primary
            onClick={() => {
              createFilterGroup();
              history.push(`/universe-builder/universe-builder/filtergroup-${filterGroups.length}`);
            }}
            className={styles.filterGroupAddButton}
          >
            +
          </BitaButton>
        ) : null}
        {component && (
          <div
            style={{
              opacity: lodash.has(
                summaryState,
                `Filter Group ${selectedFilterGroupIndex + 1}.${key}`,
              )
                ? 0.5
                : 1,
              pointerEvents: lodash.has(
                summaryState,
                `Filter Group ${selectedFilterGroupIndex + 1}.${key}`,
              )
                ? 'none'
                : 'all',
            }}
            className={`${styles.content} contentUniverseBuilder `}
            key={`key-Filter-Group-${selectedFilterGroupIndex + 1}-${component.title}`}
          >
            <Builder
              path={component.title}
              currentView={`Filter Group ${selectedFilterGroupIndex + 1}`}
              filterGroup={selectedFilterGroupIndex}
              universe="Universe Builder"
              onChange={onFilterChange}
              widgetData={filterState}
              {...component}
              // moduleName={moduleName}
              setterFunction={compSetter}
            />
          </div>
        )}
        {selectedFilterIndex !== null && (
          <div className={styles.actionButtons}>
            <BitaButton
              style={{ marginRight: '20px' }}
              width="150px"
              primary
              onClick={onClickDelete}
              disabled={
                !lodash.has(summaryState, `Filter Group ${selectedFilterGroupIndex + 1}.${key}`)
              }
            >
              Delete
            </BitaButton>
            <BitaButton
              width="150px"
              primary
              onClick={() => {
                onClickApply(false);
              }}
              disabled={
                lodash.has(summaryState, `Filter Group ${selectedFilterGroupIndex + 1}.${key}`) ||
                !activeApply
              }
            >
              Apply
            </BitaButton>
          </div>
        )}
        <BitaModal isModalOpen={previewModalOpen} setModalState={setPreviewModalOpen}>
          <PreviewValidateModal
            toggleModal={setPreviewModalOpen}
            selectUniverse={props.selectUniverse}
            universeSelected={props.universeSelected}
          />
        </BitaModal>
        {/* <BitaModal isModalOpen={visibleConfirmation} setModalState={setVisibleConfirmation}>
          <div>hola</div>
        </BitaModal> */}
        <div>
          <UniverseRevisionDatesModal
            visibility={visibleConfirmation}
            universeSelected={universeState}
            close={() => setVisibleConfirmation(false)}
          />
        </div>
      </div>
    </PageLayout>
  );
};

const mapStateToProps = state => ({
  filterGroups: state.universeBuilderOperations.filterGroups,
  selectedUniverse: state.summary[`universeBuilderOperations.universe.universeselected`],
});

const mapDispatchToProps = dispatch => ({
  createFilterGroup: () => dispatch(createNewFilterGroup()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FiltersMainPage);
