import React from 'react';
import { iconF } from '../lib/generatorIcons';
import styles from '../../builder.module.scss';

export const BuilderTitleComponent = ({ icon, title }) => {
  const ImageT = iconF(icon);
  return (
    <div className={`${styles.titleComponentContainer} builderTitle `}>
      <h2 className={styles.widgetTitle}>
        <ImageT />
        <span /> {title}
      </h2>
    </div>
  );
};
