import React from 'react';
import moment from 'moment';
import styles from '../workflow-modal.module.css';
import { IconsModules } from '../../../../constants/icons-module';

export const BitaCardSelectedContent = props => {
  const omitedAttribs = ['0', '1', 'icon', 'parent_universe_id', 'type', 'message'];
  const transformColumn = str => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  return (
    <div className={styles.selectedRowContainer}>
      <div>
        {props.icon === 'baseMethodology' ? (
          <div className="iconCardSelect">
            {props.rebalancingMode ? (
              <IconsModules.MethodologyRRIcon />
            ) : props.author === 'BITA' ? (
              <IconsModules.BitaMethodology />
            ) : (
              <IconsModules.UserMethodologyModule />
            )}
          </div>
        ) : (
          <div className="iconCardSelect">
            {props.rebalancingMode ? (
              <IconsModules.UniverseRRIcon />
            ) : props.author === 'BITA' ? (
              <IconsModules.BitaUniverseModule />
            ) : (
              <IconsModules.UserUniverseModule />
            )}
          </div>
        )}
      </div>
      <div className={styles.selectedRowContents}>
        {Object.entries(props).map(([column, value]) => {
          if (
            !omitedAttribs.includes(column) &&
            value !== '' &&
            (typeof value === 'string' || column === 'constituents')
          ) {
            if (column === 'created_at' || column === 'updated_at' || column === 'create_date') {
              return (
                <div key={column} className={styles.selectedRowLine}>
                  <b>{transformColumn(column).replace('_', ' ')}: </b>
                  {moment(value).format('YYYY-MM-DD')}
                </div>
              );
            }
            if (column === 'constituents' && Array.isArray(value)) {
              return null;
            }
            return (
              <div key={column} className={styles.selectedRowLine}>
                <b>
                  {transformColumn(column)
                    .replace('Author_id', 'Author')
                    .replace('_', ' ')}
                  :{' '}
                </b>
                {value}
              </div>
            );
          }
          return null;
        })}
      </div>
    </div>
  );
};
