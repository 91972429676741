import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Modal from 'react-modal';
import ReactColorPicker from '@super-effective/react-color-picker';
import styles from './BitaBrandingModal.module.scss';
import { Icons } from '../../constants/icons';
import BitaButton from '../../bitaComponents/BitaButton/BitaButton';
import BitaInput from '../BitaInput/BitaInput';
import { setFile } from '../../state/actions/module-upload-actions';
import ImageCropper from './ImageCropper';

const customStyles = {
  content: {
    whiteSpace: 'pre-line',
    marginTop: '15vh',
    marginBottom: '20vh',
    marginLeft: '25vw',
    marginRight: '30vw',
    maxHeight: '95%',
    right: 'auto',
    bottom: 'auto',
    // transform: 'translate(-50%, -50%)' do not use,
    background: 'rgb(41, 41, 42)',
    color: '#ffffff',
    border: 'none',
    borderRadius: '15px',
    paddingLeft: 50,
    paddingRight: 50,
    fontSize: 16,
    fontWeight: 600,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    width: '45vw',
  },
  overlay: {
    backgroundColor: 'rgba(78,78,78, 0.75)',
    zIndex: '999999',
  },
};

export const BitaBrandingModal = ({
  title,
  open,
  closeModal,
  saveChange,
  init_value,
  description,
  type,
  validation = 800,
  website,
  company,
  logo,
}) => {
  const [isOpen, setOpen] = useState(open || false);
  const [value, setValue] = useState(init_value);
  const [companyWeb, setWebsite] = useState(website);
  const [companyName, setCompanyName] = useState(company);
  const [companyLogo, setLogo] = useState(logo);
  const [files, setFiles] = useState('');
  const dispatch = useDispatch();
  const imageInputRef = React.useRef(); // See Supporting Documentation #2
  const [blob, setBlob] = useState(null);

  const getBlob = blobs => {
    // pass blob up from the ImageCropper component
    setBlob(blobs);
  };

  const requestClose = () => {
    setOpen(false);
    closeModal();
  };
  useEffect(() => {
    if (files !== '') {
      const reader = new FileReader();
      reader.readAsDataURL(files);
      reader.onload = () => {
        setLogo(reader.result);
      };
      reader.onerror = error => {
        console.info('Error converting file ', error);
      };
    }
  }, [files]);
  useEffect(() => {
    if (imageInputRef?.current) {
      imageInputRef.current.value = '';
    }
    setOpen(open);
  }, [open]);
  const returnStyle = () => {
    switch (type) {
      case 'color':
        return styles.paletteContainer;
      case 'multimedia':
        return styles.multimediaContainer;
      default:
        return styles.paletteContainer;
    }
  };

  const changeValue = val => {
    setValue(val);
  };

  const saveChanges = () => {
    if (type === 'multimedia') {
      let newLogoValue;
      if (blob) {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = () => {
          newLogoValue = reader.result;
          setLogo(newLogoValue);
          saveChange(companyWeb, 'website');
          saveChange(newLogoValue, 'logo');
          saveChange(companyName, 'company');
          requestClose();
        };
        reader.onerror = error => {
          console.info('Error converting file ', error);
        };
      } else {
        newLogoValue = companyLogo;
        setLogo(newLogoValue);
        saveChange(companyWeb, 'website');
        saveChange(newLogoValue, 'logo');
        saveChange(companyName, 'company');
        requestClose();
      }
    } else {
      saveChange(value);
      requestClose();
    }
  };

  return (
    <div>
      <Modal
        isOpen={isOpen}
        onRequestClose={requestClose}
        style={customStyles}
        shouldCloseOnOverlayClick
      >
        <div className={styles.header}>
          <Icons.Avanti style={{ marginRight: '20px' }} />
          <h2 style={{ margin: '0', padding: '0', fontSize: '22px', textTransform: 'uppercase' }}>
            Company Details
          </h2>
          <span onClick={requestClose} className="closeModal">
            &times;
          </span>
        </div>
        <div className="separator" />

        <p className={styles.modalDescription}>{description}</p>
        <div className={returnStyle()}>
          {type === 'color' && <ReactColorPicker color={value} onChange={changeValue} />}
          {type === 'textarea' && (
            <div className={styles.textContainer}>
              <textarea
                type="textarea"
                value={value}
                onChange={evt => changeValue(evt.target.value)}
                className={styles.textArea}
                maxLength={validation}
              />
              <span> Characters Left: {validation - value?.length}</span>
            </div>
          )}
          {type === 'multimedia' && (
            <div className={styles.companyInfoContainer}>
              <div className={styles.companyInfoColumn}>
                <div>
                  <input
                    type="file"
                    onChange={event => {
                      dispatch(setFile(false));
                      setFiles(event.target.files[0]);
                    }}
                    name="logoFile"
                    id="logoFile"
                    ref={imageInputRef}
                    className={styles.inputfile}
                    accept=".png,.svg,.jpg,.jpeg"
                  />
                  <label htmlFor="logoFile">Upload Logo</label>
                  <span style={{ color: '#a7a7aa' }}>{files && files.name}</span>
                </div>
                <BitaInput
                  externalTitle="Company Name"
                  externalTitleStyle={styles.brandingTitle}
                  inputStyle={styles.widgetInput}
                  placeholder="Enter Company Name"
                  id="companyname"
                  value={companyName || undefined}
                  handleChange={setCompanyName}
                  inputContainerStyle={styles.websiteContainer}
                />
                <BitaInput
                  externalTitle="Website"
                  externalTitleStyle={styles.brandingTitle}
                  inputStyle={styles.widgetInput}
                  placeholder="Enter website link"
                  id="website"
                  value={companyWeb || undefined}
                  handleChange={setWebsite}
                  inputContainerStyle={styles.websiteContainer}
                />
              </div>
              <div
                className={styles.companyInfoColumn}
                style={{
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                <label className={styles.brandingTitle}>Logo Preview</label>
                {companyLogo && companyLogo !== '' && (
                  <ImageCropper key={companyLogo} getBlob={getBlob} inputImg={companyLogo} />
                )}
              </div>
            </div>
          )}
        </div>
        <div className={styles.modalFooter}>
          <BitaButton
            primary
            height="28px"
            width="148px"
            className={styles.bitaButtonCustom}
            onClick={() => saveChanges()}
          >
            Apply
          </BitaButton>
        </div>
      </Modal>
    </div>
  );
};

export default BitaBrandingModal;
