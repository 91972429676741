import lodash from 'lodash';
import * as ACTIONS from '../../constants/index-operations-constants';

const initialState = {
  generalRules: {
    numberOfConsituents: {
      on: false,
      enabled: true,
    },
    currency: {
      on: false,
      enabled: true,
    },
    returnCalculation: {
      on: false,
      enabled: true,
    },
    weightingMechanism: {
      on: false,
      enabled: true,
    },
  },
  rebalancingReconstitution: {
    caps: {
      on: false,
      enabled: true,
    },
    floors: {
      on: false,
      enabled: true,
    },
    rebalancing: {
      on: false,
      enabled: true,
    },
    reconstitution: {
      on: false,
      enabled: true,
    },
  },
  additionalRules: {
    cashComponent: {
      on: false,
      enabled: true,
    },
    deletionReplacementRules: {
      on: false,
      enabled: true,
    },
    securities: {
      on: false,
      enabled: true,
    },
  },
  fastExitAndEntry: {
    fastEntry: {
      on: false,
      enabled: true,
    },
    fastExit: {
      on: false,
      enabled: true,
    },
  },
};

const indexOperationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.GENERIC_UPDATE: {
      const { subgroup, operation, data } = action.payload;
      return {
        ...state,
        [subgroup]: {
          ...state[subgroup],
          [operation]: {
            ...state[subgroup][operation],
            ...data,
          },
        },
      };
    }

    case ACTIONS.ALL_UPDATE: {
      return {
        ...state,
        ...lodash.merge(state, action.payload),
      };
    }
    case ACTIONS.RESET: {
      return {
        generalRules: {
          numberOfConsituents: {
            on: false,
            enabled: true,
          },
          currency: {
            on: false,
            enabled: true,
          },
          returnCalculation: {
            on: false,
            enabled: true,
          },
          weightingMechanism: {
            on: false,
            enabled: true,
          },
        },
        rebalancingReconstitution: {
          caps: {
            on: false,
            enabled: true,
          },
          floors: {
            on: false,
            enabled: true,
          },
          rebalancing: {
            on: false,
            enabled: true,
          },
          reconstitution: {
            on: false,
            enabled: true,
          },
        },
        additionalRules: {
          cashComponent: {
            on: false,
            enabled: true,
          },
          deletionReplacementRules: {
            on: false,
            enabled: true,
          },
          securities: {
            on: false,
            enabled: true,
          },
        },
        fastExitAndEntry: {
          fastEntry: {
            on: false,
            enabled: true,
          },
          fastExit: {
            on: false,
            enabled: true,
          },
        },
      };
    }

    default: {
      return state;
    }
  }
};

export default indexOperationsReducer;
