import * as axiosBase from 'axios';
import * as _ from 'lodash';
import store from './state/store/index';
import { logout } from './state/actions/auth-actions';

//* deprecated

/*



 const Axios = axios.create({
  baseURL: 'https://api.bitadata.com/v1',
}); */

// an api mock
/* const Axios = {
  post: (url, body) => {
    switch (url) {
      case '/login':
        return new Promise(resolve => {
          setTimeout(() => {
            resolve({
              data: {
                username: 'prestuccia',
                token: 'temptoken9999',
                name: 'Pablo Restuccia',
                email: 'prestuccia@gmail.com',
              },
            });
          }, 200);
        });
      default:
        return new Promise(resolve => {
          setTimeout(() => {
            resolve({ data: body });
          }, 200);
        });
    }
  },
}; */

const logoutUser = _.once(() => {
  store.dispatch(logout('Your session was initialized in another device, please login again.'));
});

const handleError = err => {
  if (err?.response?.status === 401 || err?.response?.status === 403) {
    if (err.response.data?.expired) {
      logoutUser();
    }
  }
};

const pipeThen = response => {
  //  console.info('API CALL============================================================');
};

const axios = {
  ...axiosBase,
  get: (...args) => {
    const axiosCall = axiosBase.get(...args);
    axiosCall.then(pipeThen).catch(handleError);
    return axiosCall;
  },
  post: (...args) => {
    const axiosCall = axiosBase.post(...args);
    axiosCall.then(pipeThen).catch(handleError);
    return axiosCall;
  },
};

export default axios;
