import React, { useState, useRef } from 'react';
import style from './DragAndDropFile.module.scss';

const DragAndDropFile = props => {
  const fileInputRef = useRef(null);

  const handleDragEnter = event => {
    event.preventDefault();
  };

  const handleDragOver = event => {
    event.preventDefault();
  };

  const handleDragLeave = () => {};

  const handleDrop = event => {
    event.preventDefault();
    const { files } = event.dataTransfer;
    if (files instanceof FileList) {
      delete files.length;
    }
    props.onChange(Object.values(files));
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = event => {
    const { files } = event.target;
    if (files instanceof FileList) {
      delete files.length;
    }
    props.onChange(Object.values(files));
  };

  return (
    <div
      className={style.contentDragDrop}
      onDragEnter={handleDragEnter}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      onClick={handleClick}
    >
      <p>Drag and drop your files here</p>
      <input
        type="file"
        className={style.inputDragDrop}
        ref={fileInputRef}
        onChange={handleFileChange}
        multiple
      />
    </div>
  );
};

export default DragAndDropFile;
