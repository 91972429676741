import React, { Fragment } from 'react';
import _ from 'lodash';
import FileSaver from 'file-saver';
import moment from 'moment';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'simplebar'; // or "import SimpleBar from 'simplebar';" if you want to use it manually.
import 'simplebar/dist/simplebar.css';
import { useSelector, useDispatch } from 'react-redux';
import { Icons } from '../../../../constants/icons';
import styles from '../workflow-modal.module.css';
import BitaButton from '../../../../bitaComponents/BitaButton/BitaButton';
import BitaCalendarDate from '../../../../components/BitaCalendar/BitaCalendarDate';
import BitaInput from '../../../../components/BitaInput/BitaInput';
import BitaSelect from '../../../../components/BitaSelect/BitaSelect';
import Switcher from '../../../../bitaComponents/Switcher/Switcher';
import { getDataComponents } from '../../../../api-data-mapping';
import { loaderDataObjectDecorator } from '../../../../utils/loading_status_tools';
import { toastInterceptor } from '../../../../utils/utils';
import { setBacktest } from '../../../../state/actions/backtest-actions';

const uploadFileFactsheet = (tokenUser, file) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/upload-file`, file, {
    headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${tokenUser}` },
  });
};

export const FactsheetBuilderComponent = props => {
  const [files, setFiles] = React.useState('');
  const [data, setData] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [indexDescription, setIndexDescription] = React.useState(true);
  const [performance, setPerformance] = React.useState(true);
  const [generalStatistics, setGeneralStatistics] = React.useState(true);
  const [indexCharacteristics, setIndexCharacteristics] = React.useState(true);
  const [descriptiveStats, setDescriptiveStats] = React.useState(true);
  const [topSectorCountry, setTopSectorCountry] = React.useState(true);
  const [companyDescription, setCompanyDescription] = React.useState(true);
  const [indexMethodology, setIndexMethodology] = React.useState(true);
  const [sectorClassification, setSectorClassification] = React.useState({
    id: 'GICS',
    value: 'GICS',
  });
  const [disclaimer, setDisclaimer] = React.useState(true);
  const [custom, setCustom] = React.useState(false);
  const [baseValue, setBaseValue] = React.useState('1,000');
  const [startDate, setStartDate] = React.useState(false);
  const [endDate, setEndDate] = React.useState(false);
  const token = useSelector(state => state.auth.token);

  const dispatch = useDispatch();

  return (
    <Fragment className="modal-fs-module">
      <div data-simplebar style={{ maxHeight: '63vh' }}>
        <div>
          <h2
            className="instrument_selection_titles"
            style={{ marginBottom: '2px', fontSize: '14px' }}
          >
            <div>
              <Icons.parameters />
              <div className="title">FACTSHEET BUILDER</div>
            </div>
          </h2>
          <div className="content-Widget" style={{ padding: '15px', marginBottom: '15px' }}>
            <div className={styles.contentFirstOption} style={{ paddingTop: '0px' }}>
              <div style={{ marginRight: '50px' }}>
                <div style={{ marginBottom: '10px' }}>
                  <span className={styles.titleFactsheet}>1 º STEP:</span>
                  TEMPLATE FACTSHEET DOWNLOAD
                </div>
                <BitaButton
                  primaryWhite
                  onClick={() => {
                    FileSaver.saveAs(
                      `${process.env.PUBLIC_URL}/files/Factsheet_Template.xlsx`,
                      `Factsheet_Template.xlsx`,
                    );
                  }}
                  style={{ width: '200px', marginTop: '0px' }}
                  className="runBacktestStructured"
                >
                  Factsheet Template
                </BitaButton>
              </div>
              <div>
                <div style={{ marginBottom: '10px' }}>
                  <span className={styles.titleFactsheet}>2º STEP:</span>
                  TEMPLATE UPLOAD
                </div>
                <div className={styles.contentUpload}>
                  <input
                    style={{
                      opacity: files.name ? '0.5' : '1',
                      pointerEvents: files.name ? 'none' : '',
                    }}
                    type="file"
                    // eslint-disable-next-line no-return-assign
                    onClick={e => (e.target.value = null)}
                    onChange={event => {
                      setLoading(true);
                      setFiles(event.target.files[0]);
                      const formData = new FormData();
                      formData.append('template', event.target.files[0]);
                      formData.append('type', 'factsheet');
                      uploadFileFactsheet(token, formData)
                        .then(rsp => {
                          if (rsp.data.error) {
                            toast.error(rsp.data.message);
                            setLoading(false);
                            setFiles('');
                            setStartDate(false);
                            setEndDate(false);
                          } else {
                            setStartDate(moment(rsp.data.factsheet_dates.start_date));
                            setEndDate(moment(rsp.data.factsheet_dates.end_date));
                            setData(rsp.data);
                            setLoading(false);

                            // Set status for the text fields
                            setIndexDescription(
                              rsp.data.factsheet_info.output_fields.index_description,
                            );
                            setIndexMethodology(
                              rsp.data.factsheet_info.output_fields.index_methodology,
                            );
                            setCompanyDescription(
                              rsp.data.factsheet_info.output_fields.company_description,
                            );
                            setDisclaimer(rsp.data.factsheet_info.output_fields.company_disclaimer);
                            toast('The factsheet template has been successfully uploaded.');
                          }
                        })
                        .catch(error => {
                          // setFiles('');
                          toastInterceptor(error);
                        });
                    }}
                    name="file"
                    id="file"
                    className={styles.inputfile}
                  />
                  <label
                    htmlFor="file"
                    style={{
                      opacity: files.name ? '0.5' : '1',
                      pointerEvents: files.name ? 'none' : '',
                    }}
                  >
                    Upload
                  </label>
                  {files && (
                    <p
                      className={styles.nameFile}
                      style={{
                        opacity: files.name ? '0.5' : '1',
                        pointerEvents: files.name ? 'none' : '',
                      }}
                    >
                      <strong>Uploaded File: </strong>
                      {files.name}
                    </p>
                  )}
                  {files && (
                    <BitaButton
                      primaryWhite
                      onClick={() => {
                        setFiles('');
                      }}
                      style={{
                        width: '100px',
                        marginTop: '0px',
                        opacity: loading ? '0.5' : '1',
                        pointerEvents: loading ? 'none' : '',
                      }}
                    >
                      Delete
                    </BitaButton>
                  )}
                </div>
              </div>
            </div>
          </div>

          {startDate && endDate && files.name && (
            <div>
              <h2
                className="instrument_selection_titles"
                style={{ marginBottom: '2px', fontSize: '14px' }}
              >
                <div style={{ width: '50%' }}>
                  <Icons.account />
                  <div className="title">PARAMETERS</div>
                </div>
                <div style={{ width: 'calc(50% - 8px)' }}>
                  <Icons.account />
                  <div className="title">INFO TO DISPLAY</div>
                </div>
              </h2>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div
                  className="content-Widget"
                  style={{ width: 'calc(50% - 10px)', padding: '15px', marginBottom: '15px' }}
                >
                  <div
                    className="contentFactsheet"
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <div style={{ width: 'calc(50% - 10px)' }}>
                      <div>
                        <div className={styles.titleFactsheet}>START DATE</div>
                        <BitaCalendarDate
                          title={props.title}
                          date={startDate}
                          select={value => setStartDate(value)}
                        />
                      </div>
                      <div>
                        <div className={styles.titleFactsheet}>SECTOR CLASSIFICATION</div>
                        <BitaSelect
                          key="Portfolio"
                          label="Portfolio"
                          width="140px"
                          style={{ marginRight: '15px' }}
                          value={sectorClassification}
                          data={[
                            { id: 'morningstar_sector', value: 'Morningstar' },
                            { id: 'gics_sector', value: 'GICS' },
                          ]}
                          handleSelection={selected => {
                            setSectorClassification(selected);
                          }}
                        />
                      </div>
                      <div>
                        <div className={styles.titleFactsheet}>BRANDING OPTIONS</div>
                        <div className={`${styles.contentSwitcher} `} style={{ width: '150px' }}>
                          BITA
                          <Switcher.Switch
                            className={styles.customRadio}
                            onChange={option => setCustom(!option)}
                            checked={!custom}
                          />
                        </div>
                        <div className={`${styles.contentSwitcher}`} style={{ width: '150px' }}>
                          CUSTOM
                          <Switcher.Switch
                            className={styles.customRadio}
                            onChange={option => setCustom(option)}
                            checked={custom}
                          />
                        </div>
                      </div>
                    </div>
                    <div style={{ width: 'calc(50% - 10px)' }}>
                      <div>
                        <div className={styles.titleFactsheet}>END DATE</div>
                        <BitaCalendarDate
                          title={props.title}
                          date={endDate}
                          select={value => setEndDate(value)}
                        />
                      </div>
                      <div>
                        <div className={styles.titleFactsheet}> BASE VALUE </div>
                        <BitaInput
                          value={baseValue}
                          type="text"
                          className="inputFs"
                          inputStyle="inputFs"
                          title=""
                          handleChange={val => {
                            setBaseValue(val);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="content-Widget"
                  style={{ width: 'calc(50% - 10px)', padding: '15px', marginBottom: '15px' }}
                >
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ width: 'calc(50% - 8px)' }}>
                      <div className={styles.contentSwitcher}>
                        INDEX DESCRIPTION
                        <Switcher.Switch
                          className={styles.customRadio}
                          onChange={option => setIndexDescription(option)}
                          checked={indexDescription}
                        />
                      </div>
                      <div className={styles.contentSwitcher}>
                        PERFORMANCE
                        <Switcher.Switch
                          className={styles.customRadio}
                          onChange={option => setPerformance(option)}
                          checked={performance}
                        />
                      </div>
                      <div className={styles.contentSwitcher}>
                        GENERAL STATISTICS
                        <Switcher.Switch
                          className={styles.customRadio}
                          onChange={option => setGeneralStatistics(option)}
                          checked={generalStatistics}
                        />
                      </div>
                      <div className={styles.contentSwitcher}>
                        INDEX CHARACTERISTICS
                        <Switcher.Switch
                          className={styles.customRadio}
                          onChange={option => setIndexCharacteristics(option)}
                          checked={indexCharacteristics}
                        />
                      </div>
                      <div className={styles.contentSwitcher}>
                        DESCRIPTIVE STATS
                        <Switcher.Switch
                          className={styles.customRadio}
                          onChange={option => setDescriptiveStats(option)}
                          checked={descriptiveStats}
                        />
                      </div>
                      <div className={styles.contentSwitcher}>
                        TOP SECTOR & COUNTRY
                        <Switcher.Switch
                          className={styles.customRadio}
                          onChange={option => setTopSectorCountry(option)}
                          checked={topSectorCountry}
                        />
                      </div>
                    </div>

                    <div style={{ width: 'calc(50% - 8px)' }}>
                      <div className={styles.contentSwitcher}>
                        INDEX METHODOLOGY
                        <Switcher.Switch
                          className={styles.customRadio}
                          onChange={option => setIndexMethodology(option)}
                          checked={indexMethodology}
                        />
                      </div>
                      <div className={styles.contentSwitcher}>
                        COMPANY DESCRIPTION
                        <Switcher.Switch
                          className={styles.customRadio}
                          onChange={option => setCompanyDescription(option)}
                          checked={companyDescription}
                        />
                      </div>
                      <div className={styles.contentSwitcher}>
                        DISCLAIMER
                        <Switcher.Switch
                          className={styles.customRadio}
                          onChange={option => setDisclaimer(option)}
                          checked={disclaimer}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <BitaButton
            primary
            style={{
              marginLeft: '35%',
              marginRight: '35%',
            }}
            disabled={!(startDate && endDate && files.name)}
            className="runBacktestStructured"
            onClick={() => {
              const sendData = {
                factsheet_info: data.factsheet_info,
                start_date: moment(startDate).format('YYYY-MM-DD'),
                end_date: moment(endDate).format('YYYY-MM-DD'),
                index_description: indexDescription,
                performance,
                general_statistics: generalStatistics,
                index_characteristics: indexCharacteristics,
                descriptive_stats: descriptiveStats,
                top_sector_country: topSectorCountry,
                company_description: companyDescription,
                index_methodology: indexMethodology,
                disclaimer,
                sector_provider: sectorClassification.id,
                custom,
                base_value: baseValue,
                company: data.companyId,
              };
              const dataComponent = getDataComponents.filter(
                v => v.key === 'runFactsheet' && v.title === 'runFactsheet',
              )[0];
              const backtestPromise = loaderDataObjectDecorator(dataComponent, dispatch).getUrlData(
                sendData,
                token,
              );
              backtestPromise.then(res => {
                dispatch(setBacktest({ id: res?.data?.data?.headers?.id }));
              });
            }}
          >
            Generate Factsheet
          </BitaButton>
        </div>
      </div>
    </Fragment>
  );
};
