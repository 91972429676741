import * as ACTIONS from '../../constants/api-data-constants';

const initialState = {
  apiData: {
    'Index Card Stats': [],
    'Current Index Selected': {},
    'Review Date': {
      'Universe data history': { universe_data_history: '15 Years' },
      'Revision Periodicity': {
        'Ex-Date': { id: '0', value: '1st', disabled: false },
        Day: { id: '4', value: 'Friday', disabled: false },
        Months: { id: '2', value: 'March-June-September-December', disabled: false },
        periodicity: 'QUARTERLY',
      },
    },
  },
  loading: {},
};

const apiDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.CLEAN_API_DATA:
      return {
        ...state,
        apiData: { ...state.apiData, [action.payload]: {} },
      };
    case ACTIONS.EMPTY_API_DATA:
      return initialState;
    case ACTIONS.INIT_DATA:
      return {
        ...state,
        apiData: { ...state.apiData, [action.payload]: initialState.apiData[action.payload] },
      };
    case ACTIONS.SET_API_DATA:
      return {
        ...state,
        apiData: { ...state.apiData, [action.payload.title]: action.payload.data },
      };
    case ACTIONS.WAITING_API_RESPONSE:
      return {
        ...state,
        loading: { ...state.loading, [action.payload.id]: action.payload.status },
      };
    default:
      return state;
  }
};

export default apiDataReducer;
