import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import BitaCharts from '../../../../bitaComponents/BitaCharts/BitaCharts';
import {
  WasteGrowthTheme,
  WasteGenerationTheme,
} from '../../../../bitaComponents/BitaCharts/Themes';
import styles from '../EmissionsWaste.module.scss';
import BitaSelect from '../../../BitaSelect/BitaSelect';
import StatsTable from './StatsTable';
import { thousandFormat } from '../../../../utils/utils';

const orderbyDate = (a, b, key) => {
  return new Date(a[key]) - new Date(b[key]);
};

const filterByDate = (item, key, timeframe) => {
  let filterDate = '2014-01-01';
  if (timeframe === '3Y') {
    filterDate = '2016-01-01';
  }
  return new Date(item[key]) >= new Date(filterDate);
};

const getTheme = chartName => {
  switch (chartName) {
    case 'generation':
      return WasteGenerationTheme;
    default:
      return WasteGrowthTheme;
  }
};

export const TableDatesGenerator = (categories, data) => (
  <table className={styles.generatedTable} style={{ fontSize: '10px' }}>
    <thead className={styles.generatedTableHeader}>
      <tr>
        <th />
        {categories.map(timeframe => {
          return <th className={styles.restTableHeaders}>{timeframe}</th>;
        })}
      </tr>
    </thead>
    <tbody>
      {data.map(item => (
        <tr className={styles.generatedTableHeader}>
          {' '}
          <td style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>{item.name}</td>{' '}
          {item.data.length > 0 &&
            item.data.map(value => (
              <td className={styles.restTableHeaders}>{Number(value).toFixed(4) || 'N/A'}</td>
            ))}
          {item.data.length === 0 && <td className={styles.restTableHeaders}>N/A</td>}
        </tr>
      ))}
    </tbody>
  </table>
);

const LinearChartWithDropdown = ({
  title,
  stats,
  timeframe,
  dropdownOptions,
  hideDropdown = false,
  tableTitle = 'Stats',
  hideStats = false,
  hideTitleDropdown = false,
  yAxisLabel = '%',
  chartName = 'growth',
  disclaimer = false,
}) => {
  const [emissionGrowCarbonization, setEmissionGrowCarbonization] = useState(
    hideDropdown ? '' : dropdownOptions[0],
  );
  const [categories, setCategories] = useState([]);
  const [series, setSeries] = useState([]);
  const isPercentageValue = value =>
    yAxisLabel === '%'
      ? Number((Number(value) * 100).toFixed(2))
      : Number(Number(value).toFixed(4));

  const dataChart = (data, filterSelection) => {
    const _serie = [];
    const dataIndexBenchmark = {
      index: data?.index?.[filterSelection],
      benchmark: data?.benchmark?.[filterSelection],
    };
    const scopes = Object.keys(dataIndexBenchmark || {});
    scopes.forEach(key => {
      _serie.push({
        name: key.toUpperCase(),
        data: dataIndexBenchmark?.[key]
          ? Object.values(dataIndexBenchmark?.[key])
              .filter(a => filterByDate(a, 'timestamp', timeframe))
              .sort((a, b) => orderbyDate(a, b, 'timestamp'))
              .map(x => isPercentageValue(x.value))
          : [],
      });
    });
    return _serie;
  };
  useEffect(() => {
    const _serie = [];
    const _stats = timeframe
      ? stats[timeframe]
      : emissionGrowCarbonization.id && stats.index
      ? stats.index
      : stats;
    const scopes = Object.keys(_stats || {});
    for (let i = 0; scopes.length > i; i += 1) {
      if (!_.isEmpty(_stats[scopes[i]])) {
        setCategories(
          Object.values(_stats[scopes[i]])
            .filter(a => filterByDate(a, 'timestamp', timeframe))
            .sort((a, b) => orderbyDate(a, b, 'timestamp'))
            .map(x => moment(x.timestamp).year()),
        );
        i = scopes.length + 1;
      }
    }
    scopes.forEach(key => {
      _serie.push({
        name: key.toUpperCase(),
        data: Object.values(_stats[key])
          .filter(a => filterByDate(a, 'timestamp', timeframe))
          .sort((a, b) => orderbyDate(a, b, 'timestamp'))
          .map(x => isPercentageValue(x.value)),
      });
    });
    setSeries(_serie);
  }, [emissionGrowCarbonization, timeframe, stats]);

  return (
    <>
      <div className={styles.tableContainer} style={{ padding: '5px' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '5px',
            marginRight: '5px',
          }}
        >
          <span className={styles.subtitleSize} style={{ fontWeight: 'bold', marginLeft: '10px' }}>
            {!hideDropdown
              ? `${!hideTitleDropdown ? emissionGrowCarbonization?.value : ''} ${title}`
              : title}
          </span>
          {!hideDropdown && (
            <BitaSelect
              data={dropdownOptions}
              value={emissionGrowCarbonization}
              width="160px"
              clearable={false}
              handleSelection={selected => {
                setEmissionGrowCarbonization(selected);
              }}
            />
          )}
        </div>

        <BitaCharts
          key="NormalizedRecyclingGrowth"
          options={{
            ...{
              xAxis: {
                categories,
              },
              yAxis: {
                title: {
                  text: '',
                },
                labels: {
                  formatter() {
                    // eslint-disable-next-line react/no-this-in-sfc
                    return `${thousandFormat(this.value)} ${yAxisLabel}`;
                  },
                  style: {
                    color: '#E0E0E3',
                    fontFamily: "'Neue Haas Grotesk Display Pro', sans-serif",
                  },
                },
              },
              tooltip: {
                crosshairs: true,
                shared: true,
                formatter(tooltip) {
                  // eslint-disable-next-line prefer-destructuring
                  // eslint-disable-next-line react/no-this-in-sfc
                  let format = `${this.x}<br/>`;
                  // eslint-disable-next-line react/no-this-in-sfc
                  this.points.forEach(x => {
                    x.point.y = Number(x.point.y.toFixed(4));
                    const pointSplit = `${Number(x.point.y.toFixed(4))}`.split('.');
                    format += `${x.series.name} : ${thousandFormat(
                      pointSplit[0],
                    )}.${pointSplit[1] || ''}${yAxisLabel} <br/>`;
                  });
                  return format;
                },
              },
              series: emissionGrowCarbonization.id
                ? dataChart(stats, emissionGrowCarbonization.id)
                : series,
            },
          }}
          theme={getTheme(chartName)}
          containerProps={{
            style: { padding: 20, height: '330px', width: '100%' },
          }}
        />
      </div>
      {!hideStats && (
        <StatsTable
          title={tableTitle}
          categories={categories}
          series={series}
          yAxisLabel={yAxisLabel}
          disclaimer={disclaimer}
        />
      )}
    </>
  );
};

export default LinearChartWithDropdown;
