import React, { useState } from 'react';
import styles from '../../builder.module.scss';
import Switcher from '../../../bitaComponents/Switcher/Switcher';
import { ComponentGenerator } from '../Components';

export const BuilderToggleFilter = ({
  setterFunction = Function.prototype,
  setterFilter = Function.prototype,
  setFilterHelper = Function.prototype,
  filterField = '',
  onChange = Function.prototype,
  path,
  options = [],
  id = '',
  widgetData,
}) => {
  const [selected, setSelected] = useState([]);
  React.useEffect(() => {
    if (widgetData && widgetData[id]) {
      if (typeof options[0] === 'object') {
        const dataSelected = Object.values(options).filter(
          option => option.title === widgetData[id],
        );
        setSelected(dataSelected[0]);
      } else {
        setSelected(widgetData[id]);
      }
    }
  }, []);
  React.useEffect(() => {
    if (!widgetData) setSelected([]);
  }, [widgetData]);
  const localPath = path ? `${path}.${id}` : id;
  React.useEffect(() => {
    if (setFilterHelper) setFilterHelper(selected);
    if (setterFilter && setterFunction) {
      if (selected.length > 0) setterFunction(prev => ({ ...prev, RadioGroup: selected }));
      if (setterFilter) setterFilter(() => ({ field: filterField, filter: selected }));
    }
    if (onChange) onChange(selected.id ? `${localPath}.${selected.id}` : localPath, selected.title);
  }, [selected, setterFilter, setterFunction, setterFilter]);

  if (typeof options[0] === 'object') {
    return (
      <div className={`${styles.radioParentGroup} builderToggleFilter`}>
        {Object.values(options).map(option => (
          <div key={option.title}>
            <div>
              <span>{option.title}</span>
              <Switcher.Switch
                disabled={setFilterHelper && widgetData}
                className={styles.customRadio}
                onChange={() => (option !== selected ? setSelected(option) : setSelected(''))}
                checked={option === selected}
              />
            </div>
            {option.title === selected.title && option.onclick && (
              <div
                className={styles.builderRadioGroup}
                style={{ flexDirection: option.onclick.direction === 'block' ? 'column ' : ' ' }}
              >
                {typeof option.onclick[0] === 'object' ? (
                  Object.values(option.onclick).map(comp => (
                    <ComponentGenerator
                      {...comp}
                      path={path}
                      onChange={onChange}
                      widgetData={widgetData}
                    />
                  ))
                ) : (
                  <ComponentGenerator
                    {...option.onclick}
                    path={path}
                    onChange={onChange}
                    widgetData={widgetData}
                  />
                )}
              </div>
            )}
          </div>
        ))}
      </div>
    );
  }

  return Object.values(options).map(option => (
    <div key={option}>
      <div className={`${styles.radiofilterHolder} radiofilterHolder `}>
        <span>{option}</span>
        <Switcher.Switch
          disabled={setFilterHelper && widgetData}
          key={option}
          className={`${styles.customRadio} builderToggleSwitcher `}
          onChange={() =>
            selected.includes(option)
              ? setSelected(selected.filter(opt => opt !== option))
              : setSelected([...selected, option])
          }
          checked={selected.includes(option)}
        />
      </div>
    </div>
  ));
};
