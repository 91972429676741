import { toast } from 'react-toastify';
import axios from '../../Axios';
import * as ACTIONS from '../../constants/module-upload-constants';
import * as SummaryConstants from '../../constants/summary-constants';
import * as ApiDataActions from './api-data-actions';
import { toastInterceptor } from '../../utils/utils';

export const setExcludeIsin = loading => {
  return { type: ACTIONS.UPLOAD_EXCLUDE_ISINS, payload: loading };
};

export const setFile = loading => {
  return { type: ACTIONS.UPLOAD_FILESETTINGS, payload: loading };
};

export const setShowContentManager = loading => {
  return { type: ACTIONS.UPLOAD_SHOWCONTENTMANAGER, payload: loading };
};

export const setLoading = loading => {
  return { type: ACTIONS.UPLOAD_LOADING, payload: loading };
};

export const uploadFileSettings = (token, file) => dispatch => {
  dispatch(setLoading(true));
  axios
    .post(`${process.env.REACT_APP_API_URL}/upload-file`, file, {
      headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${token}` },
    })
    .then(rsp => {
      toast(`${rsp.data.message}`);
      dispatch(setLoading(false));
      dispatch(setShowContentManager('Data Fields'));
      if (rsp.data.excludes_isin) dispatch(setExcludeIsin(rsp.data.excludes_isin));
      if (rsp.data.portfolio_id) {
        const widget = 'PortfolioSelected';
        const dataPortfolio = { id: rsp.data.portfolio_id, value: rsp.data.portfolio_name };
        dispatch(
          ApiDataActions.setApiData({
            title: 'PortfolioSelected',
            data: dataPortfolio,
          }),
        );
        dispatch({
          type: SummaryConstants.ADD_ENTRY,
          payload: { module: 'Portfolio', widget, data: dataPortfolio },
        });
      }
      dispatch(ApiDataActions.setClientDataFields(token));
      dispatch(ApiDataActions.setAviableDataFields(token));
      dispatch(ApiDataActions.setUniversesList(token));
      dispatch(ApiDataActions.setBenchmarkList(token));
      dispatch(setFile(true));
    })
    .catch(error => {
      dispatch(setLoading(false));
      toastInterceptor(error);
      console.info(error);
    });
};
