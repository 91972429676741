import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { get } from 'axios';
import XLSX from 'sheetjs-style';
import Papa from 'papaparse';
import { toast } from 'react-toastify';
import style from '../PortfolioBasketCalculation.module.scss';
import BitaButton from '../../../bitaComponents/BitaButton/BitaButton';
import { URL_CONSTANTS } from '../../../api-data-mapping/url-constants';

const FileUploader = ({ setSingleTemplateData }) => {
  const user = useSelector(state => state.auth);
  const { token } = user;
  const [filesTax, setFilesTax] = React.useState('');
  const [dataTemplate, setDataTemplate] = React.useState([]);

  const getSingleTemplate = async id => {
    const req = await get(`${URL_CONSTANTS.WITHHOLDING_TAX}/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    setDataTemplate(
      req?.data?.data[0].values.map(item => {
        return {
          country_name: item.country_name,
          date: item.date,
          'ISO Code': item.iso_code,
          value: item.value,
        };
      }),
    );
  };

  useEffect(() => {
    getSingleTemplate('default');
  }, []);

  // Function to get the latest data by country
  const getLatestDataByCountry = data => {
    const latestData = {};
    data.forEach(item => {
      const { country_name, date } = item;
      // Compare the dates
      if (!latestData[country_name] || new Date(date) > new Date(latestData[country_name].date)) {
        latestData[country_name] = item; // Update the object if the date is more recent
      }
    });
    // Return only the latest objects
    return Object.values(latestData);
  };

  const exportToExcel = () => {
    const latestInstruments = getLatestDataByCountry(dataTemplate);
    const ws = XLSX.utils.json_to_sheet(latestInstruments);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, ws, 'Results');

    // Generate the csv
    const csvBuffer = XLSX.utils.sheet_to_csv(ws);

    const blob = new Blob([csvBuffer], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = 'WHT_Template.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url); // release the url
  };

  const readFile = () => {
    Papa.parse(filesTax, {
      complete: results => {
        if (results.data.length > 1) {
          const { data } = results;
          const propertiesKey = results.data[0].includes('date')
            ? [...results.data[0], 'id']
            : [...results.data[0], 'date', 'id'];
          const countries = data
            .slice(1)
            .filter(item => item.length === results.data[0].length)
            .map((row, key) => {
              const countryObject = {};
              propertiesKey.forEach((header, index) => {
                countryObject[
                  header === 'WHT Rate in (%)'
                    ? 'value'
                    : header === 'Country Name'
                    ? 'country_name'
                    : header
                ] =
                  header === 'date'
                    ? row[index] ?? new Date().toISOString().slice(0, 10)
                    : header === 'id'
                    ? key
                    : row[index];
              });
              return countryObject;
            });

          const sortedCountries = countries?.sort((a, b) => {
            // Function to check if a value is invalid (greater than 100 or less than 0)
            const isInvalidValue = value => value > 100 || value < 0;
            const aValue = Number(a.value);
            const bValue = Number(b.value);

            // If both values are invalid, maintain their original order
            if (isInvalidValue(aValue) && isInvalidValue(bValue)) return 0;
            // If 'a' is invalid, place 'a' before 'b'
            if (isInvalidValue(aValue)) return -1;
            // If 'b' is invalid, place 'b' before 'a'
            if (isInvalidValue(bValue)) return 1;

            // If both are valid, maintain their original order
            return 0; // No change in order
          });

          setSingleTemplateData(prev => {
            return {
              ...prev,
              values: sortedCountries,
              withholding_tax_name: prev.withholding_tax_name
                ? prev.withholding_tax_name
                : filesTax?.name.split('.')[0],
            };
          });
        } else {
          toast.error(
            'Invalid file type structure. Please upload a file that matches the required format',
          );
        }
      },
      header: false,
    });
  };

  return (
    <>
      <div className={style.taxContentTemplate}>
        <div className={style.subtitle}>TEMPLATES</div>
        <div style={{ display: 'flex', gap: '20px', marginTop: '10px', flexWrap: 'wrap' }}>
          <BitaButton primaryWhite width="160px" height="35px" onClick={exportToExcel}>
            Bita Withholding Taxes Template
          </BitaButton>
        </div>
      </div>

      <div>
        <div className={style.subtitle} style={{ marginTop: '20px' }}>
          Tax Template Uploading
        </div>
        <div
          style={{
            display: 'flex',
            marginTop: '10px',
            gap: '10px',
            alignItems: 'start',
            flexWrap: 'wrap',
            flexDirection: 'column',
          }}
        >
          <input
            type="file"
            onChange={event => {
              setFilesTax(event.target.files[0]);
            }}
            name="fileTax"
            id="fileTax"
            className={style.taxInputfile}
          />
          <label htmlFor="fileTax">Select File</label>
          <BitaButton
            disabled={!filesTax}
            width="160px"
            onClick={() => {
              if (filesTax) {
                readFile();
              } else {
                toast.error('Please upload a portfolio template with a different file name.');
              }
            }}
            primary
            height="35px"
          >
            Upload File
          </BitaButton>
        </div>
        <p className={style.nameFile}>{filesTax && `(${filesTax?.name})`}</p>
      </div>
    </>
  );
};

export default FileUploader;
