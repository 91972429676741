import React, { useEffect } from 'react';
import styles from './Layout.module.scss';
import MainLeftSidebar from '../MainLeftSidebar/MainLeftSidebar';

const LeftSidePanel = () => {
  useEffect(() => {
    return () => {
      // console.info('DESMONTANDO LEFTSIDEBAR');
    };
  });

  return (
    <div className={styles.leftSidePanel}>
      <MainLeftSidebar />
    </div>
  );
};

export default LeftSidePanel;
