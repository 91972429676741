/* eslint-disable react/no-this-in-sfc */
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import BitaCharts from '../../../../bitaComponents/BitaCharts/BitaCharts';
import { ReciclingRatioTheme } from '../../../../bitaComponents/BitaCharts/Themes';
import styles from '../EmissionsWaste.module.scss';
import StatsTable from './StatsTable';
import { thousandFormat } from '../../../../utils/utils';

ReciclingRatioTheme.colors.splice(1, 1);
const orderbyDate = (a, b, key) => {
  return new Date(a[key]) - new Date(b[key]);
};

const filterByDate = (item, key) => {
  return new Date(item[key]) >= new Date('2014-01-01');
};

export const TableDatesGenerator = (categories, data) => (
  <table className={styles.generatedTable} style={{ fontSize: '10px' }}>
    <thead className={styles.generatedTableHeader}>
      <tr>
        <th />
        {categories.map(timeframe => {
          return <th className={styles.restTableHeaders}>{timeframe}</th>;
        })}
      </tr>
    </thead>
    <tbody>
      {data.map(item => (
        <tr className={styles.generatedTableHeader}>
          {' '}
          <td style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>{item.name}</td>{' '}
          {item.data.length > 0 &&
            item.data.map(value => (
              <td className={styles.restTableHeaders}>{Number(value).toFixed(4) || 'N/A'}</td>
            ))}
          {item.data.length === 0 && <td className={styles.restTableHeaders}>N/A</td>}
        </tr>
      ))}
    </tbody>
  </table>
);

const MultipleTypeChart = ({ title, stats, timeframe, tableTitle = 'Stats', yAxisLabel = '%' }) => {
  const [categories, setCategories] = useState([]);
  const [series, setSeries] = useState([]);

  useEffect(() => {
    const _serie = [];
    if (Object.keys(stats).length > 0) {
      const scopes = ['Total Waste', 'Total Recycled', 'Recycling Ratio* (RHS)'];
      if (_.has(stats, ['Total Waste'])) {
        setCategories(
          Object.values(stats['Total Waste'])
            .filter(i => filterByDate(i, 'timestamp'))
            .sort((a, b) => orderbyDate(a, b, 'timestamp'))
            .map(x => moment(x.timestamp).year()),
        );
      } else if (_.has(stats, ['Total Recycled'])) {
        setCategories(
          Object.values(stats['Total Recycled'])
            .filter(i => filterByDate(i, 'timestamp'))
            .sort((a, b) => orderbyDate(a, b, 'timestamp'))
            .map(x => moment(x.timestamp).year()),
        );
      } else if (_.has(stats, ['Recycling Ratio* (RHS)'])) {
        setCategories(
          Object.values(stats['Recycling Ratio* (RHS)'])
            .filter(i => filterByDate(i, 'timestamp'))
            .sort((a, b) => orderbyDate(a, b, 'timestamp'))
            .map(x => moment(x.timestamp).year()),
        );
      }
      scopes.forEach(key => {
        _serie.push({
          name: key.toUpperCase(),
          type: key === 'Recycling Ratio* (RHS)' ? 'spline' : 'column',
          data: Object.values(stats[key])
            .filter(i => filterByDate(i, 'timestamp'))
            .sort((a, b) => orderbyDate(a, b, 'timestamp'))
            .map(x =>
              key === 'Recycling Ratio* (RHS)'
                ? Number((Number(x.value) * 100).toFixed(2))
                : Number(x.value),
            ),
          tooltip: {
            valueSuffix: key === 'Recycling Ratio* (RHS)' ? '%' : '',
          },
        });
      });
      setSeries(_serie);
    }
  }, [timeframe, stats]);

  return (
    <>
      <div className={styles.tableContainer} style={{ padding: '0px' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '10px',
            marginRight: '10px',
          }}
        >
          <span className={styles.subtitleSize} style={{ fontWeight: 'bold', marginLeft: '10px' }}>
            {title}
          </span>
        </div>

        <BitaCharts
          key="wasteRecyclingRatio"
          options={{
            ...{
              xAxis: {
                categories,
                crosshair: true,
              },
              series:
                series.length > 0
                  ? [{ ...series[0] }, { ...series[2], yAxis: 'y_axis_1' }]
                  : series,
              tooltip: {
                crosshairs: true,
                shared: true,
                formatter(tooltip) {
                  // eslint-disable-next-line prefer-destructuring
                  let format = `${this.x}<br/>`;
                  this.points.forEach(x => {
                    x.point.y = Number(x.point.y.toFixed(4));
                    const pointSplit = `${Number(x.point.y.toFixed(4))}`.split('.');
                    format += `${x.series.name} : ${thousandFormat(
                      pointSplit[0],
                    )}.${pointSplit[1] || ''}${
                      x.series.name.toUpperCase() === 'RECYCLING RATIO* (RHS)' ? '%' : ''
                    } <br/>`;
                  });
                  return format;
                },
              },
            },
          }}
          theme={ReciclingRatioTheme}
          containerProps={{
            style: {
              padding: '5px 20px 0px 20px',
              minHeight: '250px',
              height: '100%',
              width: '100%',
            },
          }}
        />
      </div>
      <StatsTable
        title={tableTitle}
        categories={categories}
        series={series}
        yAxisLabel="ratio"
        disclaimer="*Total Recycled + Total Waste are displayed in Metric Tons and Recycling Ratio is expressed as a %."
      />
    </>
  );
};

export default MultipleTypeChart;
