import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Modal from 'react-modal';
import { ReactComponent as LogoSidebar } from '../../static/img/sidebar/sidebar-midscreen-bitalogo.svg';
import './BitaModal.scss';
import { setApiData } from '../../state/actions/api-data-actions';

const customStyles = {
  content: {
    whiteSpace: 'pre-line',
    top: '50%',
    left: '50%',
    maxHeight: '95%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    background: 'linear-gradient(270deg, #0039cb 0%, #2962ff 100%)',
    color: '#ffffff',
    border: 'none',
    borderRadius: '15px',
    paddingLeft: 50,
    paddingRight: 50,
    fontSize: 16,
    fontWeight: 600,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    maxWidth: '800px',
  },
  overlay: {
    backgroundColor: 'rgba(78,78,78, 0.75)',
    zIndex: '999999',
  },
};

export const BitaInformativeModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();

  const closeModal = () => {
    setIsOpen(false);
    dispatch(
      setApiData({
        title: 'modal',
        data: {},
      }),
    );
  };
  const modalData = useSelector(state => state.apiData.apiData.modal);

  useEffect(() => {
    if (modalData && modalData.open) {
      setIsOpen(modalData.open);
    }
  }, [modalData]);

  useEffect(() => {
    const handleEsc = event => {
      if (event.key === 'Enter') {
        closeModal();
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  return (
    <div className="transitionModal">
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        style={customStyles}
        shouldCloseOnOverlayClick
      >
        <span onClick={closeModal} className="closeModal">
          &times;
        </span>
        <LogoSidebar />
        <div className="separator" />
        <label>{modalData?.text}</label>
      </Modal>
    </div>
  );
};
