import Modal from 'react-modal';
import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import * as _ from 'lodash';
import { useLocation } from 'react-router';
//

const BitaModal = ({
  isModalOpen,
  customStyle,
  setModalState = Function.prototype,
  children,
  animateOnUrlChange = false,
  closeInOverlay = true,
  typeModal,
}) => {
  const location = animateOnUrlChange && useLocation(); // eslint-disable-line
  const [mustDisplay, setMustDisplay] = useState(!animateOnUrlChange);

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      maxHeight: '95%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: mustDisplay ? '#29292a' : '#29292a00',
      color: '#ffffff',
      border: 'none',
      borderRadius: '15px',
    },
    overlay: {
      backgroundColor: 'rgba(78,78,78, 0.75)',
      zIndex: typeModal === 'abandoneModal' ? '9999999' : '99999',
    },
  };

  const _style = { ...customStyles, content: { ...customStyles.content, ...customStyle } };

  useEffect(() => {
    if (animateOnUrlChange) {
      setMustDisplay(false);

      setTimeout(() => {
        setMustDisplay(true);
      }, 40);
    }
  }, [animateOnUrlChange && location]);

  return (
    <>
      <Modal
        style={_style}
        isOpen={isModalOpen || false}
        onRequestClose={() => {
          setModalState(false);
        }}
        shouldCloseOnOverlayClick={closeInOverlay}
        ariaHideApp={false}
      >
        {mustDisplay && (
          <motion.div
            initial={{ opacity: 0, y: '30px' }}
            animate={{ opacity: 1, y: '0' }}
            transition={{ duration: 0.3 }}
          >
            {children}
          </motion.div>
        )}
      </Modal>
    </>
  );
};

export default BitaModal;
