/* eslint-disable */

// Necestio deshabilitar ESLINT aca porque da problemas con el label.
import React, { useCallback, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';

const BitaCheckbox = props => {
  const [checkId] = React.useState(uniqueId('bitacheckbox-'));
  const [checked, setChecked] = useState(!!props.defaultChecked);
  const [disabledCheckbox, setDisabledCheckbox] = useState(!!props.disabled);

  const onChange = useCallback(({ target }) => {
    setChecked(target.checked);
    if (props.handleChange) {
      props.handleChange(target.checked);
    }
  }, []);

  const calculatedChecked = useMemo(() => {
    if (props.checked === true || props.checked === false) {
      return props.checked;
    }
    return checked;
  }, [props, checked]);

  return (
    <div style={props.style} className={`${props.className}`}>
      <input
        type="checkbox"
        className="css-checkbox"
        id={checkId}
        style={{ marginRight: props.separation, borderColor: 'black' }}
        onChange={onChange}
        checked={calculatedChecked}
        disabled={disabledCheckbox}
      />
      <label className={'css-label'} htmlFor={checkId}>
        {props.label}
      </label>
    </div>
  );
};

BitaCheckbox.propTypes = {
  label: PropTypes.string,
  handleChange: PropTypes.func,
  separation: PropTypes.number,
};

BitaCheckbox.defaultProps = {
  label: '',
  handleChange: () => null,
  separation: 5,
};

export default BitaCheckbox;
