import React, { useEffect, useState } from 'react';
import './Accordion.scss';

const TabItem = ({ name, body, children }) => {
  const [active, setActive] = useState(false);
  return (
    <li className={`accordion_item ${active ? 'active' : ''}`}>
      <div className="button" onClick={() => setActive(!active)}>
        {name}
        <span className="control">{active ? '—' : '+'} </span>
      </div>
      <div className={`answer_wrapper ${active ? 'open' : ''}`}>
        <div className="answer">{body && body}</div>
        <div className="content_body">{children && children}</div>
      </div>
    </li>
  );
};
export default TabItem;
