/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
/* eslint-disable array-callback-return */
/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, { useCallback, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { value } from 'jsonpath';
import { setBy } from '../../helpers/deepSet';
import styles from '../../builder.module.scss';
import BitaButton from '../../../bitaComponents/BitaButton/BitaButton';
import { ComponentGenerator, Components } from '../Components';

const AddibleRowsComponent = ({
  components = {},
  id = '',
  onChange,
  widgetData,
  summaryData,
  stepData,
  sumId,
  sumText,
  requirerow,
  defineaddiblerowException,
  path,
  buttonColor,
}) => {
  const [optionSelect, setoptionSelect] = useState(false);
  const [operationTypeSelection, setOperationTypeSelection] = useState(false);
  const [subComponents, setSubcomponents] = useState(
    summaryData && summaryData[id] ? [] : [Object.values(components)],
  );
  const [disableAdd, setdisableAdd] = useState(false);
  const apiData = useSelector(state => state.apiData.apiData);
  const [addAutoSum, setAutoSum] = useState([0]);
  const [addibleData, setAddibleData] = React.useState();
  const [lengthOption] = useState(() => {
    const ar = Object.values(components).filter(i => i.defineaddiblerow === true)[0];
    if (ar) {
      if (!ar.options) {
        const storeFields = apiData && apiData.data_fields && apiData.data_fields.data;
        if (ar.data_fields && storeFields) {
          return (storeFields[ar.data_fields] || []).length;
        }
      }
      return Object.values(ar.options || []).length;
    }
    return 0;
  });

  useEffect(() => {
    if (summaryData && summaryData[id]) {
      const subcomp = subComponents;
      summaryData[id].forEach(row => {
        const componentRows = [];
        Object.values(components).map(comp => {
          if (comp.type === 'Dropdown' && comp.defineaddiblerow && !comp.data_fields) {
            let aux_options;
            if (comp.options) {
              aux_options = { ...comp.options };
            } else {
              const storeFields = apiData && apiData.data_fields && apiData.data_fields.data;
              if (comp.data_fields && storeFields) {
                let storeOptions = {};
                storeFields[comp.data_fields].map((field, index) => {
                  storeOptions = { ...storeOptions, [index]: field.name };
                });
                aux_options = { ...storeOptions };
              }
            }
            if (optionSelect) {
              optionSelect.map(singleOption => {
                delete aux_options[singleOption.id];
              });
            }
            comp.options = aux_options;
            if (comp.defineaddiblerow && comp.data_fields) comp.data_fields = false;
            componentRows.push(comp);
          } else {
            componentRows.push(comp);
          }
        });
        subcomp.push(componentRows);
      });
    }
    // eslint-disable-next-line
  }, []);

  const calculateSum = () => {
    if (addibleData && addibleData.length > 0) {
      const sumArray = [];
      addibleData.map((data, index) => {
        let sum = 0;
        Object.entries(data ?? {}).map(([key, rowValue]) => {
          if (typeof rowValue !== 'object') {
            if (key !== sumId) {
              sum += Number(rowValue);
            }
          }
          return true;
        });
        sumArray.push(sum);
      });
      setAutoSum(sumArray);
    }
  };

  const changeAddibleData = (path, value, data) => {
    // hay un comportamiento raro cuando cambias el dropdown de geography, group_by viene vacio y resetea el dropdown
    if (!(path.includes('group_by') && value === undefined)) {
      if (path.split('.')[1] === 'operator_type' && path.split('.')[0] === '[0]') {
        setOperationTypeSelection(value?.value);
      }
      if (summaryData && summaryData[id]) {
        setAddibleData(summaryData[id]);
      } else {
        const _id = path.split('.')[1];
        const _index = path
          .split('.')[0]
          .replace('[', '')
          .replace(']', '');
        if (addibleData?.[_index] && addibleData?.[_index]?.[_id]) {
          delete addibleData?.[_index]?.[_id];
          if (id === 'Factors' && _id === 'operator_type') {
            delete addibleData?.[_index]?.operator;
            delete addibleData?.[_index]?.value;
          }
        }
        if (path.includes('sectorCap') || path.includes('countryCap')) {
          setAddibleData(
            setBy(
              addibleData,
              path,
              value?.map(item => {
                return { ...(item.cap_sector ?? item.cap_country), cap: item.addibleinput };
              }),
            ),
          );
        } else {
          setAddibleData(setBy(addibleData, path, value));
        }
      }
      const aux = Object.values(components).filter(i => i.defineaddiblerow === true)[0];
      if (aux && value?.id && path.split('.')[1] === aux.id) {
        if (optionSelect) {
          setoptionSelect([
            ...optionSelect.filter(i => i.path !== path.split('.')[0]),
            { id: value?.id, value: value?.value, path: path.split('.')[0] },
          ]);
        } else {
          setoptionSelect([{ id: value?.id, value: value?.value, path: path.split('.')[0] }]);
        }
      }
      if (value === ' ' && optionSelect) {
        setoptionSelect([...(optionSelect ?? []).filter(i => i.path !== path.split('.')[0])]);
      }
    }
  };

  useEffect(() => {
    const localPath = `${path}${id}`;
    if (onChange && addibleData && addibleData.length > 0) {
      const _addibledata = [];
      [...addibleData].forEach(item => {
        if (
          Object.keys(item).length > 0 &&
          !(item?.disabled && item?.disabled === true) &&
          item !== undefined
        ) {
          _addibledata.push(item);
        }
      });
      if (requirerow && id !== 'Caps') {
        if (_addibledata.filter(v => v[`${requirerow}`] !== undefined).length > 0)
          onChange(
            id,
            _addibledata.filter(v => v[`${requirerow}`] !== undefined),
          );
      }
      if (requirerow && id === 'Caps') {
        onChange(id, _addibledata);
      } else if (id === 'countryCap') {
        onChange(localPath, _addibledata);
      } else if (id === 'sectorCap') {
        onChange(localPath, _addibledata);
      } else {
        onChange(id, _addibledata);
      }
    }
    calculateSum();
    // eslint-disable-next-line
  }, [addibleData]);

  useEffect(() => {
    if (sumId && addibleData) {
      const dataToSummary = addibleData.slice();
      dataToSummary.map((addible, index) => {
        addible[sumId] = `${addAutoSum[index]}`;
      });
    }
  }, [addAutoSum]);

  useEffect(() => {
    const subComponentShow = [];
    subComponents.forEach(group => {
      // eslint-disable-next-line no-unused-expressions
      !group.disabled && subComponentShow.push(group);
    });
    setdisableAdd(!!(lengthOption > 0 && lengthOption === subComponentShow.length));
  }, [subComponents, lengthOption]);

  const addNew = useCallback(() => {
    const componentRows = [];
    Object.values(components).map(comp => {
      if (comp.type === 'Dropdown' && comp.defineaddiblerow && !comp.data_fields) {
        let aux_options;
        if (comp.options) {
          aux_options = { ...comp.options };
        } else {
          const storeFields = apiData && apiData.data_fields && apiData.data_fields.data;
          if (comp.data_fields && storeFields) {
            let storeOptions = {};
            storeFields[comp.data_fields].map((field, index) => {
              storeOptions = { ...storeOptions, [index]: field.name };
            });
            aux_options = { ...storeOptions };
          }
        }
        const aux_comp = { ...comp };
        aux_comp.options = aux_options;
        componentRows.push(aux_comp);
      } else {
        componentRows.push(comp);
      }
    });
    setSubcomponents([...subComponents, componentRows]);
    if (addibleData?.length > 0) setAddibleData([...addibleData, {}]);
    // eslint-disable-next-line
  }, [subComponents, setSubcomponents, optionSelect, addibleData]);

  const remove = index => {
    if (lengthOption > 0 && optionSelect) {
      setoptionSelect(optionSelect.filter(i => i.path !== `[${index}]`));
    }
    setSubcomponents(
      subComponents?.map((_comp, i) => {
        if (i !== index) {
          return _comp;
        }
        _comp.disabled = true;
        return _comp;
      }),
    );
    setAddibleData(
      addibleData?.map((_comp, i) => {
        if (i !== index) {
          return _comp;
        }
        _comp.disabled = true;
        return _comp;
      }),
    );
  };

  const generatePath = index => {
    const _subComponents = subComponents.slice(0, index);
    const _path = _subComponents.filter((v, i) => v !== false).length;
    return _path;
  };

  return (
    <div className={`${styles.addibleRowsStyle} addibleRows `}>
      {subComponents.map((group, index) => (
        <div
          key={index}
          className={`${styles.addibleRow} ${
            group.disabled ? 'addiblerowdisabled' : ''
          } addibleRow`}
          style={{ display: group ? '' : 'none' }}
        >
          {group &&
            group.map((comp, iKey) => (
              <ComponentGenerator
                path={`[${index}].`}
                {...comp}
                historyAddibleData={addibleData?.[index]}
                addibleData={addibleData?.filter(item => !item?.disabled)}
                defineaddiblerowDataRemove={{
                  id: Object.values(components).find(i => i.defineaddiblerow === true)?.id ?? false,
                  data: optionSelect
                    ? (optionSelect ?? [])
                        .map(item => item.value)
                        .filter(
                          item =>
                            optionSelect.filter(itemN => itemN.value === item).length === 2 ||
                            !defineaddiblerowException?.split(',')?.includes(item),
                        )
                    : [],
                }}
                onChange={changeAddibleData}
                key={iKey}
                addibleSum={addAutoSum}
                operationTypeSelection={operationTypeSelection}
              />
            ))}
          {sumId &&
            React.createElement(Components.Input, {
              key: `addSuma_${addAutoSum[index]}`,
              type: 'Input',
              typeinput: 'number',
              text: sumText,
              placeholder: `${addAutoSum[index]}%`,
              id: sumId,
              value: addAutoSum[index],
              showAsLabel: true,
            })}
          {subComponents.filter(item => item.disabled !== true).length > 1 && group && (
            <BitaButton
              className={`${styles.addibleRowRemoveButton} addibleRowRemoveButton`}
              onClick={() => remove(index)}
              style={{
                backgroundColor: buttonColor || '',
                color: buttonColor && buttonColor !== 'white ' ? 'white' : '',
              }}
              disabled={subComponents.filter(item => item.disabled !== true).length < 2}
            >
              &times;
            </BitaButton>
          )}
        </div>
      ))}

      <BitaButton
        className={styles.addibleRowPlusButton}
        onClick={addNew}
        disabled={disableAdd}
        style={{
          backgroundColor: buttonColor || '',
          color: buttonColor && buttonColor !== 'white ' ? 'white' : '',
        }}
      >
        +
      </BitaButton>
    </div>
  );
};

export default AddibleRowsComponent;
