export const TYPE_CONSTANTS = {
  DROPDOWN: 'Dropdown',
  SEARCHBAR: 'SearchBar',
  USERMANAGER: 'UserManager',
  SELECTIONLIST: 'SelectionList',
  BUTTON: 'Button',
  UNIVERSEFILTER: 'UniverseFilter',
  PREVIEWVALIDATE: 'PreviewValidate',
  DATAFIELD: 'DataField',
};
