/* eslint-disable func-names */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { setBy } from '../../helpers/deepSet';
// eslint-disable-next-line import/no-cycle
import { ComponentGenerator } from '../Components';

export const BuilderPopUpComponent = ({
  components = null,
  replaceWorkflow,
  onClick = null,
  path,
  popupHandleOnClick,
  ...props
}) => {
  const [stepData, setStepData] = useState({});
  const apiData = useSelector(state => state.apiData);
  const buttonref = React.useRef('buttonRun');

  const setPopUpData = () => {
    const loadData = _.get(apiData, 'apiData.[indexBacktest]', {});
    if (
      (props.backtestShowData || loadData?.['Index Backtest']?.['PORTFOLIO NAME']) &&
      loadData['Index Backtest']
    ) {
      const newComponents = components;
      Object.values(newComponents).forEach(comp => {
        switch (comp.type) {
          case 'Input':
            comp.default_value = loadData['Index Backtest'][comp.external_title];
            if (comp.external_title.toUpperCase() === 'INDEX NAME') {
              comp.showAsLabel = props.disable_title;
            }
            if (comp.external_title.toUpperCase() === 'PORTFOLIO NAME') {
              comp.showAsLabel = true;
            }
            break;
          case 'Calendar':
            comp.default_date = loadData['Index Backtest'][comp.title];
            break;
          case 'Dropdown':
            switch (comp.id) {
              case 'Benchmark':
                comp.default_selected = loadData['Index Backtest'].Benchmark;
                break;
              default:
                break;
            }
            break;
          case 'SearchBar':
            comp.selected_default = loadData['Index Backtest'].Benchmark;
            comp.show_selected = true;
            break;
          default:
            break;
        }
      });
      return Object.values(newComponents);
    }
    return Object.values(components);
  };
  // eslint-disable-next-line no-shadow
  const onChange = (path, value) => {
    if (path) {
      const updatedData = setBy(stepData, `${props.title}.${path}`, value);
      setStepData(updatedData);
    } else {
      // eslint-disable-next-line no-unused-vars
      setStepData(old => ({ [props.title]: value }));
    }
  };

  React.useEffect(() => {
    window.addEventListener('keydown', function(e) {
      if (e.key === 'Enter' && buttonref.current) {
        buttonref.current.focus();
      }
    });
  }, []);

  const handleKeyDownRunBacktest = keyValue => {
    if (keyValue === 'Enter') {
      buttonref.current.focus();
    }
  };

  return (
    <div className="popup">
      {components &&
        setPopUpData().map((component, key) => (
          <ComponentGenerator
            path={path}
            popupHandleOnClick={onClick || replaceWorkflow || popupHandleOnClick}
            handlePopupClose={props.handlePopupClose}
            onClick={onClick}
            onChange={onChange}
            Ref={buttonref}
            stepData={stepData}
            onKeyPress={evt =>
              component.external_title === 'BASE VALUE' && handleKeyDownRunBacktest(evt.key)
            }
            runBacktest={props.runBacktest}
            {...component}
            key={key}
          />
        ))}
    </div>
  );
};
