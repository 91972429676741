export const summaryFormat = () => {
  return {
    Cash: '%',
    exit_buffer: '%',
    addibleinput: '%',
    'Floors Value': '%',
    tax: '%',
    'Buffer Percentage': '%',
  };
};
