import { useEffect } from 'react';

function isCalendarChild(parent, child) {
  let node = child.parentNode;

  // keep iterating unless null
  while (node !== null) {
    if (node === parent || node.className === 'react-calendar__year-view') {
      return true;
    }
    node = node.parentNode;
  }
  return false;
}

const useOutsideClick = (ref, callback) => {
  // debugger;
  const handleClick = e => {
    if (ref.current && ref.current.contains && !isCalendarChild(ref.current, e.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  });
};

export default useOutsideClick;
