import React from 'react';
import { Icons } from '../../../constants/icons';
import { IconsModules } from '../../../constants/icons-module';
import iconGeneralRules from '../../../static/img/Icons-modules/general.svg';
import iconCustom from '../../../static/img/Icons/filter/custom-filter.svg';
import iconSecurityType from '../../../static/img/Icons/filter/security-type.svg';
import iconSentiment from '../../../static/img/Icons/filter/sentiment.svg';
import iconThemesIclima from '../../../static/img/Icons/filter/iClimaLogo.png';

const ImagenGeralRules = () => {
  // eslint-disable-next-line jsx-a11y/alt-text
  return <img src={iconGeneralRules} />;
};

const ImagenIclima = () => {
  // eslint-disable-next-line jsx-a11y/alt-text
  return <img src={iconThemesIclima} />;
};

const UniverseCustomFilter = () => {
  // eslint-disable-next-line jsx-a11y/alt-text
  return <img src={iconCustom} />;
};

const UniverseSecurity = () => {
  // eslint-disable-next-line jsx-a11y/alt-text
  return <img src={iconSecurityType} />;
};

const UniverseSentiment = () => {
  // eslint-disable-next-line jsx-a11y/alt-text
  return <img src={iconSentiment} />;
};

function iconF(iconName) {
  switch (iconName) {
    case 'training':
      return Icons.training;
    case 'resourcesCenter':
      return Icons.resourcesCenter;
    case 'clientSupport':
      return Icons.clientSupport;
    case 'resource':
      return Icons.resource;
    case 'mail':
      return Icons.mail;
    case 'chat':
      return Icons.chat;
    case 'geography':
      return Icons.UniverseIcongeography;
    case 'sectors':
      return Icons.UniverseIconsector;
    case 'themes':
      return Icons.UniverseIconthemes;
    case 'topics':
      return Icons.UniverseIcontopics;
    case 'ratings':
      return Icons.UniverseIconratings;
    case 'factors':
      return Icons.UniverseIconfactors;
    case 'liquitidy':
      return Icons.UniverseIconliquidity;
    case 'size':
      return Icons.UniverseIconsize;
    case 'fundamentals':
      return Icons.UniverseIconfundamentals;
    case 'singleinstrument':
      return Icons.UniverseIconsingleinstrument;
    case 'exchange':
      return Icons.UniverseIconexchange;
    case 'themasiclima':
      return ImagenIclima;
    case 'generalRules':
      return ImagenGeralRules;
    case 'additionalRules':
      return IconsModules.AdditionalRules;
    case 'fastExitEntry':
      return IconsModules.FastExitEntry;
    case 'rebalancingReconstitution':
      return IconsModules.RebalancingReconstitution;
    case 'parametrization':
      return Icons.UniverseIconparametrization;
    case 'usermanagement':
      return IconsModules.SettingsUsermanagement;
    case 'contentmanager':
      return IconsModules.SettingsContentManager;
    case 'databaseselection':
      return IconsModules.SettingsDatabaseSelection;
    case 'dataupload':
      return IconsModules.SettingsDataUpload;
    case 'customfilter':
      return UniverseCustomFilter;
    case 'securitytype':
      return UniverseSecurity;
    case 'sentimentalternativedata':
      return UniverseSentiment;
    default:
      return Icons.account;
  }
}

export { iconF };
