import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, withRouter } from 'react-router';
import BitaModal from './BitaModal';

import styles from './BitaModal.module.scss';

import { Icons } from '../../constants/icons';
import BitaButton from '../BitaButton/BitaButton';
import * as SummaryConstants from '../../constants/summary-constants';
import * as IndexConstants from '../../constants/index-operations-constants';
import { cleanCache } from '../../state/actions/summary-actions';
import { discardBacktest } from '../../state/actions/backtest-actions';
import { resetUniverse } from '../../state/actions/universe-operations-actions';
import { setApiData, resetApiDataAttribute } from '../../state/actions/api-data-actions';

const styleClose = {
  position: 'absolute',
  fontSize: '30px',
  fontWeight: 'bold',
  top: '-10px',
  right: '0',
  cursor: 'pointer',
  padding: '10px',
  boxSizing: 'border-box',
};

const ModalAbandoneProject = withRouter(props => {
  // Modal para limpiar la data del summary y del backtest en redux
  const history = useHistory();
  const dispatch = useDispatch();
  const token = useSelector(state => state.auth.token);

  const cleanProject = () => {
    dispatch({
      type: SummaryConstants.RESET,
    });
    dispatch(resetUniverse());
    dispatch(discardBacktest());
    dispatch({
      type: IndexConstants.RESET,
    });
    dispatch(
      setApiData({
        title: 'Optimized Index',
        data: {},
      }),
    );
    dispatch(resetApiDataAttribute('Review Date'));
    dispatch(resetApiDataAttribute('previusUniverseBacktest'));
    dispatch(resetApiDataAttribute('universeSelected'));
    dispatch(resetApiDataAttribute('methodologySelected'));

    dispatch(cleanCache(token));
    props.close();
    history.push(props.to);
  };

  return (
    <BitaModal
      isModalOpen={props.visibility}
      setModalState={() => {
        props.close();
      }}
    >
      <div
        className={styles.pupUpTitleContainer}
        style={{
          display: 'flex',
          borderBottom: '1px solid #eee',
          alignItems: 'center',
          padding: '10px 0',
        }}
      >
        <Icons.Avanti style={{ marginRight: '20px' }} />
        <h2 style={{ margin: '0', padding: '0', fontSize: '22px', textTransform: 'uppercase' }}>
          Abandon Project
        </h2>
        <span onClick={() => props.close()} style={styleClose}>
          &times;
        </span>
      </div>
      <div style={{ margin: '20px 0' }}> Are you sure you want to cancel the current project? </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <BitaButton
          onClick={() => {
            cleanProject();
          }}
          width="90px"
          height="30px"
          style={{ margin: '5px' }}
          primary
          autofocus
        >
          Yes
        </BitaButton>
        <BitaButton
          onClick={() => {
            props.close();
          }}
          width="90px"
          height="30px"
          style={{ margin: '5px' }}
          primary
        >
          No
        </BitaButton>
      </div>
      <div />
    </BitaModal>
  );
});

export default ModalAbandoneProject;
