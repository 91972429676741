import * as ACTIONS from '../../constants/common-constants';

// actions that save inC  the state are prefixed with store
export const updateSubheader = subheader => ({
  type: ACTIONS.SUBHEADER_UPDATE,
  payload: subheader,
});

export const appendSubheader = string => ({
  type: ACTIONS.SUBHEADER_APPEND,
  payload: string,
});

export const resetSubheader = () => ({
  type: ACTIONS.SUBHEADER_RESET,
});

export const popSubheader = () => ({
  type: ACTIONS.SUBHEADER_POP,
});
