/* eslint-disable react/no-this-in-sfc */
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import BitaCharts from '../../../../bitaComponents/BitaCharts/BitaCharts';
import {
  WasteGrowthThemeWaste,
  historicalRatingsTheme,
} from '../../../../bitaComponents/BitaCharts/Themes';
import styles from '../EmissionsWaste.module.scss';
import BitaSelect from '../../../BitaSelect/BitaSelect';
import StatsTable from './StatsTable';
import { thousandFormat } from '../../../../utils/utils';

const orderbyDate = (a, b, key) => {
  return new Date(a[key]) - new Date(b[key]);
};
const filterByDate = (item, key, timeframe) => {
  let filterDate = '2014-01-01';
  if (timeframe === '3Y') {
    filterDate = '2016-01-01';
  }
  return new Date(item[key]) >= new Date(filterDate);
};

export const TableDatesGenerator = (categories, data) => (
  <table className={styles.generatedTable} style={{ fontSize: '10px' }}>
    <thead className={styles.generatedTableHeader}>
      <tr>
        <th />
        {categories.map(timeframe => {
          return <th className={styles.restTableHeaders}>{timeframe}</th>;
        })}
      </tr>
    </thead>
    <tbody>
      {data.map(item => (
        <tr className={styles.generatedTableHeader}>
          {' '}
          <td style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>{item.name}</td>{' '}
          {item.data.length > 0 &&
            item.data.map(value => (
              <td className={styles.restTableHeaders}>{Number(value).toFixed(4) || 'N/A'}</td>
            ))}
          {item.data.length === 0 && <td className={styles.restTableHeaders}>N/A</td>}
        </tr>
      ))}
    </tbody>
  </table>
);

const BarGraphWithDropdown = ({
  title,
  stats,
  timeframe,
  dropdownOptions,
  hideDropdown = false,
  tableTitle = 'Stats',
  hideStats = false,
  yAxisLabel = '%',
  dropdownDefault = 0,
  tableDropdownTitle = false,
  dropdownClearable,
  typeIndex,
}) => {
  const [emissionGrowCarbonization, setEmissionGrowCarbonization] = useState(
    hideDropdown ? '' : dropdownOptions[dropdownDefault],
  );
  const [categories, setCategories] = useState([]);
  const [series, setSeries] = useState([]);
  const isPercentageValue = value =>
    yAxisLabel ? Number((Number(value) * 100).toFixed(2)) : Number(value);
  useEffect(() => {
    const _serie = [];
    let scopes = [];
    if (!hideDropdown) {
      switch (emissionGrowCarbonization.id) {
        case 'scope':
          if (stats[timeframe]) {
            scopes = ['Scope 1', 'Scope 2', 'Scope 3'];
            if (_.has(stats, [timeframe, 'Scope 1'])) {
              setCategories(
                Object.values(stats[timeframe]['Scope 1'])
                  .filter(a => filterByDate(a, 'timestamp', timeframe))
                  .sort((a, b) => orderbyDate(a, b, 'timestamp'))
                  .map(x => moment(x.timestamp).year()),
              );
            } else if (_.has(stats, [timeframe, 'Scope 2'])) {
              setCategories(
                Object.values(stats[timeframe]['Scope 2'])
                  .filter(a => filterByDate(a, 'timestamp', timeframe))
                  .sort((a, b) => orderbyDate(a, b, 'timestamp'))
                  .map(x => moment(x.timestamp).year()),
              );
            } else if (_.has(stats, [timeframe, 'Scope 3'])) {
              setCategories(
                Object.values(stats[timeframe]['Scope 3'])
                  .filter(a => filterByDate(a, 'timestamp', timeframe))
                  .sort((a, b) => orderbyDate(a, b, 'timestamp'))
                  .map(x => moment(x.timestamp).year()),
              );
            }
            scopes.forEach(key => {
              _serie.push({
                name: key.toUpperCase(),
                data: Object.values(stats[timeframe][key])
                  .filter(a => filterByDate(a, 'timestamp', timeframe))
                  .sort((a, b) => orderbyDate(a, b, 'timestamp'))
                  .map(x => isPercentageValue(x.value)),
              });
            });
            setSeries(_serie);
          }
          break;
        case 'totalA':
          if (stats[timeframe]) {
            if (_.has(stats, [timeframe, 'Total A'])) {
              setCategories(
                Object.values(stats[timeframe]['Total A'])
                  .filter(a => filterByDate(a, 'timestamp', timeframe))
                  .sort((a, b) => orderbyDate(a, b, 'timestamp'))
                  .map(x => moment(x.timestamp).year()),
              );
            } else {
              setCategories(
                Object.values(stats[timeframe]['Total A - Benchmark'])
                  .filter(a => filterByDate(a, 'timestamp', timeframe))
                  .sort((a, b) => orderbyDate(a, b, 'timestamp'))
                  .map(x => moment(x.timestamp).year()),
              );
            }
            _serie.push({
              name: 'Index',
              data: Object.values(stats[timeframe]['Total A'])
                .filter(a => filterByDate(a, 'timestamp', timeframe))
                .sort((a, b) => orderbyDate(a, b, 'timestamp'))
                .map(x => isPercentageValue(x.value)),
            });
            _serie.push({
              name: 'Benchmark',
              data: Object.values(stats[timeframe]['Total A - Benchmark'])
                .filter(a => filterByDate(a, 'timestamp', timeframe))
                .sort((a, b) => orderbyDate(a, b, 'timestamp'))
                .map(x => isPercentageValue(x.value)),
            });
            setSeries(_serie);
          }
          break;
        case 'total A':
          if (stats[timeframe]) {
            _serie.push({
              name: 'Index',
              data: Object.values(stats[timeframe]['Total A'].Index)
                .filter(a => filterByDate(a, 'timestamp', timeframe))
                .sort((a, b) => orderbyDate(a, b, 'timestamp'))
                .map(x => isPercentageValue(x.value)),
            });
            _serie.push({
              name: 'Benchmark',
              data: Object.values(stats[timeframe]['Total A'].Benchmark)
                .filter(a => filterByDate(a, 'timestamp', timeframe))
                .sort((a, b) => orderbyDate(a, b, 'timestamp'))
                .map(x => isPercentageValue(x.value)),
            });
            setCategories(
              Object.values(stats[timeframe]['Total A'].Benchmark)
                .filter(a => filterByDate(a, 'timestamp', timeframe))
                .sort((a, b) => orderbyDate(a, b, 'timestamp'))
                .map(x => moment(x.timestamp).year()),
            );
            setSeries(_serie);
          }
          break;
        case 'total B':
          if (stats[timeframe]) {
            _serie.push({
              name: 'Index',
              data: Object.values(stats[timeframe]['Total B'].Index)
                .filter(a => filterByDate(a, 'timestamp', timeframe))
                .sort((a, b) => orderbyDate(a, b, 'timestamp'))
                .map(x => isPercentageValue(x.value)),
            });
            _serie.push({
              name: 'Benchmark',
              data: Object.values(stats[timeframe]['Total B'].Benchmark)
                .filter(a => filterByDate(a, 'timestamp', timeframe))
                .sort((a, b) => orderbyDate(a, b, 'timestamp'))
                .map(x => isPercentageValue(x.value)),
            });
            setSeries(_serie);
            setCategories(
              Object.values(stats[timeframe]['Total A'].Benchmark)
                .filter(a => filterByDate(a, 'timestamp', timeframe))
                .sort((a, b) => orderbyDate(a, b, 'timestamp'))
                .map(x => moment(x.timestamp).year()),
            );
          }
          break;
        case 'totalB':
          if (stats[timeframe]) {
            if (_.has(stats, [timeframe, 'Total B'])) {
              setCategories(
                Object.values(stats[timeframe]['Total B'])
                  .filter(a => filterByDate(a, 'timestamp', timeframe))
                  .sort((a, b) => orderbyDate(a, b, 'timestamp'))
                  .map(x => moment(x.timestamp).year()),
              );
            } else {
              setCategories(
                Object.values(stats[timeframe]['Total B - Benchmark'])
                  .filter(a => filterByDate(a, 'timestamp', timeframe))
                  .sort((a, b) => orderbyDate(a, b, 'timestamp'))
                  .map(x => moment(x.timestamp).year()),
              );
            }
            _serie.push({
              name: 'Index',
              data: Object.values(stats[timeframe]['Total B'])
                .filter(a => filterByDate(a, 'timestamp', timeframe))
                .sort((a, b) => orderbyDate(a, b, 'timestamp'))
                .map(x => isPercentageValue(x.value)),
            });
            _serie.push({
              name: 'Benchmark',
              data: Object.values(stats[timeframe]['Total B - Benchmark'])
                .filter(a => filterByDate(a, 'timestamp', timeframe))
                .sort((a, b) => orderbyDate(a, b, 'timestamp'))
                .map(x => isPercentageValue(x.value)),
            });
            setSeries(_serie);
          }
          break;
        case 'waste':
          if (stats[timeframe]) {
            scopes = ['Hazardous', 'Non-Hazardous', 'Waste Disposal'];
            if (_.has(stats, [timeframe, 'Hazardous'])) {
              setCategories(
                Object.values(stats[timeframe].Hazardous)
                  .filter(a => filterByDate(a, 'timestamp', timeframe))
                  .sort((a, b) => orderbyDate(a, b, 'timestamp'))
                  .map(x => moment(x.timestamp).year()),
              );
            } else if (_.has(stats, [timeframe, 'Non-Hazardous'])) {
              setCategories(
                Object.values(stats[timeframe]['Non-Hazardous'])
                  .filter(a => filterByDate(a, 'timestamp', timeframe))
                  .sort((a, b) => orderbyDate(a, b, 'timestamp'))
                  .map(x => moment(x.timestamp).year()),
              );
            } else if (_.has(stats, [timeframe, 'Waste Disposal'])) {
              setCategories(
                Object.values(stats[timeframe]['Waste Disposal'])
                  .filter(a => filterByDate(a, 'timestamp', timeframe))
                  .sort((a, b) => orderbyDate(a, b, 'timestamp'))
                  .map(x => moment(x.timestamp).year()),
              );
            }
            scopes.forEach(key => {
              _serie.push({
                name: key.toUpperCase(),
                data: Object.values(stats[timeframe][key])
                  .filter(a => filterByDate(a, 'timestamp', timeframe))
                  .sort((a, b) => orderbyDate(a, b, 'timestamp'))
                  .map(x => isPercentageValue(x.value)),
              });
            });
            setSeries(_serie);
          }
          break;
        case 'totalWaste':
          if (stats[timeframe]) {
            if (_.has(stats, [timeframe, 'Total Waste'])) {
              setCategories(
                Object.values(stats[timeframe]['Total Waste'])
                  .filter(a => filterByDate(a, 'timestamp', timeframe))
                  .sort((a, b) => orderbyDate(a, b, 'timestamp'))
                  .map(x => moment(x.timestamp).year()),
              );
            } else {
              setCategories(
                Object.values(stats[timeframe]['Total Waste - Benchmark'])
                  .filter(a => filterByDate(a, 'timestamp', timeframe))
                  .sort((a, b) => orderbyDate(a, b, 'timestamp'))
                  .map(x => moment(x.timestamp).year()),
              );
            }
            _serie.push({
              name: 'Index',
              data: Object.values(stats[timeframe]['Total Waste'])
                .filter(a => filterByDate(a, 'timestamp', timeframe))
                .sort((a, b) => orderbyDate(a, b, 'timestamp'))
                .map(x => isPercentageValue(x.value)),
            });
            _serie.push({
              name: 'Benchmark',
              data: Object.values(stats[timeframe]['Total Waste - Benchmark'])
                .filter(a => filterByDate(a, 'timestamp', timeframe))
                .sort((a, b) => orderbyDate(a, b, 'timestamp'))
                .map(x => isPercentageValue(x.value)),
            });
            setSeries(_serie);
          }
          break;
        case 'totalRecycled':
          if (stats[timeframe]) {
            if (_.has(stats, [timeframe, 'Total Recycled'])) {
              setCategories(
                Object.values(stats[timeframe]['Total Recycled'])
                  .filter(a => filterByDate(a, 'timestamp', timeframe))
                  .sort((a, b) => orderbyDate(a, b, 'timestamp'))
                  .map(x => moment(x.timestamp).year()),
              );
            } else {
              setCategories(
                Object.values(stats[timeframe]['Total Recycled - Benchmark'])
                  .filter(a => filterByDate(a, 'timestamp', timeframe))
                  .sort((a, b) => orderbyDate(a, b, 'timestamp'))
                  .map(x => moment(x.timestamp).year()),
              );
            }
            _serie.push({
              name: 'Index',
              data: Object.values(stats[timeframe]['Total Recycled'])
                .filter(a => filterByDate(a, 'timestamp', timeframe))
                .sort((a, b) => orderbyDate(a, b, 'timestamp'))
                .map(x => isPercentageValue(x.value)),
            });
            _serie.push({
              name: 'Benchmark',
              data: Object.values(stats[timeframe]['Total Recycled - Benchmark'])
                .filter(a => filterByDate(a, 'timestamp', timeframe))

                .sort((a, b) => orderbyDate(a, b, 'timestamp'))
                .map(x => isPercentageValue(x.value)),
            });
            setSeries(_serie);
          }
          break;

        default:
          setCategories([]);
          setSeries([]);
          break;
      }
    } else {
      scopes = ['Total A', 'Total B'];
      if (stats[timeframe]) {
        setCategories(
          Object.values(stats[timeframe]['Total A'])
            .filter(a => filterByDate(a, 'timestamp', timeframe))
            .sort((a, b) => orderbyDate(a, b, 'timestamp'))
            .map(x => moment(x.timestamp).year()),
        );
        scopes.forEach(key => {
          _serie.push({
            name: key.toUpperCase(),
            data: Object.values(stats[timeframe][key])
              .filter(a => filterByDate(a, 'timestamp', timeframe))
              .sort((a, b) => orderbyDate(a, b, 'timestamp'))
              .map(x => isPercentageValue(x.value)),
          });
        });
        setSeries(_serie);
      }
    }
  }, [emissionGrowCarbonization, timeframe, stats]);

  return (
    <>
      <div className={styles.tableContainer} style={{ padding: '0px' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '10px',
            marginRight: '10px',
          }}
        >
          <span
            className={styles.styleFactorTitle}
            style={{ fontWeight: 'bold', marginLeft: '10px' }}
          >
            {!hideDropdown ? `${emissionGrowCarbonization?.value} ${title}` : title}
          </span>
          {!hideDropdown && (
            <BitaSelect
              data={dropdownOptions}
              value={emissionGrowCarbonization}
              clearable={dropdownClearable}
              width="160px"
              handleSelection={selected => {
                setEmissionGrowCarbonization(selected);
              }}
            />
          )}
        </div>

        <BitaCharts
          key="NormalizedRecyclingGrowth"
          options={{
            ...{
              xAxis: {
                categories,
              },
              yAxis: {
                title: {
                  text: '',
                },
                labels: {
                  formatter() {
                    // eslint-disable-next-line react/no-this-in-sfc
                    return `${thousandFormat(this.value)} ${yAxisLabel}`;
                  },
                  style: {
                    color: '#E0E0E3',
                    fontFamily: "'Neue Haas Grotesk Display Pro', sans-serif",
                  },
                },
              },

              series,
              tooltip: {
                crosshairs: true,
                shared: true,
                formatter(tooltip) {
                  // eslint-disable-next-line prefer-destructuring
                  let format = `${this.x}<br/>`;
                  this.points.forEach(x => {
                    x.point.y = Number(x.point.y.toFixed(4));
                    const pointSplit = `${Number(x.point.y.toFixed(4))}`.split('.');
                    format += `${x.series.name} : ${thousandFormat(
                      pointSplit[0],
                    )}.${pointSplit[1] || ''}${yAxisLabel} <br/>`;
                  });
                  return format;
                },
              },
            },
          }}
          theme={
            emissionGrowCarbonization?.value === 'Scopes' ||
            emissionGrowCarbonization?.value === 'Waste'
              ? WasteGrowthThemeWaste
              : historicalRatingsTheme
          }
          containerProps={{
            style: { padding: 20, height: '330px', width: '100%' },
          }}
        />
      </div>
      {!hideStats && (
        <StatsTable
          title={
            tableDropdownTitle ? `${emissionGrowCarbonization?.value} ${tableTitle}` : tableTitle
          }
          categories={categories}
          series={series}
          typeIndex={typeIndex}
          yAxisLabel={yAxisLabel}
        />
      )}
    </>
  );
};

export default BarGraphWithDropdown;
