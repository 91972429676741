import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import 'simplebar'; // or "import SimpleBar from 'simplebar';" if you want to use it manually.
import 'simplebar/dist/simplebar.css';
import './Bitabacktestbanner.scss';
import {
  displayRunningBacktestWithId,
  displayCompletedBacktestWithId,
  changeBacktestStatus,
} from '../../state/actions/backtest-actions';
import { Icons } from '../../constants/icons';
import BitaSpinner from '../../components/BitaSpinner/BitaSpinner';
import * as STATUS from '../../constants/backtest-status';
import { useSelectedBacktest, RUNNING } from '../../utils/useSelectedBacktest';

const BacktestBannerCard = ({ backtest }) => {
  const backtestTimestamp = backtest?.timestamp;
  const dispatch = useDispatch();
  const [text, setText] = useState('Connecting to the backtesting engine.');
  const history = useHistory();
  const url = '/index-builders/index-builders/step=Index+builder';

  useEffect(() => {
    if (backtest.infoMessage) {
      setText(null);
      setTimeout(() => setText(backtest.infoMessage), 200);
    }
  }, [backtest.infoMessage]);

  useEffect(() => {
    if (backtestTimestamp && !backtest.infoMessage) {
      const current = new Date().getTime();
      const rest = current - backtestTimestamp;
      const timeout = 300000 - rest;
      if (timeout > 0) {
        setTimeout(() => {
          if (!backtest.infoMessage) {
            console.info('NO DATA FROM WS');
            setText('It was impossible to connect with the backtesting engine. Please, try again.');
            dispatch(changeBacktestStatus(backtest.id, STATUS.BACKTEST_WITH_ERROR));
          }
        }, timeout);
      } else {
        setText('It was impossible to connect with the backtesting engine. Please, try again.');
      }
    }
  }, [backtestTimestamp]);

  const isBacktestCompleted = backtest.status === STATUS.BACKTEST_COMPLETED;

  const showModal = () => {
    if (!isBacktestCompleted) {
      // para forzar a que vuelva a salir el modal cambiamos el estado momentaneamente
      dispatch(displayRunningBacktestWithId(backtest.id));
    } else {
      dispatch(displayCompletedBacktestWithId(backtest.id));
    }

    // history.push(url);
  };

  return (
    <div data-simplebar style={{ height: '40px' }}>
      <motion.div
        initial={{ opacity: 1 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 1 }}
        className="bita_backtest_banner"
      >
        {backtest.data['INDEX NAME'] && (
          <>
            <div className="label_container" onClick={showModal}>
              {/* <span className="label">BACKTEST IN PROGRESS</span> */}
              <div
                className={`index_name ${isBacktestCompleted && 'backtest_completed'}`}
                onClick={showModal}
              >
                {backtest.data['INDEX NAME']}
              </div>
            </div>
            <div className="status_backtest">
              {text && (
                <motion.div
                  initial={{ opacity: 1 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 1 }}
                  onClick={showModal}
                >
                  {`${text
                    .split('')
                    .slice(0, 60)
                    .join('')}...`}
                </motion.div>
              )}
            </div>
          </>
        )}
        {!backtest.backtestCompleted && (
          <div style={{ width: '40px' }}>
            <BitaSpinner pointers loading />
          </div>
        )}
      </motion.div>
    </div>
  );
};

const Bitabacktestbanner = () => {
  const backtests = useSelector(state => state.backtest.backtests);
  const currentBacktestId = useSelector(state => state.backtest.idDisplayedRunningBacktest);
  const [top, setTop] = useState(0);

  const backtestsToShow = Object.values(backtests).filter(backtest => {
    return (
      backtest?.id !== currentBacktestId &&
      (backtest.status === STATUS.BACKTEST_RUNNING || backtest.status === STATUS.BACKTEST_COMPLETED)
    );
  });

  const downTop = () => {
    setTop(top !== (backtestsToShow.length - 1) * -40 ? top - 40 : top);
  };

  const upTop = () => {
    setTop(top !== 0 ? top + 40 : top);
  };

  useEffect(() => {
    setTop(0);
  }, [backtestsToShow.length]);

  return (
    <div style={{ height: '40px', overflow: 'hidden', display: 'flex', width: '100%' }}>
      <div style={{ position: 'relative', top: `${top}px`, width: '90%' }}>
        {backtestsToShow.map(backtest => {
          return <BacktestBannerCard backtest={backtest} />;
        })}
      </div>
      {backtestsToShow.length > 1 && (
        <div className="arrows_backtest_banner" style={{ marginLeft: '20px', paddingTop: '5px' }}>
          <div onClick={upTop}>
            <Icons.Arrowup style={{ width: '10px', opacity: top !== 0 ? '1' : '0.3' }} />
          </div>
          <div onClick={downTop}>
            <Icons.Arrowdown
              style={{
                width: '10px',
                opacity: top !== (backtestsToShow.length - 1) * -40 ? '1' : '0.3',
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Bitabacktestbanner;
