import * as ACTIONS from '../../constants/universe-constants';

export const selectUniverse = data => {
  return { type: ACTIONS.SELECT_UNIVERSE, payload: data };
};

export const updateUniverse = data => {
  return { type: ACTIONS.UPDATE_UNIVERSE, payload: data };
};

export const clearUniverses = data => {
  return { type: ACTIONS.CLEAR_UNIVERSE, payload: data };
};

export const addUniverse = data => {
  return { type: ACTIONS.ADD_UNIVERSE, payload: data };
};

export const deleteUniverse = data => {
  return { type: ACTIONS.DELETE_UNIVERSE, payload: data };
};
