import lodash from 'lodash';
import { pathUpdate } from '../../bitaHooks/useFieldUpdate';
import * as ACTIONS from '../../constants/summary-constants';

const initialState = {};

const summaryReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.ADD_ENTRY: {
      const { module, widget, data } = action.payload;
      const path = `${module}.${widget}`;
      const updatedState = pathUpdate(state, path, data);
      return updatedState;
    }
    case ACTIONS.REMOVE_ALL_ENTRIES: {
      const { module } = action.payload;
      return {
        // TODO
        ...state,
        [module]: {},
      };
    }
    case ACTIONS.REMOVE_ENTRY: {
      const { module, widget, entry } = action.payload;
      const newValue = lodash.clone(state[module][widget]);
      if (newValue) {
        delete newValue[entry];
      }

      return {
        ...state,
        [module]: {
          ...state[module],
          [widget]: newValue,
        },
      };
    }
    case ACTIONS.RESET: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default summaryReducer;
