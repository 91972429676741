/* eslint-disable func-names */
import React from 'react';
import styles from '../PerformanceAndRisk.module.scss';

const TableData = props => {
  return (
    <div className={styles.tableContainer}>
      <table className={styles.generatedTable}>
        <thead className={styles.generatedTableHeader}>
          <tr>
            <th colSpan="1" />
            <th scope="col">Index</th>
            <th scope="col">Benchmark</th>
          </tr>
        </thead>
        <tbody>
          {props.data &&
            props.data?.map(item => (
              <tr className={styles.generatedTableHeader}>
                <td style={{ fontWeight: 'bold' }}>{item.name}</td>
                <td
                  style={{ width: '30%', textAlign: 'center' }}
                  className={styles.restTableHeaders}
                >
                  {Number(item.index).toFixed(2) === 'NaN'
                    ? 'N/A'
                    : Number(item.index).toFixed(2) || ''}
                </td>
                <td
                  style={{ width: '30%', textAlign: 'center' }}
                  className={styles.restTableHeaders}
                >
                  {Number(item.benchmark).toFixed(2) === 'NaN'
                    ? 'N/A'
                    : Number(item.benchmark).toFixed(2) || ''}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableData;
