export const defaultTemplateStructure = () => {
  return {
    Rebalancing: {
      Day: {
        id: '4',
        value: 'Friday',
      },
      Months: {
        id: '2',
        value: 'March-June-September-December',
      },
      'Ex-Date': {
        id: '2',
        value: '3rd',
      },
      rebalancing: 'Quarterly',
      'Determination date': {
        id: '5',
        value: 5,
      },
    },
    Currency: {
      currency: {
        id: '0',
        value: 'USD',
      },
    },
  };
};
