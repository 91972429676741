import _ from 'lodash';
import moment from 'moment';
import { data_attribute_format, convertToNumber } from '../../../utils/utils';

export const getStructureIndexBacktestBody = (structureIndexState, backtestInfo, endDate) => {
  const structuredIndexInfo = structureIndexState['Structured Index']['New Structured Product'];

  const structuredDecrementIndex = _.get(
    structureIndexState,
    ['Structured Index', 'Decrement Index'],
    false,
  );
  const structuredLeverageIndex = _.get(
    structureIndexState,
    ['Structured Index', 'Leverage Index'],
    false,
  );
  const structuredInverseIndex = _.get(
    structureIndexState,
    ['Structured Index', 'Inverse Index'],
    false,
  );
  const structuredRiskIndex = _.get(
    structureIndexState,
    ['Structured Index', 'Risk Control Index'],
    false,
  );
  const listInstrument = structureIndexState?.['Structured Index']?.['Instrument Selected'];
  const currency = '';
  const return_calculation = '';

  const percentageNumber = numberweight => {
    return parseFloat(numberweight.replace(',', '')) / 100;
  };

  const generateBorrowingType = (b_type, b_timeframe = 'over night', b_currency) => {
    let text = b_type;
    if (b_type.toLowerCase() === 'fedfunds') {
      text = 'fed_funds';
    }
    if (b_currency) {
      text = `${text} ${b_currency}`;
    }
    text = `${text} rate ${b_timeframe}`;
    return data_attribute_format(text);
  };

  const setBorrowingRate = apiData => {
    const borrowing = _.get(apiData, ['Interest Rate'], false);
    if (borrowing) {
      const interest_type = _.get(borrowing, ['interest_rate', 'value'], 'Custom');
      const interest_timeframe = _.get(borrowing, ['interest_timeframe', 'value'], 'over night');
      const interest_currency = _.get(borrowing, ['interest_currency', 'value'], false);
      if (interest_type !== 'Custom') {
        return generateBorrowingType(interest_type, interest_timeframe, interest_currency);
      }
    }
    return 'Custom';
  };

  const StructuredIndexApiBody = {
    universe: '',
    object_backtest: {
      strategy_name: 'general_strategy',
      calculate: true,
      parameters: {
        operations: {
          constitute: {
            operation_name: 'constitute',
          },
          run_index: {
            operation_name: 'run_index',
          },
          performance_metrics: {
            operation_name: 'performance_metrics',
          },
        },
        data: {
          initial_index_value: parseFloat(backtestInfo['BASE VALUE']),
          inception_date: moment(backtestInfo['Inception Date']).format('YYYY-MM-DD'),
          start_date: moment(backtestInfo['Start Date']).format('YYYY-MM-DD'),
          end_date: moment(backtestInfo['End Date'] || endDate).format('YYYY-MM-DD'),
          operations_name: ['awf'],
          inception_value: parseFloat(backtestInfo['BASE VALUE']),
          base_currency: '',
          company: '',
        },
      },
      api_parameters: {
        currency: {
          currency: 'USD',
        },
        rebalancing: {
          Day: 'Friday',
          Months: 'March-June-September-December',
          'Ex-Date': '3rd',
          Rebalancing: 'Quarterly',
          Proforma_date: 5,
        },
        reconstitution: {},
        start_date: moment(backtestInfo['Start Date']).format('YYYY-MM-DD'),
        end_date: moment(backtestInfo['End Date'] || endDate).format('YYYY-MM-DD'),
      },
      checking_factors: {},
      benchmark: {
        type: backtestInfo.Benchmark['Instrument type'],
        id: backtestInfo?.Benchmark?.id,
      },
    },
  };

  if (structuredIndexInfo?.Currency?.currency?.value) {
    StructuredIndexApiBody.object_backtest.parameters.data.base_currency =
      structuredIndexInfo?.Currency?.currency?.value;
    StructuredIndexApiBody.object_backtest.api_parameters.currency.currency =
      structuredIndexInfo?.Currency?.currency?.value;
  }

  const auxInstrument = [];
  // eslint-disable-next-line array-callback-return
  listInstrument.map(instrument => {
    auxInstrument.push(instrument.id);
  });
  StructuredIndexApiBody.object_backtest.parameters.data.instruments_id = auxInstrument;

  if (structuredIndexInfo?.['Weighting Mechanism']?.weightingMechanism) {
    const _weightingMechanism =
      structuredIndexInfo?.['Weighting Mechanism']?.weightingMechanism === 'Equal Weight'
        ? 'equal_weight'
        : structuredIndexInfo?.['Weighting Mechanism']?.weightingMechanism === 'User-defined weight'
        ? 'defined_weights'
        : structuredIndexInfo?.['Weighting Mechanism']?.weightingMechanism === 'Momentum Weight'
        ? 'momentum_weight'
        : structuredIndexInfo?.['Weighting Mechanism']?.weightingMechanism;

    StructuredIndexApiBody.object_backtest.parameters.data.operations_name = [_weightingMechanism];

    if (
      structuredIndexInfo?.['Weighting Mechanism']?.weightingMechanism === 'User-defined weight' &&
      structuredIndexInfo?.['Weighting Mechanism']?.user_defined_weight
    ) {
      let listweight = {};
      const weight_defined = structuredIndexInfo?.['Weighting Mechanism']?.user_defined_weight?.[0];
      // eslint-disable-next-line array-callback-return
      Object.entries(weight_defined).map(([key, value]) => {
        listweight = {
          ...listweight,
          [parseFloat(
            listInstrument.filter(instrument => `${instrument?.id} ` === key)?.[0]?.id,
          )]: percentageNumber(value),
        };
      });
      StructuredIndexApiBody.object_backtest.parameters.data.user_weights = listweight;
    }
  }

  if (structuredIndexInfo?.Rebalancing) {
    StructuredIndexApiBody.object_backtest.api_parameters.rebalancing.Rebalancing =
      structuredIndexInfo?.Rebalancing?.rebalancing;

    StructuredIndexApiBody.object_backtest.api_parameters.rebalancing.Day =
      structuredIndexInfo?.Rebalancing?.Day?.value;

    StructuredIndexApiBody.object_backtest.api_parameters.rebalancing.Months =
      structuredIndexInfo?.Rebalancing?.rebalancing.toUpperCase() === 'MONTHLY'
        ? 'January-February-March-April-May-June-July-August-September-October-November-December'
        : structuredIndexInfo?.Rebalancing?.Months?.value;

    StructuredIndexApiBody.object_backtest.api_parameters.rebalancing['Ex-Date'] =
      structuredIndexInfo?.Rebalancing?.['Ex-Date']?.value;

    StructuredIndexApiBody.object_backtest.api_parameters.rebalancing.Proforma_date =
      structuredIndexInfo?.Rebalancing?.['Determination date']?.value;
  }

  // eslint-disable-next-line no-dupe-keys
  if (!StructuredIndexApiBody.object_backtest.parameters.data.operations_name.includes('awf')) {
    StructuredIndexApiBody.object_backtest.parameters.data.operations_name.push('awf');
  }

  if (structuredLeverageIndex) {
    StructuredIndexApiBody.object_backtest.api_parameters.rebalancing = {};
    StructuredIndexApiBody.object_backtest.parameters.operations = {
      performance_metrics: {
        operation_name: 'performance_metrics',
      },
      leveraged: {
        operation_name: 'leveraged',
      },
    };
    const leverage_factor = _.get(
      structuredLeverageIndex,
      ['Leverage Factor', 'Apply a Leverage of', 'value'],
      false,
    );

    const reverse_split_threshold = _.get(
      structuredLeverageIndex,
      ['Reverse Split Trigger', 'reverseSplitThreshold'],
      false,
    );

    const reverse_split_factor = _.get(
      structuredLeverageIndex,
      ['Reverse Split Trigger', 'reverseSplitFactor'],
      false,
    );

    const day_count_convention = _.get(
      structuredLeverageIndex,
      ['Day-count convention', 'decrement_convention'],
      false,
    );

    const borrowing_rate = setBorrowingRate(structuredLeverageIndex);

    StructuredIndexApiBody.object_backtest.parameters.data.leverage_factor = leverage_factor
      ? Number(leverage_factor.slice(0, -1))
      : '';

    StructuredIndexApiBody.object_backtest.parameters.data.reverse_split_threshold = reverse_split_threshold
      ? percentageNumber(reverse_split_threshold)
      : '';

    StructuredIndexApiBody.object_backtest.parameters.data.reverse_split_factor = reverse_split_factor
      ? convertToNumber(reverse_split_factor)
      : '';

    StructuredIndexApiBody.object_backtest.parameters.data.day_count_convention = day_count_convention
      ? Number(day_count_convention)
      : '';
    StructuredIndexApiBody.object_backtest.parameters.data.borrowing_rate =
      borrowing_rate !== 'Custom'
        ? data_attribute_format(borrowing_rate)
        : percentageNumber(
            _.get(structuredLeverageIndex, ['Interest Rate', 'daily_interest'], '0'),
          );

    return StructuredIndexApiBody;
  }

  if (structuredDecrementIndex) {
    StructuredIndexApiBody.object_backtest.api_parameters.rebalancing = {};
    StructuredIndexApiBody.object_backtest.parameters.operations = {
      performance_metrics: {
        operation_name: 'performance_metrics',
      },
      decrement_index_msci: {
        operation_name: 'decrement_index_msci',
      },
    };
    const decrement_type = _.get(
      structuredDecrementIndex,
      ['Decrement type', 'applyFactor', 'value'],
      '',
    );
    const decrement_application = _.get(
      structuredDecrementIndex,
      ['Decrement Frequency', 'decrement_application'],
      '',
    );
    const decrement_frequency = _.get(
      structuredDecrementIndex,
      ['Decrement Frequency', 'rebalancing'],
      '',
    );
    const day_count_convention = _.get(
      structuredDecrementIndex,
      ['Day-count convention', 'decrement_convention'],
      false,
    );
    const floor_to_stop_decrement = _.get(
      structuredDecrementIndex,
      ['Decrement Floor', 'Floor'],
      false,
    );
    const decrement_percentage = _.get(
      structuredDecrementIndex,
      ['Decrement type', 'decrement'],
      false,
    );

    const decrement_points = _.get(
      structuredDecrementIndex,
      ['Decrement type', 'decrement_point'],
      false,
    );

    StructuredIndexApiBody.object_backtest.parameters.data.decrement_type = data_attribute_format(
      decrement_type,
    );
    StructuredIndexApiBody.object_backtest.parameters.data.decrement_application = data_attribute_format(
      decrement_application,
    );
    StructuredIndexApiBody.object_backtest.parameters.data.decrement_frequency = data_attribute_format(
      decrement_frequency,
    );
    StructuredIndexApiBody.object_backtest.parameters.data.day_count_convention = day_count_convention
      ? Number(day_count_convention)
      : '';
    StructuredIndexApiBody.object_backtest.parameters.data.floor_to_stop_decrement = floor_to_stop_decrement
      ? convertToNumber(floor_to_stop_decrement)
      : '';
    StructuredIndexApiBody.object_backtest.parameters.data.decrement_percentage = decrement_percentage
      ? percentageNumber(decrement_percentage)
      : '';
    StructuredIndexApiBody.object_backtest.parameters.data.decrement_points = decrement_points
      ? convertToNumber(decrement_points)
      : '';

    return StructuredIndexApiBody;
  }

  if (structuredInverseIndex) {
    StructuredIndexApiBody.object_backtest.api_parameters.rebalancing = {};
    StructuredIndexApiBody.object_backtest.parameters.operations = {
      performance_metrics: {
        operation_name: 'performance_metrics',
      },
      inverse_index: {
        operation_name: 'inverse_index',
      },
    };
    const leverage_factor = _.get(
      structuredInverseIndex,
      ['Leverage Factor', 'Apply a Leverage of', 'value'],
      false,
    );

    const reverse_split_threshold = _.get(
      structuredInverseIndex,
      ['Reverse Split Trigger', 'reverseSplitThreshold'],
      false,
    );

    const reverse_split_factor = _.get(
      structuredInverseIndex,
      ['Reverse Split Trigger', 'reverseSplitFactor'],
      false,
    );

    StructuredIndexApiBody.object_backtest.parameters.data.leverage_factor = leverage_factor
      ? Number(leverage_factor.slice(0, -1))
      : '';

    StructuredIndexApiBody.object_backtest.parameters.data.reverse_split_threshold = reverse_split_threshold
      ? percentageNumber(reverse_split_threshold)
      : '';

    StructuredIndexApiBody.object_backtest.parameters.data.reverse_split_factor = reverse_split_factor
      ? Number(reverse_split_factor)
      : '';

    return StructuredIndexApiBody;
  }

  if (structuredRiskIndex) {
    StructuredIndexApiBody.object_backtest.api_parameters.rebalancing = {};
    StructuredIndexApiBody.object_backtest.parameters.operations = {
      performance_metrics: {
        operation_name: 'performance_metrics',
      },
      risk_control: {
        operation_name: 'risk_control',
      },
    };
    const risk_currency = _.get(structuredRiskIndex, ['Currency', 'currency', 'value'], 'USD');

    StructuredIndexApiBody.object_backtest.parameters.data.base_currency = risk_currency;
    StructuredIndexApiBody.object_backtest.api_parameters.currency.currency = risk_currency;

    const target_volatility = _.get(
      structuredRiskIndex,
      ['Target Volatility', 'target_volatility'],
      '0',
    );
    const realized_volatility_intervals = [
      convertToNumber(
        _.get(
          structuredRiskIndex,
          ['Realized Volatility Intervals', 'volatility_first_interval'],
          '0',
        ),
      ),
      convertToNumber(
        _.get(
          structuredRiskIndex,
          ['Realized Volatility Intervals', 'volatility_second_interval'],
          '0',
        ),
      ),
    ];
    const risk_asset_cap = _.get(structuredRiskIndex, ['Maximum exposure', 'risk_asset_cap'], '0');
    const tolerance_level = _.get(
      structuredRiskIndex,
      ['Volatility Tolerance', 'tolerance_level'],
      '0',
    );
    const interest_rate = setBorrowingRate(structuredRiskIndex);
    const borrowing_cost = _.get(structuredRiskIndex, ['Borrowing Cost', 'borrowing_cost'], '0');
    const day_count_convention = _.get(
      structuredRiskIndex,
      ['Day-count convention', 'day_count_convention'],
      365,
    );
    const determination_date_frequency = _.get(
      structuredRiskIndex,
      ['Rebalancing Review Frequency', 'determination_date_frequency'],
      'Annually',
    );
    StructuredIndexApiBody.object_backtest.parameters.data.target_volatility = percentageNumber(
      target_volatility,
    );
    StructuredIndexApiBody.object_backtest.parameters.data.realized_volatility_intervals = realized_volatility_intervals;
    StructuredIndexApiBody.object_backtest.parameters.data.risk_asset_cap = percentageNumber(
      risk_asset_cap,
    );
    StructuredIndexApiBody.object_backtest.parameters.data.tolerance_level = percentageNumber(
      tolerance_level,
    );
    StructuredIndexApiBody.object_backtest.parameters.data.interest_rate =
      interest_rate !== 'Custom'
        ? data_attribute_format(interest_rate)
        : percentageNumber(_.get(structuredRiskIndex, ['Interest Rate', 'daily_interest'], '0'));
    StructuredIndexApiBody.object_backtest.parameters.data.borrowing_cost =
      parseFloat(borrowing_cost) * 0.0001;

    StructuredIndexApiBody.object_backtest.parameters.data.day_count_convention = Number(
      day_count_convention,
    );

    StructuredIndexApiBody.object_backtest.parameters.data.determination_frequency = data_attribute_format(
      determination_date_frequency,
    );
  }

  return StructuredIndexApiBody;
};
