import React, { useState, useEffect } from 'react';
import BitaInput from '../../../components/BitaInput/BitaInput';
import styles from '../../builder.module.scss';

const BetweenComponent = ({
  id = '',
  onChange,
  widgetData,
  path,
  post_text,
  type,
  placeholder,
  historyAddibleData,
}) => {
  const [firstValue, setFirstValue] = useState();
  const [secondValue, setSecondValue] = useState();
  useEffect(() => {
    if (widgetData?.operator?.value === 'Between') {
      if (widgetData?.value && Array.isArray(widgetData.value)) {
        setFirstValue(widgetData.value[0]);
        setSecondValue(widgetData.value[1]);
      }
    }
  }, [widgetData]);
  useEffect(() => {
    if (onChange) {
      onChange(`${path}.${id}`, [firstValue, secondValue]);
    }
  }, [firstValue, secondValue]);
  return (
    <div className={`${styles.disclaimer_container}`}>
      <div
        className={`${styles.disclaimer_row} ${styles.builderInputComponentContainer} builderInputComponentContainer`}
      >
        <BitaInput
          value={firstValue}
          type={type}
          placeholder={`${placeholder} >=`}
          handleChange={val => {
            setFirstValue(val);
          }}
          tooltip="Greater than or equal to"
          inputStyle={styles.widgetInput}
          postText=" "
          inputContainerStyle={styles.builderInputContainer}
        />
        <BitaInput
          inputContainerStyle={styles.builderInputContainer}
          value={secondValue}
          type={type}
          placeholder={`${placeholder} <=`}
          tooltip="Less than or equal to"
          text="and"
          handleChange={val => {
            setSecondValue(val);
          }}
          inputStyle={styles.widgetInput}
          postText={post_text}
        />
      </div>
    </div>
  );
};

export default BetweenComponent;
