import React from 'react';
import styles from './BitaStepper.module.scss';

const StepperFactory = ({
  type,
  path,
  currentStep,
  setPath,
  data,
  nextStep,
  previousStep,
  submitStepper,
  goToPreviousStep,
}) => {
  const pathSelectorComponent =
    currentStep === 0 && data.pathSelector({ setPath, paths: [...data.paths], nextStep });

  const currentStepComponent =
    currentStep > 0 &&
    path[currentStep - 1].component({
      nextStep: path.length - 1 === currentStep - 1 ? submitStepper : nextStep,
      previousStep,
      submitStepper,
    });

  const styleSelector = typeStyle => (
    <div
      className={`${
        typeStyle === 'buttonSteps' ? styles.stepContainer : styles.bulletPointContainer
      }`}
    >
      {currentStep !== 0 &&
        path.map((step, stepIndex) => (
          <button
            className={`${
              typeStyle === 'buttonSteps' ? styles.stepButtons : styles.stepBulletPoint
            } ${currentStep - 1 === stepIndex &&
              (typeStyle === 'buttonSteps' ? styles.selectedStep : styles.selectedBulletPoint)}`}
            style={{
              opacity: step.status_plan === 'ACTIVE' ? '1' : '0.5',
              pointerEvents: step.status_plan === 'ACTIVE' ? 'auto' : 'none',
              display: step.status_plan === 'ACTIVE' ? 'block' : 'none',
            }}
            onClick={() => goToPreviousStep(stepIndex + 1)}
          >
            {typeStyle === 'buttonSteps' && `${stepIndex + 1}. ${step.name}`}
          </button>
        ))}
    </div>
  );

  switch (type) {
    case 'buttonSteps':
      return (
        <div className={styles.base}>
          {styleSelector(type)}
          {pathSelectorComponent}
          {currentStepComponent}
        </div>
      );
    case 'bulletSteps':
      return (
        <div className={styles.base}>
          {pathSelectorComponent}
          {currentStepComponent}
          {styleSelector(type)}
        </div>
      );
    default:
      return null;
  }
};

const BitaStepper = ({ data, submit, crntStep = 0 }) => {
  const [currentStep, setCurrentStep] = React.useState(crntStep);
  const [path, setPath] = React.useState([() => null]);

  // const auxdata = data.filter(auxStep => {
  //   // eslint-disable-next-line no-unused-expressions
  //   return auxStep.status_plan !== 'INACTIVE' && auxStep.status_plan !== 'HIDDEN';
  // });

  // const selectPath = path => alert(path);
  const nextStep = () => setCurrentStep(prev => prev + 1);
  const previousStep = () => setCurrentStep(prev => prev - 1);
  // eslint-disable-next-line
  const submitStepper = () => submit();

  const goToPreviousStep = stepIndex => {
    if (stepIndex < currentStep) {
      setCurrentStep(stepIndex);
    }
  };

  return StepperFactory({
    type: data.type,
    path,
    currentStep,
    setPath,
    data,
    nextStep,
    previousStep,
    submitStepper,
    goToPreviousStep,
  });
};

BitaStepper.defaultProps = {};

BitaStepper.propTypes = {};

export default BitaStepper;
