/* eslint-disable */
/* eslint-disable consistent-return */
/* eslint-disable no-constant-condition */
/* eslint-disable array-callback-return */
import React from 'react';
import { BuilderDefaultComponent } from './subcomponents/BuilderDefaultComponent';
import { BuilderAddibleRowsComponent } from './subcomponents/BuilderAddibleRowsComponent';
import { BuilderInstrumentSelect } from './subcomponents/BuilderInstrumentSelect';
import { BuilderCardComponent } from './subcomponents/BuilderCardComponent';
import { BuilderHorizontalContainer } from './subcomponents/BuilderHorizontalContainer';
import { BuilderToggleGroup } from './subcomponents/BuilderToggleGroup';
import { BuilderToggleOnlyOption } from './subcomponents/BuilderToggleOnlyOption';
import { BuilderToggleFilter } from './subcomponents/BuilderToggleFilter';
import { BuilderSwitchComponent } from './subcomponents/BuilderSwitchComponent';
import { BuilderSelectionList } from './subcomponents/BuilderSelectionList';
import { BuilderCalendarDateComponent } from './subcomponents/BuilderCalendarDateComponent';
import { BuilderCalendarRangeComponent } from './subcomponents/BuilderCalendarRangeComponent';
import { BuilderDropdownComponent } from './subcomponents/BuilderDropdownComponent';
import { BuilderInputComponent } from './subcomponents/BuilderInputComponent';
import { BuilderTabComponent } from './subcomponents/BuilderTabComponent';
import { BuilderBacktestStatus } from './subcomponents/BuilderBacktestStatus';
import { BuilderPopUpComponent } from './subcomponents/BuilderPopUpComponent';
import { BuilderTextComponent } from './subcomponents/BuilderTextComponent';
import { BuilderButtonComponent } from './subcomponents/BuilderButtonComponent';
import { BuilderProgressComponent } from './subcomponents/BuilderProgressComponent';
import { BuilderRadioGroupComponent } from './subcomponents/BuilderRadioGroupComponent';
import { BuilderRBMultipleComponent } from './subcomponents/BuilderRBMultipleComponent';
import { BuilderTitleComponent } from './subcomponents/BuilderTitleComponent';
import { BuilderImageComponent } from './subcomponents/BuilderImageComponent';
import { BuilderWidgetComponent } from './subcomponents/BuilderWidgetComponent';
import { BuilderInfoSquareComponent } from './subcomponents/BuilderInfoSquareComponent';
import { BuilderSumRowsComponent } from './subcomponents/BuilderSumRowsComponent';
import { ComponentGenerator } from './subcomponents/ComponentGenerator';
import { BuilderDisclaimerRow } from './subcomponents/BuilderDisclaimerRow';
import { BuilderSelectionListContainer } from './subcomponents/BuilderSelectionListContainerComponent';
import { BuilderAdditionalSettingsComponent } from './subcomponents/BuilderAdditionalSettingsComponent';
import { BuilderUploadPortfolioComponent } from './subcomponents/BuilderUploadPortfolioComponent';
import { BuilderBetweenComponent } from './subcomponents/BuilderBetweenComponent';
import { BuilderCheckBox } from './subcomponents/BuilderCheckBox';

const Components = {
  Tab: BuilderTabComponent,
  Toggle: BuilderToggleGroup,
  ToggleOnly: BuilderToggleOnlyOption,
  ToggleFilter: BuilderToggleFilter,
  HorizontalContainer: BuilderHorizontalContainer,
  Card: BuilderCardComponent,
  Button: BuilderButtonComponent,
  SearchBar: BuilderInstrumentSelect,
  Calendar: BuilderCalendarDateComponent,
  CalendarRange: BuilderCalendarRangeComponent,
  Dropdown: BuilderDropdownComponent,
  SelectionList: BuilderSelectionList,
  AddibleRows: BuilderAddibleRowsComponent,
  Input: BuilderInputComponent,
  Text: BuilderTextComponent,
  ProgressBar: BuilderProgressComponent,
  Image: BuilderImageComponent,
  PopUp: BuilderPopUpComponent,
  RatioButtons: BuilderRadioGroupComponent,
  Default: BuilderDefaultComponent,
  Title: BuilderTitleComponent,
  Widget: BuilderWidgetComponent,
  InfoSquare: BuilderInfoSquareComponent,
  BacktestMessage: BuilderBacktestStatus,
  Switch: BuilderSwitchComponent,
  SumRows: BuilderSumRowsComponent,
  DisclaimerRow: BuilderDisclaimerRow,
  SelectionListContainer: BuilderSelectionListContainer,
  AdditionalSettings: BuilderAdditionalSettingsComponent,
  UploadPortfolio: BuilderUploadPortfolioComponent,
  MultipleRatioButtons: BuilderRBMultipleComponent,
  Between: BuilderBetweenComponent,
  Checkbox: BuilderCheckBox,
};

export { Components, ComponentGenerator };
