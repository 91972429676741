import * as ACTIONS from '../../constants/module-upload-constants';

const initialState = {
  filesettings: false,
  exclude_isins: '',
  fileUploadLoading: false,
  showContentManager: '',
};

const moduleUpload = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.UPLOAD_EXCLUDE_ISINS: {
      return {
        ...state,
        exclude_isins: action.payload,
      };
    }
    case ACTIONS.UPLOAD_FILESETTINGS: {
      return {
        ...state,
        filesettings: action.payload,
      };
    }

    case ACTIONS.UPLOAD_LOADING: {
      return {
        ...state,
        fileUploadLoading: action.payload,
      };
    }

    case ACTIONS.UPLOAD_SHOWCONTENTMANAGER: {
      return {
        ...state,
        showContentManager: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default moduleUpload;
