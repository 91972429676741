/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import ConfigYAML from '../../../config/ConfigYAML';
import BitaModal from '../../../bitaComponents/BitaModal/BitaModal';
import Builder from '../../../builder/Builder';
import { getDataComponents } from '../../../api-data-mapping/dataMap';
import { Icons } from '../../../constants/icons';
import { ReactComponent as IconsU } from '../../../static/img/Universe_icon.svg';
import { toastInterceptor } from '../../../utils/utils';
import { setApiData, resetApiDataAttribute } from '../../../state/actions/api-data-actions';
import * as SummaryConstants from '../../../constants/summary-constants';
import * as IndexConstants from '../../../constants/index-operations-constants';
import { cleanCache } from '../../../state/actions/summary-actions';
import { discardBacktest } from '../../../state/actions/backtest-actions';
import { resetUniverse } from '../../../state/actions/universe-operations-actions';
import { sameNameError, emptyUniverseNameError } from '../../../constants/methodologies-constants';
import BitaSpinner from '../../BitaSpinner/BitaSpinner';

import './saveUniverse.scss';

const SaveUniverse = props => {
  const [UniverseSavepen, setUniverseSavepen] = React.useState(props.viewModal);
  const [name, setName] = React.useState('');
  const [savingBigUniverse, setBigUniverse] = React.useState(false);
  const buttonrefsave = React.useRef('buttonRun');
  const buttonrefother = React.useRef('buttonOthe');
  const [loading, setLoading] = React.useState(false);
  const token = useSelector(state => state.auth.token);
  const UniverseSummary = useSelector(state => state.summary['Universe Builder']);
  const universeBuilderState = useSelector(state => state.summary.universeBuilderOperations);
  const universebuilderModalsave = ConfigYAML.jsonPath('$.universe_builder').sections[2];
  const dispatch = useDispatch();
  const apiData = useSelector(state => state.apiData);
  const userdata = useSelector(state => state.auth.userdata);
  const universesTemplates = _.get(apiData, 'apiData.[Universes]', {});
  const allUniverses = _.get(apiData, 'apiData.[AllUniverses]', {});
  const modalCustomStyle = {
    overflow: 'none',
  };
  const history = useHistory();
  const returnAuthor = () => {
    return `${userdata.first_name || ''} ${userdata.last_name || ''}`;
  };

  React.useEffect(() => {
    setUniverseSavepen(props.viewModal);
  }, [props]);

  const validateName = () => {
    if (name === '' || name === undefined) {
      toast.error(emptyUniverseNameError, { toastId: 'emptyName' });
      return false;
    }
    if (allUniverses?.data?.filter(m => m.name.toUpperCase() === name.toUpperCase()).length > 0) {
      toast.error(sameNameError, { toastId: 'duplicatedName' });
      return false;
    }
    return true;
  };

  const handleModal = value => {
    setUniverseSavepen(value);
    props.closeModalPreview();
  };

  const getUniverseTemplate = () => {
    const selectedUniverse = universesTemplates.data.filter(
      u => u.id === universeBuilderState.filterUniverse.base_universe_id,
    );
    if (selectedUniverse.length > 0) return selectedUniverse[0];
    return {};
  };

  const saveUniverse = (id, goTo) => {
    setLoading(id);
    if (validateName()) {
      if (loading) {
        return;
      }
      if (universeBuilderState.constituents >= 6000) {
        setBigUniverse(true);
      }
      const saveData = {
        name_universe: name,
        filters: universeBuilderState.filterUniverse,
        front_parameters: UniverseSummary,
      };
      const dataComponent = getDataComponents.filter(
        v => v.key === 'saveUniverse' && v.type === 'UniverseFilter' && v.title === 'saveUniverse',
      )[0];
      if (typeof dataComponent !== 'undefined') {
        dataComponent
          .getUrlData(saveData, token)
          .then(r => {
            if (r.status === 201) {
              const universe = {
                ...{
                  id: '',
                  name: '',
                  author: '',
                  constituents: '',
                  revision_type: null,
                  revision_day: null,
                  created_at: moment().format('YYYY-MM-DD'),
                  updated_at: moment().format('YYYY-MM-DD'),
                },
                ...getUniverseTemplate(),
                ...saveData,
                ...r.data.universe,
                ...{ name },
                ...{ author: returnAuthor() },
              };
              const universeOrder = {
                id: universe.id,
                name: universe.name,
                author: universe.author,
                constituents: universe.constituents,
                revision_type: universe.revision_type,
                revision_day: universe.revision_day,
                created_at: moment().format('YYYY-MM-DD'),
                updated_at: moment().format('YYYY-MM-DD'),
              };
              allUniverses.data.push(universeOrder);
              dispatch(setApiData(allUniverses));
              handleModal(false);
              if (goTo) history.push(goTo);
              dispatch(
                setApiData({
                  title: 'modal',
                  data: { open: true, text: 'Universe created successfully' },
                }),
              );
              setBigUniverse(false);
              setLoading(false);
              setUniverseSavepen(false);
              dispatch({
                type: SummaryConstants.RESET,
              });
              dispatch(resetUniverse());
              dispatch(resetApiDataAttribute('Review Date'));
              dispatch(discardBacktest());
              dispatch({
                type: IndexConstants.RESET,
              });
              dispatch(cleanCache(token));
            }
          })
          .catch(e => {
            toastInterceptor(e);
            setTimeout(() => {
              setBigUniverse(false);
              setLoading(false);
            }, 1000);
            console.info(e);
          });
      }
    } else {
      setLoading(false);
    }
  };

  const onChange = (item, value) => {
    setName(value);
  };

  const handleKeyDownSaveUniverse = keyValue => {
    if (keyValue === 'Enter') {
      buttonrefsave.current.focus();
      buttonrefsave.current.click();
    }
  };

  React.useEffect(() => {
    window.addEventListener('keydown', e => {
      if (e.key === 'Enter' && buttonrefsave.current) {
        buttonrefsave.current.focus();
        buttonrefsave.current.click();
      }
    });
  }, []);

  return (
    <BitaModal
      isModalOpen={UniverseSavepen}
      customStyle={modalCustomStyle}
      setModalState={props.closeSaveUniverse}
      closeInOverlay={!loading}
    >
      <div style={{ width: '370px', height: '340px' }}>
        <div style={{ height: '340px' }}>
          {!loading && (
            <span onClick={() => props.closeSaveUniverse()} className="closeModalButton">
              &times;
            </span>
          )}
          <header className="headerpaths">
            <div>
              <IconsU />
              <span>
                {savingBigUniverse
                  ? 'Saving Universe'
                  : universebuilderModalsave.title.toUpperCase()}
              </span>
            </div>
            <Icons.Avanti style={{ marginRight: '10px', transform: 'scale(1.1)' }} />
          </header>
          <div className="descriptionModal">
            {!savingBigUniverse ? universebuilderModalsave.text : ''}
          </div>
          <div className="contentModalSave">
            {!savingBigUniverse &&
              Object.values(universebuilderModalsave.components).map(component => {
                if (component.action === 'closeModal') {
                  return (
                    <div
                      style={{
                        width: '48%',
                        opacity: loading && loading !== component.id ? 0.5 : 1,
                      }}
                    >
                      <Builder
                        {...component}
                        onclick={() => {
                          if (!loading) {
                            saveUniverse(component.id, component.goto);
                          }
                        }}
                        autofocus={component.title === 'Save & Go Back to Home'}
                        Ref={
                          component.title === 'Save & Go Back to Home'
                            ? buttonrefsave
                            : buttonrefother
                        }
                        loading={loading}
                      />
                    </div>
                  );
                }
                return (
                  <Builder
                    {...component}
                    onChange={onChange}
                    onBlur={validateName}
                    onKeyPress={evt => handleKeyDownSaveUniverse(evt.key)}
                  />
                );
              })}
            {savingBigUniverse && (
              <div style={{ height: 150 }}>
                <BitaSpinner />
                <span className="descriptionModal">
                  Your Universe has more than 6K constituents, saving it in the platform will take
                  some minutes. Please wait until it&apos;s saved.
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </BitaModal>
  );
};

export default SaveUniverse;
