import Att1 from '../static/img/Placeholders/Att-1.png';
import Att2 from '../static/img/Placeholders/Att-2.png';
import Att3 from '../static/img/Placeholders/Att-3.png';
import Att4 from '../static/img/Placeholders/Att-4.png';
import Performance from '../static/img/Placeholders/Performance.png';
import StyleTilts from '../static/img/Placeholders/Style_Tilts.png';
import Weight1 from '../static/img/Placeholders/Weight-1.png';
import Weight2 from '../static/img/Placeholders/Weight-2.png';
import PerformanceStats from '../static/img/Placeholders/PerformanceStats.png';
import { ReactComponent as Card1 } from '../static/img/Placeholders/1.svg';
import { ReactComponent as Card2 } from '../static/img/Placeholders/2.svg';
import { ReactComponent as Card3 } from '../static/img/Placeholders/3.svg';
import { ReactComponent as Card4 } from '../static/img/Placeholders/4.svg';
import { ReactComponent as Card5 } from '../static/img/Placeholders/5.svg';
import { ReactComponent as Card6 } from '../static/img/Placeholders/6.svg';

export const Placeholders = {
  Att1,
  Att2,
  Att3,
  Att4,
  Performance,
  PerformanceStats,
  StyleTilts,
  Weight1,
  Weight2,
  Card1,
  Card2,
  Card3,
  Card4,
  Card5,
  Card6,
};
