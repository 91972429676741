import React from 'react';
import { useHistory } from 'react-router';
import BitaButton from '../../../bitaComponents/BitaButton/BitaButton';
import BitaModal from '../../../bitaComponents/BitaModal/BitaModal';
import { ReactComponent as Logo } from '../../../static/img/Icons/BITA.svg';
import style from '../PortfolioBasketCalculation.module.scss';

const styleClose = {
  position: 'absolute',
  fontSize: '30px',
  fontWeight: 'bold',
  top: '-10px',
  right: '0',
  cursor: 'pointer',
  padding: '10px',
  boxSizing: 'border-box',
};

const SaveSuccessBasketModal = ({ showModal, setShowModal, data, setData, setFiles }) => {
  const history = useHistory();
  return (
    <BitaModal
      isModalOpen={showModal}
      setModalState={() => {
        setShowModal(false);
        setShowModal(false);
        setData(null);
        setFiles('');
      }}
    >
      <div style={{ width: ' 600px ', minHeight: '300px' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: '10px 0',
          }}
        >
          <span
            onClick={() => {
              setShowModal(false);
              setShowModal(false);
              setData(null);
              setFiles('');
            }}
            style={styleClose}
          >
            &times;
          </span>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div className={style.modalTextTitle}>Submission successfully completed!</div>
          <div style={{ display: 'flex', margin: '5%', fontSize: '20px' }}>
            <Logo style={{ width: '100px', height: '100px', marginRight: '20px' }} />
            <div>
              <p> Submission ID: {data?.id}</p>
              <p> Submission Status: Transmitted to BITA ACE </p>
              <p> Ticker: {data?.symbol}</p>
              {data?.starting_value && <p> Start Value: {data?.starting_value}</p>}
              {data?.currency && <p> Currency: {data?.currency}</p>}
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', marginTop: '20px' }}>
          <BitaButton primaryWhite onClick={() => history.push('/')} height="35px" width="200px">
            Go to Home
          </BitaButton>
          <BitaButton
            primaryWhite
            onClick={() => {
              setShowModal(false);
              setData(null);
              setFiles('');
            }}
            height="35px"
            width="200px"
          >
            Submit another basket
          </BitaButton>
        </div>
      </div>
    </BitaModal>
  );
};

export default SaveSuccessBasketModal;
