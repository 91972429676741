import React from 'react';
import 'simplebar'; // or "import SimpleBar from 'simplebar';" if you want to use it manually.
import 'simplebar/dist/simplebar.css';
import BitaButton from '../../../bitaComponents/BitaButton/BitaButton';
import BitaCheckbox from '../../BitaCheckbox/BitaCheckbox';
import '../ComponentsSettings.css';
import { remove_option } from '../../../utils/utils';

const ContentUser = ({ user, options }) => {
  return (
    <div className="contentUser">
      <div className="icon">{`${
        user.first_name ? user.first_name.substr(0, 1) : user.user.substr(0, 1)
      }${user.last_name ? user.last_name.substr(0, 1) : ''}`}</div>
      <div className="userName">{user.first_name ? user.first_name : user.user}</div>
      {options.map(option => (
        <div key={option.value}>
          <BitaCheckbox
            label={option.value}
            checked={
              (user.type_user === 'READ' && option.value === 'Read') ||
              user.type_user === 'ADMIN' ||
              (user.type_user === 'CREATE' &&
                (option.value === 'Read' || option.value === 'Create'))
            }
            handleChange={checked => {
              if (checked && option.onClick) {
                option.onClick();
              }
            }}
          />
        </div>
      ))}
    </div>
  );
};

const UserManagement = ({
  users = [],
  options = [],
  actions = [],
  onApply = Function.prototype,
  disabled = false,
}) => {
  return (
    <div className="UserManagement">
      <>
        <div data-simplebar style={{ height: '395px', overflow: 'auto' }}>
          <div>
            {users.length > 0 ? (
              users.map(user => <ContentUser key={user.user} user={user} options={options} />)
            ) : (
              <div> You do not have authorization for this </div>
            )}
          </div>
        </div>
        <div className="contentBotones">
          {actions
            .filter(act => remove_option(!act.disabled))
            .map(action => (
              <BitaButton
                key={action.title}
                width="220px"
                height="35px"
                primary
                disabled={action.disabled}
                onClick={() => action.onClick && action.onClick()}
              >
                {action.title}
              </BitaButton>
            ))}
          {remove_option(false) && (
            <BitaButton width="120px" height="35px" primary onClick={onApply} disabled>
              Apply
            </BitaButton>
          )}
        </div>
      </>
    </div>
  );
};

export default UserManagement;
