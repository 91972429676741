import * as ACTIONS from '../../constants/common-constants';

const initialState = {
  subheader: '',
};

function commonReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.SUBHEADER_UPDATE: {
      return {
        ...state,
        subheader: action.payload,
      };
    }
    case ACTIONS.SUBHEADER_APPEND: {
      return {
        ...state,
        subheader: state.subheader ? state.subheader.concat(' · ', action.payload) : action.payload,
      };
    }
    case ACTIONS.SUBHEADER_RESET: {
      return {
        ...state,
        subheader: '',
      };
    }
    case ACTIONS.SUBHEADER_POP: {
      const lastIndex = state.subheader.lastIndex('.');
      return {
        ...state,
        subheader: state.subheader.substring(0, lastIndex),
      };
    }
    default:
      return state;
  }
}

export default commonReducer;
