// import lodash from 'lodash';
import * as ACTIONS from '../../constants/universe-constants';

const initialState = {
  selectedUniverse: {},
};

const universeReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SELECT_UNIVERSE:
      return { ...state, selectedUniverse: action.payload };
    case ACTIONS.CLEAR_UNIVERSE:
      return { ...state, selectedUniverse: null };
    case ACTIONS.ADD_UNIVERSE:
      return state;
    case ACTIONS.UPDATE_UNIVERSE:
      return state;
    case ACTIONS.DELETE_UNIVERSE:
      return state;
    default: {
      return state;
    }
  }
};

export default universeReducer;
