/* eslint-disable func-names */
import React from 'react';
import _ from 'lodash';
import BitaCharts from '../../../../../bitaComponents/BitaCharts/BitaCharts';
import { contributionsTheme } from '../../../../../bitaComponents/BitaCharts/Themes';
import { capitalizeText } from '../../../../../utils/utils';

export default ({ indexStats, index }) => {
  const deleteNullValues = obj => {
    const propNames = Object.getOwnPropertyNames(obj);
    for (let i = 0; i < propNames.length; i += 1) {
      const propName = propNames[i];
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName];
      }
    }
    return obj;
  };
  const chartStat = deleteNullValues(
    _.get(indexStats, `[${index}].value['Active Factor Risk Breakdown']`, {}),
  );

  const getCategories = () => {
    const list = Object.keys(chartStat)
      .sort(function(a, b) {
        return chartStat[a] - chartStat[b];
      })
      .reverse()
      .slice(0, 20)
      .map(w => capitalizeText(w.replace('_', ' ')));
    return list;
  };
  const reducer = (acc, current) => acc + current;
  const availableData = Object.values(
    _.get(indexStats, `[${index}].value['Active Factor Risk Breakdown']`, [0]),
  ).reduce(reducer);

  const getData = () => {
    if (indexStats && availableData !== 0) {
      return Object.values(chartStat)
        .sort(function(a, b) {
          return a - b;
        })
        .reverse()
        .slice(0, 20);
    }
    return [];
  };
  return (
    <BitaCharts
      theme={contributionsTheme}
      options={{
        xAxis: {
          categories: indexStats && getCategories(),
        },
        series: [
          {
            name: '',
            data: getData(),
          },
        ],
      }}
      containerProps={{ style: { height: '310px', width: '100%' } }}
    />
  );
};
