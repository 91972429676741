/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable camelcase */
/* eslint-disable func-names */
import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import axios from 'axios';
import styles from '../../builder.module.scss';
import { getDataComponents } from '../../../api-data-mapping/dataMap';
import { setApiData } from '../../../state/actions/api-data-actions';
import BitaButton from '../../../bitaComponents/BitaButton/BitaButton';
import BitaSearchBar from '../../../bitaComponents/BitaSearchBar/BitaSearchBar';
import { loaderDataObjectDecorator } from '../../../utils/loading_status_tools';
import { defaultBenchmarks } from '../../../constants/benchmark-default-constants';

const getRowFirstValue = row => {
  if (row && Object.entries(row).length > 0) return Object.values(row)[0];
};

const PlainRadioGroup = ({ options }) => {
  const [selected, setSelected] = useState('');

  return options.map(option => (
    <div key={option}>
      <input
        id={`radio-${option}`}
        type="radio"
        name="radio-filter"
        value={option}
        onChange={() => {
          setSelected(option);
        }}
        checked={selected === option}
      />
      {/* eslint-disable-next-line */}
      <label htmlFor={`radio-${option}`}>{option}</label>
    </div>
  ));
};

const InstrumentSelect = ({
  onChange = Function.prototype,
  data,
  title,
  path,
  data_key = '',
  filter,
  filter_options,
  isasync = false,
  id = '',
  max_instruments_selected,
  show_selected = false,
  external_title,
  selected_default,
  result_searchbar = true,
}) => {
  const stateApiData = useSelector(state => state.apiData.apiData);
  const [selectedRows, setSelectedRows] = useState([]);
  const [searchOptions, setSearchOptions] = useState(null);
  const backtestData = useSelector(state => state.backtest);
  const summary = useSelector(state => state.summary);
  const location = useLocation();
  const dispatch = useDispatch();

  // Data from Yaml
  const rows = useMemo(
    () =>
      data && data[0] && typeof data[0].value === 'object'
        ? Object.values(data[0].value)
            .reduce(result => {
              // eslint-disable-next-line no-param-reassign
              result = [];
              let row = {};
              Object.values(data).forEach(column => {
                row = column;
              });
              return result.concat(row);
            }, [])
            .filter(
              row =>
                !selectedRows.some(
                  selected => getRowFirstValue(row) === getRowFirstValue(selected),
                ),
            )
        : [{ label: 'Fetching', value: '' }],
    [data, selectedRows],
  );
  // Data convert options for SearchBar no async
  useEffect(() => {
    let apiData =
      stateApiData &&
      Object.entries(stateApiData).filter(([key, value]) => key === `${data_key}_${title}`)[0];
    // Caso puntual searchbar run backtest
    if (apiData === undefined) {
      if (data_key === 'Benchmark & Indexes') {
        const getDataFor = data_key.replace(/\s/g, '').split('&');
        let instrumentList = [];
        // eslint-disable-next-line array-callback-return
        getDataFor.map(dataName => {
          const list = _.get(stateApiData, dataName, {});
          if (list.data) {
            let dataList = list.data;
            if (dataName === 'Benchmark') {
              dataList = list.data.Benchmark;
            }
            instrumentList = instrumentList.concat(
              dataList.map(row => {
                return {
                  'Instrument name': row.name,
                  'Instrument type': row.index_type
                    ? row.index_type
                        .replace('regular', 'INDEX')
                        .replace('structured_index', 'STRUCTURED INDEX')
                        .replace('optimized_index', 'OPTIMIZED INDEX')
                    : 'ETF',
                  id: row.id,
                };
              }),
            );
          }
        });
        apiData = [data_key, instrumentList];
      }
    }
    if (!rows || !rows.length) return [];
    if (apiData !== undefined && apiData[0] !== 'Benchmark') {
      const resApiData = apiData[1].map(value => ({
        label: `${Object.values(value)[0]} - ${Object.values(value)[1]}`,
        value,
      }));
      setSearchOptions(resApiData);
    }
    if (!apiData && rows) setSearchOptions(rows);
    // eslint-disable-next-line
  }, [stateApiData, rows]);

  // eslint-disable-next-line

  // Data from Api  async
  const apiData = () => {
    if (data_key && data_key === 'Benchmark & Indexes') {
      (async function() {
        const dataComponent = getDataComponents.filter(
          v => v.key === data_key && v.title === title,
          // v => v.key === data_key && v.title === title && v.type === type,
        )[0];
        if (typeof dataComponent !== 'undefined') {
          const res = await loaderDataObjectDecorator(dataComponent, dispatch).getUrlData();
          dispatch(
            setApiData({
              title: `${data_key}_${dataComponent.title}`,
              data: res.data,
            }),
          );
        }
      })();
    }
  };

  useEffect(() => {
    if (!isasync) apiData();
    // eslint-disable-next-line
  }, []);
  const getLoadOptionsData = async (dataComponent, inputValue, cancelToken) => {
    let response;
    if (data_key !== 'Securities SearchBar') {
      response = await loaderDataObjectDecorator(dataComponent, dispatch).getUrlData(inputValue);
      return response;
    }
    response = await loaderDataObjectDecorator(dataComponent, dispatch).getUrlData(
      _.get(summary, ['universeBuilderOperations', 'universe', 'id'], false),
      cancelToken,
      inputValue,
    );
    return response;
  };
  // Data from Api convert options for SearchBar no async
  let cancelToken;
  const loadOptions = inputValue =>
    new Promise(resolve => {
      if (inputValue.length >= 3) {
        if (data_key) {
          (async function setData() {
            const dataComponent = getDataComponents.filter(
              v => v.key === data_key && v.title === title,
              // v => v.key === data_key && v.title === title && v.type === type,
            )[0];
            if (typeof dataComponent !== 'undefined') {
              if (typeof cancelToken !== typeof undefined) {
                cancelToken.cancel('Operation canceled due to new request.');
              }
              cancelToken = axios.CancelToken.source();
              try {
                const res = await getLoadOptionsData(dataComponent, inputValue, cancelToken);
                const resApiData = res.data.map(value => ({
                  label: `${value.figi} - ${value.security_name}`,
                  value: {
                    'Company Name: ': value.security_name,
                    'FIGI: ': value.figi,
                  },
                  data: { value: value.security_name, id: value.m_security_id },
                }));
                dispatch(
                  setApiData({
                    title: `${data_key}_${dataComponent.title}`,
                    data: res.data,
                  }),
                );
                resolve(resApiData);
              } catch (e) {
                console.info('Error at search bar', e);
              }
            }
          })();
        }
      } else {
        resolve([]);
      }
    });

  const onSearchChange = useCallback(
    selected => {
      if (max_instruments_selected && max_instruments_selected === 1) {
        if (selected.value !== 'Fetching') {
          setSelectedRows(selected);
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (typeof selected.value === 'string') {
          if (selected.value !== 'Fetching') {
            setSelectedRows(
              selectedRows.concat(rows.find(row => getRowFirstValue(row) === selected.value)),
            );
          }
        } else if (id === 'securities') {
          if (!selectedRows.find(x => x.label === selected.label)) {
            setSelectedRows(prev => [...prev, selected]);
          }
        } else {
          setSelectedRows(prev => [...prev, selected]);
        }
      }
    },
    [setSelectedRows, selectedRows, rows],
  );

  useEffect(() => {
    if (selected_default) {
      onChange(id, selected_default);
      setSelectedRows(selected_default);
    }
  }, [selected_default]);

  useEffect(() => {
    if (selectedRows.length > 0 && title !== 'Select Benchmark') {
      onChange(
        `${path}${id}`,
        selectedRows.map(row => row.data),
      );
    } else if (id === 'Benchmark' && selectedRows.value) {
      if (selectedRows.value.id) {
        onChange(id, selectedRows.value);
      } else {
        onChange(id, selectedRows !== [] ? selectedRows : selected_default);
      }
    }
    if (id === 'securities') {
      onChange(
        `${path}${id}`,
        selectedRows.map(row => row.data),
      );
    }
    // eslint-disable-next-line
  }, [selectedRows]);

  useEffect(() => {
    if (
      id === 'Benchmark' &&
      backtestData?.runBacktestForm?.riskModelSelected?.data &&
      location?.pathname.startsWith('/index-builders')
    ) {
      const _default =
        defaultBenchmarks[backtestData?.runBacktestForm?.riskModelSelected?.data?.value];
      if (
        searchOptions &&
        searchOptions.filter(r => r.value?.['Instrument name'] === _default).length > 0
      ) {
        setSelectedRows(searchOptions.filter(r => r.value?.['Instrument name'] === _default)[0]);
        onChange(id, searchOptions.filter(r => r.value?.['Instrument name'] === _default)[0].value);
      }
    }
  }, [backtestData?.runBacktestForm?.riskModelSelected?.data]);

  return (
    <div key={title} className="builderSearchBar">
      <div className={styles.searchTop}>
        {filter ? (
          <div className={styles.searchFilterOptions}>
            <PlainRadioGroup options={Object.values(filter_options)} />
          </div>
        ) : external_title ? (
          <div className="externalTitle"> {external_title.toUpperCase()}</div>
        ) : null}
        <div className={styles.searchInput}>
          {isasync ? (
            <BitaSearchBar
              loadOptions={loadOptions}
              onChange={onSearchChange}
              isasync={isasync}
              value={show_selected ? selectedRows.label : null}
            />
          ) : (
            <BitaSearchBar
              searchOptions={searchOptions}
              value={show_selected ? selectedRows.label : null}
              onChange={onSearchChange}
            />
          )}
        </div>
      </div>
      {result_searchbar && id !== 'Benchmark' && selectedRows && selectedRows.length > 0 ? (
        <div className={styles.searchResultsContainer}>
          {selectedRows.map((row, index) => (
            <div
              key={`row_${index}`}
              className={`${styles.searchResult} searchResultColumn builderSearchColumn`}
            >
              {row.value
                ? Object.entries(row.value).map(([column, value]) => (
                    <div
                      key={`${column}_${value}`}
                      className={`${styles.searchResultColumn} searchResultColumn `}
                    >
                      <b>{column}</b>
                      {value}
                    </div>
                  ))
                : Object.entries(row).map(([column, value]) => (
                    <div
                      key={`${column}_${value}`}
                      className={`${styles.searchResultColumn} searchResultColumn `}
                    >
                      <b>{column}</b>
                      {value}
                    </div>
                  ))}
              <div>
                <BitaButton
                  onClick={() => {
                    if (selectedRows.length > 1) {
                      setSelectedRows(
                        selectedRows.filter(
                          selectedRow => getRowFirstValue(row) !== getRowFirstValue(selectedRow),
                        ),
                      );
                    } else if (id === 'securities') setSelectedRows([]);
                  }}
                  primaryWhite
                  className={styles.deleteResult}
                >
                  {id === 'securities' ? '-' : 'Delete'}
                </BitaButton>
              </div>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default InstrumentSelect;
