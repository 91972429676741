import React, { useState } from 'react';
import classnames from 'classnames';
import styles from '../../builder.module.scss';
import Switcher from '../../../bitaComponents/Switcher/Switcher';
import { ComponentGenerator } from '../Components';

export const BuilderSwitchComponent = ({ labelon, labeloff, onclick, path, onChange }) => {
  const [checked, setChecked] = useState(false);
  const handleChange = value => {
    setChecked(value);
    const name = checked ? labelon : labeloff;
    if (onChange) onChange(path, { Switch: name });
  };

  return (
    <div className={`${styles.builderSwitchComponentContainer} builderSwitchComponentContainer `}>
      <div className={classnames(styles.optimizationSwitchComponentTop)}>
        <b>{labeloff}</b>
        <span style={{ display: 'inline-block', marginRight: '30px' }}>
          <Switcher.Switch onChange={value => handleChange(value)} checked={checked} />
        </span>
        <b>{labelon}</b>
      </div>
      {onclick &&
        checked &&
        Object.values(onclick).map((comp, index) => (
          <div className={`${styles.builderSwitchContent} builderSwitchContent `} key={index}>
            <ComponentGenerator
              {...comp}
              path={`${path}.${comp.title || comp.name || comp.label}`}
              onChange={onChange}
            />
          </div>
        ))}
    </div>
  );
};
