import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
} from '@tanstack/react-table';
import 'simplebar';
import 'simplebar/dist/simplebar.css';
import { post } from 'axios';
import { BiSolidError, BiX } from 'react-icons/bi';
import { FaCheckSquare } from 'react-icons/fa';

import { toast } from 'react-toastify';
import { URL_CONSTANTS } from '../../../api-data-mapping/url-constants';
import { CURRENCIES_ARRAY } from '../utils/constants';
import BitaInput from '../../../components/BitaInput/BitaInput';
import BitaSelect from '../../../components/BitaSelect/BitaSelect';
import BitaButton from '../../../bitaComponents/BitaButton/BitaButton';
import { hasValidUBSOnly } from '../../../utils/utils';

import BitaCheckbox from '../../../components/BitaCheckbox/BitaCheckbox';

const reCalculateCoverage = (tokenUser, data) => {
  return post(URL_CONSTANTS.COVERAGE_REQUEST_UPDATE_URL, data, {
    headers: { Authorization: `Bearer ${tokenUser}` },
  });
};

const TableCell = ({ getValue, row, column, table, index }) => {
  const token = useSelector(state => state.auth.token);
  const initialValue = getValue();
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const onBlur = () => {
    if (table.options.meta?.updateData) {
      table.options.meta.updateData(row.index, column.id, parseFloat(value));
      table.options.meta.setErrorReferenceData(parseFloat(value) === 0);
    }
  };

  return (
    <div className={`${column.id === 'type' ? 'info-icon-live' : ''}`}>
      {column.id === 'isin' ? (
        <div style={{ display: 'flex' }}>
          {index === 0 && (
            <BitaCheckbox
              checked={table.options.meta.constituentsDelete
                ?.map(item => item.isin ?? item.sedol)
                ?.includes(value ?? row.original?.sedol)}
              handleChange={checked => {
                table.options.meta.updateCheckbox(row.original, checked);
              }}
            />
          )}
          {value?.includes('CASH_') ? (
            <BitaSelect
              data={CURRENCIES_ARRAY.map(item => {
                return { label: `CASH_${item}`, value: `CASH_${item}` };
              })}
              clearable={false}
              value={{ label: value, value }}
              handleSelection={val => {
                if (table.options.meta?.updateData) {
                  table.options.meta.updateData(row.index, column.id, val?.value);
                }
              }}
            />
          ) : (
            value ?? '-'
          )}
        </div>
      ) : column.id === 'type' ? (
        value === undefined || value === 'with_coverage' ? (
          <FaCheckSquare className="icon-info" />
        ) : (
          <BiSolidError className="icon-error" />
        )
      ) : column.id === 'shares' && row.original.isin ? (
        <BitaInput
          style={{ background: 'transparent' }}
          value={value}
          type="number"
          inputStyle={value === 0 ? 'needValidation' : ''}
          handleChange={val => setValue(val.replace(/,/g, ''))}
          onBlur={onBlur}
        />
      ) : column.id === 'exchange' && value && !value?.includes('CASH') ? (
        <BitaSelect
          data={table.options.meta?.listMic.map(item => {
            return { label: item, value: item };
          })}
          clearable={false}
          value={{ label: value, value }}
          handleSelection={val => {
            if (table.options.meta?.updateData) {
              table.options.meta.updateData(row.index, column.id, val?.value);
            }
            reCalculateCoverage(token, [
              {
                isin: row.original.isin,
                exchange: val?.value,
              },
            ])
              .then(rsp => {
                if (!rsp.data.data.full_coverage) {
                  toast.error(
                    'The MIC you recently edited does not have coverage for the specified ISIN',
                  );
                }
                table.options.meta.updateData(
                  row.index,
                  'type',
                  rsp.data.data.full_coverage ? 'with_coverage' : 'request_coverage',
                );
              })
              .catch(error => {
                console.info(error);
              });
          }}
        />
      ) : column.id === 'sedol' && true ? (
        <div style={{ display: 'flex' }}>
          {index === 0 && (
            <BitaCheckbox
              checked={table.options.meta.constituentsDelete
                ?.map(item => item.isin ?? item.sedol)
                ?.includes(row.original.isin ?? row.original?.sedol)}
              handleChange={checked => {
                table.options.meta.updateCheckbox(row.original, checked);
              }}
            />
          )}
          {value ?? '-'}
        </div>
      ) : (
        value ?? '-'
      )}
      {column.id === 'type' && !(value === undefined || value === 'with_coverage') && (
        <div className="tooltip error-tooltip">
          <div>
            <BiX className="tooltip-icon icon-error" />
          </div>
          <span>
            {value === 'not_found'
              ? `This instrument is not covered by BITA's Reference Database. 
              You can request coverage using the button below, 
              " Request Coverage." We will receive the request, process it, 
              and communicate with you via e-mail as soon as possible.`
              : `This instrument is not under the BITA Calculation platform pricing
              coverage. You can request the coverage using the button below " Request Coverage ". We will receive the request, process it, and
              communicate with you via e-mail as soon as possible.`}
          </span>
        </div>
      )}
    </div>
  );
};

// Helper de columnas
const columnHelper = createColumnHelper();

export const Table = ({
  rows,
  setMetadata,
  setConstituentsDelete,
  constituentsDelete,
  listMic,
  pagination = {},
  setPagination,
  setErrorReferenceData,
  withSedol,
}) => {
  const userdata = useSelector(state => state.auth.userdata);
  const [data, setData] = useState(() => [...(rows ?? [])]);

  const itemsColumns = Object.keys({ ...data?.[0], type: data?.[0] ?? '' } ?? {});

  const headerColumn = {
    exchange: 'MIC',
    isin: 'Constituents',
    type: ' ',
  };

  const columns = (itemsColumns?.includes('sedol') && hasValidUBSOnly(userdata.id_company)
    ? ['sedol', ...itemsColumns.filter(item => item !== 'sedol')]
    : ['isin', ...itemsColumns.filter(item => item !== 'isin')]
  ).map(item => {
    return columnHelper.accessor(item, {
      header: headerColumn?.[item] ? headerColumn?.[item] : item,
      cell: TableCell,
    });
  });

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    state: {
      pagination,
    },
    meta: {
      constituentsDelete,
      listMic,
      setErrorReferenceData,
      updateData: (rowIndex, columnId, value) => {
        setData(old =>
          old.map((row, index) => {
            if (
              index === rowIndex &&
              columnId === 'isin' &&
              row.isin?.includes('CASH_') &&
              row?.sedol
            ) {
              return {
                ...old[rowIndex],
                sedol: value,
                [columnId]: value,
              };
            }
            if (index === rowIndex) {
              return {
                ...old[rowIndex],
                [columnId]: value,
              };
            }
            return row;
          }),
        );
        setMetadata(prev => {
          return {
            ...prev,
            meta_reference_data: prev?.meta_reference_data.map((row, index) => {
              if (
                index === rowIndex &&
                columnId === 'isin' &&
                columnId === 'isin' &&
                row.isin?.includes('CASH_') &&
                row.sedol
              ) {
                return {
                  ...prev?.meta_reference_data?.[rowIndex],
                  sedol: value,
                  [columnId]: value,
                };
              }
              if (index === rowIndex) {
                return {
                  ...prev?.meta_reference_data?.[rowIndex],
                  [columnId]: value,
                };
              }
              return row;
            }),
          };
        });
      },
      updateCheckbox: (value, checked) => {
        if (checked) {
          setConstituentsDelete(prev => [...prev, value]);
        } else {
          setConstituentsDelete(prev => prev.filter(item => item !== value));
        }
      },
    },
  });

  return (
    <div className="basket-table">
      <table data-simplebar style={{ height: '560px', width: '100%' }}>
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map(row => (
            <tr
              key={row.id}
              style={{
                background:
                  !row?.original?.type || row?.original?.type === 'with_coverage'
                    ? ''
                    : 'rgba(243, 87, 87, 0.53)',
              }}
            >
              {row.getVisibleCells().map((cell, index) => (
                <td key={cell.id} className={`table-basket-${cell.column.id}`}>
                  {flexRender(cell.column.columnDef.cell, { ...cell.getContext(), index })}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
        <BitaButton
          style={{ width: '35px', height: '25px', marginRight: '5px' }}
          primaryWhite
          disabled={!table.getCanPreviousPage()}
          onClick={() => {
            table.firstPage();
          }}
        >
          {'<<'}
        </BitaButton>
        <BitaButton
          style={{ width: '35px', height: '25px', marginRight: '5px' }}
          primaryWhite
          disabled={!table.getCanPreviousPage()}
          onClick={() => {
            table.previousPage();
          }}
        >
          {'<'}
        </BitaButton>
        <BitaButton
          style={{ width: '35px', height: '25px', marginRight: '5px' }}
          primaryWhite
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          {'>'}
        </BitaButton>
        <BitaButton
          style={{ width: '35px', height: '25px', marginRight: '5px' }}
          primaryWhite
          onClick={() => table.lastPage()}
          disabled={!table.getCanNextPage()}
        >
          {'>>'}
        </BitaButton>
        <span style={{ marginLeft: '10px' }}>
          {table.getState().pagination.pageIndex + 1}/{table.getPageCount().toLocaleString()}
        </span>
      </div>
    </div>
  );
};
