import React from 'react';
// eslint-disable-next-line import/no-cycle
import { Components } from '../Components';
import { ComponentErrorBoundary } from './ComponentErrorBoundary';

export const ComponentGenerator = props => {
  const componentBuilded = React.createElement(Components[props.type], {
    key: `${props.type}_${props.title || 'null'}`,
    ...props,
  });

  return <ComponentErrorBoundary>{componentBuilded}</ComponentErrorBoundary>;
};
