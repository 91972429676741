import React, { useState } from 'react';
import https from 'https';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import axios from 'axios';
import Builder from '../../../../builder/Builder';
import { ReactComponent as Logo } from '../../../../static/img/Icons/BITA.svg';
import BitaButton from '../../../../bitaComponents/BitaButton/BitaButton';
import BitaSpinner from '../../../../components/BitaSpinner/BitaSpinner';
import { toastInterceptor } from '../../../../utils/utils';
import { Icons } from '../../../../constants/icons';

export const BitaContentWidgetBuilder = props => {
  const { widget } = props;
  const token = useSelector(state => state.auth.token);
  const [sendData, setSendData] = useState(false);
  const [sendLoading, setSendLoading] = useState(false);
  const [stepData, setStepData] = useState({});
  const date = new Date();
  const day = `${date.getDate()}`.padStart(2, '0');
  const month = `${date.getMonth() + 1}`.padStart(2, '0');
  const year = date.getFullYear();

  const onChange = (path, value) => {
    setStepData(old => ({ ...old, ...value }));
  };

  const sendDataActions = (tokenUser, data) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/deploy_ace`, data, {
      headers: { Authorization: `Bearer ${tokenUser}` },
    });
  };

  return (
    <div>
      <div
        style={{
          display: sendData || sendLoading ? 'none' : 'flex',
          flexDirection: 'column',
        }}
      >
        <div className="text-title">{props.widget.text}</div>
        <div className="content">
          <div data-simplebar className="content_action_basket scrollParameters">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Icons.account style={{ marginRight: '10px' }} />
              {widget.title}
            </div>
            <div style={{ display: sendData ? 'none' : '' }}>
              {Object.values(widget.components).map(component => (
                <Builder
                  path={component.title}
                  universe="Action Calculation"
                  onChange={onChange}
                  widgetData={stepData}
                  {...component}
                />
              ))}
            </div>
          </div>
        </div>
        <BitaButton
          width={300}
          onClick={() => {
            if (
              !(
                stepData?.['Start parameters']?.['Start value'] &&
                stepData?.['Start parameters']?.Ticker
              )
            ) {
              toast(
                `${
                  !stepData?.['Start parameters']?.['Start value'] ? 'Start value' : 'Ticker'
                } is required for the next action`,
              );
            } else {
              const data = {
                symbol: stepData?.['Start parameters']?.Ticker,
                starting_value: Number(
                  stepData?.['Start parameters']?.['Start value']?.replace(/,/g, ''),
                ),
              };
              sendDataActions(token, data)
                .then(rsp => {
                  setSendLoading(true);
                  setStepData({});
                  setTimeout(() => {
                    setSendLoading(false);
                    setSendData(true);
                  }, 3000);
                })
                .catch(error => {
                  toastInterceptor(error);
                });
            }
          }}
          primary
          style={{ margin: 'auto' }}
        >
          Apply parameters and send to calculation
        </BitaButton>
      </div>
      <div
        style={{
          display: !sendData ? 'none' : 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div className="text-title">Submission successfully completed!</div>
        <div style={{ display: 'flex', margin: '5%', fontSize: '20px' }}>
          <Logo style={{ width: '100px', height: '100px', marginRight: '20px' }} />

          <div>
            <p>Submission timestamp: {`${year}-${month}-${day}`}</p>
            <p>{props.widget.typecontent} Submission ID: BKT0014</p>
            <p>{props.widget.typecontent} Submission Status: Transmitted to BITA ACE </p>
          </div>
        </div>
      </div>
      {sendLoading && (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '20%' }}>
          <div style={{ width: '150px' }}>
            <BitaSpinner loading />
          </div>
        </div>
      )}
    </div>
  );
};
