import React, { useMemo, useState, useEffect } from 'react';
import { uniqueId } from 'lodash';
import styles from '../../builder.module.scss';
import { ComponentGenerator } from '../Components';

export const BuilderRadioGroupComponent = ({
  options = [],
  onclick_option,
  onChange,
  path = '',
  default_option = false,
  id,
  option_text,
  widgetData,
}) => {
  const [selected, setSelected] = useState(default_option || '');
  const [components, setComponents] = useState([]);
  const [uniqueName] = useState(uniqueId('radio-filter-'));
  let localPath = `${path}${id}`;
  if (path !== '' && id) {
    localPath = `${path}${id}`;
  }

  const data = useMemo(() => {
    if (default_option && onChange) {
      onChange(localPath, default_option);
    }
    if (onclick_option) {
      const storeOptions = Object.values(options).map(option => ({
        name: option.value,
        onclick: option.onclick,
        disabled: option.disabled || false,
      }));
      return storeOptions;
    }
    const storeOptions = Object.values(options).map(option => ({
      name: option.value || option,
      disabled: option.disabled || false,
    }));
    return storeOptions;

    // eslint-disable-next-line
  }, [options]);

  useEffect(() => {
    if (!default_option && id === 'universe_data_history') {
      setSelected(widgetData[id]);
    }
  }, [widgetData]);

  return (
    <div className={`${styles.builderRatioButtonsContainer} builderRatioButtonsContainer`}>
      <div className={`${styles.builderRatioButtonsOptions} builderRatioButtonsOptions`}>
        {Object.values(data).map(option => (
          <div
            style={{
              pointerEvents: option.disabled ? 'none' : '',
              opacity: option.disabled ? '0.5' : '',
            }}
          >
            <input
              id={`radio-${uniqueName}-${option.name}`}
              type={data.length === 1 ? 'checkbox' : 'radio'}
              name={uniqueName}
              key={uniqueName}
              value={option.name}
              onChange={e => {
                if (data.length === 1) {
                  if (!e.target.checked) {
                    setSelected(' ');
                    setComponents([]);
                  } else {
                    if (onChange) {
                      onChange(localPath, option.name);
                    }
                    setSelected(option.name);
                    setComponents(option.onclick);
                  }
                } else {
                  if (onChange) {
                    onChange(localPath, option.name);
                  }
                  setSelected(option.name);
                  if (option.onclick) {
                    setComponents(option.onclick);
                  } else {
                    setComponents([]);
                  }
                }
              }}
              checked={selected === option.name}
            />
            <label htmlFor={`radio-${uniqueName}-${option.name}`}>
              {option_text ? `${option.name} ${option_text}` : option.name}
            </label>
          </div>
        ))}
      </div>
      {Object.values(components).map(comp => (
        <ComponentGenerator {...comp} onChange={onChange} path={path} />
      ))}
    </div>
  );
};
