import React from 'react';
import styles from '../../builder.module.scss';
import { IconsModules } from '../../../constants/icons-module';
import { ComponentGenerator } from '../Components';

export const BuilderDefaultComponent = ({
  onApply = Function.prototype,
  onDelete = Function.prototype,
  title,
  components,
  path,
  onChange,
  setterFunction = Function.prototype,
  currentView,
  universe,
}) => {
  return (
    <div className={`${styles.pageContainer} builderDefault `}>
      {title ? (
        <h2 className={styles.defaultPageTitle}>
          <IconsModules.StructureIModule /> {title.toUpperCase()}
        </h2>
      ) : null}
      <div>
        {Object.values(components).map((component, index) => (
          <ComponentGenerator
            {...component}
            path={path}
            onApply={onApply}
            onDelete={onDelete}
            onChange={onChange}
            key={index}
            currentView={currentView}
            universe={universe}
            setterFunction={setterFunction}
          />
        ))}
      </div>
    </div>
  );
};
