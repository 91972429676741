import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { toast } from 'react-toastify';
import BitaButton from '../../../../bitaComponents/BitaButton/BitaButton';
import { IconsModules } from '../../../../constants/icons-module';
import { Icons } from '../../../../constants/icons';
import styles from './IndexBuilderModal.module.scss';
import TabsComponent from './tabsComponent';
import saveMethodology from './saveMethodology';
import { sameNameError, emptyMethNameError } from '../../../../constants/methodologies-constants';
import * as SummaryConstants from '../../../../constants/summary-constants';
import * as IndexConstants from '../../../../constants/index-operations-constants';
// eslint-disable-next-line import/named
import { cleanCache } from '../../../../state/actions/summary-actions';
import { discardBacktest } from '../../../../state/actions/backtest-actions';

function IndexBuilderModal({ summaryData, toggleModal }) {
  const authToken = useSelector(state => state.auth.token);
  const userName = useSelector(state => state.auth.username);
  const [name, setName] = useState('');
  const dispatch = useDispatch();
  const [description, setDescription] = useState('');
  const history = useHistory();
  const apiData = useSelector(state => state.apiData);
  const allMethodologies = _.get(apiData, 'apiData.[Methodologies]', {});

  const routeChange = () => {
    const path =
      summaryData?.['Type Methodology'] === 'Rebalancing and Reconstitution'
        ? '/rebalancing-reconstitution/rebalancing-reconstitution'
        : `/`;
    toggleModal(false);
    history.push(path);
  };

  const validateName = () => {
    if (name === '') {
      toast.error(emptyMethNameError, { toastId: 'emptyName' });
      return false;
    }

    if (
      allMethodologies.data?.filter(m => m.name.toUpperCase() === name.toUpperCase()).length > 0
    ) {
      toast.error(sameNameError, { toastId: 'duplicatedName' });
      return false;
    }
    return true;
  };

  const clear = () => {
    dispatch({
      type: SummaryConstants.RESET,
    });
    dispatch(discardBacktest());
    dispatch({
      type: IndexConstants.RESET,
    });
    dispatch(cleanCache(authToken));
  };

  const handleKeyDownMethodology = keyValue => {
    if (keyValue === 'Enter' && validateName()) {
      saveMethodology(
        name,
        description,
        summaryData,
        authToken,
        routeChange,
        clear,
        dispatch,
        allMethodologies,
        userName,
      );
    }
  };

  return (
    <div className={styles.modalContainer}>
      {/* eslint-disable-next-line */}
      <span onClick={() => toggleModal(false)} className={styles.closeButton}>
        &times;
      </span>
      <div className={styles.titleContainer}>
        <div>
          <IconsModules.MethodologyModule />
          <span style={{ marginLeft: 15 }}>METHODOLOGY COMPLETED</span>
        </div>
        <Icons.Avanti style={{ marginRight: '10px', transform: 'scale(1.1)' }} />
      </div>
      <div className={styles.dividerModal} />

      <div className={styles.tabsContainer}>
        <TabsComponent data={summaryData} />
      </div>
      <div className={styles.nameContainer}>
        <span className={styles.methodologyText}>METHODOLOGY NAME</span>
        <input
          className={styles.methodologyInput}
          value={name}
          onChange={event => setName(event.target.value)}
          type="text"
          maxLength="40"
          onBlur={validateName}
          onKeyPress={evt => handleKeyDownMethodology(evt.key)}
        />
      </div>
      <div className={styles.nameContainer}>
        <span className={styles.methodologyText}>METHODOLOGY DESCRIPTION</span>
        <input
          className={styles.methodologyInput}
          value={description}
          onChange={event => setDescription(event.target.value)}
          type="text"
          onKeyPress={evt => handleKeyDownMethodology(evt.key)}
        />
      </div>
      <div className={styles.saveContainer}>
        <BitaButton
          primary
          width="190px"
          height="30px"
          autofocus
          autofocusfixed
          onClick={() => {
            if (validateName()) {
              saveMethodology(
                name,
                description,
                summaryData,
                authToken,
                routeChange,
                clear,
                dispatch,
                allMethodologies,
                userName,
              );
            }
          }}
        >
          Save Methodology
        </BitaButton>
      </div>
    </div>
  );
}

export default IndexBuilderModal;
