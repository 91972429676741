export const defaultTemplate = () => {
  return {
    'General Rules': {
      Currency: {
        Currency: {
          id: '0',
          value: 'USD',
        },
      },
      'Return calculation': {
        return_calculation: 'Total return',
      },
      'Weighting mechanism': {
        'Weight by': {
          id: 2,
          value: 'Free Float Market Cap',
        },
        weighting_mechanism: 'Traditional',
      },
    },
    'Additional Rules': {
      'Deletion & replacement rules': {
        deletion_replacement_rules: 'Next reconstitution',
      },
    },
    'Rebalancing & Reconstitution': {
      Rebalancing: {
        Day: {
          id: '4',
          value: 'Friday',
        },
        Months: {
          id: '2',
          value: 'March-June-September-December',
        },
        'Ex-Date': {
          id: '2',
          value: '3rd',
        },
        Rebalancing: 'Quarterly',
        'Determination date': {
          id: '10',
          value: 10,
        },
      },
      Reconstitution: {
        Reconstitution: 'Same as Rebalancing',
      },
    },
  };
};

export const sameNameError = 'The name is already in use. Please choose another one';
export const emptyUniverseNameError = 'Please, enter the name of your universe.';
export const emptyMethNameError = 'Please, enter the name of your methodology.';

export const nameSectorMethodologyCap = {
  'GICS Sectors': 'gics_sector',
  'GICS Industry Group': 'gics_industry_group',
  'GICS Industry': 'gics_industry',
  'GICS Sub-Industry': 'gics_sub_industry',
};

export const nameCountryMethodologyCap = {
  'Country of Incorporation': 'country',
  'Country of Headquarters': 'head_country',
  'BITA Assigned Country': 'bita_country',
  'Country of Primary Listing': 'primary_listing',
};
