import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styles from '../../builder.module.scss';
import { useSelectedBacktest, RUNNING } from '../../../utils/useSelectedBacktest';

export const BuilderBacktestStatus = props => {
  const backtestID = useSelector(state => state.backtest.idDisplayedRunningBacktest);
  const selectedBacktest = useSelector(state => state.backtest.backtests[backtestID]);

  const infoMessage = selectedBacktest?.infoMessage;
  const backtestTimestamp = selectedBacktest?.timestamp;
  const [text, setText] = useState('Connecting to the backtesting engine.');

  useEffect(() => {
    if (backtestTimestamp && !infoMessage) {
      const current = new Date().getTime();
      const rest = current - backtestTimestamp;
      const timeout = 300000 - rest;
      if (timeout > 0) {
        setTimeout(() => {
          if (!infoMessage) {
            console.info('NO DATA FROM WS!');
            setText('It was impossible to connect with the backtesting engine. Please, try again.');
          }
        }, timeout);
      } else {
        setText('It was impossible to connect with the backtesting engine. Please, try again.');
      }
    }
  }, [backtestTimestamp]);

  console.info('UPDATING STATUS MODAL ', infoMessage);
  return (
    <div className={`${styles.backtest_status_message}`}>
      {props?.module === 'Factsheet' && infoMessage === 'Backtest completed.'
        ? 'Factsheet Process completed.'
        : infoMessage || text}
    </div>
  );
};
