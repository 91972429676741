/* eslint-disable react/no-array-index-key */
/* eslint-disable import/no-cycle */
/* eslint-disable react/no-array-index-key */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable react/no-array-index-key */
import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import lodash from 'lodash';
import { object } from 'prop-types';
import styles from '../../builder.module.scss';
import BitaMultiSelectArea from '../../../components/BitaMultiSelectArea/BitaMultiSelectArea';
import { ComponentGenerator } from '../Components';
import { getDataComponents } from '../../../api-data-mapping';
import { setApiData } from '../../../state/actions/api-data-actions';
import { loaderDataObjectDecorator } from '../../../utils/loading_status_tools';
import { remove_option } from '../../../utils/utils';

const SelectionList = ({
  data,
  path,
  name,
  onChange,
  selection_helpers,
  filter_field,
  data_key = '',
  data_set_key = '',
  id = '',
  widgetData,
  filteredData,
  search = false,
  loading = false,
}) => {
  // const [data, setData] = React.useState(null);
  const [filterHelper, setFilterHelper] = useState({});
  const [returnData, setReturnData] = useState([]);
  const [forceLoading, setForceLoading] = useState(false);
  const stateApiData = useSelector(state => state.apiData.apiData);
  const [dataReturnObj, setDataReturnObj] = React.useState();
  const [selectionButtonsStatus, setSelectionButtonsStatus] = React.useState(true);
  const universeSelected = useSelector(state => state.summary);
  const auth = useSelector(state => state.auth);

  // eslint-disable-next-line
  const dispatch = useDispatch();
  let idUniverse = false;
  if (
    universeSelected &&
    universeSelected.universeBuilderOperations &&
    universeSelected.universeBuilderOperations.universe.id
  ) {
    idUniverse = universeSelected.universeBuilderOperations.universe.id;
  }
  const dataSubSection = dataSubSe => {
    setDataReturnObj(prev => {
      return {};
    });
    Object.entries(dataSubSe).map(([aux1, value]) => {
      const arrayTemporal = [];
      Object.entries(value.value).map(([aux2, val]) => {
        if (remove_option(!val.disabled)) {
          arrayTemporal.push(val);
        }
      });
      setDataReturnObj(prev => {
        return { ...prev, [value.option]: arrayTemporal };
      });
    });
  };
  React.useEffect(() => {
    if (data_set_key !== '') {
      (async () => {
        const dataComponent = getDataComponents.filter(v => v.key === data_set_key)[0];
        if (typeof dataComponent !== 'undefined') {
          let res = {};
          if (idUniverse || data_set_key === 'Security Type') {
            res = await loaderDataObjectDecorator(dataComponent, dispatch).getUrlData(
              idUniverse || false,
              auth.token,
            );
            dispatch(
              setApiData({
                title: `${data_set_key}`,
                data: dataComponent.formatData(res.data),
              }),
            );
          }
        }
      })();
    }
  }, [data_set_key]);
  const setterMethod = dataIn => {
    if (
      lodash.isObject(
        lodash.get(Object.entries(lodash.get(Object.entries(dataIn), '0.1', [])), '1.1', []),
      )
    ) {
      dataSubSection(dataIn);
    } else {
      const optiones = Object.entries(dataIn || {}).map(([option, value]) => {
        if (value?.option && value?.value) {
          return value;
        }
        return { option, value };
      });
      setDataReturnObj(optiones);
    }
  };
  React.useEffect(() => {
    if (data) {
      if (data_key === 'Themes Dropdo' || name === 'iClima Themes') {
        let apiData = stateApiData && data_key && stateApiData[data_key];
        if (filteredData) apiData = filteredData;
        if (apiData) {
          Object.values(data).map(entrie => {
            Object.values(entrie.value).map(_entrie => {
              if (apiData.filter(item => item.option === _entrie.option).length > 0) {
                _entrie.value =
                  apiData.filter(item => item.option === _entrie.option)?.[0]?.value || false;
                _entrie.id = apiData.filter(item => item.option === _entrie.option)?.[0]?.id;
              } else {
                _entrie.value = false;
              }
            });
          });
        }
      }
      setterMethod(data);
    } else {
      const nameData =
        data_key && data_key.includes('Geography') ? `${data_key}_${path}` : data_key;
      const apiData = stateApiData && data_key && stateApiData[nameData];
      if (Array.isArray(apiData)) {
        // order alphabetically by the option property
        const sortedApiData = apiData.sort((a, b) => {
          if (a.option < b.option) {
            return -1;
          }
          if (a.option > b.option) {
            return 1;
          }
          return 0;
        });
        if (sortedApiData) {
          setterMethod(sortedApiData);
        }
      }
      if (apiData && data_key === 'Themes Dropdown_Pureness') {
        setDataReturnObj(apiData);
      }
    }
    // eslint-disable-next-line
  }, [data, filteredData, stateApiData]);

  useEffect(() => {
    if (
      data_key &&
      data_key?.includes('Geography') &&
      stateApiData?.[`${data_key}_${path}`] &&
      !loading
    ) {
      setForceLoading(true);
      setTimeout(() => {
        setForceLoading(false);
      }, 1000);
    }
  }, [stateApiData?.[`${data_key}_${path}`]]);

  // fixed option problem
  const handleChange = value => {
    setReturnData(value);
  };

  useEffect(() => {
    if (onChange) onChange(`${path}${id}`, returnData);
    // eslint-disable-next-line
  }, [returnData]);

  const topHelpers = useMemo(() => {
    return Object.values(selection_helpers || []).filter(
      ({ type }) => !['SelectAllButton', 'DeselectAllButton'].includes(type),
    );
  }, [selection_helpers]);

  const searchFunction = () => {
    if (
      typeof dataReturnObj === 'object' &&
      !Array.isArray(dataReturnObj) &&
      dataReturnObj !== null &&
      Object.keys(dataReturnObj).length > 0
    ) {
      const objResult = {};
      const keysT = Object.keys(dataReturnObj);
      keysT.forEach(item => {
        objResult[item] = [...dataReturnObj[item]].map(entry => {
          if (search && search !== '') {
            return {
              ...entry,
              hidden: !entry.option?.toUpperCase().includes(search?.toUpperCase()),
            };
          }
          return { ...entry, hidden: false };
        });
      });
      return objResult;
    }
    return [...(Array.isArray(dataReturnObj) ? dataReturnObj ?? [] : [])].map(entry => {
      if (search && search !== '') {
        return { ...entry, hidden: entry.option?.toUpperCase().includes(search?.toUpperCase()) };
      }
      return { ...entry, hidden: false };
    });
  };

  return (
    <div className={`${styles.builderMultiSelectContainer} builderMultiSelectContainer `}>
      {topHelpers.length
        ? topHelpers.map((helper, index) => (
            <div className={`${styles.topHelpers} topHelpers`} key={`keys-${index}`}>
              <ComponentGenerator
                widgetData={widgetData}
                {...helper}
                setFilterHelper={setFilterHelper}
                key={`key-${index}`}
              />
            </div>
          ))
        : null}
      <BitaMultiSelectArea
        className={styles.builderMultiSelect}
        labelClassName={styles.builderMultiSelectLabel}
        filterHelper={filterHelper}
        filterField={filter_field}
        data={
          !(loading || forceLoading)
            ? search && search !== '' && dataReturnObj
              ? searchFunction()
              : dataReturnObj
            : undefined
        }
        widgetData={widgetData}
        value={returnData}
        handleChange={handleChange}
        selectButtonsEnabled={selectionButtonsStatus}
      />
    </div>
  );
};

export default SelectionList;
