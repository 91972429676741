import React, { useEffect, useState } from 'react';
import styles from '../../builder.module.scss';
import BitaInput from '../../../components/BitaInput/BitaInput';

export const BuilderInputComponent = ({
  default_value,
  external_title,
  path,
  onChange,
  typeinput,
  id,
  text,
  labelinput,
  widgetData,
  placeholder = '',
  title = '',
  post_text,
  maxlength,
  showAsLabel = false,
  justify,
  final = false,
  tooltip,
  upper_limit,
  lower_limit,
  typeoutput,
  historyAddibleData,
  autofocus,
  row_style,
  onKeyPress,
  validation,
  hidden,
  typetime = false,
  ...props
}) => {
  const [value, setValue] = useState(default_value || '');
  let localPath = `${path}${id}`;
  if (path && id) {
    localPath = `${path}${id}`;
  } else if (external_title) {
    localPath = external_title;
  }

  useEffect(() => {
    if (onChange) onChange(localPath, default_value);
  }, [default_value]);

  const onLocalChange = targetValue => {
    setValue(targetValue);
    if (onChange) onChange(localPath, targetValue, final);
  };

  useEffect(() => {
    if (widgetData && widgetData[id]) onLocalChange(widgetData[id]);
  }, []);

  const postTextLiquidity = () => {
    const widgetDataName =
      widgetData?.indicator?.value ||
      widgetData?.fundamental?.value ||
      historyAddibleData?.indicator?.value ||
      historyAddibleData?.fundamental?.value ||
      false;
    const regAdtv = /ADTV \d+ Month/;
    if (
      regAdtv.test(widgetDataName) &&
      (widgetData?.operator?.id === '4' ||
        widgetData?.operator?.id === '5' ||
        historyAddibleData?.operator?.id === '4' ||
        historyAddibleData?.operator?.id === '5')
    ) {
      return 'in Millions of USD';
    }
    if (
      widgetDataName === 'Days to trade - 10 million' &&
      (widgetData?.operator?.id === '4' ||
        widgetData?.operator?.id === '5' ||
        historyAddibleData?.operator?.id === '4' ||
        historyAddibleData?.operator?.id === '5')
    ) {
      return 'in days';
    }
    if (
      widgetDataName === 'Total Revenues - LTM' &&
      (widgetData?.operator?.id === '4' ||
        widgetData?.operator?.id === '5' ||
        historyAddibleData?.operator?.id === '4' ||
        historyAddibleData?.operator?.id === '5')
    ) {
      return 'in Millions of USD';
    }
    return post_text;
  };
  if (
    widgetData?.indicator?.value ||
    widgetData?.fundamental?.value ||
    historyAddibleData?.indicator?.value ||
    historyAddibleData?.fundamental?.value
  ) {
    post_text = postTextLiquidity();

    if (
      historyAddibleData?.indicator?.value === 'Foreign Room' &&
      (historyAddibleData?.operator?.id === '4' || historyAddibleData?.operator?.id === '5')
    ) {
      typeinput = 'percentage';
      placeholder = 'Enter %';
    }
  }
  /**
   * Bita ESG input props
   */
  if (
    historyAddibleData?.provider?.value === 'Bita' &&
    (historyAddibleData?.operator?.id === '4' || historyAddibleData?.operator?.id === '5')
  ) {
    if (historyAddibleData?.data_field_filter?.value) {
      switch (historyAddibleData.data_field_filter.value) {
        case 'Emissions':
          tooltip = 'Please indicate a value higher than 0';
          lower_limit = 0;
          break;
        case 'Normalized Emissions':
          tooltip = 'Please indicate between -3 and 3';
          lower_limit = -3;
          upper_limit = 3;
          break;
        case 'Emissions Intensity':
          tooltip = 'Please indicate a value higher than 0';
          lower_limit = 0;
          break;
        case 'Sector-Normalized':
          tooltip = 'Please indicate between -3 and 3';
          lower_limit = -3;
          upper_limit = 3;
          break;
        case 'Waste Intensity':
          tooltip = 'Please indicate a value higher than 0';
          lower_limit = 0;
          break;
        case 'Waste':
          lower_limit = 0;
          break;
        default:
          break;
      }
    }
  }

  useEffect(() => {
    if (
      historyAddibleData?.indicator?.value === 'Foreign Room' &&
      (historyAddibleData?.operator?.id === '4' || historyAddibleData?.operator?.id === '5')
    ) {
      setValue(value.replace(/,/g, '') > 100 ? ' ' : value);
    }
  }, [historyAddibleData?.indicator, historyAddibleData?.operator]);

  useEffect(() => {
    if (widgetData && widgetData[id]) setValue(widgetData[id]);
  }, [widgetData]);
  return (
    <div
      className={`${styles.builderInputComponentContainer} builderInputComponentContainer`}
      style={{
        display: hidden?.includes('geography')
          ? historyAddibleData?.indicator?.value
            ? ''
            : 'none'
          : '',
      }}
    >
      <BitaInput
        inputContainerStyle={styles.builderInputContainer}
        externalTitle={external_title}
        externalTitleStyle={styles.optimizationInputTitle}
        type={typeinput}
        id={localPath}
        autoFocus={autofocus}
        text={text}
        tooltip={tooltip}
        inputStyle={
          localPath !== 'Universe name' ? styles.widgetInput : styles.widgetTransparentInput
        }
        labelinput={labelinput}
        /*  Title no es usado en bitaInput */
        title={title}
        placeholder={placeholder}
        maxlength={maxlength}
        value={value}
        postText={post_text}
        upper_limit={upper_limit}
        lower_limit={lower_limit}
        handleChange={onLocalChange}
        showAsLabel={showAsLabel}
        justify={justify}
        typeoutput={typeoutput}
        row_style={row_style}
        onKeyPress={onKeyPress}
        validation={validation}
        typeTime={typetime}
      />
    </div>
  );
};
