import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'simplebar'; // or "import SimpleBar from 'simplebar';" if you want to use it manually.
import 'simplebar/dist/simplebar.css';
import _ from 'lodash';
import styles from '../workflow-modal.module.css';
import { RadioGroup } from './RadioGroup';
import * as SummaryConstants from '../../../../constants/summary-constants';
import BitaSearchBar from '../../../../bitaComponents/BitaSearchBar/BitaSearchBar';
import BitaButton from '../../../../bitaComponents/BitaButton/BitaButton';

export const BitaInstrumentSelect = props => {
  const { widget, onChange = Function.prototype } = props;
  const [selectedRows, setSelectedRows] = useState([]);
  const [instruments, setInstruments] = useState([]);
  const [optionSelected, setOptionSelected] = useState(widget.selected_default || '');
  const apiData = useSelector(state => state.apiData.apiData);
  const summary = useSelector(state => state.summary);
  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);
  const max_instruments_selected = widget?.max_instruments_selected;
  useEffect(() => {
    if (widget.data_api_key && apiData) {
      const getDataFor = widget.data_api_key.replace(/\s/g, '').split('&');
      let instrumentList = [];
      // eslint-disable-next-line array-callback-return
      getDataFor.map(dataName => {
        const list = _.get(apiData, dataName, {});
        if (list.data) {
          let dataList = list.data;
          if (dataName === 'Benchmark') {
            dataList = list.data.Benchmark;
          }
          instrumentList = instrumentList.concat(
            dataList.map(row => {
              return {
                'Instrument name': row.name,
                'Instrument type': row.index_type
                  ? row.index_type
                      .replace('regular', 'INDEX')
                      .replace('structured_index', 'STRUCTURED INDEX')
                      .replace('optimized_index', 'OPTIMIZED INDEX')
                  : 'ETF',
                id: row.id,
              };
            }),
          );
        }
      });
      setInstruments(instrumentList);
    } else {
      setInstruments(
        Object.values(widget.data[0].values).reduce((result, extra, index) => {
          const row = {};
          Object.values(widget.data).forEach(column => {
            row[column.field_name] = column.values[index];
          });
          return result.concat(row);
        }, []),
      );
    }
  }, [apiData.Benchmark, apiData.Indexes, auth.token]);

  const rows = useMemo(
    () =>
      instruments.filter(
        row =>
          !selectedRows.some(
            selected => row?.['Instrument name'] === selected?.['Instrument name'],
          ) &&
          (optionSelected === '' || row?.['Instrument type'] === optionSelected.toUpperCase()),
      ),
    [instruments, selectedRows, optionSelected],
  );

  const searchOptions = useMemo(() => {
    if (!rows || !rows.length) {
      return [];
    }
    return rows.map(row => ({
      label: `${row['Instrument name']} - ${row['Instrument type']}`,
      value: row,
    }));
  }, [rows]);

  const onSearchChange = useCallback(
    selected => {
      if (!(max_instruments_selected && selectedRows.length >= max_instruments_selected)) {
        setSelectedRows(
          selectedRows.concat(
            rows.find(row => row['Instrument name'] === selected.value['Instrument name']),
          ),
        );
      }
      dispatch({
        type: SummaryConstants.ADD_ENTRY,
        payload: {
          module: 'Structured Index',
          widget: 'Instrument Selected',
          data: [rows.find(row => row['Instrument name'] === selected.value['Instrument name'])],
        },
      });
    },

    [setSelectedRows, selectedRows, rows],
  );
  const onChangeSelected = option => {
    setOptionSelected(option);
  };
  /* eslint-disable */

  useEffect(() => {
    onChange(selectedRows);
    let auxSelect = [];
    if (selectedRows) {
      auxSelect = selectedRows.map(row => row);
    }
    auxSelect;
    widget.selected = auxSelect;
  }, [selectedRows, onChange]);

  useEffect(() => {
    if (max_instruments_selected && selectedRows.length >= max_instruments_selected)
      setDisabled(true);
    else {
      setDisabled(false);
    }
  }, [selectedRows]);
  /* eslint-enable */

  useEffect(() => {
    const auxSelected = [];
    if (summary['Structured Index']?.['Instrument Selected']) {
      // eslint-disable-next-line array-callback-return
      summary['Structured Index']['Instrument Selected'].map(auxItem => {
        // eslint-disable-next-line array-callback-return
        // eslint-disable-next-line array-callback-return
        rows.map(item => {
          if (item['Instrument name'] === auxItem) {
            auxSelected.push(item);
          }
        });
      });
    }
    setSelectedRows(auxSelected);
  }, [instruments]);

  return (
    <Fragment key={widget.title}>
      <div className={styles.searchTop}>
        {widget.filter ? (
          <div className={`${styles.searchFilterOptions} searchFilterOptions`}>
            <RadioGroup
              options={Object.values(widget.filter_options)}
              optionSelected={optionSelected}
              onChange={onChangeSelected}
            />
          </div>
        ) : null}
        <div
          className={styles.searchInput}
          style={{ opacity: disabled ? '0.5' : '1', pointerEvents: disabled ? 'none' : 'auto' }}
        >
          <BitaSearchBar searchOptions={searchOptions} value={null} onChange={onSearchChange} />
        </div>
      </div>
      {widget.data ? (
        <div className="searchInstrumentsModal" data-simplebar style={{ height: '320px' }}>
          <div className={styles.searchResultsContainer}>
            {selectedRows.map(row => (
              <div key={row} className={styles.searchResult}>
                {Object.entries(row).map(([column, value]) => (
                  <div key={value} className={styles.searchResultColumn}>
                    <div className={styles.searchResultHeadColumn}>
                      <b>{column}</b>
                    </div>
                    <div>{value}</div>
                  </div>
                ))}
                <div>
                  <BitaButton
                    onClick={() => {
                      setSelectedRows(
                        selectedRows.filter(
                          selectedRow => row['Instrument name'] !== selectedRow['Instrument name'],
                        ),
                      );
                    }}
                    primaryWhite
                    className={styles.deleteResult}
                  >
                    Delete
                  </BitaButton>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </Fragment>
  );
};
