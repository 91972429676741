import React from 'react';
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import './BitaWsConnectionWidget.scss';
import Websocket, { restartWebSocket } from '../../components/Websocket/Websocket'; // Import the Websocket component and restartWebSocket function

const BitaWSConnectionWidget = () => {
  const isConnected = useSelector(state => state?.websocket?.connected || false);
  const userToken = useSelector(state => state.auth.token);

  const color = isConnected ? '#B6D22D' : 'red';
  const message = isConnected
    ? 'Connected to the WebSocket, click to reset connection'
    : 'Connection lost, click to connect again';

  // Access the restartWebSocket function from the Websocket component
  const initializeWS = () => {
    console.info('WS CONNECTION RESTART');
    restartWebSocket(Websocket, userToken); // Call the restartWebSocket function with the required arguments
  };

  return (
    <div
      onClick={initializeWS}
      className="bita_ws_connection_widget"
      style={{ backgroundColor: color }}
    >
      <p data-tip={message} className="ugly-fix">
        .
      </p>
      <ReactTooltip />
    </div>
  );
};

export default BitaWSConnectionWidget;
