import React from 'react';
import PropTypes from 'prop-types';
import Switch from 'react-switch';

const _Switch = props => {
  return (
    <div className={`BitaSwitch ${props.label} ${props.classSwitch}`}>
      <Switch
        disabled={props.disabled}
        onChange={props.onChange}
        handleDiameter={13.7}
        offColor="#fff"
        onColor="#0039CB"
        offHandleColor="#0039CB"
        onHandleColor="#fff"
        uncheckedIcon={false}
        checkedIcon={false}
        height={20}
        width={40}
        activeBoxShadow="0px 0px 2px 2px rgba(0, 0, 0, 0.2)"
        checked={props.checked}
      />
      <span className={props.classLabel}>{props.label}</span>
    </div>
  );
};

_Switch.propTypes = {
  classSwitch: PropTypes.string,
  classLabel: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  label: PropTypes.string,
};

_Switch.defaultProps = {
  classSwitch: '',
  classLabel: '',
};

export default _Switch;
