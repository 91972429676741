import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';

import * as CommonActions from '../../state/actions/common-actions';
import { defaultTemplateStructure } from '../../constants/structured-index-constants';
import Builder from '../../builder/Builder';
// import { setBy } from '../../builder/helpers/deepSet';
import { useSummary } from '../../bitaHooks';

const BuildStructured = ({ parametrization, selectedInstruments = [] }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [addToSummary, resetSummary] = useSummary(
    'Structured Index',
    location.pathname.includes('Decrement')
      ? 'Decrement Index'
      : location.pathname.includes('Leverage')
      ? 'Leverage Index'
      : 'New Structured Product',
  );

  const template = defaultTemplateStructure();
  const [structuredData, setStructuredData] = useState(template);

  useEffect(() => {
    dispatch(CommonActions.resetSubheader());
    addToSummary(
      'Structured Index',
      location.pathname.includes('Decrement')
        ? 'Decrement Index'
        : location.pathname.includes('Leverage')
        ? 'Leverage Index'
        : location.pathname.includes('Risk')
        ? 'Risk Control Index'
        : 'New Structured Product',
    );

    Object.values(parametrization.header_path).forEach(title => {
      dispatch(CommonActions.appendSubheader(title));
    });

    if (
      !(
        location.pathname.includes('Leverage') ||
        location.pathname.includes('Decrement') ||
        location.pathname.includes('Risk')
      )
    ) {
      addToSummary(structuredData);
    }
  }, [parametrization]);

  const deleteSummary = () => resetSummary();

  const sendSummary = state => setStructuredData(old => ({ ...old, ...state }));

  return (
    <Builder
      key="structured-index"
      onChange={() => null}
      onApply={sendSummary}
      onDelete={deleteSummary}
      currentView={
        location.pathname.includes('Decrement')
          ? 'Decrement Index'
          : location.pathname === '/structures-product/structures-product/Leverage+Index'
          ? 'Leverage Index'
          : location.pathname.includes('Risk')
          ? 'Risk Control Index'
          : 'New Structured Product'
      }
      universe="Structured Index"
      {...parametrization}
      selectedInstruments={selectedInstruments}
      widgetData={structuredData}
    />
  );
};

export default BuildStructured;
