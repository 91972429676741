import React from 'react';
import DisclaimerRow from '../lib/DisclaimerRow';

export const BuilderDisclaimerRow = ({
  components = {},
  id = '',
  onChange,
  setterFunction = Function.prototype,
  widgetData,
  summaryData,
  stepData,
  disclaimer,
  path,
}) => {
  return (
    <DisclaimerRow
      components={components}
      id={id}
      onChange={onChange}
      setterFunction={setterFunction}
      widgetData={widgetData}
      summaryData={summaryData}
      stepData={stepData}
      disclaimer={disclaimer}
      path={path}
    />
  );
};
