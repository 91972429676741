import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router';
import PageLayout from '../../components/Layout/PageLayout';
import AnalyzerComponent from '../../components/Analyzer/AnalyzerComponent';

const IndexAnalyzer = () => {
  const location = useLocation();
  const tab = Number(location.search.split('=')[1]) || 0;

  return (
    <PageLayout>
      <AnalyzerComponent tabActive={tab} />
    </PageLayout>
  );
};

export default IndexAnalyzer;
