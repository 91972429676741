import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';
import PageLayout from '../../components/Layout/PageLayout';
import Waste from '../../components/Analyzer/EmissionAndWaste/Waste';

import * as CommonActions from '../../state/actions/common-actions';

const WasteComponent = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  dispatch(CommonActions.resetSubheader());
  dispatch(CommonActions.appendSubheader('ANALYZER · WASTE'));

  const apiData = useSelector(state => state.apiData.apiData);
  const [benchmarkList, setBenchmarkList] = useState([]);
  useEffect(() => {
    const getDataFor = ['Benchmark', 'Indexes'];
    let instrumentList = [];
    // eslint-disable-next-line array-callback-return
    getDataFor.map(dataName => {
      const list = _.get(apiData, dataName, {});
      if (list.data) {
        let dataList = list.data;
        if (dataName === 'Benchmark') {
          dataList = list.data.Benchmark;
        }
        instrumentList = instrumentList.concat(
          dataList.map(row => {
            return {
              label: `${row.name} - ${
                row.index_type
                  ? row.index_type
                      .replace('regular', 'INDEX')
                      .replace('structured_index', 'STRUCTURED PRODUCT')
                      .replace('optimized_index', 'OPTIMIZED PRODUCT')
                  : 'ETF'
              }`,
              value: row.name,
              id: row.id,
              'Instrument type': row.index_type
                ? row.index_type
                    .replace('regular', 'INDEX')
                    .replace('structured_index', 'STRUCTURED PRODUCT')
                    .replace('optimized_index', 'OPTIMIZED PRODUCT')
                : 'ETF',
            };
          }),
        );
      }
    });
    setBenchmarkList(instrumentList);
  }, [apiData.Benchmark, apiData.Indexes]);

  const styleContent = {
    width: 'calc(100% - 5px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '5px 10px 5px 0px',
    borderRadius: '5px',
    backgroundColor: '#3b3f44',
    height: '60px',
    padding: '20px',
  };

  return (
    <PageLayout>
      <Waste benchmarkList={benchmarkList} />
    </PageLayout>
  );
};

export default WasteComponent;
