import * as DownloadActions from '../../constants/download-constants';

const initialState = {
  open: false,
  file: '',
  factsheet: false,
  report: false,
  esg: false,
};

const downloadReducer = (state = initialState, action) => {
  switch (action.type) {
    case DownloadActions.INIT_STATE:
      return initialState;
    case DownloadActions.UPDATE_MODAL:
      return { ...Object.assign(state, action.payload.data) };
    default:
      return state;
  }
};

export default downloadReducer;
