/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { NavContext } from 'react-sidenav';
import styles from '../SideNavbar.module.scss';
import * as SubheaderActions from '../../../state/actions/common-actions';
import { excludeUser } from '../../../constants/exclude-modules-user';
import ModalAbandoneProject from '../../BitaModal/ModalabandoneProject';

const NavTitle = ({ title, to, children, appendSubheader, resetSubheader, collapsed, status }) => {
  const [showChildren, setShowChildren] = React.useState(false);
  const context = React.useContext(NavContext);
  const selected = context.selected && showChildren ? styles.selected : '';
  // automatically hidde submenu subsections from side bar
  // const showSubmenu = state.selectedSection.includes(title);
  const [visibleConfirmation, setVisibleConfirmation] = React.useState(null);
  const summaryProyect = Object.keys(useSelector(state => state.summary));
  const apiData = useSelector(state => state.apiData.apiData);
  const typeMethodology = useSelector(state => state.common.subheader);
  const userdata = useSelector(state => state.auth.userdata);

  const location = useLocation();
  const currentModuleUrl = location.pathname.split('/')[1];
  const isHidden =
    status === 'HIDDEN' ||
    (title === 'OPTIMIZED INDEX' &&
      userdata.id_company === process.env.REACT_APP_DEMO_COMPANY_ID) ||
    (excludeUser[userdata?.company?.name] &&
      excludeUser[userdata?.company?.name]?.[title?.toLowerCase()]);

  return (
    <>
      {(summaryProyect.length > 0 ||
        (apiData?.['Optimized Index'] && Object.keys(apiData?.['Optimized Index'])?.length > 0)) &&
      to.split('/')[1] !== currentModuleUrl ? (
        <div
          onClick={() => {
            setVisibleConfirmation(true);
          }}
        >
          <div
            className={classNames(styles.sideNavbarTitle, selected)}
            style={{
              opacity: status === 'ACTIVE' ? '1' : '0.5',
              pointerEvents: status === 'ACTIVE' ? 'auto' : 'none',
              display: isHidden ? 'none' : 'block',
            }}
          >
            {title}
          </div>
        </div>
      ) : (
        <Link
          // eslint-disable-next-line no-nested-ternary
          to={status === 'ACTIVE' ? to : '#'}
          onClick={() => {
            if (status === 'ACTIVE') {
              setShowChildren(prev => !prev);
              resetSubheader();
              appendSubheader(
                typeMethodology !== 'INDEX BUILDER' && title === 'INDEX BUILDER'
                  ? 'CREATE NEW METHODOLOGY · WHITE CANVAS'
                  : title,
              );
            }
          }}
        >
          <div
            className={classNames(styles.sideNavbarTitle, selected)}
            style={{
              opacity: status === 'ACTIVE' ? '1' : '0.5',
              pointerEvents: status === 'ACTIVE' ? 'auto' : 'none',
              display: isHidden ? 'none' : 'block',
            }}
          >
            {title}
          </div>
        </Link>
      )}

      {!collapsed && children}
      <ModalAbandoneProject
        visibility={visibleConfirmation}
        to={to}
        close={() => setVisibleConfirmation(false)}
      />
    </>
  );
};

NavTitle.defaultProps = {
  to: '/',
};

const mapStateToProps = state => ({
  selectedSection: state.common.subheader,
});

const mapDispatchToProps = dispatch => {
  return {
    resetSubheader: () => dispatch(SubheaderActions.resetSubheader()),
    appendSubheader: string => dispatch(SubheaderActions.appendSubheader(string)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavTitle);
