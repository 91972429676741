import React from 'react';
import BetweenComponent from '../lib/BetweenComponent';

export const BuilderBetweenComponent = ({
  components = {},
  id = '',
  onChange,
  setterFunction = Function.prototype,
  widgetData,
  summaryData,
  stepData,
  path,
  historyAddibleData,
  post_text,
  type,
  placeholder,
}) => {
  return (
    <BetweenComponent
      components={components}
      id={id}
      onChange={onChange}
      setterFunction={setterFunction}
      widgetData={widgetData}
      summaryData={summaryData}
      stepData={stepData}
      path={path}
      historyAddibleData={historyAddibleData}
      type={type}
      placeholder={placeholder}
      post_text={post_text}
    />
  );
};
