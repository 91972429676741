import React from 'react';
import styles from '../ControversyTopics.module.scss';

const TablesStats = props => {
  const dataTableItemsHead = { number_constituents: 'Number Constituents', weight: 'Weight' };
  return (
    <div className={styles.tableContainer}>
      <table className={styles.generatedTable}>
        <thead className={styles.generatedTableHeader}>
          <tr>
            <th scope="col" style={{ textAlign: 'left' }}>
              {props.type === 'Arabesque' ? ' ' : 'Issue'}
            </th>
            <th colSpan="2" scope="col" className={styles.restTableHeaders}>
              {props.typeIndex === 'rebalancing_module' ? 'Portfolio' : 'Index'}
            </th>
            <th colSpan="2" scope="col" className={styles.restTableHeaders}>
              Benchmark
            </th>
          </tr>
          <tr>
            <th />
            {Object.values(dataTableItemsHead).map(item => (
              <th
                scope="col"
                className={item !== 'Weight' ? styles.restTableHeaders : ''}
                style={{
                  width: item === 'Weight' ? '70px' : '',
                  textAlign: item === 'Weight' ? 'left' : '',
                }}
                key={item}
              >
                {item}
              </th>
            ))}
            {Object.values(dataTableItemsHead).map(item => (
              <th
                scope="col"
                className={item !== 'Weight' ? styles.restTableHeaders : ''}
                style={{
                  width: item === 'Weight' ? '70px' : '',
                  textAlign: item === 'Weight' ? 'left' : '',
                }}
                key={item}
              >
                {item}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {props.data?.map(item => (
            <tr className={styles.generatedTableHeader}>
              <td>{item.name}</td>
              <td className={styles.restTableHeaders}>{item?.index?.value?.constituents}</td>
              <td>{`${(item?.index?.value?.weight * 100).toFixed(2)}%`}</td>
              <td className={styles.restTableHeaders}>{item?.benchmark?.value?.constituents}</td>
              <td>{`${(item?.benchmark?.value?.weight * 100).toFixed(2)}%`}</td>
            </tr>
          ))}
          <tr className={styles.generatedTableHeader}>
            <td style={{ fontWeight: 'bold' }}>Total Allocation</td>
            <td className={styles.restTableHeaders}>
              {Object.values(props.data).reduce(
                (sum, value) =>
                  typeof Number(value?.index?.value?.constituents) === 'number'
                    ? sum + Number(value?.index?.value?.constituents)
                    : sum,
                0,
              ) ||
                0 ||
                '0'}
            </td>
            <td>
              {`${(
                Object.values(props.data).reduce(
                  (sum, value) =>
                    typeof Number(value.index?.value?.weight) === 'number'
                      ? sum + Number(value.index?.value?.weight * 100)
                      : sum,
                  0,
                ) || 0
              ).toFixed(2)}%` || 'N/A'}
            </td>
            <td className={styles.restTableHeaders}>
              {Object.values(props.data).reduce(
                (sum, value) =>
                  typeof Number(value?.benchmark?.value?.constituents) === 'number'
                    ? sum + Number(value?.benchmark?.value?.constituents)
                    : sum,
                0,
              ) ||
                0 ||
                '0'}
            </td>
            <td>
              {`${(
                Object.values(props.data).reduce(
                  (sum, value) =>
                    typeof Number(value.benchmark?.value?.weight) === 'number'
                      ? sum + Number(value.benchmark?.value?.weight * 100)
                      : sum,
                  0,
                ) || 0
              ).toFixed(2)}%` || 'N/A'}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default TablesStats;
