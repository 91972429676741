import React from 'react';
import ReactDOM from 'react-dom';
// import LogRocket from 'logrocket';

import './css/tailwind.css';

import './sass/App.scss';
import * as serviceWorker from './serviceWorker';

import App from './App';

// TODO remove after check how use it
import ConfigYAML from './config/ConfigYAML';

serviceWorker.unregister();
// serviceWorker.register();

/* if (process.env.REACT_APP_ENV === 'development') {
  LogRocket.init('ktbsyb/bita_dev');
  console.info('DEVELOPMENT MODE');
} */

window.ConfigYAML = ConfigYAML;

const c = Object.assign(console);
// eslint-disable-next-line func-names
c.log = function() {};
window.console = c;
// console.warn('YAML document is ', ConfigYAML.DOCUMENT);
// console.warn("jsonPath's sample ", ConfigYAML.jsonPath('$..reconstitution..dropdown'));

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
