import React from 'react';
import SelectionList from '../lib/SelectionList';

export const BuilderSelectionList = ({
  data,
  path,
  onChange,
  selection_helpers,
  filter_field,
  data_key = '',
  id = '',
  widgetData,
  data_set_key,
}) => {
  return (
    <SelectionList
      data={data}
      path={path}
      onChange={onChange}
      widgetData={widgetData && widgetData[id]}
      selection_helpers={selection_helpers}
      filter_field={filter_field}
      data_key={data_key}
      data_set_key={data_set_key}
      id={id}
    />
  );
};
