/* eslint-disable array-callback-return */
import React, { useState, useEffect, useLayoutEffect } from 'react';
import _ from 'lodash';
import moment from 'moment';
import axios from 'axios';
import 'simplebar/dist/simplebar.css';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styles from './AnalyzerComponent.module.scss';
import BitaCardChart from '../../bitaComponents/BitaCardChart/BitaCardChart';
import BitaCardRebalancing from '../../bitaComponents/BitaCardChart/BitaCardRebalancing';
import { ReactComponent as FilterIcon } from '../../static/img/Icons/filters.svg';
import * as API_DATA_ACTIONS from '../../state/actions/api-data-actions';
import BitaInput from '../BitaInput/BitaInput';
import './AnalyzerComponents.scss';
import { getAnalyzerCards } from '../../utils/utils';
import PaginationComponent from './PaginationComponent';
import BitaSpinner from '../BitaSpinner/BitaSpinner';

const roundUpDecimals = number => {
  return Math.round((number + Number.EPSILON) * 100) / 100;
};
const RadioGroup = props => {
  return props.options.map(option => (
    <React.Fragment key={option}>
      <input
        id={`radio-${option}`}
        type="radio"
        name="radio-filter"
        value={option}
        onChange={() => {
          props.onChange(option);
        }}
        checked={props.optionSelected === option}
      />
      <label htmlFor={`radio-${option}`}>{option}</label>
    </React.Fragment>
  ));
};
// Componente tabs
const TabsComponent = props => {
  // Controla en el estado del componente la pestaña activa
  const [activeTab, setActiveTab] = React.useState(props.initialValue);
  return (
    <div className={styles.tabsComponentContainer}>
      {/* Renderizado de pestañas en base a datos recibidos por la prop data */}
      <div className={styles.tabsContainer}>
        <div className={styles.tabsButtonContainer}>
          {props.data.map((tabTitle, index) => (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events
            // eslint-disable-next-line
            <div
              key={index}
              style={{
                display:
                  tabTitle.title === 'OPTIMIZED INDEXES' &&
                  props.user.id_company === process.env.REACT_APP_DEMO_COMPANY_ID
                    ? 'none'
                    : '',
              }}
              className={`${styles.tabTitleButton} ${
                index === activeTab ? styles.tabTitleButtonActive : styles.tabTitleButtonInactive
              }`}
              onClick={() => setActiveTab(index)}
            >
              {tabTitle.title}
            </div>
          ))}
        </div>
        <div className={styles.searchBarContainer} />
      </div>
      {/* Renderizado del contenido de la tab, recibido por la propiedad content de la prop data */}
      <div className={styles.tabContentContainer}>
        {props.data.map(
          (tab, index) =>
            index === activeTab &&
            tab.content({
              universeSelected: props.universeSelected,
              history: props.history,
              search: props.search,
              apiData: props.apiData,
              type:
                activeTab === 1
                  ? 'optimized_index'
                  : activeTab === 2
                  ? 'structured_index'
                  : activeTab === 3
                  ? 'basket_index'
                  : activeTab === 4
                  ? 'rebalancing_module'
                  : activeTab === 5
                  ? 'live_index'
                  : 'regular',
            }),
        )}
      </div>
    </div>
  );
};

// eslint-disable-next-line
const IndexesComponent = ({ history, search, type }) => {
  const token = useSelector(state => state.auth.token);
  const [shownCards, setShownCards] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState();
  const [filterBy, setFilterBy] = useState('created_at');
  const dispatch = useDispatch();
  const userdata = useSelector(state => state.auth.userdata);
  const [optionSelected, setOptionSelected] = useState('by Organization');
  useEffect(() => {
    dispatch(API_DATA_ACTIONS.setApiData({ title: 'Current Index Selected', data: {} }));
  }, []);
  const onChangeSelected = option => {
    setOptionSelected(option);
  };

  useEffect(() => {
    if (token) {
      setLoading(true);
      setShownCards([]);
      getAnalyzerCards(
        token,
        type === 'live_index' ? 'regular' : type,
        type === 'live_index' ? maxPage : page,
        search !== '' ? search : false,
        filterBy !== 'created_at',
        optionSelected !== 'by Organization' ? optionSelected : false,
      ).then(resp => {
        setMaxPage(type === 'live_index' ? 1 : resp.data.total_pages);
        setShownCards(
          type === 'live_index'
            ? resp?.data?.data?.slice(resp?.data?.data.length - 1, resp?.data?.data.length)
            : resp.data.data,
        );
        setLoading(false);
      });
    }
  }, [page, type, optionSelected]);

  useEffect(() => {
    setPage(1);
  }, [type]);

  // eslint-disable-next-line consistent-return
  useLayoutEffect(() => {
    if (search || search === '') {
      if (page !== 1) {
        setPage(1);
      } else {
        setShownCards([]);
        setLoading(true);
        const { cancel } = axios.CancelToken.source();
        const timeOutId = setTimeout(
          () =>
            getAnalyzerCards(
              token,
              type === 'live_index' ? 'regular' : type,
              type === 'live_index' ? maxPage : page,
              search !== '' ? search : false,
              filterBy !== 'created_at',
              optionSelected !== 'by Organization' ? optionSelected : false,
            ).then(resp => {
              setMaxPage(type === 'live_index' ? 1 : resp.data.total_pages);
              setShownCards(
                type === 'live_index'
                  ? resp?.data?.data?.slice(resp?.data?.data.length - 1, resp?.data?.data.length)
                  : resp.data.data.slice(0, 21),
              );
              setLoading(false);
            }),
          500,
        );
        return () => cancel('No longer latest query') || clearTimeout(timeOutId);
      }
    }
  }, [search]);

  const filterCards = option => {
    setFilterBy(option);
    setShownCards([]);
    if (page !== 1) {
      setPage(1);
    } else {
      setLoading(true);
      getAnalyzerCards(
        token,
        type === 'live_index' ? 'regular' : type,
        type === 'live_index' ? maxPage : page,
        search !== '' ? search : false,
        option !== 'created_at',
        optionSelected !== 'by Organization' ? optionSelected : false,
      ).then(resp => {
        setMaxPage(type === 'live_index' ? 1 : resp.data.total_pages);
        setShownCards(
          type === 'live_index'
            ? resp?.data?.data?.slice(resp?.data?.data.length - 1, resp?.data?.data.length)
            : resp.data.data,
        );
        setLoading(false);
      });
    }
  };

  const setCurrentPage = value => {
    setPage(value);
  };

  const getNoIndexTest = () => {
    switch (type) {
      case 'optimized_index':
        return 'No Optimized Indexes has been created. Please, run a backtest first';
      case 'structured_index':
        return 'No Structured Indexes have been created. Please, run a backtest first.';
      default:
        return 'No Indexes has been created. Please, run a backtest first';
    }
  };

  return (
    <div className={styles.cardsContainer} key="container">
      <div className={styles.orderContainer}>
        <FilterIcon />
        <button className={styles.orderButton} onClick={() => filterCards('name')}>
          Order by Name
        </button>
      </div>
      <div className={styles.orderContainer}>
        <FilterIcon />
        <button className={styles.orderButton} onClick={() => filterCards('created_at')}>
          Order by Date
        </button>
      </div>
      <div className={styles.orderContainer}>
        <RadioGroup
          options={['by Organization', `by ${userdata.first_name} ${userdata.last_name}`]}
          optionSelected={optionSelected}
          onChange={onChangeSelected}
        />
      </div>
      {search === '' || !search ? (
        <div className={styles.orderContainer} style={{ justifyContent: 'flex-end', flex: 1 }}>
          <PaginationComponent totalPages={maxPage} onClick={setCurrentPage} />
        </div>
      ) : (
        <div />
      )}
      <div id="card-list" style={{ height: 545 }} className={styles.tabContent}>
        {shownCards?.length > 0 ? (
          <div className={styles.cardWrapper}>
            {shownCards.map((card, index) => (
              <div
                className={styles.placeholderContainerCard}
                onClick={() => {
                  if (card.data.length > 0) {
                    dispatch(API_DATA_ACTIONS.setCurrentIndex(token, card.id));
                    if (type === 'rebalancing_module') {
                      history.push(`/index-analyzer/characteristics/${card.id}`);
                    } else if (type === 'live_index') {
                      history.push(`/index-analyzer/performance/${card.id}?type=live_index`);
                    } else {
                      history.push(`/index-analyzer/performance/${card.id}`);
                    }
                  }
                }}
                key={`${card.name}_${index}_${card.id}`}
              >
                {type === 'rebalancing_module' ? (
                  <BitaCardRebalancing
                    width="300px"
                    title={card.name}
                    data={card.data?.slice().map(value => ({
                      date: moment(value.timestamp).format('YYYY/MM/DD'),
                      y: parseFloat(value.stat_value),
                    }))}
                  />
                ) : (
                  <BitaCardChart
                    width="300px"
                    title={card.name}
                    data={card.data?.slice().map(value => ({
                      date: moment(value.timestamp).format('ddd MMM Do YYYY'),
                      y: parseFloat(value.stat_value),
                    }))}
                    firstButton={{
                      text: '1 YR RETURN',
                      value: card['1YR']
                        ? `${roundUpDecimals(parseFloat(card['1YR']) * 100)}%`
                        : 'No Data',
                    }}
                    secondButton={{
                      text: '1 YR VOLATILITY',
                      value: card['1YV']
                        ? `${roundUpDecimals(parseFloat(card['1YV']) * 100)}%`
                        : 'No Data',
                    }}
                  />
                )}
              </div>
            ))}
          </div>
        ) : (
          <div className={styles.loadingContainer}>
            {!loading ? (
              <div>{getNoIndexTest()}</div>
            ) : (
              <div>
                <BitaSpinner />
                <br />
                Loading index page
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

// Datos para generar las pestañas y contenidos de la misma, en la propiedad content iria el componente a renderizar como contenido

const data = [
  { title: 'INDEXES', content: IndexesComponent },
  { title: 'OPTIMIZED INDEXES', content: IndexesComponent },
  { title: 'STRUCTURED INDEXES', content: IndexesComponent },
  { title: 'PORTFOLIO / BASKET BACKTESTING', content: IndexesComponent },
  { title: 'REBALANCED PORTFOLIOS', content: IndexesComponent },
];

const AnalyzerComponent = ({ tabActive }) => {
  const [value, setValue] = useState();
  const token = useSelector(state => state.auth.token);
  const user = useSelector(state => state.auth.userdata);
  const cards = useSelector(state => state.apiData.apiData['Index Card Stats']);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(API_DATA_ACTIONS.setApiData({ title: 'Benchmark Card Stats', data: [] }));
    dispatch(API_DATA_ACTIONS.setApiData({ title: 'Current Index Selected', data: [] }));
    dispatch(API_DATA_ACTIONS.setApiData({ title: 'Index Stats', data: [] }));
    document.title = `${process.env.REACT_APP_SITE_TITLE} | Analyzer`;
  }, []);

  useEffect(() => {
    if (cards.length <= 0) {
      dispatch(API_DATA_ACTIONS.setCardStats(token));
    }
  }, [dispatch, token]);

  // Utility Hooks
  const history = useHistory();
  return (
    <div className={styles.parentContainer}>
      <div className={styles.base}>
        <div className={styles.libraryContainer}>
          <div className={styles.libraryTitleContainer}>
            <span className={styles.libraryTitle}>LIBRARY</span>
            <div className={styles.librarySearch}>
              <BitaInput
                value={value}
                type="text"
                className="searchInput"
                inputStyle="searchInput"
                placeholder="Search by..."
                autoFocus
                handleChange={val => {
                  setValue(val);
                }}
              />
            </div>
            <span className={styles.libraryDescription}>
              Select what you would like to analyze:
            </span>
          </div>
          <div className={styles.tabsSearchContainer}>
            <div className={styles.tabsContainer}>
              <TabsComponent
                data={
                  user.id_company === process.env.REACT_APP_BITA_ID ||
                  user.id_company === process.env.REACT_APP_DEMO_COMPANY_ID
                    ? [...data, { title: 'LIVE INDEXES', content: IndexesComponent }]
                    : data
                }
                history={history}
                search={value}
                user={user}
                initialValue={tabActive}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnalyzerComponent;
