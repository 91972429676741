function pathUpdate(obj, path, updater) {
  // eslint-disable-next-line
  path = path
    .replace(/\[(\d*)\]/gm, '__int__$1')
    .replace('[', '')
    .replace(']', '.')
    .replace(/\.{2,}/, '.')
    .split('.')
    .map(d => {
      if (d.indexOf('__int__') === 0) {
        return parseInt(d.substring('__int__'.length), 10);
      }
      return d;
    });

  function doSet(parent) {
    if (!path.length) {
      return typeof updater === 'function' ? updater(parent) : updater;
    }

    const key = path.shift();

    if (typeof key === 'string') {
      if (typeof parent === 'object') {
        return {
          ...parent,
          [key]: doSet(parent[key]),
        };
      }
      return {
        [key]: doSet(),
      };
    }

    if (typeof key === 'number') {
      if (Array.isArray(parent)) {
        const prefix = parent.slice(0, key);
        return [
          ...(prefix.length ? prefix : new Array(key)),
          doSet(parent[key]),
          ...parent.slice(key + 1),
        ];
      }
      return [...new Array(key), doSet()];
    }

    throw new Error('Uh oh!');
  }

  return doSet(obj);
}

function onChangeUpdate(setFunction, { path, value }) {
  console.log(setFunction);
  setFunction(old => {
    return pathUpdate(old, path, value);
  });
}

export { pathUpdate, onChangeUpdate };
