import { useSelector } from 'react-redux';
import { setWaitingResponse } from '../../state/actions/api-data-actions';

export const loaderDataObjectDecorator = (objData, dispatch) => {
  const { id, title } = objData;
  if (!(typeof id === 'string' || typeof title === 'string')) {
    throw new Error(`useLoadStatus error parameter must be a string, you pass ${id}`);
  }

  const parameters = { id: id?.toLowerCase() || title?.toLowerCase(), status: true };
  return {
    getUrlData(...args) {
      dispatch(setWaitingResponse(parameters));
      const x = objData.getUrlData.bind(objData)(...args);
      x.then(() => {
        dispatch(setWaitingResponse({ ...parameters, status: false }));
      });
      x.catch(e => {
        console.error('ERROR API CALL ', id, ' error ', e);
        dispatch(setWaitingResponse({ ...parameters, status: false }));
      });

      return x;
    },
  };
};

export const loaderAxiosDecorator = (dispatch, id) => axiosCall => {
  const parameters = { id: id.toLowerCase(), status: true };

  dispatch(setWaitingResponse(parameters));

  axiosCall
    .then(() => {
      dispatch(setWaitingResponse({ ...parameters, status: false }));
    })
    .catch(e => {
      console.error('ERROR API CALL ', id, ' error ', e);
      dispatch(setWaitingResponse({ ...parameters, status: false }));
    });

  return axiosCall;
};

export const useLoadStatus = id => {
  // get info about loading status, if id is empty load the whole data a an object
  const isLoading = useSelector(state => state?.apiData?.loading);

  return id ? isLoading && isLoading[id.toLowerCase()] : isLoading;
};
