import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Switch } from 'react-router-dom';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createBrowserHistory } from 'history';
import Websocket from './components/Websocket/Websocket';
import './sass/App.scss';
import store from './state/store';
import LeftSidePanel from './components/Layout/LeftSidePanel';
import RightSidePanel from './components/Layout/RightSidePanel';
import Login from './pages/Login/Login';
import PublicRoute from './components/Router/PublicRoute';
import Logout from './pages/Login/Logout';
import * as IndexActions from './state/actions/index-operations-actions';
import * as UniverseActions from './state/actions/universe-operations-actions';
import { BitaInformativeModal } from './components/BitaModal/BitaModal';
import BacktestCompletedModal from './components/BacktestModal/BacktestCompletedModal';
import BacktestInProgressModalB from './components/BacktestModal/BacktestInProgressModalB';

// Esto es para configurar las notificaciones
toast.configure({
  autoClose: 9000,
  hideProgressBar: true,
});

function App() {
  // Esto es para inicializar los widgets de operaciones desde el arvhico en public/init-components-default.json. La idea es que puedan haber varios dependiendo del cliente a mostar.
  useEffect(() => {
    store.dispatch(IndexActions.initOperationWidgets());
    store.dispatch(UniverseActions.initOperationWidgets());
  }, []);

  const history = createBrowserHistory();
  //* * Disable copy paste for production */
  const enableCopyPaste =
    process.env.REACT_APP_API_URL === 'https://api.bitacore.com/v1' ? { userSelect: 'none' } : {};
  return (
    <div style={enableCopyPaste}>
      <Provider store={store}>
        <BitaInformativeModal />
        <Websocket>
          <Router history={history}>
            <BacktestCompletedModal history={history} />
            <BacktestInProgressModalB />
            <Switch>
              <PublicRoute exact path="/login" component={Login} />
              <PublicRoute exact path="/logout" component={Logout} />
              <div className="main">
                <LeftSidePanel />
                <RightSidePanel />
              </div>
            </Switch>
          </Router>
        </Websocket>
      </Provider>
    </div>
  );
}

export default App;
