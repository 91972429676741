import { toast } from 'react-toastify';
import * as _ from 'lodash';
import axios from '../../Axios';
import * as ACTIONS from '../../constants/backtest-constants';
import * as SummaryConstants from '../../constants/summary-constants';
import { URL_CONSTANTS as URL } from '../../api-data-mapping/url-constants';
import { loadFilesFTP } from './download-actions';

import { cleanCache } from './summary-actions';
import { cleanProject } from './project-actions';
import * as STATUS from '../../constants/backtest-status';

import * as API_DATA_ACTIONS from './api-data-actions';
import { loaderAxiosDecorator } from '../../utils/loading_status_tools';

export const setBacktest = data => {
  return { type: ACTIONS.SET_BACKTEST, payload: { data } };
};

export const setIndexSubtype = subType => ({
  type: ACTIONS.STORE_INDEX_SUBTYPE,
  payload: subType,
});

/*
 * @deprecated
 * */
export const setSiConstituents = n_constituents => ({
  type: ACTIONS.STORE_SI_N_CONSTITUENTS,
  payload: n_constituents,
});

/*
 * @deprecated
 * */
export const setSiInstrument = instrument => ({
  type: ACTIONS.STORE_STRUCTURED_INSTRUMENT,
  payload: instrument,
});

/*
 * @deprecated
 * */
export const setParentIndexId = id => ({
  type: ACTIONS.STORE_PARENT_INDEX_ID,
  payload: id,
});

export const setParentIndex = indexName => ({
  type: ACTIONS.STORE_PARENT_INDEX,
  payload: indexName,
});

export const setParentIndexStartDate = indexStartDate => ({
  type: ACTIONS.STORE_PARENT_INDEX_STARTDATE,
  payload: indexStartDate,
});

export const setFormData = data => {
  return { type: ACTIONS.SET_FORM_DATA, payload: data };
};

export const setRiskModel = data => {
  return { type: ACTIONS.SET_RISK_MODEL, payload: { data } };
};

export const setBacktestFrontParameters = front_parameters => ({
  type: ACTIONS.STORE_FRONT_PARAMETERS,
  payload: front_parameters,
});

export const setBacktestType = type => ({
  type: ACTIONS.STORE_TYPE,
  payload: type,
});

export const setBacktestTime = () => ({
  type: ACTIONS.BACKTEST_TIME,
});

export const hideRunningBacktest = hide => ({
  type: ACTIONS.HIDE_BACKTEST,
  payload: { hide },
});

/**
 * @deprecated
 * @param backtestinbackground
 * @param popUpData
 * @returns {{payload: {popUpData, backtestinbackground}, type: string}}
 */
export const runBacktestInBackground = ({ backtestinbackground, popUpData }) => ({
  type: 'RUN_BACKTEST_IN_BACKGROUND/deprecated',
  payload: { backtestinbackground, popUpData },
});

/**
 * @deprecated
 * @param display
 * @returns {{payload, type: string}}
 */
export const setBacktestModalMustDisplay = display => ({
  type: ACTIONS.SET_BACKTEST_MODAL_DISPLAY,
  payload: display,
});

// cambia el status del backtest, si está siendo mostrado lo elimina
export const changeBacktestStatus = (id, newStatus) => ({
  type: ACTIONS.BACKTEST_STATUS_CHANGE,
  payload: { id, newStatus },
});

export const setRunningBacktestModalMustDisplay = display => ({
  type: ACTIONS.SET_RUNNING_BACKTEST_MODAL_DISPLAY,
  payload: display,
});

/**
 * @deprecated use changeBacktestStatus to cancel some backtest
 * @param removeRunningBacktest
 * @returns {{payload: {removeRunningBacktest}, type: string}}
 */
export const discardBacktest = removeRunningBacktest => {
  return {
    type: 'ACTIONS.DISCARD_BACKTEST/deprecated',
    payload: { removeRunningBacktest },
  };
};

export const storeInformationMessage = (backtestID, message) => {
  return {
    type: ACTIONS.STORE_INFO_MESSAGE,
    payload: { id: backtestID, message },
  };
};

export const displayRunningBacktestWithId = id => {
  return {
    type: ACTIONS.DISPLAY_RUNNING_BACKTEST_WITH_ID,
    payload: id,
  };
};

export const displayCompletedBacktestWithId = id => {
  return {
    type: ACTIONS.DISPLAY_COMPLETE_BACKTEST_WITH_ID,
    payload: id,
  };
};

export const storeBacktestError = id => {
  return {
    type: ACTIONS.STORE_BACKTEST_ERROR,
    payload: id,
  };
};

export const cleanBacktestData = () => ({
  type: ACTIONS.CLEAN_BACKTESTS,
});

export const dateFirsTRevisionBacktest = (token, idUniverse) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}/first-revision?universe=${idUniverse}`;
  axios.get(url, { headers: { Authorization: `Bearer ${token}` } }).then(rsp => {
    dispatch(
      API_DATA_ACTIONS.setApiData({
        title: 'Date First Revision Backtest',
        data: rsp.data,
      }),
    );
  });
};

export const getUniverseById = (token, id) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}/universe/${id}`;
  axios.get(url, { headers: { Authorization: `Bearer ${token}` } }).then(rsp => {
    dispatch(
      API_DATA_ACTIONS.setApiData({
        title: 'universeSelected',
        data: { ...rsp.data.data[0] },
      }),
    );
  });
};

export const getMethodologyById = (token, id) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}/methodology/${id}`;
  axios.get(url, { headers: { Authorization: `Bearer ${token}` } }).then(rsp => {
    dispatch(
      API_DATA_ACTIONS.setApiData({
        title: 'methodologySelected',
        data: { ...rsp.data.data[0].rules, id },
      }),
    );
  });
};

export const acceptBacktest = ({
  companyId,
  token,
  payload: { backtest, history, apiData, wasHide },
  postAccept,
  generateFTP,
  universeIdSelected = null,
  methodologyIdSelected = null,
}) => dispatch => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  // debugger;
  try {
    // right now this is "hardcoded but in the future would be possible run
    // several backtest at time
    // const matchingBacktestId = backtestId || Object.keys(backTestState.backtestSaveStats)[0];
    const saveStats = backtest.backtestSaveStats;
    const getTimeSeries = _.get(saveStats, 'TIMESERIES', []);
    const getSingleSeries = _.get(saveStats, 'SINGLE', []);
    let showErrorToast = true;

    const data = {
      id_backtest: backtest.id,
      index_type: backtest?.data?.type || 'regular',
      front_parameters: backtest?.data?.front_parameters || {},
      company: companyId,
      universe: universeIdSelected ?? apiData.universeSelected?.id,
      methodology: methodologyIdSelected ?? apiData.methodologySelected?.id,
      name: backtest?.data?.['INDEX NAME'] || backtest?.data?.['PORTFOLIO NAME'],
      risk_model: backtest?.data?.riskModelSelected?.id || backtest?.data?.risk_model_id?.id,
      benchmark: backtest?.data?.Benchmark?.id,
      base_value: backtest?.data?.['BASE VALUE'],
      inception_date: backtest?.data?.['Inception Date'],
      start_date: backtest?.data?.['Start Date'],
      end_date: backtest?.data?.['End Date'],
      sector_provider: backtest?.data?.['Sector Classification']?.value || 'gics_sector',
      parent_index:
        Number(backtest?.data?.parent_index_id) || Number(backtest.parent_index_id) || null,
      stats: {
        ts: getTimeSeries,
        single: getSingleSeries,
        'ts-json': _.get(saveStats, 'JSON TIMESERIES', []),
        json: _.get(saveStats, 'JSON', []),
      },
      obj_data: {
        data: getTimeSeries.filter(s => s.m_stats_id === 45),
        '1YV': getSingleSeries.filter(s => s.m_stats_id === 3)[0]
          ? getSingleSeries.filter(s => s.m_stats_id === 3)[0].stat_value
          : 0,
        '1YR': getSingleSeries.filter(s => s.m_stats_id === 21)[0]
          ? getSingleSeries.filter(s => s.m_stats_id === 21)[0].stat_value
          : 0,
      },
      ...backtest.backtestIndexData,
    };

    const postData = loaderAxiosDecorator(
      dispatch,
      'saveBacktest',
    )(axios.post(URL.SAVE_INDEX, data, { headers }));

    postData
      .then(rsp => {
        showErrorToast = false;
        // dispatch(setBacktestModalMustDisplay(false));

        dispatch(API_DATA_ACTIONS.setIndexesList(token));
        dispatch(API_DATA_ACTIONS.setCardStats(token));

        // dispatch(discardBacktest());
        dispatch(
          API_DATA_ACTIONS.setApiData({
            title: 'Optimized Index',
            data: {},
          }),
        );
        if (data.index_type === 'rebalancing_module') {
          dispatch(
            API_DATA_ACTIONS.setApiData({
              title: 'modal',
              data: { open: true, text: 'Rebalancing Saved' },
            }),
          );
        } else {
          dispatch(
            API_DATA_ACTIONS.setApiData({
              title: 'modal',
              data: { open: true, text: 'Backtest Saved' },
            }),
          );
        }

        dispatch(changeBacktestStatus(backtest.id, STATUS.BACKTEST_SAVED));
        const userLocation = history.location?.pathname || false;
        const analyzeTab =
          userLocation === '/structures-product/structures-product'
            ? 2
            : userLocation === '/portfolio-basket-calculation/portfolio-basket-calculation'
            ? 3
            : 0;

        if (userLocation === '/optimized-index/optimized-index') {
          history.push('/index-analyzer/analyzer?tab=1');
        } else if (
          !wasHide ||
          (userLocation &&
            userLocation.search('universe') === -1 &&
            userLocation.search('methodology') === -1 &&
            userLocation.search('optimized') === -1)
        ) {
          dispatch(cleanCache(token));
          dispatch(cleanProject(token, true));
          dispatch({
            type: SummaryConstants.RESET,
          });
          history.push(`/index-analyzer/analyzer?tab=${analyzeTab}`);
        }
        if (postAccept) {
          postAccept();
        }
        if (generateFTP) {
          const requestFTP = { index: rsp.data.data.id, ...generateFTP };
          dispatch(loadFilesFTP(token, requestFTP));
        }
        return Promise.resolve(rsp);
      })
      .catch(e => {
        dispatch(cleanCache(token));
        dispatch(cleanProject(token, true));
        dispatch({
          type: SummaryConstants.RESET,
        });
        dispatch(changeBacktestStatus(backtest.id, STATUS.BACKTEST_CANCELLED));
        // dispatch(discardBacktest());
        dispatch(
          API_DATA_ACTIONS.setApiData({
            title: 'Optimized Index',
            data: {},
          }),
        );
        console.error('backtest api response[x] ERROR', e);
        if (showErrorToast) {
          toast.error("Can't save backtest. Please try again.");
        }
        return Promise.resolve(e);
      });
  } catch (error) {
    console.error('backtest-action[x]: ERROR ', error);
    dispatch(cleanCache(token));
    dispatch(cleanProject(token, true));
    dispatch({
      type: SummaryConstants.RESET,
    });
    // dispatch(discardBacktest());
    dispatch(
      API_DATA_ACTIONS.setApiData({
        title: 'Optimized Index',
        data: {},
      }),
    );
    history.push('/');
    toast.error("Can't save the backtest, please try again");
    return Promise.resolve(error);
  }
  return Promise.resolve(token);
};

export const addBacktestDetails = details => ({
  type: ACTIONS.ADD_BACKTEST_DETAILS,
  payload: details,
});

export const getBacktestDetails = () => ({
  type: ACTIONS.GET_BACKTEST_DETAILS,
});
