/* eslint import/no-unresolved: 2 */
const YAML = require('yamljs');
const jp = require('jsonpath');

window.jp = jp;

/*
Singleton allow access to the yaml document like ConfigYAML.DOCUMENT
also you can use jsonpath for get some nested node using
ConfigYAML.jsonPath, for instance

ConfigYAML.jsonPath('$..reconstitution..dropdown') get all dropdown inside Reconstitution

check docs for more details https://www.npmjs.com/package/jsonpath
 */
class ConfigYAMLSingleton {
  constructor() {
    const DOC_PATH = `${process.env.PUBLIC_URL}/config.yaml`;
    this.DOCUMENT = this._fixKeys(YAML.load(DOC_PATH));
    this.optimizer = this._fixKeys(YAML.load(`${process.env.PUBLIC_URL}/optimizer.yaml`));
    // TODO uncomment if you want to make debug in the browser console
    window.DOCUMENT = this.DOCUMENT;
    // window.jp = jp;
  }

  // this method noramize if it's necesary the query converting from array to jspath
  // eslint-disable-next-line class-methods-use-this
  _normalizeQuery(query) {
    if (Array.isArray(query)) {
      return `$..${query.join('..')}`;
    }
    return query;
  }

  /*
  this function replace all keys in the yaml removing spaces and downcase them
  this is preferable when you use json and even more important, for use json path without troubles
   */
  _fixKeys(o) {
    const resultingObject = {};
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const key of Object.keys(o)) {
      const changedKey = key.toLowerCase().replace(/\s/g, '_');
      let value = o[key];

      if (value && typeof value === 'object') {
        value = this._fixKeys(value);
      }
      resultingObject[changedKey] = value;
    }
    return resultingObject;
  }

  // check if value is a template, if it is..then get the template value, else return the same input
  _getValueOrTemplateValues(value) {
    const rgx = /template\(([/A-Za-z0-9_-]+)\)/;
    if (value && typeof value === 'string' && rgx.test(value)) {
      const path = rgx.exec(value);

      if (path.length < 2) throw new Error(`template node ${value} has an incorrect formath`);

      return this._getDeepNodes(this.jsonPath(`$${path[1].replace(/\//g, '..')}`));
    }
    return value;
  }

  // check if posibly the value is an array but using object format..it it is..then convert to plain object
  // eslint-disable-next-line class-methods-use-this
  _convertToArrayWhenRequired(probablyArray) {
    if (typeof probablyArray === 'object' && probablyArray[0]) return Object.values(probablyArray);

    return probablyArray;
  }

  // recursive method for attach templates to response
  _getDeepNodes(objNode) {
    const deepNodes = {};

    // eslint-disable-next-line no-restricted-syntax
    for (const objkey of Object.keys(objNode)) {
      let value = objNode[objkey];

      // if values are object recursively get the nodes
      // replace value and attach to the resulting object
      if (value && typeof value === 'object') {
        value = this._getDeepNodes(value);
        deepNodes[objkey] = this._convertToArrayWhenRequired(value);
        // eslint-disable-next-line no-continue
        continue;
      }

      value = this._getValueOrTemplateValues(value);

      deepNodes[objkey] = value;
    }
    return deepNodes;
  }

  // get nodes inside some path, additionally you can resolve templates in nested path or avoid them
  // and just get the string pointing to the template like "template(some/componente)"
  jsonPath(path, deepNodes = false) {
    let values = jp.query(this.DOCUMENT, this._normalizeQuery(path))[0];

    if (deepNodes && typeof values === 'string') {
      return this._getValueOrTemplateValues(values);
    }

    if (deepNodes && typeof values === 'object') {
      values = this._getDeepNodes(values);
    }

    return this._convertToArrayWhenRequired(values);
  }

  jsonPathOptimizer(path, count) {
    return jp.query(this.DOCUMENT, path, count);
  }
}

const ConfigYAML = new ConfigYAMLSingleton();
Object.freeze(ConfigYAML);
export default ConfigYAML;
