import React, { useState } from 'react';
import BitaSearchBar from '../../../bitaComponents/BitaSearchBar/BitaSearchBar';
import BitaTable from '../../../bitaComponents/BitaTable/BitaTable';
import style from '../PortfolioBasketCalculation.module.scss';
import { isFloatWithMoreThanTwoDecimals } from '../utils';

const BasketTableComponent = ({ basketsData, basket }) => {
  const renderBitaTable = () => {
    const columns = Object.keys(basketsData?.[basket]?.metadata);
    columns.unshift('constituents');

    const rows = Object.keys(basketsData[basket]?.metadata.awf).map(constituent => {
      const rowData = {
        constituents: constituent,
      };

      Object.keys(basketsData[basket].metadata).forEach(key => {
        rowData[key] =
          key === 'divisor'
            ? isFloatWithMoreThanTwoDecimals(basketsData[basket].metadata[key]) ||
              basketsData[basket].metadata[key]
            : isFloatWithMoreThanTwoDecimals(basketsData[basket].metadata[key][constituent]) ||
              basketsData[basket].metadata[key][constituent];
      });

      return rowData;
    });

    return (
      <>
        <BitaTable
          data={rows}
          search={false}
          showFields={columns}
          selectAction={() => {}}
          CustomRowComponent={({ value }) => (
            <div>
              <p style={{ paddingRight: '20px' }}>{value}</p>
            </div>
          )}
        />
      </>
    );
  };

  return <div>{renderBitaTable()}</div>;
};

const BulkPrevieBaskets = ({ ListBasket, basketsData }) => {
  const [selectOptionPreview, setSelectOptionPreview] = useState(ListBasket[0]);

  return (
    <div className={style.contentWidget}>
      <div className={style.bulkContainterTitleTable}>
        <div style={{ border: 'none', marginBottom: '10px' }}>Basket Data Preview</div>

        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <div style={{ marginBottom: '10px', paddingRight: '20px' }}>SELECTED BASKET</div>
          <div style={{ width: '200px' }}>
            <BitaSearchBar
              searchOptions={ListBasket}
              style={{ with: '200px', float: 'right' }}
              value={selectOptionPreview.label}
              onChange={value => setSelectOptionPreview(value)}
            />
          </div>
        </div>
      </div>
      <BasketTableComponent basket={selectOptionPreview?.value} basketsData={basketsData} />
    </div>
  );
};

export { BulkPrevieBaskets, BasketTableComponent };
