/* eslint-disable array-callback-return */
// TODO KAREM, luego de corregirlo se debe poder deshabilitar esto
// eslint-disable react-hooks/rules-of-hooks
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import lodash from 'lodash';
import { useLocation } from 'react-router';
import jsonpath from 'jsonpath';
import { toast } from 'react-toastify';
import { setBy } from '../../helpers/deepSet';
import { useCurrentModule, useSummary } from '../../../bitaHooks';
import styles from '../../builder.module.scss';
import { defaultActiveApplyStructure } from '../../../constants/structured-index-active-apply-constants';
import Switcher from '../../../bitaComponents/Switcher/Switcher';
import BitaButton from '../../../bitaComponents/BitaButton/BitaButton';
import { riskControlInterestRate } from '../../../utils/utils';
// eslint-disable-next-line import/no-cycle
import { ComponentGenerator } from '../Components';

export const BuilderWidgetComponent = ({
  toggled_by_default = false,
  disabled_toggle = false,
  title,
  text,
  path = '',
  components = null,
  onChange,
  universe,
  currentView,
  historyPlan = {},
  nobuttons = false,
  initWidgetData,
}) => {
  const [widgetData, setWidgetData] = useState(initWidgetData || {});
  const [summaryWidgetData, setSummaryWidgetData] = useState(initWidgetData || {});
  const [summaryData, setSummaryData] = useState({});
  const summary = useSelector(state => state.summary);
  const [addToSummary] = useSummary(universe, `${currentView}.${title}`);
  const [a, removeFromSummary] = useSummary(universe, `${currentView}`);
  const [checked, setChecked] = useState(toggled_by_default || false);
  const [activeApply, setActiveApply] = useState(false);
  const [handleDeleteApply, setHandleDeleteApply] = useState(true);

  const { pathname } = useLocation();
  let status = 'ACTIVE';
  const userPlan = {};
  // eslint-disable-next-line array-callback-return
  const modules = useSelector(state => state.auth.plan.modules);
  if (modules)
    modules.forEach(module => {
      userPlan[module.name] = module;
    });

  useEffect(() => {
    const idRequired = [];
    const opcionalRequired = [];
    const _idRequiereInclude = [];
    jsonpath.nodes(components, `$..required`).map(item => idRequired.push(item.value));
    jsonpath
      .nodes(components, `$..opcionalrequired`)
      .map(item => opcionalRequired.push(item.value));
    if (idRequired > 0) idRequired.unique();
    idRequired.map(itemRequired => {
      if (
        jsonpath.nodes(widgetData, `$..['${itemRequired}']`).length > 0 &&
        jsonpath.nodes(widgetData, `$..['${itemRequired}']`)?.[0]?.value
      ) {
        _idRequiereInclude.push(jsonpath.nodes(widgetData, `$..['${itemRequired}']`).length);
      }
    });

    if (idRequired.length === _idRequiereInclude.length || idRequired.length === 0) {
      setActiveApply(true);
      if (components?.[0].id === 'Rebalancing') {
        const { Rebalancing } = widgetData;
        setActiveApply(false);
        if (
          Rebalancing?.Rebalancing !== 'Monthly' &&
          Rebalancing?.['Ex-Date'] !== ' ' &&
          Rebalancing?.Day?.id &&
          Rebalancing?.['Determination date']?.id &&
          Rebalancing?.Months?.id
        ) {
          setActiveApply(true);
        }
        if (
          Rebalancing?.Rebalancing === 'Monthly' &&
          Rebalancing?.['Ex-Date'] !== ' ' &&
          Rebalancing?.Day?.id &&
          Rebalancing?.['Determination date']?.id
        ) {
          setActiveApply(true);
        }
      }
      if (components?.[0].id === 'Reconstitution') {
        const { Reconstitution } = widgetData;
        setActiveApply(false);
        if (
          Reconstitution?.Reconstitution !== 'Monthly' &&
          Reconstitution?.['Ex-Date'] !== ' ' &&
          Reconstitution?.Day?.id &&
          Reconstitution?.['Determination date']?.id &&
          Reconstitution?.Months?.id
        ) {
          setActiveApply(true);
        }
        if (
          Reconstitution?.Reconstitution === 'Monthly' &&
          Reconstitution?.['Ex-Date'] !== ' ' &&
          Reconstitution?.Day?.id &&
          Reconstitution?.['Determination date']?.id
        ) {
          setActiveApply(true);
        }
      }
      if (components?.[0].id === 'Floors') {
        setActiveApply(false);
        const caps = widgetData?.Floors?.Floors;
        // eslint-disable-next-line no-unused-expressions
        let validation = false;
        for (let i = 0; i < caps?.length; i += 1) {
          if (
            (caps[i]?.Selected?.value === 'Security' ||
              caps[i]?.Selected?.value === 'Country' ||
              caps[i]?.Selected?.value === 'Sector') &&
            !caps[i]?.['Floors Value']
          ) {
            validation = false;
            break;
          }
          validation = true;
        }
        setActiveApply(validation);
      }
      if (components?.[0].id === 'Caps') {
        setActiveApply(false);
        const caps = widgetData?.Caps?.Caps;
        // eslint-disable-next-line no-unused-expressions
        let validation = true;
        for (let i = 0; i < caps?.length; i += 1) {
          if (
            (caps[i]?.Selected?.value === 'Issuer' ||
              caps[i]?.Selected?.value === 'Security' ||
              caps[i]?.Selected?.value === 'Industry') &&
            !caps[i].addibleinput
          ) {
            validation = false;
            break;
          }
          if (caps[i]?.Selected?.value === 'Country') {
            if (
              caps[i]?.Selected?.value === 'Country' &&
              (!caps[i]?.CapType || caps[i]?.CapType?.value === 'Generic') &&
              !caps[i].addibleinput
            ) {
              validation = false;
              break;
            }
            if (
              caps[i]?.Selected?.value === 'Country' &&
              caps[i]?.CapType?.value === 'Specific' &&
              !caps[i]?.countryCap
            ) {
              validation = false;
              break;
            }
            if (
              caps[i]?.Selected?.value === 'Country' &&
              caps[i]?.CapType?.value === 'Specific' &&
              caps[i]?.countryCap &&
              !(
                (caps[i]?.countryCap ?? [])?.length ===
                (caps[i]?.countryCap ?? [])
                  ?.map(item => item.cap)
                  ?.filter(item => item !== undefined && item !== '')?.length
              )
            ) {
              validation = false;
              break;
            }
            validation = true;
          }
          if (caps[i]?.Selected?.value === 'Sector') {
            if (
              caps[i]?.Selected?.value === 'Sector' &&
              (!caps[i]?.SectorType || caps[i]?.SectorType?.value === 'Generic') &&
              !caps[i].addibleinput
            ) {
              validation = false;
              break;
            }
            if (
              caps[i]?.Selected?.value === 'Sector' &&
              caps[i]?.SectorType?.value === 'Specific' &&
              !caps[i]?.sectorCap
            ) {
              validation = false;
              break;
            }
            if (
              caps[i]?.Selected?.value === 'Sector' &&
              caps[i]?.SectorType?.value === 'Specific' &&
              caps[i]?.sectorCap &&
              !(
                (caps[i]?.sectorCap ?? [])?.length ===
                (caps[i]?.sectorCap ?? [])
                  ?.map(item => item.cap)
                  ?.filter(item => item !== undefined && item !== '')?.length
              )
            ) {
              validation = false;
              break;
            }
          }

          if (
            caps[i]?.Selected?.value === 'Liquidity' &&
            !(
              caps[i]?.liquidity_column?.id &&
              caps[i]?.adtv_threshold &&
              caps[i]?.investment_threshold
            )
          ) {
            validation = false;
            break;
          }
          if (
            (caps[i]?.Selected?.value === 'Individual Issuer (UCITS group)' ||
              caps[i]?.Selected?.value === 'Individual Securities (UCITS group)') &&
            !(caps[i]?.combination_threshold && caps[i]?.combined_cap_limit)
          ) {
            validation = false;
            break;
          }
        }
        setActiveApply(validation);
      }
      if (components?.[0].id === 'number_of_constituents') {
        const numberConstituents = widgetData?.['Number of constituents'];
        setActiveApply(false);
        if (
          numberConstituents?.index_len &&
          numberConstituents?.index_len !== undefined &&
          numberConstituents?.number_of_constituents?.id &&
          numberConstituents?.rank_by?.id &&
          numberConstituents?.ranking_type?.id
        ) {
          setActiveApply(true);
        }
        if (
          numberConstituents?.group_by?.id &&
          (numberConstituents?.group_by.value === 'BITA Assigned Country' ||
            numberConstituents?.group_by.value === 'Country of Primary Listing') &&
          numberConstituents?.number_of_constituents?.id &&
          numberConstituents?.rank_by_buffer?.id &&
          numberConstituents?.ranking_type?.id &&
          !numberConstituents?.value_default_nro_constituents
        ) {
          setActiveApply(true);
        }
        if (
          numberConstituents?.group_by?.id &&
          !(
            numberConstituents?.group_by.value === 'BITA Assigned Country' &&
            numberConstituents?.group_by.value === 'Country of Primary Listing'
          ) &&
          numberConstituents?.number_of_constituents?.id &&
          numberConstituents?.rank_by_buffer?.id &&
          numberConstituents?.ranking_type?.id &&
          !numberConstituents?.value_default_nro_constituents &&
          numberConstituents?.target_range &&
          numberConstituents?.upper_buffer &&
          numberConstituents?.lower_buffer
        ) {
          setActiveApply(true);
        }
        if (
          numberConstituents?.group_by?.id &&
          numberConstituents?.number_of_constituents?.id &&
          numberConstituents?.rank_by_buffer?.id &&
          numberConstituents?.ranking_type?.id &&
          numberConstituents?.value_default_nro_constituents === 'Select Values' &&
          numberConstituents?.target_range &&
          numberConstituents?.upper_buffer &&
          numberConstituents?.lower_buffer
        ) {
          setActiveApply(true);
        }
      }

      if (
        widgetData?.['Fast Entry']?.['Buffer options']?.value === 'Buffer Position' &&
        widgetData?.['Fast Entry']?.['Buffer Position'] === undefined
      ) {
        setActiveApply(false);
      }
    } else {
      setActiveApply(false);

      if (
        opcionalRequired.length > 0
        // &&
        // widgetData?.['Weighting mechanism']?.['Weight by']?.value !== 'Fundamental' &&
        // widgetData?.['Weighting mechanism']?.['Weight by']?.value !== 'ESG Scores' &&
        // widgetData?.['Weighting mechanism']?.['Weight by']?.value !== 'Factor Exposure'
      ) {
        opcionalRequired.map(itemRequired => {
          if (
            jsonpath.nodes(widgetData, `$..['${itemRequired}']`).length > 0 &&
            jsonpath.nodes(widgetData, `$..['${itemRequired}']`)?.[0]?.value
          ) {
            if (
              widgetData?.['Fast Entry']?.['Buffer options']?.value === 'Buffer Percentage' &&
              widgetData?.['Fast Entry']?.['Buffer Percentage'] === undefined
            ) {
              setActiveApply(false);
            } else {
              setActiveApply(true);
            }
          }
        });
      }
      // Solo para el caso de Methodology Rebalancing & Reconstitution, Reconstitution same as Rebalancing
      if (
        components?.[0].id === 'Reconstitution' &&
        widgetData?.Reconstitution?.Reconstitution === 'Same as Rebalancing'
      ) {
        setActiveApply(true);
      }
    }
    const securitiesConsiderations = widgetData?.['Securities Considerations'];

    const isMissingMinimumCriteria =
      securitiesConsiderations &&
      ((securitiesConsiderations.minimum_free_float_percentage &&
        !securitiesConsiderations[
          'Only include instruments whose Free Float Market Cap is more than'
        ]) ||
        (securitiesConsiderations.minimum_foreign_room &&
          !securitiesConsiderations['Only include instruments whose Foreign Room is more than']) ||
        (securitiesConsiderations.minimum_free_float_available &&
          !securitiesConsiderations[
            'Only include instruments whose Free Float Available to Foreign Investors is more than'
          ]) ||
        (securitiesConsiderations.minimum_foreign_ownership_limit &&
          !securitiesConsiderations[
            'Only include instruments whose Foreign Ownership Limit is more than'
          ]));

    const isMissingRules =
      securitiesConsiderations &&
      ((securitiesConsiderations.ipo_rule && !securitiesConsiderations.Period?.id) ||
        (securitiesConsiderations.share_class_rule && !securitiesConsiderations.Rule) ||
        securitiesConsiderations.Rule === ' ');

    const isMissingHighestRankConsiderationFactor =
      securitiesConsiderations?.Rule?.value === 'Highest Ranked' &&
      (!securitiesConsiderations?.ranking_type || !securitiesConsiderations?.rank_by);

    const isMissingNumberOfConstituents =
      securitiesConsiderations?.Rule?.value === 'Highest Ranked' &&
      !summary?.['Index Builder']?.['General Rules']?.['Number of constituents']
        ?.number_of_constituents?.value;

    if (
      isMissingMinimumCriteria ||
      isMissingRules ||
      isMissingHighestRankConsiderationFactor ||
      isMissingNumberOfConstituents
    ) {
      setActiveApply(false);
    }
  }, [widgetData]);

  useEffect(() => {
    if (
      widgetData?.['Securities Considerations']?.Rule?.value === 'Highest Ranked' &&
      !summary?.['Index Builder']?.['General Rules']?.['Number of constituents']
        ?.number_of_constituents?.value
    ) {
      toast.error(
        'Validation Failed. For the "Highest Ranked Shareclass Consideration" rule, please provide input for the "Number of Constituents" in the "General Rules" section.',
      );
    }
  }, [widgetData?.['Securities Considerations']?.Rule?.value === 'Highest Ranked']);

  useEffect(() => {
    // validate the apply button when i remove number of constituents and i have highest ranked option
    const isNumberOfConstituentsRemoved = !summary?.['Index Builder']?.['General Rules']?.[
      'Number of constituents'
    ]?.number_of_constituents?.value;

    const isHighestRankedSelected =
      widgetData?.['Securities Considerations']?.Rule?.value === 'Highest Ranked';

    if (isNumberOfConstituentsRemoved && isHighestRankedSelected) {
      setActiveApply(false);
    }
    if (lodash.has(summary, `${universe}.${currentView}.${title}`)) {
      setSummaryData(summary[universe][currentView]);
    } else {
      setSummaryData({});
    }
  }, [summary]);

  useEffect(() => {
    if (lodash.has(summary, `${universe}.${currentView}.${title}`)) {
      setWidgetData(summary[universe][currentView]);
      setSummaryData(summary[universe][currentView]);
      setChecked(true);
    } else if (!initWidgetData) {
      setWidgetData({});
      setSummaryData({});
      setChecked(false);
    }
    if (toggled_by_default) {
      setChecked(true);
    }
  }, []);

  useEffect(() => {
    if (onChange) onChange(path, { ...(Object.keys(widgetData).length > 0 && { ...widgetData }) });
  }, [widgetData]);

  const widgetChange = (_path, value, final, erase) => {
    if (title === 'Securities Considerations') {
      setWidgetData(old => setBy(old, `${title}.${_path || erase}`, value));
    } else if (_path) {
      setWidgetData(old => setBy(old, `${title}.${_path}`, value));
    } else {
      setWidgetData(old => ({ [title]: value }));
    }
  };

  const getCapitalice = auxString => {
    return auxString.replace(/\b[a-z]/g, c => c.toUpperCase());
  };

  const module = useCurrentModule()[0];
  if (!historyPlan.module && Object.values(userPlan).lenght > 0) {
    historyPlan.module = module.toUpperCase();
    if (historyPlan.module === 'STRUCTURED INDEX') {
      historyPlan.module = 'STRUCTURED INDEX BUILDER';
    }

    const location = pathname.split('/');
    let currentViewUrl = location[location.length - 1].replace('-', ' ');

    if (currentViewUrl === 'structures product') {
      currentViewUrl = 'STRUCTURED INDEX BUILDER';
    }

    let submodule = false;
    if (historyPlan.module !== currentViewUrl.toUpperCase()) {
      submodule = currentViewUrl;
      historyPlan.sub_module = currentViewUrl.toUpperCase();
    }

    const getItemPlan = (plan, _submodule, _title) => {
      const auxTitleCapitalice = getCapitalice(_title);
      if (_submodule) {
        const auxModuleCapitalice = getCapitalice(_submodule);
        const newPath = `$..*[?(@.name === "${_submodule}" || @.name === "${_submodule.toUpperCase()}" || @.name === "${auxModuleCapitalice}" )].*[?(@.name === "${_title}" || @.name  === "${_title.toUpperCase()}"  || @.name === "${auxTitleCapitalice}")]`;
        return jsonpath.nodes(plan, newPath);
      }
      const newPath = `$..*[?(@.name === "${_title}" || @.name === "${_title.toUpperCase()}" || @.name === "${auxTitleCapitalice}" )]`;
      return jsonpath.nodes(plan, newPath);
    };

    const getPlan = getItemPlan(userPlan[historyPlan.module], submodule, title);
    if (getPlan.length > 0 && getPlan[0].value) {
      status = getPlan[0].value.status_plan;
    }
  }

  const sendDataWidget = data => {
    if (data?.Caps) {
      data.Caps.map(item => {
        if (item?.Selected?.value === 'Country' || item?.Selected?.value === 'Sector') {
          if (item.CapType?.value === 'Generic' || item?.SectorType?.value === 'Generic') {
            delete item?.sectorCap;
            delete item?.countryCap;
          } else {
            delete item?.addibleinput;
          }
        }
      });
    }
    return data?.interest_rate?.value ? riskControlInterestRate(data) : data;
  };

  useEffect(() => {
    if (
      summary?.['Index Builder']?.['Additional Rules']?.['Securities Considerations']?.Rule
        ?.value === 'Highest Ranked' &&
      components?.[0].id === 'number_of_constituents'
    ) {
      setHandleDeleteApply(false);
    } else if (components?.[0].id === 'number_of_constituents') {
      setHandleDeleteApply(true);
    }
  }, [summary, widgetData]);

  return (
    <>
      <div
        className={`${styles.widgetSwitchComponentContainer} ${title} builderWidget`}
        style={{
          opacity: status === 'ACTIVE' ? '1' : '0.5',
          pointerEvents: status === 'ACTIVE' ? 'auto' : 'none',
          display: status === 'HIDDEN' ? 'none' : ' ',
        }}
      >
        <div className={styles.widgetHeaderContainer}>
          <span className={`${styles.widgetTitle} optimizationTitle `}>{title}</span>
          <span
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: '50%',
              opacity: disabled_toggle ? '0' : '1',
            }}
            className="contentSwitcher"
          >
            <Switcher.Switch onChange={check => setChecked(check)} checked={checked} />
          </span>
          <span className={`${styles.widgetDescription} widgetDescription`}>{text}</span>
        </div>
        {checked && components && (
          <div
            className={styles.widgetContent}
            style={{ flexDirection: title === 'Realized Volatility Intervals' ? 'column' : 'auto' }}
          >
            {Object.values(components).map(comp => (
              <div
                key={comp.id}
                style={{
                  display: 'flex',
                  paddingTop: '5px',
                  alignItems: 'center',
                  opacity: lodash.has(summary, `${universe}.${currentView}.${title}`) ? 0.5 : 1,
                  pointerEvents: lodash.has(summary, `${universe}.${currentView}.${title}`)
                    ? 'none'
                    : 'all',
                }}
              >
                <ComponentGenerator
                  onChange={widgetChange}
                  widgetData={widgetData[title]}
                  stepData={widgetData[title]}
                  summaryData={summaryData[title]}
                  path={path}
                  {...comp}
                  key={comp.id}
                />
              </div>
            ))}
            {!nobuttons && (
              <div className={styles.applyDeleteContainer}>
                <BitaButton
                  onClick={() => {
                    const sendData = sendDataWidget(widgetData[title]);
                    addToSummary(sendData);
                  }}
                  className={styles.applyDeleteButton}
                  disabled={
                    lodash.has(summary, `${universe}.${currentView}.${title}`) || !activeApply
                  }
                  width="90px"
                  height="30px"
                  primary
                >
                  Apply
                </BitaButton>
                <BitaButton
                  onClick={() => {
                    const aux = JSON.parse(JSON.stringify(widgetData));
                    delete aux[title];
                    setWidgetData(aux);
                    removeFromSummary(title);
                    setChecked(false);
                  }}
                  className={styles.applyDeleteButton}
                  disabled={
                    !lodash.has(summary, `${universe}.${currentView}.${title}`) ||
                    !handleDeleteApply
                  }
                  width="90px"
                  height="30px"
                  primary
                >
                  Delete
                </BitaButton>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};
