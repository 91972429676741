export const SET_BACKTEST = 'BACKTEST/SET';
export const SET_BACKTEST_MODAL_DISPLAY = 'BACKTEST/SET_MODAL_DISPLAY';
export const SET_RUNNING_BACKTEST_MODAL_DISPLAY = 'BACKTEST/SET_RUNNING_BACKTEST_MODAL_DISPLAY';
export const STORE_BACKTEST_METRIC = 'BACKTEST/STORE_BACKTEST_METRIC';
export const SET_BACKTEST_COMPLETED = 'BACKTEST/SET_BACKTEST_COMPLETED';
export const DISCARD_BACKTEST = 'BACKTEST/DISCARD_BACKTEST';
export const STORE_INFO_MESSAGE = 'BACKTEST/STORE_INFO_MESSAGE';
export const RUN_BACKTEST_IN_BACKGROUND = 'BACKTEST/RUN_BACKTEST_IN_BACKGROUND';
export const STORE_BACKTEST_ERROR = 'BACKTEST/STORE_BACKTEST_ERROR';
export const STORE_PDF = 'BACKTEST/STORE_PDF';
export const STORE_ID = 'BACKTEST/STORE_ID';
export const CLEAN_BACKTESTS = 'BACKTEST/CLEAN_BACKTESTS';
export const STORE_TYPE = 'BACKTEST/STORE_TYPE';
export const BACKTEST_TIME = 'BACKTEST/TIME';
export const BACKTEST_STATUS_CHANGE = 'BACKTEST/BACKTEST_STATUS_CHANGE';
export const SET_RISK_MODEL = 'BACKTEST/SET_RISK_MODEL';
export const SET_FORM_DATA = 'BACKTEST/SET_FORM_DATA';
export const HIDE_BACKTEST = 'BACKTEST/HIDE_BACKTEST';
export const DISPLAY_RUNNING_BACKTEST_WITH_ID = 'BACKTEST/DISPLAY_RUNNING_BACKTEST_WITH_ID';
export const DISPLAY_COMPLETE_BACKTEST_WITH_ID = 'BACKTEST/DISPLAY_COMPLETE_BACKTEST_WITH_ID';
export const CANCEL_BACKTEST = 'BACKTEST/CANCEL_BACKTEST';
export const STORE_PARENT_INDEX = 'BACKTEST/STORE_PARENT_INDEX';
export const STORE_INDEX_SUBTYPE = 'BACKTEST/STORE_INDEX_SUBTYPE';
export const STORE_SI_N_CONSTITUENTS = 'BACKTEST/STORE_SI_N_CONSTITUENTS';
export const STORE_STRUCTURED_INSTRUMENT = 'BACKTEST/STORE_STRUCTURED_INSTRUMENT';
export const STORE_PARENT_INDEX_ID = 'BACKTEST/STORE_PARENT_INDEX_ID';
export const STORE_PARENT_INDEX_STARTDATE = 'STORE_PARENT_INDEX_STARTDATE';
export const STORE_FRONT_PARAMETERS = 'BACKTEST/STORE_FRONT_PARAMETERS';
export const ADD_BACKTEST_DETAILS = 'ADD_BACKTEST_DETAIL';
export const GET_BACKTEST_DETAILS = 'GET_BACKTEST_DETAILS';
