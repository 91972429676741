import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaCheckSquare, FaTimes } from 'react-icons/fa';
import styles from './Sidebar.module.scss';
import BitaModal from '../../bitaComponents/BitaModal/BitaModal';
import BitaButton from '../../bitaComponents/BitaButton/BitaButton';
import { Icons } from '../../constants/icons';
import { changePassword } from '../../state/actions/auth-actions';

// This component is also being used in the topbar
const SideUserbar = props => {
  const userData = useSelector(state => state.auth.userdata);
  const [openModal, setOpenModal] = React.useState();
  const token = useSelector(state => state.auth.token);

  const [name, setName] = React.useState(`${userData.first_name} ${userData.last_name}`);
  const [password, setPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [passwordErrors, setPasswordErrors] = React.useState([]);
  const dispatch = useDispatch();

  const passwordPolicies = [
    { regex: /[a-z]/, message: 'Have at least one lowercase letter in the password.' },
    { regex: /[A-Z]/, message: 'Have at least one capital letter in the password.' },
    { regex: /\d/, message: 'Have at least one numeric character in the password.' },
    {
      regex: /^(?=.*[!@$%^&*()_+[\]{}:|.,<>/?])(?!.*[\\#'"=;-])/,
      message:
        'Have at least one special character: ! @ $ % ^ & * ( ) _ + = [ ] { } : | . , < > / ? but must not contain \\ # \' " = ; -',
    },
    { regex: /.{8,}/, message: 'The length of the password is at least 8 characters.' },
    { regex: /^\S*$/, message: 'Password must not contain spaces.' },
  ];

  useEffect(() => {
    setOpenModal(userData?.first_time_user || false);
  }, [userData]);

  const validatePassword = passwordInput => {
    const errors = [];
    if (passwordInput) {
      passwordPolicies.forEach(policy => {
        if (!policy.regex.test(passwordInput)) {
          errors.push(policy.message);
        }
      });
    }
    return errors;
  };

  const handlePasswordChange = event => {
    const newPassword = event.target.value;
    setPassword(newPassword);
    setPasswordErrors(validatePassword(newPassword));
  };

  const handleConfirmPasswordChange = event => {
    setConfirmPassword(event.target.value);
  };

  const isPolicyMet = policy => password && policy.regex.test(password);

  return (
    <div
      className={
        props.topBar
          ? styles.userbar
          : openModal
          ? styles.userbarSelected
          : `${styles.userbar} + ${styles.userTopbar}`
      }
      style={{ padding: props.topBar ? '0px' : '' }}
    >
      {/* <div className={styles.userDataLeftSide}>
        <div className={styles.userPic}>BU</div>
      </div>
      <div className={styles.userDataRightSide}>
        <div className={styles.username}>{username}</div>
        <div className={styles.email}>{email}</div>
      </div> */}
      <div
        onClick={() => {
          setOpenModal(true);
        }}
        className={styles.accountP}
        style={{
          textTransform: props.topBar ? 'uppercase' : '',
          fontSize: props.topBar ? '10px' : '',
        }}
      >
        <Icons.IconAccount style={{ width: '16px', height: '16px' }} />
        Account Preferences
      </div>
      <BitaModal
        isModalOpen={openModal}
        setModalState={setOpenModal}
        closeInOverlay={userData?.first_time_user || false}
      >
        <div style={{ height: '50%', width: '90vh' }}>
          <div
            style={{
              display: 'flex',
              borderBottom: '1px solid #eee',
              alignItems: 'center',
              padding: '10px 0',
            }}
          >
            <Icons.Avanti style={{ marginRight: '10px' }} />
            <h2
              className="titleModal"
              style={{ padding: '0px', margin: '0px', border: 'none', width: '340px' }}
            >
              Account Preferences
            </h2>
            <div>
              {userData.first_time_user ? 'Please, change your password.' : 'Account Preferences'}
            </div>
            <span
              onClick={() => setOpenModal(false)}
              className="close"
              style={{
                position: 'absolute',
                right: '0px',
                top: '0px',
                left: 'calc(100% - 20px)',
                marginRight: '20px',
                display: userData?.first_time_user ? 'none' : 'initial',
              }}
            >
              &times;
            </span>
          </div>
          <div className="contentCreateUpload">
            <div className={styles.column} style={{ opacity: '0.5' }}>
              <span className={styles.inputTitle}>NAME & SURNAME</span>
              <input
                type="text"
                value={name}
                onChange={event => setName(event.target.value)}
                disabled
              />
            </div>
            <div className={styles.column} style={{ opacity: '0.5' }}>
              <span className={styles.inputTitle}>BUSINESS EMAIL</span>
              <input type="text" value={userData.email} disabled />
            </div>
            <div className={styles.column}>
              <span className={styles.inputTitle}>PASSWORD*</span>
              <input type="password" value={password} onChange={handlePasswordChange} />
            </div>
            <div className={styles.column}>
              <span className={styles.inputTitle}>CONFIRM PASSWORD*</span>
              <input
                type="password"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
              />
            </div>
            <div className={styles.column}>
              {passwordPolicies.map((policy, index) => (
                <div
                  key={index}
                  className={isPolicyMet(policy) ? styles.policyMet : styles.policyNotMet}
                >
                  {isPolicyMet(policy) ? (
                    <FaCheckSquare className={styles.iconSuccess} />
                  ) : (
                    <FaTimes className={styles.iconFailure} />
                  )}{' '}
                  {policy.message}
                </div>
              ))}
              {password && confirmPassword && password !== confirmPassword && (
                <div className={styles.policyNotMet}>
                  <FaTimes className={styles.iconFailure} /> Password and confirm password must
                  match.
                </div>
              )}
            </div>
            <div
              style={{
                width: '100%',
                marginTop: '12px',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <BitaButton
                primary
                disabled={
                  !password ||
                  !confirmPassword ||
                  passwordErrors.length > 0 ||
                  password !== confirmPassword
                }
                style={{ marginRight: '10px' }}
                onClick={() => {
                  dispatch(changePassword(token, userData._id, password));
                  setOpenModal(false);
                  setPassword('');
                  setConfirmPassword('');
                }}
              >
                Apply
              </BitaButton>
              <BitaButton primary onClick={() => setOpenModal(false)}>
                Cancel
              </BitaButton>
            </div>
          </div>
        </div>
      </BitaModal>
    </div>
  );
};

export default SideUserbar;
