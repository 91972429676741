/* eslint-disable func-names */
import React from 'react';
import _ from 'lodash';
import BitaCharts from '../../../../../bitaComponents/BitaCharts/BitaCharts';
import { activeRiskTheme } from '../../../../../bitaComponents/BitaCharts/Themes';
import { capitalizeText } from '../../../../../utils/utils';

export default ({ indexStats, index }) => {
  const reducer = (acc, current) => acc + current;
  const availableData = Object.values(
    _.get(indexStats, `[${index}].value['Active Risk']`, [0]),
  ).reduce(reducer);

  const getData = () => {
    if (indexStats && availableData !== 0) {
      return Object.values(_.get(indexStats, `[${index}].value['Active Risk']`, []));
    }
    return [];
  };

  return (
    <BitaCharts
      theme={activeRiskTheme}
      options={{
        xAxis: {
          categories:
            indexStats &&
            Object.keys(_.get(indexStats, `[${index}].value['Active Risk']`, [])).map(w =>
              capitalizeText(w.replace('_', ' ')),
            ),
        },
        series: [
          {
            name: '',
            data: getData(),
          },
        ],
      }}
      containerProps={{ style: { height: '310px', width: '100%' } }}
    />
  );
};
