import React from 'react';
import InstrumentSelect from '../lib/InstrumentSelect';

export const BuilderInstrumentSelect = ({
  onChange = Function.prototype,
  data,
  title,
  path,
  data_key = '',
  filter,
  filter_options,
  isasync = false,
  id = '',
  max_instruments_selected,
  show_selected,
  external_title,
  selected_default,
  result_searchbar,
}) => {
  return (
    <InstrumentSelect
      onChange={onChange}
      data={data}
      title={title}
      path={path}
      data_key={data_key}
      filter={filter}
      filter_options={filter_options}
      isasync={isasync}
      max_instruments_selected={max_instruments_selected}
      show_selected={show_selected}
      external_title={external_title}
      result_searchbar={result_searchbar}
      id={id}
      selected_default={selected_default}
    />
  );
};
