import { useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';
import lodash from 'lodash';
import { iconF } from '../lib/generatorIcons';
import styles from '../../builder.module.scss';
import { ComponentGenerator } from '../Components';

export const BuilderTabComponent = ({
  icon,
  title,
  onChange,
  components = null,
  popupHandleOnClick = null,
  // addibletabs
  onClick = null,
  setterFunction = Function.prototype,
  addibletabs = false,
  universe,
  widgetData,
  currentView,
}) => {
  const summary = useSelector(state => state.summary);
  const Image = iconF(icon);
  const [stepData, setsetStepData] = useState(
    lodash.get(summary, `${universe}.${currentView}.${title}`, {}),
  );
  useEffect(() => {
    setsetStepData(lodash.get(widgetData, `${currentView}.${title}`, {}));
  }, [widgetData]);

  return (
    <div>
      <div className={`${styles.tab} componentTab `}>
        <div className={`${styles.tabHeading} ${title} ${addibletabs ? 'tabHeadingFilter' : ' '}`}>
          <Image /> {title}
        </div>
        {components &&
          Object.values(components).map((component, index) => (
            <ComponentGenerator
              popupHandleOnClick={popupHandleOnClick}
              onChange={onChange}
              widgetData={lodash.get(summary, `${universe}.${currentView}.${title}`, {})}
              summaryData={lodash.get(summary, `${universe}.${currentView}.${title}`, {})}
              stepData={stepData}
              path={`${title}.`}
              {...component}
              parentKey={title}
              onClick={onClick}
              setterFunction={setterFunction}
              key={index}
            />
          ))}
      </div>
    </div>
  );
};
