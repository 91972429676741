import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { setApiData, resetApiDataAttribute } from '../../../state/actions/api-data-actions';
import BitaSearchBar from '../../../bitaComponents/BitaSearchBar/BitaSearchBar';
import { ComponentGenerator } from '../Components';
import SelectionList from '../lib/SelectionList';
import { URL_CONSTANTS } from '../../../api-data-mapping/url-constants';
import { listThemes } from '../../../constants/user-company-access';

export const BuilderSelectionListContainer = ({
  id,
  onChange,
  widgetData,
  path,
  components,
  data,
  selection_helpers,
  filter_field,
  data_key,
  data_set_key,
  stepData,
  ...props
}) => {
  const [filteredData, setFilteredData] = useState();
  const [search, setSearh] = useState();

  const [loading, setLoading] = useState(false);
  const universeSelected = useSelector(state => state.summary);
  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();
  let idUniverse = false;
  if (
    universeSelected &&
    universeSelected.universeBuilderOperations &&
    universeSelected.universeBuilderOperations.universe.id
  ) {
    idUniverse = universeSelected.universeBuilderOperations.universe.id;
  }
  const getThemes = () => {
    const exposure = Number(stepData.exposure) / 100;
    let url = `${URL_CONSTANTS.THEMES_GET_URL}&exposure=${exposure}&operator=${stepData.operator.value}`;
    if (!idUniverse) {
      url = url.replace('universe={{UNIVERSE}}&', '');
    }
    url = url.replace('{{UNIVERSE}}', `${idUniverse}`).replace('{{TYPE}}', 'Custom');
    url = props.name === 'iClima Themes' ? `${url}&provider=iClima` : url;
    return axios.get(url, { headers: { Authorization: `Bearer ${auth.token}` } });
  };

  const getIClimasThemesAll = () => {
    let url = URL_CONSTANTS.THEMES_GET_URL.replace('{{TYPE}}', `All`);
    if (!idUniverse) {
      url = url.replace('universe={{UNIVERSE}}&', '');
    }
    url = url.replace('{{UNIVERSE}}', `${idUniverse}`);
    url = props.name === 'iClima Themes' ? `${url}&provider=iClima` : url;
    return axios.get(url, { headers: { Authorization: `Bearer ${auth.token}` } });
  };

  const getAllThemes = () => {
    let url = URL_CONSTANTS.THEMES_GET_URL.replace('{{TYPE}}', `All`);
    if (!idUniverse) {
      url = url.replace('universe={{UNIVERSE}}&', '');
    }
    url =
      props.name === 'iClima Themes'
        ? `${url
            .replace('{{UNIVERSE}}', `${idUniverse}`)
            .replace('{{TYPE}}', 'Custom')}&provider=iClima`
        : url.replace('{{UNIVERSE}}', `${idUniverse}`).replace('{{TYPE}}', 'Custom');
    return axios.get(url, { headers: { Authorization: `Bearer ${auth.token}` } });
  };

  useEffect(() => {
    setSearh('');
  }, [stepData.type]);

  useEffect(() => {
    dispatch(
      setApiData({
        title: 'Themes Dropdown_Pureness',
        data: [],
      }),
    );
    dispatch(resetApiDataAttribute('Themes Dropdown_Pureness'));
  }, [stepData.category_type]);

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    setLoading(true);
  }, [stepData.exposure]);

  useEffect(() => {
    if (stepData?.operator_type === ' ') {
      onChange('Themes.category_type', ' ', [{ id: '0', value: 'Themes', disabled: false }]);
      onChange('Themes.type', ' ');
      onChange('Themes.selection', []);
      setSearh('');
    }
    if (stepData?.type?.value === 'All') {
      onChange('Themes.operator', undefined);
      onChange('Themes.exposure', undefined);
    }
  }, [stepData.operator_type, stepData.type]);

  const ValidateDateUser = dataT => {
    if (
      auth.userdata.id_company === process.env.REACT_APP_PACER_ID &&
      stepData.category_type.value !== 'Themes'
    ) {
      return dataT.filter(item => listThemes.includes(item.name.trim()));
    }
    return dataT;
  };

  const formDataNew = dataN => {
    const validateDate = ValidateDateUser(dataN);
    const result = {};
    validateDate.forEach(item => {
      const { category } = item;
      if (!result[category ?? '']) {
        result[category ?? ''] = [];
      }
      result[category ?? ''].push({
        id: item.id,
        option: item.name,
        value: item.count,
      });
    });
    const finalResult = {};
    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const category in result) {
      finalResult[category] = result[category];
    }
    return finalResult;
  };

  // eslint-disable-next-line consistent-return
  useLayoutEffect(() => {
    if (
      stepData.type &&
      stepData?.type.value === 'Custom' &&
      stepData?.operator &&
      !stepData?.category_type &&
      stepData?.exposure
    ) {
      setLoading(true);
      const { cancel } = axios.CancelToken.source();
      const timeOutId = setTimeout(
        () =>
          getThemes().then(resp => {
            if (resp.data?.data) {
              setFilteredData(
                formDataNew(resp.data.data.find(item => item.level === 1)?.data ?? []),
              );
              dispatch(
                setApiData({
                  title: 'Themes Dropdown_Pureness',
                  data: formDataNew(resp.data.data.find(item => item.level === 1)?.data ?? []),
                }),
              );
              setLoading(false);
            }
          }),
        500,
      );
      return () => cancel('No longer latest query') || clearTimeout(timeOutId);
    }
    if (
      props.name === 'iClima Themes' &&
      stepData.type &&
      stepData?.type.value === 'All' &&
      stepData?.operator_type
    ) {
      setLoading(true);
      const { cancel } = axios.CancelToken.source();
      const timeOutId = setTimeout(
        () =>
          getIClimasThemesAll().then(resp => {
            if (resp.data?.data) {
              setFilteredData(
                formDataNew(resp.data.data.find(item => item.level === 1)?.data ?? []),
              );
              dispatch(
                setApiData({
                  title: 'Themes Dropdown_Pureness',
                  data: formDataNew(resp.data.data.find(item => item.level === 1)?.data ?? []),
                }),
              );
              setLoading(false);
            }
          }),
        500,
      );
      return () => cancel('No longer latest query') || clearTimeout(timeOutId);
    }

    if (
      stepData?.type &&
      stepData?.type?.value === 'Custom' &&
      stepData?.operator &&
      stepData?.category_type?.id &&
      stepData?.exposure
    ) {
      setLoading(true);
      const { cancel } = axios.CancelToken.source();
      const timeOutId = setTimeout(
        () =>
          getThemes().then(resp => {
            if (resp.data?.data) {
              setFilteredData(
                formDataNew(
                  resp.data.data.find(item => item.level === Number(stepData.category_type.id) + 1)
                    ?.data ?? [],
                ),
              );
              dispatch(
                setApiData({
                  title: 'Themes Dropdown_Pureness',
                  data: formDataNew(
                    resp.data.data.find(
                      item => item.level === Number(stepData.category_type.id) + 1,
                    )?.data ?? [],
                  ),
                }),
              );
              setLoading(false);
            }
          }),
        500,
      );
      return () => cancel('No longer latest query') || clearTimeout(timeOutId);
    }
    if (stepData?.category_type && stepData?.type?.value === 'All') {
      setLoading(true);
      const { cancel } = axios.CancelToken.source();
      const timeOutId = setTimeout(
        () =>
          getAllThemes().then(resp => {
            if (resp.data?.data) {
              setFilteredData(
                formDataNew(
                  resp.data.data.find(item => item.level === Number(stepData.category_type.id) + 1)
                    ?.data ?? [],
                ),
              );
              dispatch(
                setApiData({
                  title: 'Themes Dropdown_Pureness',
                  data: formDataNew(
                    resp.data.data.find(
                      item => item.level === Number(stepData.category_type.id) + 1,
                    )?.data ?? [],
                  ),
                }),
              );
              setLoading(false);
            }
          }),
        500,
      );
      return () => cancel('No longer latest query') || clearTimeout(timeOutId);
    }
  }, [stepData?.type, stepData?.exposure, stepData?.operator]);

  return (
    <div className="selection-list-container">
      <div>
        {components &&
          Object.values(components).map(comp => (
            <ComponentGenerator
              key={`${comp.id}-${comp.data_fields}`}
              {...comp}
              onChange={onChange}
              widgetData={widgetData}
              stepData={stepData}
              path={path}
            />
          ))}
      </div>
      <div>
        {((stepData.type &&
          stepData.type !== ' ' &&
          stepData.operator_type !== ' ' &&
          stepData.category_type !== ' ' &&
          stepData.type.value !== 'Custom') ||
          (stepData.type &&
            stepData.type.value === 'Custom' &&
            stepData.operator &&
            stepData.exposure)) &&
          props.name !== 'iClima Themes' &&
          data_key === 'Themes Dropdown_Pureness' && (
            <input
              style={{
                width: '300px !important',
                background: '#2a2d31',
                border: '1px solid white',
                borderRadius: '5px',
              }}
              className="searchInput"
              type="text"
              value={search}
              onChange={e => setSearh(e.target.value)}
              placeholder="Search by...(Start typing name here)"
            />
          )}
      </div>
      <div>
        {((stepData.type &&
          stepData.type !== ' ' &&
          stepData.operator_type !== ' ' &&
          stepData.category_type !== ' ' &&
          stepData.type.value !== 'Custom') ||
          (stepData.type &&
            stepData.type.value === 'Custom' &&
            stepData.operator &&
            stepData.exposure)) && (
          <SelectionList
            data={data}
            path={path}
            loading={loading}
            onChange={onChange}
            widgetData={widgetData && widgetData[id]}
            selection_helpers={selection_helpers}
            filter_field={filter_field}
            data_key={data_key}
            data_set_key={data_set_key}
            search={search}
            id={id}
            name={props.name}
            filteredData={filteredData}
          />
        )}
      </div>
    </div>
  );
};
