import React from 'react';
import styles from './BacktestModal.module.scss';

const BaseDetails = ({ title, content }) => {
  return (
    <div className={styles.baseContainer}>
      <div className={styles.baseTitleContainer}>
        <span className={styles.baseTitle}>{title}</span>
      </div>
      <div className={styles.baseContentContainer}>
        <span className={styles.baseContent}>{content}</span>
      </div>
    </div>
  );
};

export default BaseDetails;
