import React, { Component } from 'react';
import { isDevEnvironment } from '../../../utils/utils';

export class ComponentErrorBoundary extends Component {
  state = {
    error: null,
  };

  static getDerivedStateFromError(error) {
    // Update state so next render shows fallback UI.
    return { error };
  }

  componentDidCatch(error, info) {
    // Log the error to an error reporting service
    console.error('ComponentErrorBoundary[x] ERROR >', error, info);

    if (isDevEnvironment()) {
      // eslint-disable-next-line no-alert
      if (window && window.alert)
        // eslint-disable-next-line no-alert
        window.alert(`DEBUG INFO (this is only visible at dev \n${error} \n\n${info}`);
    }
  }

  render() {
    if (this.state.error) {
      // You can render any custom fallback UI
      return <div className="ERROR_HERE_PLEASE_FIX_ME" />;
    }
    return this.props.children;
  }
}
