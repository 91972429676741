import React from 'react';
import styles from '../../builder.module.scss';
import { ComponentGenerator } from '../Components';
import { remove_option } from '../../../utils/utils';

export const BuilderHorizontalContainer = ({ components, setterFunction = Function.prototype }) => {
  return (
    <div className={`${styles.builderHorizontalContainer} builderHorizontalContainer`}>
      {Object.values(components)
        .filter(card => remove_option(!card.disabled))
        .map((component, index) => (
          <ComponentGenerator {...component} setterFunction={setterFunction} key={index} />
        ))}
    </div>
  );
};
