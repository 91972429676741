import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, withRouter } from 'react-router-dom';
import PageLayout from '../../components/Layout/PageLayout';
import { Icons } from '../../constants/icons';
import { useSummary } from '../../bitaHooks';
import Builder from '../../builder/Builder';
import { IconsModules } from '../../constants/icons-module';
import * as CommonActions from '../../state/actions/common-actions';
import styles from './index-builder.module.css';
// import iconGeneralRules from '../../static/img/Icons-modules/general.svg';
import iconAvailable from '../../static/img/Icons-modules/available_rules.svg';

const Imagen = IconsModules.MethodologyModule;

const IndexBuilder = withRouter(({ parametrization, history }) => {
  const dispatch = useDispatch();
  // eslint-disable-next-line
  const [stepData, setStepData] = useState({});
  const [currentView, setCurrentView] = React.useState(null);
  const [builderState, setBuilderState] = React.useState({});
  const [addToSummary, resetSummary] = useSummary('Index Builder', currentView);
  const summary = useSelector(state => state.summary);
  const subHeader = useSelector(state => state.common.subheader);
  const [widgets, setWidgets] = useState(null);
  const location = useLocation();
  const cards = parametrization.components.filter(field => field.type === 'Card');

  useEffect(() => {
    document.title = `${process.env.REACT_APP_SITE_TITLE} | Index Builder`;
  }, []);

  useEffect(() => {
    switch (location.pathname) {
      case '/index-builders/index-builders':
        setWidgets(null);
        break;
      case '/index-builders/rebalancing-reconstitution':
        setWidgets(null);
        break;
      case (location.pathname.match(/filter/) || {}).input:
        setWidgets(null);
        break;
      case '/index-builders/parametrization/general-rules':
        setWidgets(cards[0].onclick.widgets);
        setCurrentView('General Rules');
        break;
      case '/index-builders/parametrization/rebalacing-reconstitution':
        setWidgets(cards[1].onclick.widgets);
        setCurrentView('Rebalancing & Reconstitution');
        break;
      case '/index-builders/parametrization/fast-exit-entry':
        setWidgets(cards[2].onclick.widgets);
        setCurrentView('Fast Entry & Exit');
        break;
      case '/index-builders/parametrization/additional-rules':
        setWidgets(cards[3].onclick.widgets);
        setCurrentView('Additional Rules');
        break;
      case '/index-builders/parametrization/caps-floors-buffer':
        setWidgets(cards[1].onclick.widgets);
        setCurrentView('Caps, Floors & Buffer');
        break;
      default:
        // eslint-disable-next-line
        return;
    }
    // eslint-disable-next-line
  }, [location]);

  // checking the title in the card we set the url to go
  const onClick = (comp, title) => {
    let url;
    switch (title.toLowerCase()) {
      case 'general rules': {
        url = '/index-builders/parametrization/general-rules';
        break;
      }

      case 'rebalancing & reconstitution': {
        url = '/index-builders/parametrization/rebalacing-reconstitution';
        break;
      }

      case 'fast entry & exit': {
        url = '/index-builders/parametrization/fast-exit-entry';
        break;
      }

      case 'additional rules': {
        url = '/index-builders/parametrization/additional-rules';
        break;
      }
      case 'caps, floors & buffer': {
        url = '/index-builders/parametrization/caps-floors-buffer';
        break;
      }

      default: {
        throw new Error(`cant find a path for this card title ${title}, please check IndexBuilder`);
      }
    }

    history.push(url);
    /* if (comp.widgets) {
      setCurrentView(title);
      setWidgets(comp.widgets);
    } */
  };

  const onChange = (path, value) => {
    setBuilderState(old => ({ ...old, [currentView]: { ...old[currentView], ...value } }));
  };
  const sendSummary = () => addToSummary(builderState[currentView]);
  const deleteSummary = key => resetSummary(key);

  useEffect(() => {
    dispatch(CommonActions.resetSubheader());
    dispatch(
      CommonActions.appendSubheader(
        location.pathname.includes('/index-builders/rebalancing-reconstitution')
          ? 'CREATE NEW METHODOLOGY · WHITE CANVAS'
          : 'INDEX BUILDER',
      ),
    );
  }, [parametrization]);

  return (
    <PageLayout>
      <div className="content">
        <div className={styles.pageHeading}>
          <div className={styles.pageTitle}>
            <Imagen />
            {summary && summary['Index Builder'] && summary['Index Builder']['Methodology Name']
              ? summary['Index Builder']['Methodology Name']
              : 'UNTITLED METHODOLOGY'}
          </div>
        </div>
        {!widgets && cards && (
          <div className={styles.availableRules}>
            <div className={styles.parametrizationTitle}>
              {/* eslint-disable-next-line */}
              <img src={iconAvailable} />
              {parametrization.title.toUpperCase()}
            </div>
            <div className={styles.cards}>
              {cards.map(card => (
                <Builder key={card.title} onChange={onChange} {...card} setterFunction={onClick} />
              ))}
            </div>
          </div>
        )}
        {widgets &&
          Object.values(widgets).map(widget => (
            <Builder
              key={widget.title}
              onApply={sendSummary}
              currentView={currentView}
              universe="Index Builder"
              onDelete={deleteSummary}
              onChange={onChange}
              onClick={onClick}
              setStepData={setStepData}
              {...widget}
            />
          ))}
      </div>
    </PageLayout>
  );
});

export default IndexBuilder;
