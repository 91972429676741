import * as ACTIONS from '../../constants/auth-constants';

const initialState = {
  username: '',
  token: '',
  login: {
    loading: false,
    error: null,
  },
  plan: {},
  userdata: {
    name: '',
    email: '',
  },
  org_prefix: '',
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.LOGIN_LOADING: {
      return {
        ...state,
        login: {
          ...state.login,
          loading: action.payload,
          error: null,
        },
      };
    }
    case ACTIONS.LOGIN_ERROR: {
      return {
        ...state,
        login: {
          ...state.login,
          loading: false,
          error: action.payload,
        },
      };
    }

    case 'SET-TOKEN': {
      return { ...state, token: action.payload };
    }

    case ACTIONS.LOGIN_SUCCESS: {
      return {
        ...state,
        login: {
          ...state.login,
          loading: false,
          error: null,
        },
        username: action.payload.user.user,
        company: action.payload.user.company.name,
        token: action.payload.token,
        plan: action.payload.plan,
        org_prefix: action.payload.org_prefix,
        userdata: {
          ...action.payload.user,
          ...{
            customization: {
              ...action.payload.customization,
              ...{ logo: action.payload.user.company.logo },
              ...{ website: action.payload.user.company.website },
            },
          },
        },
      };
    }
    case ACTIONS.UPDATE_CUSTOMIZATION: {
      return {
        ...state,
        userdata: {
          ...state.userdata,
          ...{ customization: action.payload },
        },
      };
    }
    case ACTIONS.COMPANY_CHANGE: {
      return {
        ...state,
        company: action.payload,
        userdata: {
          ...state.userdata,
          company: {
            ...state.userdata.company,
            name: action.payload,
          },
        },
      };
    }
    case ACTIONS.PASSWORD_CHANGE: {
      return {
        ...state,
        userdata: {
          ...state.userdata,
          first_time_user: false,
        },
      };
    }
    case ACTIONS.LOGOUT: {
      return {
        ...state,
        username: '',
        token: null,
        login: {
          loading: false,
          error: null,
        },
        userdata: {
          name: '',
          email: '',
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default authReducer;
