import React from 'react';
import BitaButton from '../../../bitaComponents/BitaButton/BitaButton';
import BitaCheckbox from '../../BitaCheckbox/BitaCheckbox';
import '../ComponentsSettings.css';

const MatrixContent = ({ row, onApply = Function.prototype }) => {
  return (
    <div className="selectionMatrixRow">
      <div>{row.value}</div>
      {Object.values(row.columns).map((itemCheckbox, index) => (
        <div onClick={onApply} key={index}>
          {(itemCheckbox.checked === false || itemCheckbox.checked === true) && (
            <BitaCheckbox defaultChecked={itemCheckbox.checked} disabled={itemCheckbox.disabled} />
          )}
        </div>
      ))}
    </div>
  );
};

const SelectionMatrix = ({
  rows = [],
  columns = [],
  onApply = Function.prototype,
  onClick = Function.prototype,
}) => {
  return (
    <div className="selectionMatrixContainer">
      <div className="headings">
        <div />
        {columns.map(column => (
          <div key={column}>{column}</div>
        ))}
      </div>
      <div>
        {rows.map((row, index) => (
          <MatrixContent row={row} onApply={onClick} key={index} />
        ))}
      </div>
      <div className="contentBotones">
        <div />
        <BitaButton
          width="120px"
          height="35px"
          primary
          disabled
          // onClick={onApply}
        >
          Apply
        </BitaButton>
      </div>
    </div>
  );
};

export default SelectionMatrix;
