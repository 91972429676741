import { post, get, put } from 'axios';
import { URL_CONSTANTS } from '../../../api-data-mapping/url-constants';

// Function to get basket by ticker
const getBasketByTicker = (tokenUser, ticker) => {
  const url = URL_CONSTANTS.GET_BASKET_BY_TICKER.replace('{{BASKET}}', ticker);

  return get(url, {
    headers: { Authorization: `Bearer ${tokenUser}` },
  });
};

const createBasket = (tokenUser, data) => {
  return post(URL_CONSTANTS.SUBMITLIVEBASKET, data, {
    headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${tokenUser}` },
  });
};

const uploadFileSettings = (tokenUser, file) => {
  return post(URL_CONSTANTS.UPLOADFILELIVEBASKET, file, {
    headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${tokenUser}` },
  });
};

const coverageRequest = (tokenUser, data) => {
  return post(URL_CONSTANTS.COVERAGE_REQUEST_URL, data, {
    headers: { Authorization: `Bearer ${tokenUser}` },
  });
};

const updateBasket = (tokenUser, data) => {
  return post(URL_CONSTANTS.UPDATELIVEBASKET, data, {
    headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${tokenUser}` },
  });
};

const createTax = (tokenUser, data) => {
  return post(URL_CONSTANTS.CREATE_WITHHOLDING_TAX, data, {
    headers: { Authorization: `Bearer ${tokenUser}` },
  });
};

const updateTax = (tokenUser, data) => {
  return put(URL_CONSTANTS.CREATE_WITHHOLDING_TAX, data, {
    headers: { Authorization: `Bearer ${tokenUser}` },
  });
};

export {
  getBasketByTicker,
  createBasket,
  uploadFileSettings,
  coverageRequest,
  updateBasket,
  createTax,
  updateTax,
};
