import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import './BitaSelect.scss';
// eslint-disable-next-line import/imports-first
import { BiSolidError } from 'react-icons/bi';

const BitaSelect = props => {
  // const { data, label, handleSelection, className, selected } = props;
  const { data, externalPlaceholder, clearable = true } = props;
  const styles = {
    select: {
      width: props.width,
    },
  };
  // Este codigo convierte el array que le manden al formato que recibe el select-react
  // eslint-disable-next-line func-names
  const newData = data.map(dato => {
    const newDato = {
      label: dato?.value,
      value: dato?.id,
      disabled: dato?.disabled,
      date: dato?.date,
    };
    if (dato?.disabled) newDato.isDisabled = true;
    return newDato;
  });

  const optionDefaultSelected = props.value
    ? { label: props.value.value, value: props.value.id }
    : false;

  const handleChange = (selectedOption, actionType) => {
    if (selectedOption) {
      if (props.handleSelection) {
        const formattedSelectedOption = {
          id: selectedOption.value,
          value: selectedOption.label,
          date: selectedOption.date,
        };
        props.handleSelection(formattedSelectedOption);
      }
    } else if (actionType.action === 'clear') {
      if (props.handleSelection) {
        props.handleSelection(null);
      }
    }
  };

  const CustomStyle = {
    option: (base, state) => ({
      ...base,
      color: state.isDisabled ? '#999' : state.isSelected ? '#2a2d31' : '',
    }),
  };

  // Custom Placeholder Component
  // eslint-disable-next-line jsx-a11y/accessible-emoji
  const CustomPlaceholder = () => <BiSolidError className="icon-error" />;

  return (
    <div className={props.className} style={{ ...styles.select, ...props.style }}>
      {externalPlaceholder && <div>{externalPlaceholder}</div>}
      <Select
        {...props}
        options={newData}
        maxMenuHeight={props.menu_height || 200}
        value={optionDefaultSelected}
        isClearable={clearable}
        placeholder={props.label}
        className={`dropdownContainer ${props.selectClassname}`}
        classNamePrefix="dropdown"
        onChange={handleChange}
        styles={CustomStyle}
        components={props.warningIcon && { Placeholder: CustomPlaceholder }}
        theme={theme => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            neutral90: 'white',
            primary: 'white',
            primary25: '#2a2d31',
            neutral0: '#2a2d31',
          },
        })}
      />
    </div>
  );
};

BitaSelect.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.array.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
  width: PropTypes.string,
  handleSelection: PropTypes.func.isRequired,
};

BitaSelect.defaultProps = {
  label: 'Select ',
  className: ' ',
  width: '200px',
};

export default BitaSelect;
