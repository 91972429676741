import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PageLayout from '../../components/Layout/PageLayout';
import ConfigYAML from '../../config/ConfigYAML';
import * as CommonActions from '../../state/actions/common-actions';
import PageBuilderComponent from '../structures-product/PageBuilder/PageBuilder';
import styles from './settings.module.css';

const Settings = () => {
  const dispatch = useDispatch();

  dispatch(CommonActions.resetSubheader());
  dispatch(CommonActions.appendSubheader('Settings'));

  const [stepData, setStepData] = useState({});
  const [defaultStructure, setDefaultStructure] = useState(null);

  useEffect(() => {
    document.title = `${process.env.REACT_APP_SITE_TITLE} | Settings`;
    const settings = ConfigYAML.jsonPath('$.settings');
    const sections = Object.values(settings.sections).map(s => {
      const section = { ...s };
      if (section.widgets) {
        section.widgets = Object.values(section.widgets);
      } else if (section.components) {
        section.components = Object.values(section.components);
      }
      return section;
    });
    setDefaultStructure(sections.find(({ type }) => type === 'Default'));
  }, []);

  useEffect(() => {
    if (defaultStructure) {
      dispatch(CommonActions.resetSubheader());

      Object.values(defaultStructure.header_path).forEach(title => {
        dispatch(CommonActions.appendSubheader(title));
      });
    }
  }, [defaultStructure]);

  return (
    <PageLayout>
      {defaultStructure ? (
        <div className="settings">
          <PageBuilderComponent
            stepData={stepData}
            setStepData={setStepData}
            {...defaultStructure}
            defaultWidgetsContainerClass={styles.container}
          />
        </div>
      ) : null}
      {/* <ContentSettings /> */}
    </PageLayout>
  );
};

export default Settings;
