import moment from 'moment';

export const getBasketIndexBacktestBody = (basketIndexState, backtestInfo, endDate) => {
  const basketIndexInfo = basketIndexState.Portfolio.Portfolio;
  const BasketIndexApiBody = {
    universe: '',
    object_backtest: {
      strategy_name: 'general_strategy',
      calculate: true,
      risk_model_id: backtestInfo?.risk_model_id?.id,
      parameters: {
        operations: {
          constitute: {
            operation_name: 'constitute',
          },
          run_index: {
            operation_name: 'run_index',
          },
          performance_metrics: {
            operation_name: 'performance_metrics',
          },
        },
        data: {
          initial_index_value: parseFloat(backtestInfo['BASE VALUE']),
          inception_date: moment(backtestInfo['Inception Date']).format('YYYY-MM-DD'),
          start_date: moment(backtestInfo['Start Date']).format('YYYY-MM-DD'),
          end_date: moment(backtestInfo['End Date'] || endDate).format('YYYY-MM-DD'),
          operations_name: ['awf', 'defined_weights'],
          inception_value: parseFloat(backtestInfo['BASE VALUE']),
          base_currency:
            basketIndexInfo?.Currency?.currency?.value === 'Use Local Currency'
              ? 'local_currency'
              : basketIndexInfo?.Currency?.currency?.value,
          basket_id: basketIndexState.Portfolio.PortfolioSelected.id,
        },
      },
      api_parameters: {
        currency: {
          currency:
            basketIndexInfo?.Currency?.currency?.value === 'Use Local Currency'
              ? 'local_currency'
              : basketIndexInfo?.Currency?.currency?.value,
        },
        rebalancing: {},
        reconstitution: {},
        returnCalculation: basketIndexInfo?.['Return calculation']?.return_calculation,
        start_date: moment(backtestInfo['Start Date']).format('YYYY-MM-DD'),
        end_date: moment(backtestInfo['End Date'] || endDate).format('YYYY-MM-DD'),
      },
      checking_factors: {},
      benchmark: {
        type: backtestInfo.Benchmark['Instrument type'],
        id: backtestInfo?.Benchmark?.id,
      },
    },
  };

  if (
    basketIndexInfo?.Rebalancing &&
    basketIndexInfo?.Rebalancing?.rebalancing !== 'Same as the Uploaded Portfolio'
  ) {
    BasketIndexApiBody.object_backtest.api_parameters.rebalancing.Rebalancing =
      basketIndexInfo?.Rebalancing?.rebalancing;

    BasketIndexApiBody.object_backtest.api_parameters.rebalancing.Day =
      basketIndexInfo?.Rebalancing?.Day?.value;

    BasketIndexApiBody.object_backtest.api_parameters.rebalancing.Months =
      basketIndexInfo?.Rebalancing?.rebalancing.toUpperCase() === 'MONTHLY'
        ? 'January-February-March-April-May-June-July-August-September-October-November-December'
        : basketIndexInfo?.Rebalancing?.Months?.value;

    BasketIndexApiBody.object_backtest.api_parameters.rebalancing['Ex-Date'] =
      basketIndexInfo?.Rebalancing?.['Ex-Date']?.value;

    BasketIndexApiBody.object_backtest.api_parameters.rebalancing.Proforma_date =
      basketIndexInfo?.Rebalancing?.['Determination date']?.value;
  }

  return BasketIndexApiBody;
};
