import React from 'react';

import AddibleRowsComponent from '../lib/AddibleRowsComponent';

export const BuilderAddibleRowsComponent = ({
  components = {},
  id = '',
  onChange,
  setterFunction = Function.prototype,
  widgetData,
  summaryData,
  stepData,
  sum_id,
  sum_text,
  requirerow,
  path,
  defineaddiblerowexception,
  buttoncolor,
}) => {
  return (
    <AddibleRowsComponent
      components={components}
      id={id}
      onChange={onChange}
      setterFunction={setterFunction}
      widgetData={widgetData}
      summaryData={summaryData}
      buttonColor={buttoncolor}
      stepData={stepData}
      sumId={sum_id}
      sumText={sum_text}
      requirerow={requirerow}
      defineaddiblerowException={defineaddiblerowexception}
      path={path}
    />
  );
};
