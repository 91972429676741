import _ from 'lodash';

export const getOptimizedBacktestBody = (optimizedIndex, methodologyParams) => {
  let parent_index_id = '';
  let risk_model_id = false;
  let objective_function = '';
  let currency = '';
  let type_return = '';
  let cash = '';
  const weight_limits = {};
  const factor_per_constraints = {};
  const alpha_score_coefficients = {};
  let constraints_names = [];
  const parent_constraints_names = [];
  let historical_drawdown_len = '';
  const penalization_ratios = {};
  const removable_constraints_names = [];
  const operations_name = ['optimizer'];
  let neglectable_value = false;
  let linear_coefficient_name = '';

  const addOperationName = operation => {
    if (!operations_name.find(op => op === operation)) {
      operations_name.push(operation);
    }
  };

  const data_attribute_format = att => {
    return att.replace(/\s/g, '_').toLowerCase();
  };

  const getPeriod = period => {
    if (period) {
      switch (Number(period.id)) {
        case 0:
          return 1;
        case 1:
          return 3;
        case 2:
          return 5;
        case 3:
          return 10;
        case 4:
          return '';
        default:
          return '';
      }
    }
    return 3;
  };

  const generateFactorList = (floor, cap) => {
    const factorList = [
      'Momentum',
      'Value',
      'Size',
      'Quality',
      'Low Volatility',
      'Liquidity',
      'Yield',
    ];
    return factorList.map(f => ({
      factor: { value: f },
      floor,
      cap,
    }));
  };

  const setFactor = factor => {
    const factorName = _.get(factor, 'factor', false);
    const factorType = _.get(factor, ['weights', 'value'], 'All Factors');
    const factorList =
      factorType === 'All Factors'
        ? generateFactorList(factor.floor || 0, factor.cap || 1)
        : _.get(factor, ['factors'], []);
    if (factorName.toLowerCase().search('active') !== -1) {
      factorList.forEach(f => {
        parent_constraints_names.push(data_attribute_format(f.factor.value));
        factor_per_constraints[data_attribute_format(f.factor.value)] = [
          1 - (parseFloat(f.floor) || 0),
          1 + (parseFloat(f.cap) || 0),
        ];
      });
      return;
    }
    factorList.forEach(f => {
      constraints_names.push(data_attribute_format(f.factor.value));
      weight_limits[data_attribute_format(f.factor.value)] = [
        parseFloat(f.floor) || -3,
        parseFloat(f.cap) || 3,
      ];
    });
  };
  // eslint-disable-next-line array-callback-return
  Object.entries(optimizedIndex || {}).map(([step, value]) => {
    let constraintName = '';
    if (step.endsWith('Optimization types')) {
      switch (value.optimization_type.toUpperCase()) {
        case 'SHARPE RATIO OPTIMIZATION':
          objective_function = 'max_sharpe_ratio';
          operations_name.push('covariance_matrix');
          break;
        case 'VARIANCE MINIMIZATION':
          objective_function = 'min_variance';
          operations_name.push('covariance_matrix');
          break;
        case 'RETURNS MAXIMIZATION':
          objective_function = 'max_return';
          break;
        case 'FACTOR EXPOSURE OPTIMIZATION':
          objective_function = 'max_alpha_score';
          break;
        case 'SINGLE DATAFIELD OPTIMIZATION':
          objective_function = 'max_linear_objective';
          break;
        case 'EQUAL RISK CONTRIBUTION':
          objective_function = 'equal_risk_contribution';
          operations_name.push('covariance_matrix');
          break;
        case 'MAXIMIZED DIVERSIFICATION':
          objective_function = 'max_diversification_ratio';
          operations_name.push('covariance_matrix');
          operations_name.push('volatility');
          break;
        case 'INFORMATION RATIO MAXIMIZATION':
          objective_function = 'max_information_ratio';
          operations_name.push('covariance_matrix');
          break;
        default:
          objective_function = 'max_sharpe_ratio';
          break;
      }
      penalization_ratios[objective_function] = 1;
      return;
    }
    if (step.endsWith('Parent Index')) {
      parent_index_id = value.parent_index.id;
      return;
    }
    if (step.endsWith('Risk Model')) {
      if (value.risk_model === 'Bita') risk_model_id = value.risk_model_id.id;
      return;
    }
    if (step.endsWith('Factor Selection')) {
      if (typeof value === 'string') {
        switch (value.toLowerCase()) {
          case 'minimize':
            objective_function = 'min_alpha_score';
            delete penalization_ratios.max_alpha_score;
            break;
          default:
            objective_function = 'max_alpha_score';
            delete penalization_ratios.min_alpha_score;
            break;
        }
        penalization_ratios[objective_function] = 1;
        return;
      }
      const factorObj = Object.keys(value || {})[0]?.toLowerCase();
      let factorList;
      if (factorObj === 'factor_exposure') {
        factorList = _.get(value, 'factors', []);
        factorList.forEach(f => {
          const name = _.get(f, ['factor', 'value'], false);
          if (name) {
            alpha_score_coefficients[data_attribute_format(name)] =
              parseFloat(f.weight || 100) / 100;
          }
        });
        return;
      }
      setFactor(value);
    }

    if (step === '3_0_Datafield Selection') {
      if (typeof value === 'string') {
        switch (value.toLowerCase()) {
          case 'minimize':
            objective_function = 'min_linear_objective';
            delete penalization_ratios.max_linear_objective;

            break;
          default:
            objective_function = 'max_linear_objective';
            delete penalization_ratios.min_linear_objective;

            break;
        }
        penalization_ratios[objective_function] = 1;
        return;
      }
    }
    if (step === '3_1_Datafield Selection') {
      // Size, Liquidity, Fundamentals, CustomFields
      if (value['Weight by'] !== undefined) {
        linear_coefficient_name = value['Weight by'].id;
      }

      // ESG Ratings
      // BITA
      if (value.data_field_filter !== undefined) {
        if (value.data_field_filter.value === 'Emissions') {
          linear_coefficient_name = value.score4.id;
        } else if (value.data_field_filter.value === 'Emissions Growth Rates') {
          linear_coefficient_name = value.score5.id;
        } else if (value.data_field_filter.value === 'Emissions Intensity') {
          linear_coefficient_name = value.score6.id;
        } else if (value.data_field_filter.value === 'Emissions Intensity Growth Rates') {
          linear_coefficient_name = value.score7.id;
        } else if (value.data_field_filter.value === 'Sector-Normalized Emissions') {
          linear_coefficient_name = value.score8.id;
        } else if (value.data_field_filter.value === 'Sector-Normalized Waste') {
          linear_coefficient_name = value.score9.id;
        } else if (value.data_field_filter.value === 'Sector-Normalized Emissions Growth Rates') {
          linear_coefficient_name = value.score10.id;
        } else if (value.data_field_filter.value === 'Sector-Normalized Waste Growth Rates') {
          linear_coefficient_name = value.score11.id;
        } else if (value.data_field_filter.value === 'Waste') {
          linear_coefficient_name = value.score12.id;
        } else if (value.data_field_filter.value === 'Waste Growth Rates') {
          linear_coefficient_name = value.score13.id;
        } else if (value.data_field_filter.value === 'Waste Intensity') {
          linear_coefficient_name = value.score14.id;
        } else if (value.data_field_filter.value === 'Waste Intensity Growth Rates') {
          linear_coefficient_name = value.score15.id;
        }
      }

      // Arabesque
      if (value.scoreType !== undefined) {
        if (value.scoreType.value === 'GC Scores') {
          linear_coefficient_name = value.score1.id;
        } else if (value.scoreType.value === 'ESG Scores') {
          linear_coefficient_name = value.score2.id;
        } else if (value.scoreType.value === 'Features') {
          linear_coefficient_name = value.score3.id;
        }
      }

      // Sustainalitycs
      if (value.score16 !== undefined) {
        linear_coefficient_name = value.score16.id;
      }
    }

    if (step.endsWith('Constraints (Optional)')) {
      constraintName = Object.keys(value || {})[0]?.toLowerCase();
      if (constraintName === 'factor') {
        setFactor(value);
        return;
      }
      if (constraintName?.search('volatility') > -1) {
        operations_name.push('covariance_matrix');
        if (value[constraintName]?.toLowerCase().search('same as parent') > -1) {
          constraintName = 'target_risk';
          parent_constraints_names.push(constraintName);
          factor_per_constraints[constraintName] = [1, 1];
          return;
        }
        constraintName = 'target_risk';
      } else if (constraintName?.search('return') > -1) {
        if (value[constraintName]?.toLowerCase().search('same as parent') > -1) {
          constraintName = 'target_return';
          parent_constraints_names.push(constraintName);
          factor_per_constraints[constraintName] = [1, 1];
          return;
        }
        constraintName = 'target_return';
      } else if (constraintName?.search('track') > -1) {
        constraintName = 'tracking_error';
        addOperationName('covariance_matrix');
      } else if (constraintName === 'maximum_drawdown' || constraintName === 'period') {
        constraintName = 'max_drawdown';
      }
      if (value[constraintName]?.toLowerCase().search('active') > -1) {
        parent_constraints_names.push(constraintName);
        if (constraintName === 'diversification_target') {
          factor_per_constraints[constraintName] = [
            1 - (Number(value.min) || 1) / 100,
            1 + (Number(value.max) || 0) / 100,
          ];
          return;
        }
        factor_per_constraints[constraintName] = [
          1 - (value.floor || 1) / 100,
          1 + (value.cap || 0) / 100,
        ];
        return;
      }

      constraints_names.push(constraintName);
      if (constraintName === 'diversification_target') {
        weight_limits[constraintName] = [
          (Number(value.min) || 0) / 100,
          (Number(value.max) || 100) / 100,
        ];
        return;
      }

      if (value.factors) {
        // eslint-disable-next-line array-callback-return
        value.factors.map(fac => {
          weight_limits[fac.factor.value.toLowerCase()] = [
            (fac.floor || 0) / 100,
            (fac.cap || 100) / 100,
          ];
        });
      }
      if (value.maximum_drawdown || value.period) {
        weight_limits[constraintName] = [
          0,
          value.maximum_drawdown ? Number(value.maximum_drawdown) / 100 : 1,
        ];
        historical_drawdown_len = getPeriod(value.period);
        return;
      }
      if (value.cap || value.floor || value.turnover) {
        if (value.weights && value.weights.value && value.weights.value.indexOf('All') !== -1) {
          constraintName = value.weights.value.toLowerCase().replace(/\s/g, '_');
        }
        weight_limits[constraintName] = [
          (value.floor || 0) / 100,
          (value.cap || value.turnover || 100) / 100,
        ];
        return;
      }
      if (value.tracking_error) {
        weight_limits[constraintName] = [0, value.tracking_error / 100];
      }
      if (value.target_volatility || value.target_return) {
        weight_limits[constraintName] = [
          (value.target_volatility || value.target_return) / 100,
          (value.target_volatility || value.target_return) / 100,
        ];
      }
      return;
    }
    if (step.endsWith('Constraint Prioritization (Optional)')) {
      if (value.constraint_prioritization) {
        // eslint-disable-next-line array-callback-return
        value.constraint_prioritization.map(constraint => {
          const fullName = constraint.constraint.toLowerCase().split('cap - floor');
          // eslint-disable-next-line prefer-destructuring
          constraintName =
            fullName[0] === 'diversification target'
              ? fullName[0].replace(' ', '_')
              : fullName[0].replace(' ', '');
          if (constraintName.search('volatility') > -1) {
            addOperationName('volatility');
            constraintName = 'target_risk';
          } else if (constraintName.search('return') > -1) {
            constraintName = 'target_return';
          } else if (constraintName.search('track') > -1) {
            constraintName = 'tracking_error';
          } else if (constraintName.search('drawdown') > -1) {
            constraintName = 'max_drawdown';
          }
          if (constraint.soft) {
            constraints_names = constraints_names.filter(item => item !== constraintName);
            penalization_ratios[constraintName] = 5 ** (constraint.penalization - 3);
          }
          if (constraint.removable) {
            removable_constraints_names.push(constraintName);
          }
        });
      }
      return;
    }
    if (step.toLowerCase().endsWith('extra rules (optional)')) {
      neglectable_value = value.negligible_weight ? Number(value.negligible_weight) / 100 : 2e-4;
      operations_name.push('filter_neglectable_weight');
    }
  });

  if (methodologyParams['General Rules']) {
    // const generalRules = methodologyParams['General Rules'];
    if (_.has(methodologyParams, ['General Rules', 'Currency', 'Currency', 'value'])) {
      currency = _.get(methodologyParams, ['General Rules', 'Currency', 'Currency', 'value'], '');
    }
    if (_.has(methodologyParams, ['Additional Rules', 'Cash Component', 'Cash'])) {
      cash = _.get(methodologyParams, ['Additional Rules', 'Cash Component', 'Cash'], '');
    }
    if (_.has(methodologyParams, ['General Rules', 'Return calculation', 'return_calculation'])) {
      type_return = _.get(
        methodologyParams,
        ['General Rules', 'Return calculation', 'return_calculation'],
        '',
      )
        .toLowerCase()
        .replace(/\s/g, '_');
    }
  }

  const optimizedApiBody = {
    operations_name,
    risk_model_id,
    parameters: {
      data: {
        parent_index_id,
        weight_limits,
        constraints_names,
        parent_constraints_names,
        alpha_score_coefficients,
        historical_drawdown_len,
        factor_per_constraints,
        penalization_ratios,
        removable_constraints_names,
        neglectable_value,
        objective_function,
        linear_coefficient_name,
        type_fundamentals: ['reconstitution'],
        tax: '',
        rank_by: '',
        ipo_rule: '',
        index_len: '',
        share_class_rule: '',
        rank_by_ascending: '',
        minimum_free_float: '',
        deletion_rule: 'next_reconstitution',
        type_return,
        cash: '',
        currency,
      },
    },
  };
  if (objective_function !== '') {
    return optimizedApiBody;
  }
  return {};
};
