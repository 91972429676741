import React from 'react';
import styles from '../Summary.module.scss';
import BitaButton from '../../../bitaComponents/BitaButton/BitaButton';

const PreviewValidate = ({ handleClick }) => {
  return (
    <div className={styles.previewValidateContainer}>
      <BitaButton width="190px" height="35px" primary onClick={() => handleClick(prev => !prev)}>
        Preview &amp; Validate
      </BitaButton>
    </div>
  );
};

export default PreviewValidate;
