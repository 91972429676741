/* eslint-disable func-names */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Heatmap from 'highcharts/modules/heatmap';
import HighchartsNoDataToDisplay from 'highcharts/modules/no-data-to-display';
// import Boost from 'highcharts/modules/boost';
import _ from 'lodash';
import { defaultTheme } from './Themes';

Heatmap(Highcharts);
HighchartsNoDataToDisplay(Highcharts);
const BitaCharts = ({ options, containerProps = {}, theme = defaultTheme }) => {
  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={_.merge(options, theme)}
      containerProps={containerProps}
    />
  );
};

export default BitaCharts;
