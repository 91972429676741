/* eslint-disable */
import React from 'react';
import BitaCharts from '../BitaCharts/BitaCharts';
import style from './BitaCardChart.module.scss';
import { setDecimal, thousandFormat } from '../../utils/utils';
const cardTheme = {
  chart: {
    backgroundColor: 'transparent',
    scrollablePlotArea: {
      maxHeight: 80,
    },
    animation: false,
  },
  boost: {
    useGPUTranslations: true,
  },

  xAxis: {
    visible: false,
  },

  yAxis: {
    visible: false,
  },

  title: '',

  legend: {
    enabled: false,
  },

  plotOptions: {
    line: {
      marker: {
        enabled: false,
      },
    },
    series: {
      color: 'white',
      marker: {
        enabled: false,
      },
      animation: false,
      boostThreshold: 100,
      turboThreshold: 15000,
    },
  },
  credits: {
    enabled: false,
  },
};

const cardOptions = data => ({
  tooltip: {
    formatter: function() {
      return (
        this.point.date + ' <br>' + this.series.name + ': ' + thousandFormat(setDecimal(this.y))
      );
    },
    style: {
      fontFamily: "'Neue Haas Grotesk Display Pro', sans-serif",
    },
    outside: true,
  },
  series: [{ name: 'Cumulative Return', data }],
});

const BitaCardChart = React.memo(({ title, data, width, firstButton, secondButton }) => {
  const isEmptyData = () => _.isEmpty(data);
  const waitingCardStyle = {
    filter: 'grayscale(36)',
    cursor: 'wait',
  };

  return (
    <div
      className={style.cardContainer}
      style={{
        ...{ width, height: '214px', cursor: 'pointer' },
        ...(isEmptyData() ? waitingCardStyle : {}),
      }}
    >
      <span className={style.cardTitle}>{title}</span>
      {_.isEmpty(data) ? (
        'LOADING...PLEASE WAIT'
      ) : (
        <BitaCharts
          key="historicalPerformanceChart"
          options={cardOptions(data)}
          theme={cardTheme}
          containerProps={{
            style: { height: '90px', width: '100%' },
          }}
        />
      )}
      {firstButton && secondButton && (
        <div className={style.buttonContainer}>
          <div className={style.firstButton}>
            <span className={style.firstText}>{firstButton.text}</span>
            <span className={style.firstValue}>{firstButton.value}</span>
          </div>
          <div className={style.firstButton}>
            <span className={style.firstText}>{secondButton.text}</span>
            <span className={style.firstValue}>{secondButton.value}</span>
          </div>
        </div>
      )}
    </div>
  );
});

export default BitaCardChart;
