export const messageMaintenance = (type, time) => {
  let message = ' ';
  if (type === 'URGENT') {
    message = `
            Dear User,
    
            We would like to inform you that the BITACore platform will go into maintenance
             and your session will be closed.
    
            Access to the platform will be restricted until further notice.
    
            We apologize for any inconvenience.
    
            Thank you,
            BITA team`;
  }
  if (type === 'PLANNED') {
    message = `
        Dear User,
  
        We would like to inform you that the BITACore platform will go into maintenance
         and your session will be closed ${time ? `in ${time}` : ''}.
  
        Access to the platform will be restricted until further notice.
  
        We apologize for any inconvenience.
  
        Thank you,
        BITA team`;
  }
  return message;
};
