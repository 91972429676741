import React from 'react';
import styles from './Layout.module.scss';
import SubTopbar from './SubTopbar';

const PageLayout = props => {
  return (
    <div className={styles.centralLeftPanel}>
      <SubTopbar />
      <div className={styles.pageLayout}>{props.children}</div>
    </div>
  );
};

export default PageLayout;
