import * as ACTIONS from '../../constants/universe-operations-constants';

const loadOperationsEnableStates = async (client = 'default') => {
  const filename = `init-universe-components-${client}.json`;

  /* eslint-disable */
  // desactivo eslint porque pide el fetch que es algo global.
  return fetch(`../../${filename}`).then(response => {
    return response.json();
  });
};

export const createNewFilterGroup = () => {
  return { type: ACTIONS.UNIVERSE_BUILDER_FILTERGROUP_CREATE };
};

export const resetUniverse = () => {
  return { type: ACTIONS.RESET };
};

export const selectUniverse = payload => {
  return { type: ACTIONS.UNIVERSE_BUILDER_UNIVERSE_SELECT, payload };
};

export const updateFilterData = payload => {
  return { type: ACTIONS.UNIVERSE_BUILDER_FILTERGROUP_UPDATEDATA, payload };
};

export const updateAllOperations = payload => {
  return { type: ACTIONS.ALL_UPDATE, payload };
};

export const updateGeneric = (subgroup, operation, data) => {
  return { type: ACTIONS.GENERIC_UPDATE, payload: { subgroup, operation, data } };
};

export const initOperationWidgets = client => dispatch => {
  loadOperationsEnableStates(client).then(rsp => {
    dispatch(updateAllOperations(rsp));
  });
};
