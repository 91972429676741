import React from 'react';
import { useSelector } from 'react-redux';
import styles from '../../builder.module.scss';
import { setBy } from '../../helpers/deepSet';
import { ComponentGenerator, Components } from '../Components';

export const BuilderSumRowsComponent = ({
  default_value,
  external_title,
  path,
  onChange = Function.prototype,
  components = {},
  id = '',
  widgetData,
  summaryData,
  stepData,
  sum_text,
  sum_id,
  data,
}) => {
  const [addAutoSum, setAutoSum] = React.useState(0);
  const [value, setValue] = React.useState(default_value || '');
  const [instruments, setInstruments] = React.useState([]);
  const [addData, setAddData] = React.useState();
  const summary = useSelector(state => state.summary);
  React.useEffect(() => {
    if (data === 'structure index') {
      setInstruments(summary?.['Structured Index']?.['Instrument Selected']);
    }
  }, [data]);

  let localPath = `${path}${id}`;
  if (path && id) {
    localPath = `${path}${id}`;
  } else if (external_title) {
    localPath = external_title;
  }

  const calculateSum = () => {
    if (addData && Object.values(addData).length > 0) {
      let sum = 0;
      Object.entries(addData).map(([key, rowValue]) => {
        if (rowValue) {
          sum += Number(rowValue);
        }
        return true;
      });
      // eslint-disable-next-line no-restricted-globals
      setAutoSum(isNaN(sum) ? 0 : sum);
    }
  };

  const onLocalChange = (pathl, auxvalue) => {
    setValue(pathl);
    if (auxvalue) {
      setAddData(setBy(addData, pathl, auxvalue));
      const auxSetData = addData;
      if (onChange) onChange(id, [auxSetData]);
    }
  };

  React.useEffect(() => {
    if (widgetData && widgetData[id]) onLocalChange(widgetData[id]);
  }, []);

  React.useEffect(() => {
    calculateSum();
  }, [addData]);

  React.useEffect(() => {
    if (!(addAutoSum === 0)) {
      const auxSetData = { ...addData };
      if (onChange) onChange(id, [auxSetData]);
    }
  }, [addAutoSum]);

  React.useEffect(() => {
    if (widgetData && widgetData[id]) setValue(widgetData[id]);
  }, [widgetData]);
  return (
    <div className="sumrowscontent">
      {instruments &&
        instruments.length > 0 &&
        Object.values(instruments).map((instrument, index) => (
          <div style={{ display: 'flex', alignItems: 'center' }} key={index} className="sumrow">
            <div style={{ marginRight: '10px', width: '300px' }}>
              {instrument?.['Instrument name']}{' '}
            </div>
            {components &&
              Object.values(components).map((component, indexC) => (
                <ComponentGenerator
                  path={instrument?.id}
                  widgetData={stepData && stepData[id] && stepData[id][indexC]}
                  {...component}
                  onChange={onLocalChange}
                  id=" "
                  key={indexC}
                  addibleSum={addAutoSum}
                />
              ))}
          </div>
        ))}
      {React.createElement(Components.Input, {
        key: `addSuma_${addAutoSum}`,
        type: 'Input',
        typeinput: 'number',
        text: sum_text,
        placeholder: `${addAutoSum}%`,
        id: 'sumId',
        value: addAutoSum,
        showAsLabel: true,
      })}
    </div>
  );
};
