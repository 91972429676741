const BULK_BASKET_DATA = {
  BDELUT: {
    metadata: {
      awf: {
        CH0025238863: 1,
        DE0005552004: 1,
      },
      iwf: {
        CH0025238863: 1,
        DE0005552004: 1,
      },
      shares: {
        CH0025238863: 172.3357525,
        DE0005552004: 4822.281564,
      },
      mic: {
        CH0025238863: 'XNYS',
        DE0005552004: 'XNYS',
      },
    },
  },
  BDELUN: {
    metadata: {
      awf: {
        US8716071076: 1,
        US1924461023: 1,
        US58733R1023: 1,
        US02079K3059: 1,
        US4824801009: 1,
      },
      iwf: {
        US8716071076: 1,
        US1924461023: 1,
        US58733R1023: 1,
        US02079K3059: 1,
        US4824801009: 1,
      },
      shares: {
        US8716071076: 152083817,
        US1924461023: 501412546,
        US58733R1023: 50559537,
        US02079K3059: 5725000000,
        US4824801009: 135932316,
      },
      mic: {
        US8716071076: 'XNAS',
        US1924461023: 'XNAS',
        US58733R1023: 'XNAS',
        US02079K3059: 'XNAS',
        US4824801009: 'XNAS',
      },
    },
  },
  BDELUP: {
    metadata: {
      awf: {
        IE00B4Q5ZN47: 1,
        NL0015000DX5: 1,
        US20451W1018: 1,
        CA89788C1041: 1,
        CA05156X8843: 1,
        US88688T1007: 1,
        US75955J4022: 1,
        CA22587M1068: 1,
        CA22717L1013: 1,
        US45781V1017: 1,
        US39986L1098: 1,
        IE000GID8VI0: 1,
        CA92338D1015: 1,
        CA60255C8850: 1,
        CA68620P7056: 1,
        CA13765Y1034: 1,
        CA1380357048: 1,
        US81577F2083: 1,
        CA23126M1023: 1,
        DE0006231004: 1,
        NL0012169213: 1,
        DE0007164600: 1,
        DE0008404005: 1,
      },
      iwf: {
        IE00B4Q5ZN47: 1,
        NL0015000DX5: 1,
        US20451W1018: 1,
        CA89788C1041: 1,
        CA05156X8843: 1,
        US88688T1007: 1,
        US75955J4022: 1,
        CA22587M1068: 1,
        CA22717L1013: 1,
        US45781V1017: 1,
        US39986L1098: 1,
        IE000GID8VI0: 1,
        CA92338D1015: 1,
        CA60255C8850: 1,
        CA68620P7056: 1,
        CA13765Y1034: 1,
        CA1380357048: 1,
        US81577F2083: 1,
        CA23126M1023: 1,
        DE0006231004: 1,
        NL0012169213: 1,
        DE0007164600: 1,
        DE0008404005: 1,
      },
      shares: {
        IE00B4Q5ZN47: 63134812,
        NL0015000DX5: 166010476,
        US20451W1018: 45783171,
        CA89788C1041: 159761126,
        CA05156X8843: 354205652,
        US88688T1007: 703257224,
        US75955J4022: 30099203,
        CA22587M1068: 316693073,
        CA22717L1013: 381089357,
        US45781V1017: 28039982,
        US39986L1098: 61231786,
        IE000GID8VI0: 52020849,
        CA92338D1015: 343367514,
        CA60255C8850: 39700000,
        CA68620P7056: 80498694,
        CA13765Y1034: 397028032,
        CA1380357048: 71727448,
        US81577F2083: 4245511,
        CA23126M1023: 631026947,
        DE0006231004: 1305921137,
        NL0012169213: 228035000,
        DE0007164600: 1228504232,
        DE0008404005: 391718983,
      },
      mic: {
        IE00B4Q5ZN47: 'XNAS',
        NL0015000DX5: 'XNAS',
        US20451W1018: 'XNAS',
        CA89788C1041: 'XCNQ',
        CA05156X8843: 'XTSE',
        US88688T1007: 'XNAS',
        US75955J4022: 'XNAS',
        CA22587M1068: 'XCNQ',
        CA22717L1013: 'XTSE',
        US45781V1017: 'XNYS',
        US39986L1098: 'XNAS',
        IE000GID8VI0: 'XNAS',
        CA92338D1015: 'NEOE',
        CA60255C8850: 'XNAS',
        CA68620P7056: 'XTSE',
        CA13765Y1034: 'NEOE',
        CA1380357048: 'XTSE',
        US81577F2083: 'XNAS',
        CA23126M1023: 'XTSE',
        DE0006231004: 'XETR',
        NL0012169213: 'XETR',
        DE0007164600: 'XETR',
        DE0008404005: 'XETR',
      },
    },
  },
  BDELET: {
    metadata: {
      awf: {
        IE00B4Q5ZN47: 1,
        NL0015000DX5: 1,
        US20451W1018: 1,
        CA89788C1041: 1,
      },
      iwf: {
        IE00B4Q5ZN47: 1,
        NL0015000DX5: 1,
        US20451W1018: 1,
        CA89788C1041: 1,
      },
      shares: {
        IE00B4Q5ZN47: 63134812,
        NL0015000DX5: 166010476,
        US20451W1018: 45783171,
        CA89788C1041: 159761126,
      },
      mic: {
        IE00B4Q5ZN47: 'XNAS',
        NL0015000DX5: 'XNAS',
        US20451W1018: 'XNAS',
        CA89788C1041: 'XCNQ',
      },
    },
  },
};

const DATA_DUMMIE_BACKTEST = [
  {
    timestamp: '2024-01-08',
    value: 346.33003479675256,
  },
  {
    timestamp: '2024-01-09',
    value: 344.97767292139974,
  },
  {
    timestamp: '2024-01-10',
    value: 347.02822361244307,
  },
  {
    timestamp: '2024-01-11',
    value: 346.33003479675256,
  },
  {
    timestamp: '2024-01-12',
    value: 344.97767292139974,
  },
  {
    timestamp: '2024-01-13',
    value: 347.02822361244307,
  },
];

const DUMMIE_PREFIX = 'ABC';

export { BULK_BASKET_DATA, DATA_DUMMIE_BACKTEST, DUMMIE_PREFIX };
