import React, { useState } from 'react';
import { ComponentGenerator, Components } from '../Components';
import styles from '../../builder.module.scss';

const DisclaimerRow = ({
  components = {},
  id = '',
  onChange,
  widgetData,
  summaryData,
  stepData,
  disclaimer,
  path,
}) => {
  return (
    <div className={`${styles.disclaimer_container}`}>
      <div className={`${styles.disclaimer_row}`}>
        {Object.values(components).map(
          (group, index) =>
            group && (
              <ComponentGenerator
                path={`[${path}].`}
                {...group}
                onChange={onChange}
                key={`${id}-${index}`}
              />
            ),
        )}
      </div>

      <label>{disclaimer}</label>
    </div>
  );
};

export default DisclaimerRow;
