// Private
const truncateFloat = value => Math.trunc(value * 100) / 100; // Truncate to two decimal places

// Public
export const isFloatWithMoreThanTwoDecimals = value => {
  try {
    // Check if the value is a number and has a decimal part
    if (typeof value === 'number' && !Number.isInteger(value)) {
      const decimalPart = value % 1; // Extract the decimal part

      const numberOfDecimals = decimalPart.toString().split('.')[1]?.length; // Count the number of decimals by converting to string and splitting

      if (numberOfDecimals > 2) {
        // If more than two decimals, truncate and return the truncated value
        return truncateFloat(value);
      }
    }

    return false; // Return false for non-numeric values or integers, or for values with two or fewer decimals
  } catch (error) {
    return false; // If an error occurs, just return false by default
  }
};
