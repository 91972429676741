/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import BitaButton from '../../../BitaButton/BitaButton';
import styles from './DefaultComponentsLayout.module.scss';
import { IconsModules } from '../../../../constants/icons-module';
import { Icons } from '../../../../constants/icons';
import { useLoadStatus } from '../../../../utils/loading_status_tools';
import { thousandFormat } from '../../../../utils/utils';

const IconFilter = Icons.filters;

// Componente que genera el primer campo de la fila en la tabla, hecho para que este de acuerdo al diseño
export const FirstRowComponent = ({
  user,
  title,
  applyButton,
  selectAction,
  typeElementTable,
  front_parameters,
}) => {
  const iconF = iconY => {
    switch (iconY) {
      case 'Methodologies':
        if (user === 'BITA') {
          return IconsModules.BitaMethodology;
        }
        return IconsModules.UserMethodologyModule;

      case 'settingsUniverse':
        if (user === 'BITA') {
          return IconsModules.BitaUniverseModule;
        }
        return IconsModules.UserUniverseModule;
      case 'settingsIndexes':
        return IconsModules.IndexModule;
      case 'settingsMethodologies':
        if (user === 'BITA') {
          return IconsModules.BitaMethodology;
        }
        return IconsModules.UserMethodologyModule;
      case 'settingsStructured Indexes':
        return IconsModules.StructureIModule;
      case 'settingsOptimized Indexes':
        return IconsModules.OptimizedModule;
      case 'settingsundefined':
        if (user === 'BITA') {
          return IconsModules.BitaUniverseModule;
        }
        return IconsModules.UserUniverseModule;
      default:
        if (user === 'BITA') {
          return IconsModules.BitaUniverseModule;
        }
        if (front_parameters && !iconY.includes('settings')) {
          if (Object.entries(front_parameters)?.length === 0 && user !== 'BITA') {
            return IconsModules.UniverseUserUpload;
          }
        }
        return IconsModules.UserUniverseModule;
    }
  };
  const Image = iconF(typeElementTable);
  const [loading, setLoading] = React.useState(false);
  const isSelected = useLoadStatus('universeselected');

  return (
    <div className={styles.firstRowContainer}>
      <div className={styles.titleIconContainer} onClick={selectAction}>
        <Image />
        <span className={`${styles.firstRowText} firstRowText`}>
          {title === 'Waste Intensity' ? `${title}*` : title}
        </span>
      </div>
      {applyButton && (
        <BitaButton
          disabled={isSelected}
          loadingColor="#2962ff"
          loading={loading && isSelected}
          onClick={() => {
            setLoading(true);
            selectAction();
          }}
          width="53px"
          height="23px"
          primaryWhite
        >
          Select
        </BitaButton>
      )}
    </div>
  );
};

const numberWithCommas = n => {
  const auxFloat = n.indexOf('.');
  const aux = n.replace(/M/g, '').replace(/,/g, '');
  const parts = aux.toString().split('.');
  return (
    parts[0].replace(/[^0-9]/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
    (parts[1] ? `.${parts[1].replace(/[^0-9]/g, '')}` : auxFloat > 0 ? '.' : '')
  );
};

const numberWithCommasM = n => {
  const auxFloat = n.indexOf('.');
  const aux = n.replace(/M/g, '').replace(/,/g, '');
  const parts = aux.toString().split('.');
  return `${parts[0].replace(/[^0-9]/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
    (parts[1] ? `.${parts[1].replace(/[^0-9]/g, '')}` : auxFloat > 0 ? '.' : '')}M`;
};

// eslint-disable-next-line consistent-return
const abbreviateNumber = auxValue => {
  const value = auxValue.replace(/,/g, '');
  const n = value;
  if (n < 1e6) return n;
  if (n >= 1e6) return `${numberWithCommas((n / 1e6).toFixed(1))}M`;
};

// Funcion helper que devuelve Componentes en base al nombre de la key en el objeto dentro del array de datos,
// (ej: En caso del objeto tener una propiedad { edit: true }  devuelve un Boton a mostrar con texto Edit)
// hecha pensando en un futuro cuando los datos vengan desde backend y para que esten de acuerdo al diseño.
export const getDisplayComponent = (
  key,
  value,
  typeElementTable,
  index,
  applyButton,
  selectAction,
  user,
  front_parameters,
) => {
  if (index === 0 || key === 'name') {
    return (
      <FirstRowComponent
        user={user}
        title={value}
        applyButton={applyButton}
        selectAction={selectAction}
        typeElementTable={typeElementTable}
        front_parameters={front_parameters}
      />
    );
  }
  switch (key) {
    case 'permitSharing':
      return (
        <div className={styles.previewButtonContainer}>
          <div className={styles.previewIconContainer}>ICON </div>
          <div>{value.sharing}</div>
        </div>
      );
    case 'edit':
      return (
        <BitaButton height="23px" width="55px" primaryWhite={value} secondary={!value}>
          Edit
        </BitaButton>
      );
    case 'preview':
      return (
        <BitaButton height="23px" width="77px" primaryWhite={value} secondary={!value}>
          Preview
        </BitaButton>
      );
    case 'Market Capitalization':
      return <div>{value === '' ? 'N/A' : abbreviateNumber(value)}</div>;
    case 'Market Cap':
      return (
        <div>
          {value === ''
            ? 'N/A'
            : value.indexOf('M') > -1
            ? numberWithCommasM(value)
            : numberWithCommas(value)}{' '}
        </div>
      );
    case 'Free Float Available to Foreign Investors':
      return (
        <div>
          {value === '' ? 'N/A' : numberWithCommas(value)}
          {'%'}
        </div>
      );
    case 'constituents':
      return <div>{value === '' ? 'N/A' : thousandFormat(value)} </div>;

    case 'Free Float Market Cap':
      return (
        <div>
          {value === ''
            ? 'N/A'
            : value.indexOf('M') > -1
            ? numberWithCommasM(value)
            : numberWithCommas(value)}{' '}
        </div>
      );
    case 'carbon_intensity':
      return <div>{value === '0' ? 'N/A' : `${value}`}</div>;
    case 'Relative Intensity':
      return <div>{value === '0' ? 'N/A' : `${value}%`}</div>;
    case 'created_at':
      return <div>{value.split('T')[0]}</div>;
    case 'caps':
      return <div>{value === '0%' || value === '0.00%' ? 'No Cap' : value}</div>;
    case 'floors':
      return <div>{value === '0%' || value === '0.00%' ? 'No Floor' : value}</div>;
    case 'updated_at':
      return <div>{value.split('T')[0]}</div>;
    default:
      return <div>{value === '' ? 'N/A' : value}</div>;
  }
};

// Componente default a usar en las cabeceras de la tabla.
export const DefaultTableHeaderComponent = props => (
  <th {...props}>
    {props.children} {props.children !== '' && <IconFilter />}
  </th>
);
