import React from 'react';
import { Route } from 'react-router-dom';
import Summary from '../Summary/Summary';
import Topbar from '../Topbar/Topbar';
import styles from './Layout.module.scss';
import PagesContainer from './PagesContainer';

const RightSidePanel = () => {
  return (
    <div className={styles.rightSidePanel}>
      <Topbar />
      <div className={styles.centralPanel}>
        <PagesContainer />
        <Route component={Summary} />
      </div>
    </div>
  );
};

export default RightSidePanel;
