import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PageLayout from '../../components/Layout/PageLayout';
import ConfigYAML from '../../config/ConfigYAML';
import BuildStructured from './BuildStructured';
import WorkflowModal from './WorkflowModal/WorkflowModal';
import { cleanProject } from '../../state/actions/project-actions';

const StructuresProduct = () => {
  const [initialWorkFlow, setInitialWorkflow] = useState(null);
  const [parametrization, setParametrization] = useState(null);
  const [modalOpened, setModalOpened] = useState(true);
  const [selectedInstruments, setSelectedInstruments] = useState([]);
  const token = useSelector(state => state.auth.token);
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const pathGeneral = location.pathname;
  const closeModal = (value, stayAtWorkFlow) => {
    setModalOpened(value);
    const path = `/`;
    if (!stayAtWorkFlow) {
      history.push(path);
    }
  };

  useEffect(() => {
    document.title = `${process.env.REACT_APP_SITE_TITLE} | Structured Index Builder`;
  }, []);

  useEffect(() => {
    if (pathGeneral.includes('/step=')) setModalOpened(true);
  }, [pathGeneral]);

  useEffect(() => {
    dispatch(cleanProject(token));
    const structuredProducts = ConfigYAML.jsonPath('$.structured_products');
    const sections = Object.values(structuredProducts.sections).map(s => {
      const section = { ...s };
      if (section.widgets) {
        section.widgets = Object.values(section.widgets);
      } else if (section.components) {
        section.components = Object.values(section.components);
      }
      return section;
    });
    setInitialWorkflow(sections.find(({ type }) => type === 'Slides'));
    setParametrization(sections.find(({ type }) => type === 'Default'));
  }, []);

  useEffect(() => {
    if (pathGeneral === '/structures-product/structures-product/step=STRUCTURED+INDEX+BUILDER') {
      dispatch(cleanProject(token));
    }
    const structuredProducts = ConfigYAML.jsonPath('$.structured_products');
    const sections = Object.values(structuredProducts.sections).map(s => {
      const section = { ...s };
      if (section.widgets) {
        section.widgets = Object.values(section.widgets);
      } else if (section.components) {
        section.components = Object.values(section.components);
      }
      return section;
    });
    if (pathGeneral.includes('Decrement')) {
      setParametrization(sections.find(({ type }) => type === 'Decrement Index'));
    } else if (pathGeneral === '/structures-product/structures-product/Leverage+Index') {
      setParametrization(sections.find(({ type }) => type === 'Leverage Index'));
    } else if (pathGeneral === '/structures-product/structures-product/step=Risk+Control+Index') {
      setParametrization(sections.find(({ type }) => type === 'Risk Control Index'));
    } else {
      setParametrization(sections.find(({ type }) => type === 'Default'));
    }
  }, [location.pathname]);

  return (
    <PageLayout>
      {initialWorkFlow && modalOpened && (
        <WorkflowModal
          {...initialWorkFlow}
          isModalOpen={modalOpened}
          setModalState={closeModal}
          onInstrumentSelect={setSelectedInstruments}
          instrumentApply={selectedInstruments}
        />
      )}
      {!modalOpened && (
        <BuildStructured
          parametrization={{ ...parametrization, type: 'Default' }}
          selectedInstruments={selectedInstruments}
        />
      )}
    </PageLayout>
  );
};

export default StructuresProduct;
