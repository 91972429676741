/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { DatePickerInput } from 'rc-datepicker';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify'; // Import css modules stylesheet as styles
import moment from 'moment';
import './BitaCalendarStyle.scss';
import styles from './BitaCalendar.module.scss';

const BitaCalendar = ({ date, minDate, maxDate, select, title, toUpperCaseTitle = true }) => {
  const [dateSelect, setdateSelect] = React.useState(date || null);

  useEffect(() => {
    if (date || dateSelect === null) {
      setdateSelect(date);
      select(date);
    } else if (minDate) {
      setdateSelect(minDate);
      select(minDate);
    }
  }, [date, minDate]);

  const onSelect = date => {
    setdateSelect(date);
    if (select) select(date);
  };

  return (
    <div className="CalendarDate">
      <div className={styles.text}>{toUpperCaseTitle ? title.toUpperCase() : title}</div>
      <DatePickerInput
        onChange={onSelect}
        minDate={minDate}
        maxDate={maxDate || new Date()}
        displayFormat="YYYY/MM/DD"
        returnFormat="YYYY-MM-DD"
        showOnInputClick
        value={dateSelect}
        className="bita-calendar-date"
      />
    </div>
  );
};

BitaCalendar.propTypes = {
  fecha: PropTypes.instanceOf(Date),
  select: PropTypes.func.isRequired,
  title: PropTypes.string,
  toUpperCaseTitle: Boolean,
  // eslint-disable-next-line react/forbid-prop-types
  minDate: PropTypes.any,
};

BitaCalendar.defaultProps = {
  title: ' ',
  toUpperCaseTitle: true,
  fecha: new Date(),
  minDate: null,
};

export default BitaCalendar;
