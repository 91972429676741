/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { orderBy, split, isEmpty } from 'lodash';
import 'simplebar'; // or "import SimpleBar from 'simplebar';" if you want to use it manually.
import 'simplebar/dist/simplebar.css';
import styles from '../../../../bitaComponents/BitaTable/BitaTable.module.scss';
import { Icons } from '../../../../constants/icons';
import BitaMultiSelect from '../../../../components/BitaMultiSelect/BitaMultiSelect';
import BitaButton from '../../../../bitaComponents/BitaButton/BitaButton';
import {
  getDisplayComponent,
  DefaultTableHeaderComponent,
} from '../../../../bitaComponents/BitaTable/lib/DefaultComponents/DefaultComponentsLayout';

const DropdownSelect = props => {
  const { setHeadersContent, headers, defaultHeaders } = props;
  return (
    <div className={styles.dropdownContainer}>
      <BitaMultiSelect
        options={headers}
        image={Icons.settings}
        returnArray
        defaultSelected={defaultHeaders}
        handleSelection={select => {
          setHeadersContent(select);
        }}
      />
    </div>
  );
};

export const RadioGroup = props => {
  return props.options.map(option => (
    <React.Fragment key={option}>
      <input
        id={`radio-${option}`}
        type="radio"
        name="radio-filter"
        value={option}
        onChange={() => {
          props.onChange(option);
        }}
        checked={props.optionSelected === option}
      />
      <label htmlFor={`radio-${option}`}>{option}</label>
    </React.Fragment>
  ));
};

const VisualizationTable = ({
  id,
  data,
  title,
  applyButton,
  CustomRowComponent,
  tableHeaderComponent,
  selectAction,
  showFields,
  secondaryTheme,
  typeElementTable,
  search = true,
  orderByTable,
  showOnly,
  checkboxContent,
  pagination = false,
  filterAuthor = false,
  height = false,
  scroll = true,
  equalColumnsWidth = false,
  spacedRows = false,
}) => {
  const orderbyDate = (a, b, key) => {
    return new Date(b[key]) - new Date(a[key]);
  };
  const [filteredData, setFilteredData] = React.useState(
    data.sort((a, b) => orderbyDate(a, b, orderByTable)),
  );
  const [sortDirection, setSortDirection] = React.useState('asc');
  const [showHeaders, setShowHeaders] = React.useState(showFields);
  const [page, setPage] = React.useState(1);
  const [optionSelected, setOptionSelected] = React.useState('Created by Organization');
  const userdata = useSelector(state => state.auth.userdata);

  const createOrderedObject = (objTableValue, order, defaultValue = '-') => {
    return order.reduce((acc, key) => {
      acc[key] = key in objTableValue ? objTableValue[key] : defaultValue;
      return acc;
    }, {});
  };

  const searchData = searchValue => {
    const auxSearch = [];
    if (searchValue !== '' && searchValue !== undefined) {
      data.map(obj => {
        if (obj.name && obj.name.toLowerCase().includes(searchValue.toLowerCase())) {
          auxSearch.push(obj);
        }
        if (
          obj?.['WHT Table Name'] &&
          obj?.['WHT Table Name'].toLowerCase().includes(searchValue.toLowerCase())
        ) {
          auxSearch.push(obj);
        }
      });
      if (orderByTable) auxSearch.sort((a, b) => orderbyDate(a, b, orderByTable));
      setFilteredData(auxSearch);
      setPage(1);
    } else {
      if (orderByTable) data.sort((a, b) => orderbyDate(a, b, orderByTable));
      setFilteredData(data);
      setPage(1);
    }
  };

  useEffect(() => {
    let _data = [...data];
    if (typeElementTable === 'AllUniverses' && optionSelected === 'Created by Organization') {
      _data = data.filter(obj => {
        return obj?.author !== 'BITA';
      });
    }
    setFilteredData(_data);
    if (isEmpty(showHeaders))
      setShowHeaders(Object.keys(data && (data[0] || {})).filter(item => item !== 'id'));
  }, [data]);

  const sortTable = (key, index) => {
    if (index !== 0) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
      if (
        key === 'Price' ||
        key === 'Waste Intensity' ||
        key === 'carbon_intensity' ||
        key === 'Relative Intensity'
      ) {
        if (sortDirection === 'asc') setFilteredData(filteredData.sort((a, b) => a[key] - b[key]));
        if (sortDirection === 'desc') setFilteredData(filteredData.sort((a, b) => b[key] - a[key]));
      } else if (key === 'Weight') {
        if (sortDirection === 'asc') {
          setFilteredData(filteredData.sort((a, b) => a[key].split('%')[0] - b[key].split('%')[0]));
        }
        if (sortDirection === 'desc')
          setFilteredData(filteredData.sort((a, b) => b[key].split('%')[0] - a[key].split('%')[0]));
      } else if (
        key === 'Market Cap' ||
        key === 'Free Float Market Cap' ||
        key === 'Free Float Available to Foreign Investors'
      ) {
        if (sortDirection === 'asc')
          setFilteredData(
            filteredData.sort((a, b) => a[key].replace(/M/g, '') - b[key].replace(/M/g, '')),
          );
        if (sortDirection === 'desc')
          setFilteredData(
            filteredData.sort((a, b) => b[key].replace(/M/g, '') - a[key].replace(/M/g, '')),
          );
      } else if (key === 'Market Capitalization') {
        if (sortDirection === 'asc')
          setFilteredData(
            filteredData.sort((a, b) => a[key].replace(/,/g, '') - b[key].replace(/,/g, '')),
          );
        if (sortDirection === 'desc')
          setFilteredData(
            filteredData.sort((a, b) => b[key].replace(/,/g, '') - a[key].replace(/,/g, '')),
          );
      } else {
        setFilteredData(orderBy(filteredData, [key], sortDirection));
      }
    }
    if (index === 0 && (key === 'ISIN' || key === 'Name' || key === 'Company Name')) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
      setFilteredData(orderBy(filteredData, [key], sortDirection));
    }
  };

  const onChangeSelected = option => {
    setOptionSelected(option);
  };

  const changeRowColor = type => {
    if (type === 'request_coverage' || type === 'not_found' || type === 'delete') {
      return 'rgba(243, 87, 87, 0.53)';
    }
    if (type === 'addition') {
      return '#5bd7525b';
    }
    if (type === 'update') {
      return '#e9d45c9f';
    }
  };

  useEffect(() => {
    let _data = [...data];

    if (optionSelected.includes(userdata.first_name)) {
      _data = data.filter(obj => {
        return obj.author && obj.author.includes(`${userdata.first_name} ${userdata.last_name}`);
      });
    }
    if (optionSelected === 'Templates by BITA') {
      _data = data.filter(obj => {
        return obj.author && obj.author === 'BITA';
      });
      if (
        userdata.id_company === process.env.REACT_APP_ZBK_ID ||
        userdata.id_company === process.env.REACT_APP_ARCLAY_ID
      ) {
        _data = data.filter(universe => universe.id === 12500);
      }
    }

    if (typeElementTable === 'AllUniverses' && optionSelected === 'Created by Organization') {
      _data = data.filter(obj => {
        return obj?.author !== 'BITA';
      });
    }

    if (orderByTable) _data.sort((a, b) => orderbyDate(a, b, orderByTable));
    setFilteredData(_data);
  }, [optionSelected]);

  return (
    <div className="w tablemodal" style={{ height: height || '' }}>
      <div className={styles.bitaTableContainer}>
        <div className={scroll ? styles.tableTitleContainer : ''}>
          {filterAuthor && typeElementTable !== 'Universes' && (
            <div>
              <RadioGroup
                options={
                  typeElementTable === 'EditUniverses'
                    ? [
                        'Created by Organization',
                        `Created by ${userdata.first_name} ${userdata.last_name}`,
                      ]
                    : [
                        'Created by Organization',
                        `Created by ${userdata.first_name} ${userdata.last_name}`,
                        'Templates by BITA',
                      ]
                }
                optionSelected={optionSelected}
                onChange={onChangeSelected}
              />
            </div>
          )}
          <div className={`${styles.tableTitle} tabletitle`}>
            {typeof title === 'function' ? title() : title}
          </div>

          {search ? (
            <div className={styles.searchContainer}>
              <input
                className="searchInput"
                type="text"
                onChange={e => searchData(e.target.value)}
                autoFocus
                placeholder="Search by..."
              />
            </div>
          ) : (
            ' '
          )}
        </div>
        <div className="content-Widget" style={{ minWidth: '800px', height: height || '380px' }}>
          {scroll ? (
            <div data-simplebar style={{ height: pagination ? 'calc(100% - 50px)' : '100%' }}>
              <table className={`${styles.bitaTable} ${spacedRows && styles.tableSpacedRows}`}>
                <thead
                  className={`${styles.bitaTableHead} ${
                    secondaryTheme ? '' : styles.primaryThemeWidth
                  }`}
                >
                  {secondaryTheme && (
                    <DropdownSelect
                      headers={Object.keys(data[0] || {})}
                      defaultHeaders={showFields}
                      setHeadersContent={newHeaders =>
                        setShowHeaders(newHeaders.filter(item => item !== 'id'))
                      }
                    />
                  )}
                  <tr
                    className={`${styles.bitaTableHeaderRow} ${secondaryTheme ||
                      styles.headerPrimaryTheme}`}
                  >
                    {Object.keys(data[0] || {}).map((key, index) =>
                      tableHeaderComponent ? (
                        <>
                          {showHeaders.includes(key) && (
                            <tableHeaderComponent onClick={() => sortTable(key, index)}>
                              {key === 'revision_type'
                                ? 'Review Period'
                                : key === 'Market Capitalization'
                                ? 'Market Cap'
                                : key === 'waste_intensity'
                                ? 'Waste Intensity *'
                                : key.replace(/_|#|-|@|<>/g, ' ')}
                            </tableHeaderComponent>
                          )}
                        </>
                      ) : (
                        <>
                          {showHeaders.includes(key) && (
                            <DefaultTableHeaderComponent
                              className={`${secondaryTheme && styles.headerSecondaryTheme}`}
                              onClick={() => sortTable(key, index)}
                            >
                              {key === 'revision_type'
                                ? 'Review Period'
                                : key === 'Market Capitalization'
                                ? 'Market Cap'
                                : key === 'waste_intensity'
                                ? 'Waste Intensity *'
                                : key === 'carbon_intensity'
                                ? 'Carbon Intensity*'
                                : key === 'type' && id === 'basket'
                                ? ''
                                : key === 'exchange' && id === 'basket'
                                ? 'MIC'
                                : key === 'isin' && id === 'basket'
                                ? 'Constituents'
                                : key === '0'
                                ? 'date'
                                : key === '1'
                                ? 'value'
                                : key.replace(/_|#|-|@|<>/g, ' ')}
                            </DefaultTableHeaderComponent>
                          )}
                        </>
                      ),
                    )}
                    {checkboxContent && <th />}
                  </tr>
                </thead>
                <tbody className={styles.bitaTableBody}>
                  {Object.entries(
                    pagination ? filteredData.slice(page - 1, page + 9) : filteredData,
                  )
                    .slice(0, showOnly)
                    .map(([key, tableValue]) => {
                      return (
                        <tr
                          style={{
                            background:
                              id === 'basket' &&
                              tableValue?.type &&
                              changeRowColor(tableValue?.type),
                          }}
                          className={`tr ${!secondaryTheme ? styles.bitaTableContentColumn : ''}`}
                          id={`${key} ${tableValue}`}
                        >
                          {Object.entries(tableValue).map(([entryKey, value], index) => (
                            <>
                              {showHeaders.includes(entryKey) && (
                                <td
                                  key={index}
                                  id={`${index}`}
                                  className={`${
                                    equalColumnsWidth ? styles.defaultFistColumnWidth : ''
                                  } ${styles.td} ${secondaryTheme && styles.rowSecondaryTheme}`}
                                >
                                  <div className={styles.bitaTableRowContent}>
                                    {/* "Just like the headers, use a custom component to render the rows if provided via props."
                                     */}
                                    {CustomRowComponent
                                      ? // <CustomRowComponent data={{ ...tableValue }} />
                                        CustomRowComponent({
                                          data: { ...tableValue },
                                          index,
                                          entryKey,
                                          value,
                                        })
                                      : getDisplayComponent(
                                          entryKey,
                                          value,
                                          typeElementTable,
                                          index,
                                          applyButton,
                                          () => selectAction(tableValue),
                                          tableValue.author,
                                          tableValue.front_parameters,
                                        )}
                                  </div>
                                </td>
                              )}
                            </>
                          ))}

                          {checkboxContent && checkboxContent(typeElementTable, tableValue.id)}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          ) : (
            <div style={{ height: pagination ? 'calc(100% - 50px)' : '100%' }}>
              <table className={styles.bitaTable}>
                <thead
                  className={`${styles.bitaTableHead} ${
                    secondaryTheme ? '' : styles.primaryThemeWidth
                  }`}
                >
                  {secondaryTheme && (
                    <DropdownSelect
                      headers={Object.keys(data[0] || {})}
                      defaultHeaders={showFields}
                      setHeadersContent={newHeaders =>
                        setShowHeaders(newHeaders.filter(item => item !== 'id'))
                      }
                    />
                  )}
                  <tr
                    className={`${styles.bitaTableHeaderRow} ${secondaryTheme ||
                      styles.headerPrimaryTheme}`}
                  >
                    {Object.keys(data[0] || {}).map((key, index) =>
                      tableHeaderComponent ? (
                        <>
                          {showHeaders.includes(key) && (
                            <tableHeaderComponent onClick={() => sortTable(key, index)}>
                              {key === 'revision_type'
                                ? 'Review Period'
                                : key === 'Market Capitalization'
                                ? 'Market Cap'
                                : key === 'waste_intensity'
                                ? 'Waste Intensity *'
                                : key.replace(/_|#|-|@|<>/g, ' ')}
                            </tableHeaderComponent>
                          )}
                        </>
                      ) : (
                        <>
                          {showHeaders.includes(key) && (
                            <DefaultTableHeaderComponent
                              className={`${secondaryTheme && styles.headerSecondaryTheme}`}
                              onClick={() => sortTable(key, index)}
                            >
                              {key === 'revision_type'
                                ? 'Review Period'
                                : key === 'Market Capitalization'
                                ? 'Market Cap'
                                : key === 'waste_intensity'
                                ? 'Waste Intensity *'
                                : key === 'carbon_intensity'
                                ? 'Carbon Intensity*'
                                : key === 'info'
                                ? ''
                                : key === '0'
                                ? 'date'
                                : key === '1'
                                ? 'value'
                                : key.replace(/_|#|-|@|<>/g, ' ')}
                            </DefaultTableHeaderComponent>
                          )}
                        </>
                      ),
                    )}
                    {checkboxContent && <th />}
                  </tr>
                </thead>
                <tbody className={styles.bitaTableBody}>
                  {Object.entries(
                    pagination ? filteredData.slice(page - 1, page + 9) : filteredData,
                  )
                    .slice(0, showOnly)
                    .map(([key, tableValue]) => (
                      <tr
                        style={{
                          background:
                            id === 'basket' &&
                            tableValue?.type &&
                            tableValue?.type !== 'with_coverage'
                              ? 'rgba(243, 87, 87, 0.53)'
                              : '',
                        }}
                        className={`tr ${!secondaryTheme ? styles.bitaTableContentColumn : ''}`}
                        id={`${key} ${tableValue}`}
                      >
                        {Object.entries(
                          id === 'basket' && !tableValue?.exchange
                            ? createOrderedObject(tableValue, showHeaders, '-')
                            : tableValue,
                        ).map(([entryKey, value], index) => (
                          <>
                            {showHeaders.includes(entryKey) && (
                              <td
                                key={index}
                                id={`${index}`}
                                className={`${styles.td} ${secondaryTheme &&
                                  styles.rowSecondaryTheme}`}
                              >
                                <div className={styles.bitaTableRowContent}>
                                  {CustomRowComponent
                                    ? // <CustomRowComponent data={{ ...tableValue }} />
                                      CustomRowComponent({
                                        data: { ...tableValue },
                                        index,
                                        entryKey,
                                        value,
                                      })
                                    : getDisplayComponent(
                                        entryKey,
                                        value,
                                        typeElementTable,
                                        index,
                                        applyButton,
                                        () => selectAction(tableValue),
                                        tableValue.author,
                                        tableValue.front_parameters,
                                      )}
                                </div>
                              </td>
                            )}
                          </>
                        ))}

                        {checkboxContent && checkboxContent(typeElementTable, tableValue.id)}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          )}

          <br />
          {pagination && (
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
              <BitaButton
                style={{ width: '35px', height: '25px', marginRight: '5px' }}
                primaryWhite
                disabled={page === 1}
                onClick={() => {
                  setPage(1);
                }}
              >
                {'<<'}
              </BitaButton>
              <BitaButton
                style={{ width: '35px', height: '25px', marginRight: '5px' }}
                primaryWhite
                disabled={page === 1}
                onClick={() => {
                  setPage(prev => (prev < 11 ? 1 : prev - 10));
                }}
              >
                {'<'}
              </BitaButton>
              <BitaButton
                disabled={
                  (page && Math.ceil(page / 10)) === (data && Math.ceil(filteredData?.length / 10))
                }
                style={{ width: '35px', height: '25px', marginRight: '5px' }}
                primaryWhite
                onClick={() => {
                  setPage(prev => prev + 10);
                }}
              >
                {'>'}
              </BitaButton>
              <BitaButton
                disabled={
                  (page && Math.ceil(page / 10)) === (data && Math.ceil(filteredData?.length / 10))
                }
                style={{ width: '35px', height: '25px', marginRight: '5px' }}
                primaryWhite
                onClick={() => {
                  setPage((data && Math.ceil(filteredData?.length / 10)) * 10 - 9);
                }}
              >
                {'>>'}
              </BitaButton>
              <span style={{ marginLeft: '10px' }}>
                {page && Math.ceil(page / 10)}/{data && Math.ceil(filteredData?.length / 10)}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

VisualizationTable.defaultProps = {
  applyButton: false,
  showFields: [],
};

export default VisualizationTable;
