/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-shadow */
import React from 'react';
import DateRangePicker from 'react-daterange-picker';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Transition } from 'react-spring/renderprops.cjs';
import useOutsideClick from './useOutsideClick';
import './BitaCalendarStyle.scss';
import styles from './BitaCalendar.module.scss'; // Import css modules stylesheet as styles

const Calendar = props => {
  const [dateEnd, setdateEnd] = React.useState(new Date());
  const [dateStart, setdateStart] = React.useState(new Date());

  const [isCalendarDisplayed, setCalendarDisplayed] = React.useState(false);
  const ref = React.useRef();

  const showCalendar = () => {
    setCalendarDisplayed(!isCalendarDisplayed);
  };

  const selectfecha = date => {
    setdateStart(date.start);
    setdateEnd(date.end);
    if (props.select) props.select(date);
    showCalendar();
  };

  useOutsideClick(ref, () => {
    if (isCalendarDisplayed) {
      setCalendarDisplayed(false);
    }
  });
  return (
    <div className="CalendarRange">
      <div className={styles.text}>{props.title.toUpperCase()}</div>
      <input
        type="text"
        value={`${moment(dateStart).format('ll')} - ${moment(dateEnd).format('ll')}`}
        onClick={showCalendar}
        className={styles.input}
      />
      <div className={styles.contenteCalendar} ref={ref}>
        <Transition
          items={isCalendarDisplayed}
          from={{ opacity: 0 }}
          enter={{ opacity: 1 }}
          leave={{ opacity: 0 }}
        >
          {showCalendar =>
            showCalendar &&
            (styleAnimated => (
              <div style={styleAnimated} className={styles.calendar}>
                <DateRangePicker onSelect={selectfecha} />
              </div>
            ))
          }
        </Transition>
      </div>
    </div>
  );
};

Calendar.propTypes = {
  select: PropTypes.func.isRequired,
  title: PropTypes.string,
};

Calendar.defaultProps = {
  title: ' ',
};

export default Calendar;
