import React, { useLayoutEffect, useState } from 'react';
import moment from 'moment';
import BitaCharts from '../../../bitaComponents/BitaCharts/BitaCharts';
import BitaButton from '../../../bitaComponents/BitaButton/BitaButton';
import { thousandFormat } from '../../../utils/utils';
import styles from '../Summary.module.scss';

const GraphComponent = props => {
  // Genera datos falsos a partir del Number of Constituents recibidos
  // usados para generar la grafica de una manera mas asemejada al diseño
  const [categories, setCategories] = useState([]);
  const [values, setValues] = useState([]);

  useLayoutEffect(() => {
    const category = [];
    const val = [];
    const sortedData = Object.keys(props.constituentsgraph).sort(
      (a, b) => new Date(b) - new Date(a),
    );
    // eslint-disable-next-line no-plusplus
    for (let index = sortedData.length - 1; index >= 0; index--) {
      category.push([sortedData[index]]);
      val.push(props.constituentsgraph[sortedData[index]]);
    }
    if (category.length > 1 && new Date(category[0]) > new Date(category[1])) {
      setCategories(category.slice().reverse());
      setValues(val.slice().reverse());
    } else {
      setCategories(category);
      setValues(val);
    }
  }, [props]);

  const chartData = {
    chart: {
      type: 'column',
    },
    title: {
      text: '',
    },
    yAxis: {
      title: {
        text: '',
      },
      labels: {
        formatter() {
          // eslint-disable-next-line react/no-this-in-sfc
          return `${thousandFormat(this.value)}`;
        },
      },
    },
    xAxis: {
      categories: categories.slice(),
      title: {
        text: '',
      },
    },
    tooltip: {
      pointFormat:
        '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
      shared: true,
    },
    series: [{ data: values.slice() }],
    lang: {
      noData: "There's no data available for the time frame you've selected",
    },
    noData: {
      style: {
        fontWeight: 'bold',
        fontSize: '12px',
        color: '#ffffff',
      },
    },
  };
  // generateConstituents(
  // universeSelected.noConstituents || universeSelected['Nº of constituents'],
  // 11,
  // );
  return (
    <div className={styles.graphContainer}>
      <div className={styles.chartTitleContainer}>
        <div className={styles.chartTitle}>CONSTITUENTS EVOLUTION</div>
        <div className={styles.chartButtons}>
          <BitaButton
            className={styles.chartButtonsWhite}
            primaryWhite
            onClick={() => {
              props.filterYears(-1);
            }}
          >
            YTD
          </BitaButton>
          <BitaButton
            className={styles.chartButtonsWhite}
            primaryWhite
            onClick={() => {
              props.filterYears(1);
            }}
          >
            1 Year
          </BitaButton>
          <BitaButton
            className={styles.chartButtonsWhite}
            primaryWhite
            onClick={() => {
              props.filterYears(3);
            }}
          >
            3 Years
          </BitaButton>
          <BitaButton
            className={styles.chartButtonsWhite}
            primaryWhite
            onClick={() => {
              props.filterYears(5);
            }}
          >
            5 Years
          </BitaButton>
          <BitaButton
            className={styles.chartButtonsWhite}
            primaryWhite
            onClick={() => {
              props.filterYears(moment(categories && categories[0] && categories[0][0]).year());
            }}
          >
            Max.
          </BitaButton>
        </div>
      </div>

      <BitaCharts
        options={chartData}
        containerProps={{ style: { width: '100%', height: 'calc(100% - 110px)' } }}
      />

      <div className={styles.previewModalButtonText}>NUMBER OF CONSTITUENTS THROUGHOUT TIME</div>
    </div>
  );
};
export default GraphComponent;
