import React from 'react';
import styles from './GeneralWidget.module.scss';

const GeneralWidget = props => {
  const { title, info, extraComponent, extraComponentProps } = props;
  return (
    <div className={styles.widgetContainer}>
      <div className={styles.titleContainer}>
        <div className={styles.title}>{title}</div>
        {extraComponent && extraComponent(extraComponentProps)}
      </div>
      <div className={styles.titleLineSeparator} />
      {info && <div className={styles.infoContainer}>{info}</div>}
    </div>
  );
};

export default GeneralWidget;
