import React from 'react';
import classNames from 'classnames';
import { ReactComponent as ArrowIcon } from '../../assets/img/icons/arrow.svg';
import styles from '../SideNavbar.module.scss';

const NavArrow = data => {
  const arrowStyle = data.collapsed === 'collapsed' ? styles.arrowCollapsed : styles.arrowExpanded;
  const classnames = classNames(styles.arrow, arrowStyle);
  return <ArrowIcon className={classnames} />;
};

export default NavArrow;
