import { ReactComponent as UniverseModule } from '../static/img/Icons-modules/Universe_icon.svg';
import { ReactComponent as BitaUniverseModule } from '../static/img/Icons-modules/Bita_-_Universe_-_Icon.svg';
import { ReactComponent as BitaMethodology } from '../static/img/Icons-modules/Bita_-_Methodology_-_Icon.svg';

import { ReactComponent as UserUniverseModule } from '../static/img/Icons-modules/User_-_Universe_-_Icon.svg';
import { ReactComponent as UserMethodologyModule } from '../static/img/Icons-modules/User_-_Methodology_-_Icon.svg';

import { ReactComponent as IndexModule } from '../static/img/Icons-modules/Index_Icon.svg';
import { ReactComponent as OptimizedModule } from '../static/img/Icons-modules/Optimized_Indexes_Icon.svg';
import { ReactComponent as StructureIModule } from '../static/img/Icons-modules/Structured_Indexes_Icon.svg';
import { ReactComponent as PortfolioBasketModule } from '../static/img/Icons-modules/Basket-Icon.svg';
import { ReactComponent as FactsheetModule } from '../static/img/Icons-modules/FB-Icon.svg';
import { ReactComponent as RebalancingReconstitutionModule } from '../static/img/Icons-modules/RR-Icon.svg';

import { ReactComponent as MethodologyModule } from '../static/img/Icons-modules/MethodologyIcon.svg';
import { ReactComponent as IndexUser } from '../static/img/Icons-modules/User_-_Index_-_Icon.svg';
import { ReactComponent as UniverseUserUpload } from '../static/img/Icons-modules/Universe _User_Upload_Icon.svg';
import { ReactComponent as CreateNewIndex } from '../static/img/Icons-modules/Create_New_Index.svg';
import { ReactComponent as CreateNewMethodology } from '../static/img/Icons-modules/Create_New_Methodology.svg';
import { ReactComponent as BaseUniverse } from '../static/img/Icons-modules/Base_Universe.svg';
import { ReactComponent as BaseMethodology } from '../static/img/Icons-modules/Base_Methodology.svg';
import { ReactComponent as CreateFromTemplate } from '../static/img/Icons-modules/Create_from_Template.svg';
import { ReactComponent as IndexWhiteCanvas } from '../static/img/Icons-modules/White_Canvas_-_2.svg';
import { ReactComponent as EditMethodology } from '../static/img/Icons-modules/Edit_Methodology.svg';

import { ReactComponent as CreateNewUniverse } from '../static/img/Icons-modules/Create_New_Universe.svg';
import { ReactComponent as EditExistingUniverse } from '../static/img/Icons-modules/Edit_Existing_Universe.svg';
import { ReactComponent as UniverseWhiteCanvas } from '../static/img/Icons-modules/White_Canvas.svg';
import { ReactComponent as StartfromTemplate } from '../static/img/Icons-modules/Start_from_Template.svg';

// iconos index builder
import { ReactComponent as RebalancingReconstitution } from '../static/img/Icons-modules/rebalancing_and_reconstitution.svg';
import { ReactComponent as FastExitEntry } from '../static/img/Icons-modules/fast_exit_and_entry.svg';
import { ReactComponent as AvailableRules } from '../static/img/Icons-modules/available_rules.svg';
import { ReactComponent as GeneralRules } from '../static/img/Icons-modules/general.svg';
import { ReactComponent as AdditionalRules } from '../static/img/Icons-modules/additional_rules.svg';
import { ReactComponent as SettingsUsermanagement } from '../static/img/Icons-modules/user_management.svg';
import { ReactComponent as SettingsDataUpload } from '../static/img/Icons-modules/data_upload.svg';
import { ReactComponent as SettingsDatabaseSelection } from '../static/img/Icons-modules/database_selection.svg';
import { ReactComponent as SettingsContentManager } from '../static/img/Icons-modules/content_manager.svg';
import { ReactComponent as dos } from '../static/img/Icons/user.svg';
import { ReactComponent as MethodologyRRIcon } from '../static/img/Icons-modules/Methodology-RR-Icon.svg';
import { ReactComponent as UniverseRRIcon } from '../static/img/Icons-modules/Universe-RR-Icon.svg';
import { ReactComponent as ACIcon } from '../static/img/Icons-modules/AC-Provisional-name.svg';

export const IconsModules = {
  SettingsUsermanagement,
  SettingsDataUpload,
  SettingsDatabaseSelection,
  SettingsContentManager,
  UniverseModule,
  BitaUniverseModule,
  ACIcon,
  BitaMethodology,
  UserUniverseModule,
  UserMethodologyModule,
  IndexModule,
  IndexUser,
  OptimizedModule,
  StructureIModule,
  PortfolioBasketModule,
  MethodologyModule,
  FactsheetModule,
  RebalancingReconstitution,
  FastExitEntry,
  AvailableRules,
  GeneralRules,
  AdditionalRules,
  CreateNewIndex,
  CreateNewMethodology,
  BaseUniverse,
  UniverseUserUpload,
  BaseMethodology,
  CreateFromTemplate,
  StartfromTemplate,
  UniverseWhiteCanvas,
  IndexWhiteCanvas,
  CreateNewUniverse,
  EditExistingUniverse,
  EditMethodology,
  dos,
  RebalancingReconstitutionModule,
  MethodologyRRIcon,
  UniverseRRIcon,
};
