import axios from 'axios';
import _ from 'lodash';
import { toast } from 'react-toastify';
import FileSaver from 'file-saver';
import * as DownloadConstants from '../../constants/download-constants';

export const setDataModal = data => {
  return { type: DownloadConstants.UPDATE_MODAL, payload: { data } };
};

export const setOpenModal = open => {
  return { type: DownloadConstants.OPEN_MODAL, payload: { open } };
};

export const downloadFile = (token, data) => {
  const url = `${process.env.REACT_APP_API_URL}/generate-file`;
  const body = {
    type_file: data.file,
    user: data.user,
    index: data.index,
    company: data.company,
    customizable: data?.customizable ?? false,
    timeframe: data.timeframe,
    reconstitution_date: data.reconstitution_date.value,
    rebalancing_file: data.rebalancing_file,
  };
  const updateState = {
    open: false,
    factsheet: data.file === 'factsheet',
    report: data.file === 'report',
    pcfConstituents: data.file === 'pcf_constituent',
    indexMetadata: data.file === 'index_metadata',
    rebalancing_file: data.file === 'rebalancing_file',
    esg:
      data.file === 'emission' ||
      data.file === 'waste' ||
      data.file === 'controversy' ||
      data.file === 'rating',
  };
  axios
    .post(url, body, { headers: { Authorization: `Bearer ${token}` } })
    .then(rsp => {})
    .catch(e => {
      console.info('Error generating file', e);
    });
  return { type: DownloadConstants.UPDATE_MODAL, payload: { data: { ...updateState } } };
};

export const loadFilesFTP = (token, data) => {
  const url = `${process.env.REACT_APP_API_URL}/pack-files`;

  const body = [
    // {
    //   type_file: 'pdf',
    //   user: data.user,
    //   index: data.index,
    //   company: data.company,
    //   timeframe: data.timeframe,
    // },
    // {
    //   type_file: 'excel',
    //   user: data.user,
    //   index: data.index,
    // },
    // {
    //   type_file: 'factsheet',
    //   user: data.user,
    //   index: data.index,
    //   company: data.company,
    //   timeframe: data.timeframe,
    //   reconstitution_date: data.reconstitution_date.value,
    // },
    // {
    //   type_file: 'report',
    //   user: data.user,
    //   index: data.index,
    //   company: data.company,
    //   timeframe: data.timeframe,
    //   reconstitution_date: data.reconstitution_date.value,
    // },
    // {
    //   type_file: 'ratings',
    //   user: data.user,
    //   index: data.index,
    //   company: data.company,
    //   timeframe: data.timeframe,
    //   reconstitution_date: data.reconstitution_date.value,
    // },
    // {
    //   type_file: 'emission',
    //   user: data.user,
    //   index: data.index,
    //   company: data.company,
    //   timeframe: data.timeframe,
    //   reconstitution_date: data.reconstitution_date.value,
    // },
    // {
    //   type_file: 'controversy',
    //   user: data.user,
    //   index: data.index,
    //   company: data.company,
    //   timeframe: data.timeframe,
    //   reconstitution_date: data.reconstitution_date.value,
    // },
    // {
    //   type_file: 'waste',
    //   user: data.user,
    //   index: data.index,
    //   company: data.company,
    //   timeframe: data.timeframe,
    //   reconstitution_date: data.reconstitution_date.value,
    // },
    {
      type_file: 'excel_constituents',
      user: data.user,
      index: data.index,
      company: data.company,
    },
    {
      type_file: 'excel',
      user: data.user,
      index: data.index,
      company: data.company,
    },
  ];
  console.log(body, 'body in downloadactions');
  const updateState = {
    open: false,
  };
  axios
    .post(url, body, { headers: { Authorization: `Bearer ${token}` } })
    .then(rsp => {
      toast('Load to FTP started', { autoClose: 15000 });
    })
    .catch(e => {
      console.info('Error generating file', e);
    });
  return { type: DownloadConstants.UPDATE_MODAL, payload: { data: { ...updateState } } };
};

export const downloadService = filename => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/downloads`, {
      responseType: 'blob',
      params: { file: filename },
    })
    .then(response => {
      FileSaver.saveAs(response.data, `${filename}`);
    })
    .catch(e => console.info('Error downloading file', filename));
  const updateStateFactsheet = {
    factsheet: false,
  };
  const updateStateReport = {
    report: false,
  };
  const updateStateESG = {
    esg: false,
  };
  const stateToUpdate = () => {
    if (filename.search('-Report') > -1) {
      return { ...updateStateReport };
    }
    if (filename.search('-ESG') > -1) {
      return { ...updateStateESG };
    }
    return { ...updateStateFactsheet };
  };
  return { type: DownloadConstants.UPDATE_MODAL, payload: { data: stateToUpdate() } };
};

export const initState = () => {
  return { type: DownloadConstants.INIT_STATE, payload: {} };
};
