import React, { useState, useEffect } from 'react';
import styles from '../AttributionAnalyzer.module.scss';
import { dropdownOptions } from './Tabs';
import BitaSelect from '../../../BitaSelect/BitaSelect';
import { getAttributionIndex } from './charts/GetAttributionChartIndex';

export default ({ currentTab, indexStats, period }) => {
  const [currentChart, setCurrentChart] = useState(null);
  const [index, setIndex] = useState('');
  const [selectedTimeFrame, setSelectedTimeFrame] = useState('1y');

  const currentText = currentTab === 'RISK FACTOR ATTRIBUTION' ? 'Risk' : 'Performance';

  useEffect(() => {
    setCurrentChart(dropdownOptions[currentText][0]);
  }, [currentText]);

  useEffect(() => {
    setIndex(getAttributionIndex(period));
  }, [period]);

  return (
    <div className={styles.radioButtonHolder}>
      <div className={styles.radioButtonsContainer}>
        {currentChart && (
          <div className={styles.filterContainer}>
            <p className={styles.filterText}>Select the graph you would like to visualize:</p>
            <BitaSelect
              className={styles.filterDropdown}
              data={dropdownOptions[currentText]}
              label={currentChart.value}
              handleSelection={selected => setCurrentChart(selected)}
              selected={currentChart.value}
            />
          </div>
        )}
      </div>
      <div className={styles.sectorsChartContainer}>
        {currentChart && currentChart.id({ indexStats, index })}
      </div>
    </div>
  );
};
