const GeneralStatsPITButtons = [
  { name: '1 Year', value: '1y' },
  { name: '3 Years', value: '3y' },
  { name: '5 Years', value: '5y' },
  { name: 'Max', value: 'max' },
];

const years = ['2020', '2019', '2018', '2017', '2016', '2015', '2014', '2013'];

const yearsReverse = ['2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020'];
const sustainalyticsESG = [
  {
    name: 'ESG Risk Score',
    value: 'ESG Risk Score',
    id: 150,
  },
  {
    name: 'Overall Exposure Score',
    value: 'Overall Exposure Score',
    id: 151,
  },
  {
    name: 'Overall Management Score',
    value: 'Overall Management Score',
    id: 152,
  },
];

const gcScores = [
  { name: 'GC Score Total', value: 'GC Score Total', id: 141 },
  {
    name: 'Human Rights',
    value: 'Human Rights',
    id: 142,
  },
  {
    name: 'Labour Rights',
    value: 'Labour Rights',
    id: 143,
  },
  { name: 'Environment', value: 'Environment', id: 144 },
  {
    name: 'Anti-Corruption',
    value: 'Anti-Corruption',
    id: 145,
  },
];
const esgScores = [
  { name: 'ESG Score Total', value: 'ESG Score Total', id: 146 },
  {
    name: 'Environment',
    value: 'Environment',
    id: 147,
  },
  {
    name: 'Social',
    value: 'Social',
    id: 148,
  },
  {
    name: 'Governance',
    value: 'Governance',
    id: 149,
  },
];

const features = [
  { feature_name: 'Business Ethics Scores', id: 153 },
  { feature_name: 'Capital Structures Scores', id: 154 },
  { feature_name: 'Community Relations Scores', id: 173 },
  { feature_name: 'Compensation Scores', id: 162 },
  { feature_name: 'Corporate Governance Score', id: 155 },
  { feature_name: 'Diversity Score', id: 163 },
  { feature_name: 'Emissions Score', id: 157 },
  { feature_name: 'Employment Quality Scores', id: 164 },
  { feature_name: 'Environmental Management	Score', id: 158 },
  { feature_name: 'Environmental Stewardship Score', id: 160 },
  { feature_name: 'Environmental Solutions Score', id: 172 },
  { feature_name: 'Forensic Accounting Score', id: 161 },
  { feature_name: 'Human Rights Score', id: 165 },
  { feature_name: 'Labour Rights Score', id: 166 },
  { feature_name: 'Occupational Health & Safety Score', id: 167 },
  { feature_name: 'Product Access Score', id: 174 },
  { feature_name: 'Product Quality & Safety Score', id: 169 },
  { feature_name: 'Resource Use Score', id: 170 },
  { feature_name: 'Training and Development Score', id: 168 },
  { feature_name: 'Transparency Score', id: 156 },
  { feature_name: 'Waste Score', id: 159 },
  { feature_name: 'Water Score', id: 171 },
];

export {
  esgScores,
  gcScores,
  years,
  yearsReverse,
  sustainalyticsESG,
  GeneralStatsPITButtons,
  features,
};
