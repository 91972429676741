import React from 'react';
import BitaCalendarRange from '../../../components/BitaCalendar/BitaCalendarRange';

export const BuilderCalendarRangeComponent = props => {
  const onSelect = value => {
    props.onChange(props.title, value);
  };

  return <BitaCalendarRange title={props.title} select={onSelect} />;
};
