import lodash from 'lodash';
import * as ACTIONS from '../../constants/universe-operations-constants';

const initialState = {
  selectedUniverse: { 'Universe Name': 'UNIVERSAL GLOBAL CAP', noConstituents: 12354 },
  filterGroups: [{}],
  filtersData: [],
};

const universeOperationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.GENERIC_UPDATE: {
      const { subgroup, operation, data } = action.payload;
      return {
        ...state,
        [subgroup]: {
          ...state[subgroup],
          [operation]: {
            ...state[subgroup][operation],
            ...data,
          },
        },
      };
    }
    case ACTIONS.ALL_UPDATE: {
      return {
        ...state,
        ...lodash.merge(state, action.payload),
      };
    }
    case ACTIONS.UNIVERSE_BUILDER_UNIVERSE_SELECT: {
      return {
        ...state,
        selectedUniverse: action.payload,
      };
    }
    case ACTIONS.UNIVERSE_BUILDER_FILTERGROUP_CREATE: {
      return {
        ...state,
        filterGroups: state.filterGroups.concat({}),
      };
    }

    case ACTIONS.UNIVERSE_BUILDER_FILTERGROUP_UPDATEDATA: {
      return {
        ...state,
        filtersData: action.payload,
      };
    }
    case ACTIONS.RESET: {
      return {
        selectedUniverse: { 'Universe Name': 'UNIVERSAL GLOBAL CAP', noConstituents: 12354 },
        filterGroups: [{}],
        filtersData: [],
      };
    }

    default: {
      return state;
    }
  }
};

export default universeOperationsReducer;
