import React from 'react';
import { Icons } from '../../constants/icons';
import UniverseFiltersBuilder from './universe_filters/UniverseFiltersBuilder';
// import IndexBuilderSidebar from './index-builder/IndexBuilderSidebar';

const indexBuildersItems = [
  { title: 'General Rules', to: '/index-builders/parametrization/general-rules' },
  {
    title: 'Rebalancing & Reconstitution',
    to: '/index-builders/parametrization/rebalacing-reconstitution',
  },
  { title: 'Fast Entry & Exit', to: '/index-builders/parametrization/fast-exit-entry' },
  { title: 'Additional Rules', to: '/index-builders/parametrization/additional-rules' },
];
/*
const optimizedIndexItems = [
  {
    title: 'Universe / Index selection',
    to: '/optimized-index/universe-index-selection',
    icon: Icons.universelection,
  },
  {
    title: 'Specify Constraints',
    to: '/optimized-index/specify-contraints',
    icon: Icons.constraints,
  },
];
const structuresProductItmes = [
  {
    title: 'Build Structured',
    to: '/structures-product/build-structured',
    icon: Icons.structured,
  }, ];
*/

const indexESGAnalyticsItems = [
  {
    title: 'ESG Rating',
    to: '/index-analyzer/ratings',
  },
  {
    title: 'ESG Issues',
    to: '/index-analyzer/esg-issues',
  },
  {
    title: 'Emissions',
    to: '/index-analyzer/emission',
  },
  {
    title: 'Waste',
    to: '/index-analyzer/waste',
  },
];

const indexAnalyzerItems = [
  {
    title: 'Performance and Risk',
    to: '/index-analyzer/performance',
  },
  {
    title: 'Characteristics',
    to: '/index-analyzer/characteristics',
  },
  {
    title: 'Attribution',
    to: '/index-analyzer/risk-attribution',
  },
  {
    title: 'ESG Analytics',
    childs: indexESGAnalyticsItems,
    to: '/index-analyzer/ratings/step=ESG+ANALYTICS',
  },
];

const liveBasketItems = [
  {
    title: 'Performance and Risk',
    to: '/index-analyzer/performance',
  },
  {
    title: 'Characteristics',
    to: '/index-analyzer/characteristics',
  },
  {
    title: 'Attribution',
    to: '/index-analyzer/risk-attribution',
  },
  {
    title: 'ESG Analytics',
    to: '/index-analyzer/ratings/step=ESG+ANALYTICS',
  },
];

export const userItems = [
  {
    title: 'Settings',
    to: '/settings',
    icon: Icons.parameters,
  },
  {
    title: 'Tutorials',
    to: '/tutorials',
    icon: Icons.IconTutorials,
    banner: 'New',
  },
  {
    title: 'Customer Service',
    to: '/customer-service',
    icon: Icons.CustomerService,
  },
  {
    title: 'Log Out',
    to: '/logout',
    icon: Icons.logout,
  },
];

const liveBasketItem = [
  {
    title: 'SING. BASKET SUBMISSION',
    to: '/live-calculation/basket/submit-basket',
  },
  {
    title: 'BASKET UPDATE/EDITION',
    to: '/live-calculation/basket/update-basket',
  },
  {
    title: 'BASKET TO INDEX CONV.',
    to: '/live-calculation/basket/baskt-to-index',
  },
  {
    title: 'BULK BASKET SUBMISSION',
    to: '/live-calculation/basket/bulk-basket-submission',
  },
];

export const sideBarNavigationItems = [
  {
    title: 'HOME',
    items: null,
    to: '/',
  },
  {
    title: 'UNIVERSE BUILDER',
    // items: UniverseBuilderFiltersBuilder,
    content: <UniverseFiltersBuilder />,
    to: '/universe-builder/universe-builder',
  },
  {
    title: 'INDEX BUILDER',
    items: indexBuildersItems,
    // content: <IndexBuilderSidebar />,
    to: '/index-builders/index-builders',
  },
  {
    title: 'OPTIMIZED INDEX',
    items: null,
    to: '/optimized-index/optimized-index',
  },
  {
    title: 'STRUCTURED INDEX',
    // items: structuresProductItmes,
    // cambios que se perdieron cuando se mergeo test
    to: '/structures-product/structures-product/step=STRUCTURED+INDEX+BUILDER',
  },
  {
    title: 'PORTFOLIO / BASKET CALCULATION',
    to: '/portfolio-basket-calculation/portfolio-basket-calculation',
  },
  {
    title: 'REBALANCING & RECONSTITUTION',
    to: '/rebalancing-reconstitution/rebalancing-reconstitution',
  },
  {
    title: 'ANALYZER',
    items: indexAnalyzerItems,
    to: '/index-analyzer/analyzer',
    hideFilter: true,
  },
  {
    title: 'FACTSHEET BUILDER',
    to: '/factsheet-builder/factsheet-builder',
  },
  {
    title: 'BASKET MANAGEMENT MODULES',
    items: liveBasketItem,
    to: '/live-calculation/basket/submit-basket',
    hideFilter: true,
  },
];
