import React from 'react';

export const RadioGroup = props => {
  return props.options.map(option => (
    <React.Fragment
      key={option?.value || option}
      style={{ opacity: option.disabled ? '0.5' : '1' }}
    >
      <input
        id={`radio-${option?.value || option}`}
        type="radio"
        name="radio-filter"
        value={option?.value || option}
        onChange={() => {
          props.onChange(option?.value || option);
        }}
        style={{
          opacity: option.disabled ? '0.5' : '1',
          pointerEvents: option.disabled ? 'none' : '',
        }}
        checked={props.optionSelected === (option?.value || option)}
      />
      <label
        htmlFor={`radio-${option?.value || option}`}
        style={{
          opacity: option.disabled ? '0.5' : '1',
          pointerEvents: option.disabled ? 'none' : '',
        }}
      >
        {option?.value || option}
      </label>
    </React.Fragment>
  ));
};
