import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import Modal from 'react-modal';
import './BitaModal.scss';
import { setDataModal, downloadFile, loadFilesFTP } from '../../state/actions/download-actions';
import { Icons } from '../../constants/icons';
import styles from '../Analyzer/PerformanceAndRisk/PerformanceAndRisk.module.scss';
import BitaButton from '../../bitaComponents/BitaButton/BitaButton';

const customStyles = {
  content: {
    whiteSpace: 'pre-line',
    top: '50%',
    left: '50%',
    maxHeight: '95%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    background: 'rgb(41, 41, 42)',
    color: '#ffffff',
    border: 'none',
    borderRadius: '15px',
    paddingLeft: 50,
    paddingRight: 50,
    fontSize: 16,
    fontWeight: 600,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  overlay: {
    backgroundColor: 'rgba(78,78,78, 0.75)',
    zIndex: '999999',
  },
};

const esgTimeframes = [
  { name: '1 Year', value: '1Y' },
  { name: '3 Years', value: '3Y' },
  { name: '5 Years', value: '5Y' },
];

const esgTemplates = [
  { name: 'Ratings', value: 'ratings' },
  { name: 'ESG Issues', value: 'controversy' },
  { name: 'Emissions', value: 'emission' },
  { name: 'Waste', value: 'waste' },
];
const timeFrameButtons = [
  { name: 'YTD', value: 'YTD' },
  { name: '1 Year', value: '1Y' },
  { name: '3 Years', value: '3Y' },
  { name: '5 Years', value: '5Y' },
  { name: 'Max', value: 'Max' },
];

export const DownloadCenterModal = props => {
  const [isOpen, setIsOpen] = useState(false);
  const [timeframe, setTimeframe] = useState('1Y');
  const [custom, setCustom] = useState(false);
  const [esg, setEsg] = useState('ratings');

  const [dateSelected, setDateSelected] = useState({});
  const [datesArray, setDateArray] = useState([]);
  const currentIndexSelected = useSelector(
    state => state.apiData.apiData['Current Index Selected'],
  );
  const token = useSelector(state => state.auth?.token || false);
  const user = useSelector(state => state.auth?.userdata?._id);
  const company = useSelector(state => state.auth?.userdata?.id_company || false);
  const dispatch = useDispatch();
  const closeModal = () => {
    dispatch(setDataModal({ open: false }));
    setIsOpen(false);
  };
  const history = useHistory();
  const brandingButtons = [
    { name: 'BITA', value: false },
    { name: 'CUSTOM', value: company },
  ];
  const modalData = useSelector(state => state.download_center);
  useEffect(() => {
    if (modalData) {
      setIsOpen(modalData.open || false);
    }
  }, [modalData]);

  useEffect(() => {
    const dateList = _.get(currentIndexSelected, 'data.dates_constitutions_real', false);
    if (dateList) {
      setDateArray(
        Object.entries(dateList)
          .slice()
          .reverse()
          .map(([key, reconstitution_date], i) => {
            return { id: i, value: key };
          }),
      );
    }
  }, [currentIndexSelected]);
  useEffect(() => {
    setDateSelected(datesArray[0] || '');
  }, [datesArray]);

  const requestDownload = () => {
    const request = {
      file: modalData?.file !== 'emission' ? modalData.file : esg,
      user,
      index: currentIndexSelected.data.id,
      company: company || false,
      customizable: custom,
      timeframe,
      reconstitution_date: dateSelected,
    };
    dispatch(downloadFile(token, request));
  };

  const goToBrandingWidget = () => {
    dispatch(setDataModal({ open: false }));
    setIsOpen(false);
    history.push('/settings', { goToBottom: true });
  };

  return (
    <div className="transitionModal">
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        style={customStyles}
        shouldCloseOnOverlayClick
      >
        <div className="downloadHeader">
          <Icons.Avanti style={{ marginRight: '20px' }} />
          <h2 style={{ margin: '0', padding: '0', fontSize: '22px', textTransform: 'uppercase' }}>
            {modalData?.file
              .replace('si_factsheet', 'factsheet')
              .replace('factors_report', 'STYLE FACTOR TILTS REPORT')
              .replace('emission', 'ESG Report')}
            Options
          </h2>
          <span onClick={closeModal} className="closeDownloadModal">
            &times;
          </span>
        </div>
        <div className="separator" />
        <label className="modalText">
          Select how would you like to customize your{' '}
          {modalData?.file
            .replace('si_factsheet', 'factsheet')
            .replace('bi_factsheet', 'factsheet')
            .replace('mini_factsheet', 'mini factsheet')
            .replace('bi_report', 'report')
            .replace('factors_report', 'Style Factor Tilts report:')
            .replace('emission', 'ESG Report')
            .replace('File generation', 'generated files')}
        </label>
        {modalData?.file === 'emission' && (
          <div>
            <span className="modalTitle">ANALYTICS FOR:</span>
            {esgTemplates.map(pit => (
              <div>
                <input
                  id={`download-center${pit.value}`}
                  type="radio"
                  onClick={() => {
                    if (
                      timeframe === '5Y' &&
                      (pit.value !== 'controversy' || pit.value !== 'ratings')
                    ) {
                      setTimeframe('1Y');
                    }
                    setEsg(pit.value);
                  }}
                  checked={esg === pit.value}
                />
                <label htmlFor={`download-center${pit.value}`}>{pit.name}</label>
              </div>
            ))}
          </div>
        )}
        <span className="modalTitle">Timeframe</span>
        {modalData?.file !== 'emission' && (
          <div className="radioButtonsContainer">
            {timeFrameButtons.map(pit => (
              <div>
                <input
                  id={`download-center${pit.value}`}
                  type="radio"
                  onClick={() => setTimeframe(pit.value)}
                  checked={timeframe === pit.value}
                />
                <label htmlFor={`download-center${pit.value}`}>{pit.name}</label>
              </div>
            ))}
          </div>
        )}
        {modalData?.file === 'emission' && (
          <div className="radioButtonsESGContainer">
            {esgTimeframes.map(pit => (
              <div
                style={{
                  opacity:
                    (esg === 'emission' || esg === 'waste') && pit.value === '5Y' ? '0.51' : '1',
                }}
              >
                <input
                  id={`download-center${pit.value}`}
                  type="radio"
                  onClick={() => setTimeframe(pit.value)}
                  checked={timeframe === pit.value}
                  disabled={(esg === 'emission' || esg === 'waste') && pit.value === '5Y'}
                />
                <label htmlFor={`download-center${pit.value}`}>{pit.name}</label>
              </div>
            ))}
          </div>
        )}
        {/* 
        <span className="modalTitle">Characteristics Snapshot Date</span>
        datesArray.length > 0 && (
          <BitaSelect
            data={datesArray}
            menu_height={130}
            handleSelection={selected => {
              setDateSelected(selected);
            }}
            value={dateSelected}
            label={dateSelected.value}
            width="100%"
          />
          ) */}

        <span className="modalTitle">Branding</span>
        <div className="radioButtonsContainer" style={{ justifyContent: 'flex-start' }}>
          {brandingButtons.map(pit => (
            <div>
              <input
                id={`download-center${pit.value}`}
                type="radio"
                onClick={() => setCustom(pit.value)}
                checked={custom === pit.value}
              />
              <label htmlFor={`download-center${pit.value}`}>{pit.name}</label>
            </div>
          ))}
        </div>
        <div className="buttonsFooter">
          <BitaButton
            primaryWhite
            height="28px"
            width="148px"
            className={styles.bitaButtonCustom}
            onClick={() => goToBrandingWidget()}
          >
            Setup Branding
          </BitaButton>
          <BitaButton
            primary
            height="28px"
            width="148px"
            className={styles.bitaButtonCustom}
            onClick={() => {
              if (modalData?.file === 'File generation') {
                const request = {
                  user,
                  company,
                  timeframe,
                  reconstitution_date: dateSelected,
                };
                props.onClick(request);
              } else {
                requestDownload();
              }
            }}
          >
            Generate Output
          </BitaButton>
        </div>
      </Modal>
    </div>
  );
};
