/* eslint-disable radix */
import React from 'react';
import BitaCharts from '../../../bitaComponents/BitaCharts/BitaCharts';
import { invertedTheme } from '../../../bitaComponents/BitaCharts/Themes';
// import BitaButton from '../../../bitaComponents/BitaButton/BitaButton';
import styles from '../Summary.module.scss';

const getData = (universe, filterGroups) => {
  return {
    chart: {
      type: 'column',
      inverted: true,
    },
    title: {
      text: '',
    },
    yAxis: {
      tickInterval: 50,
      title: {
        text: '',
      },
    },
    xAxis: {
      categories:
        universe?.['Universe Name'] === 'WHITE CANVAS'
          ? [
              ...Object.keys(filterGroups || {}).map(
                filter => `Filter Group #${parseInt(filter) + 1}`,
              ),
            ]
          : [
              'Full Universe',
              ...Object.keys(filterGroups || {}).map(
                filter => `Filter Group #${parseInt(filter) + 1}`,
              ),
            ],
      title: {
        text: '',
      },
    },
    tooltip: {
      pointFormat:
        '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
      shared: true,
    },
    series: [
      {
        showInLegend: false,
        data: [5, 3, 4, 7, 2],
      },
    ],
  };
};
const FiltersImpactComponent = props => {
  // Genera datos falsos a partir del Number of Constituents recibidos
  const data = getData(props.universe, props.firstImpact);
  // usados para generar la grafica de una manera mas asemejada al diseño
  const chartData = {
    ...data,
    series: [
      {
        ...data.series[0],
        data:
          props.universe?.['Universe Name'] === 'WHITE CANVAS'
            ? [
                ...Object.keys(props.firstImpact || {}).map(
                  filter => filter && props.firstImpact[filter]?.elements,
                ),
              ]
            : [
                parseInt(props.universe.constituents),
                ...Object.keys(props.firstImpact || {}).map(
                  filter => props.firstImpact[filter]?.elements,
                ),
              ],
      },
    ],
  };
  // generateConstituents(
  // universeSelected.noConstituents || universeSelected['Nº of constituents'],
  // 4,
  // );
  return (
    <div className={styles.filtersImpactContainer}>
      <div className={styles.chartWithSub}>
        <div className={styles.chartTitle}>FILTERS IMPACT</div>
        <div className={styles.chartSubTitle}>
          This is the count of how many assets will be included in the last revision of the universe
          using the filters you selected
        </div>
      </div>
      <BitaCharts
        options={chartData}
        theme={invertedTheme}
        containerProps={{ style: { width: '100%', height: 'calc(100% - 120px)' } }}
      />
    </div>
  );
};
export default FiltersImpactComponent;
