import React, { useState } from 'react';
import Modal from 'react-modal';
// eslint-disable-next-line import/no-cycle
import { ComponentGenerator } from '../Components';
import styles from '../builderAdditionalSettings.module.scss';
import { Icons } from '../../../constants/icons';
import BitaButton from '../../../bitaComponents/BitaButton/BitaButton';

export const BuilderAdditionalSettingsComponent = props => {
  const [isOpen, setOpen] = useState(false);

  const openModal = () => {
    setOpen(true);
  };
  const closeModal = () => {
    setOpen(false);
  };
  const customStyles = {
    content: {
      whiteSpace: 'pre-line',
      top: '50%',
      left: '50%',
      maxHeight: '95%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'rgba(42,45,49, 1)',
      color: '#ffffff',
      border: 'none',
      borderRadius: '15px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      minWidth: '500px',
      minHeight: '400px',
    },
    overlay: {
      backgroundColor: 'rgba(78,78,78, 0.75)',
      zIndex: '999999',
    },
  };
  const Icon = Icons.account;

  return (
    <div className={styles.clickable_container}>
      <div className="additionalSettingButton">
        <BitaButton height="28px" width="100px" primaryWhite onClick={() => openModal(true)}>
          {props.title}
        </BitaButton>
      </div>
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={false}
        style={customStyles}
      >
        <header className={styles.settings_header}>
          <div className={styles.title_container}>
            <Icon style={{ marginRight: '20px' }} width={40} height={40} />
            <span style={{ fontSize: '20px', marginLeft: '0px' }}>{props.title}</span>
          </div>
          <Icons.Avanti style={{ marginRight: '20px', transform: 'scale(1.3)' }} />
        </header>
        <div className={styles.modal_container}>
          {Object.values(props.components).map((component, index) => (
            <ComponentGenerator
              {...component}
              onChange={props.onChange}
              default_option_selected_position={
                props.stepData['Index Backtest']?.['Sector Classification']?.id ||
                component.default_option_selected_position
              }
            />
          ))}
        </div>
        <footer>
          <BitaButton height="28px" width="148px" primary onClick={closeModal}>
            Apply
          </BitaButton>
        </footer>
      </Modal>
    </div>
  );
};

export default BuilderAdditionalSettingsComponent;
