import React from 'react';
import styles from './Topbar.module.scss';

const TopbarButton = props => {
  const { label, callback, children } = props;
  return (
    <button className={styles.topbarButton} onClick={callback}>
      {children}
      <span style={{ width: label === 'Log Out' ? '50px' : '' }}>{label}</span>
    </button>
  );
};

export default TopbarButton;
