const controversyTopics = [
  {
    id: 108,
    name: ' Adult Entertainment Flag',
    value: ' Adult Entertainment Flag',
  },
  {
    id: 109,
    name: ' Alcohol Flag',
    value: ' Alcohol Flag',
  },
  { id: 110, name: ' Defense Flag', value: ' Defense Flag' },
  { id: 111, name: ' Fossil Fuel Flag', value: ' Fossil Fuel Flag' },
  { id: 112, name: ' Gambling Flag', value: ' Gambling Flag' },
  {
    id: 113,
    name: ' Genetically Modified Organisms Flag',
    value: ' Genetically Modified Organisms Flag',
  },
  {
    id: 114,
    name: ' Nuclear Flag',
    value: ' Nuclear Flag',
  },
  {
    id: 115,
    name: ' Pork Flag',
    value: ' Pork Flag',
  },
  {
    id: 116,
    name: ' Stem Cells Flag',
    value: ' Stem Cells Flag',
  },
  {
    id: 117,
    name: ' Tobacco Flag',
    value: ' Tobacco Flag',
  },
  {
    id: 260,
    name: 'Recreational Drugs Flag',
    value: 'Recreational Drugs Flag',
  },
  {
    id: 258,
    name: 'Thermal Coal Flag',
    value: 'Thermal Coal Flag',
  },
  {
    id: 259,
    name: 'Firearms Flag',
    value: 'Firearms Flag',
  },
  {
    id: 257,
    name: 'Controversial Weapons Flag',
    value: 'Controversial Weapons Flag',
  },
  {
    id: 'Total Allocation',
    value: 'Total Allocation',
  },
];

const sustainalytics = [
  {
    id: 120,
    value: ' Human Capital',
  },
  {
    id: 121,
    value: ' Data Privacy and Security',
  },
  {
    id: 123,
    value: ' Access to Basic Services',
  },

  {
    id: 124,
    value: ' Bribery and Corruption',
  },

  {
    id: 125,
    value: ' Community Relations',
  },
  {
    id: 126,
    value: ' Emissions, Effluents and Waste',
  },
  {
    id: 127,
    value: ' Carbon - Own Operations',
  },

  {
    id: 128,
    value: ' Carbon - Products and Services',
  },
  {
    id: 129,
    value: ' E&S Impact of Products and Services',
  },
  {
    id: 131,
    value: ' Human Rights - Supply Chain',
  },

  {
    id: 133,
    value: ' Land Use and Biodiversity',
  },
  {
    id: 134,
    value: ' Land Use and Biodiversity - Supply Chain',
  },

  {
    id: 135,
    value: ' Occupational Health and Safety',
  },
  {
    id: 136,
    value: ' ESG Integration - Financials',
  },
  {
    id: 137,
    value: ' Product Governance',
  },
  {
    id: 138,
    value: ' Resilience',
  },
  {
    id: 139,
    value: ' Resource Use',
  },
  {
    id: 140,
    value: ' Resource Use - Supply Chain',
  },
  {
    id: 'Total Allocation',
    value: 'Total Allocation',
  },
];

export { controversyTopics, sustainalytics };
