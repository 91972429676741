import {
  ActivePerformanceChart,
  ActiveRiskChart,
  ContributionActivePerformance,
  ContributionActiveRisk,
} from './charts';

export const dropdownOptions = {
  Performance: [
    { value: 'Active Performance', id: ActivePerformanceChart },
    {
      value: 'Active Factor Performance Breakdown',
      id: ContributionActivePerformance,
    },
  ],
  Risk: [
    { value: 'Active Risk', id: ActiveRiskChart },
    { value: 'Active Factor Risk Breakdown', id: ContributionActiveRisk },
  ],
};
const tabOptions = {
  Performance: [
    { radioName: 'Active Performance', chart: ActivePerformanceChart },
    {
      radioName: 'Active Factor Performance Breakdown',
      chart: ContributionActivePerformance,
    },
  ],
  Risk: [
    { radioName: 'Active Risk', chart: ActiveRiskChart },
    { radioName: 'Active Factor Risk Breakdown', chart: ContributionActiveRisk },
  ],
};

export default tabOptions;
