import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
// eslint-disable-next-line import/no-unresolved
import 'simplebar'; // or "import SimpleBar from 'simplebar';" if you want to use it manually.
import 'simplebar/dist/simplebar.css';
import FileSaver from 'file-saver';
import axios from 'axios';
import ConfigYAML from '../../config/ConfigYAML';
import * as CommonActions from '../../state/actions/common-actions';
import * as ApiDataActions from '../../state/actions/api-data-actions';
import PageLayout from '../../components/Layout/PageLayout';
import styles from './index-builder.module.css';
import style from './PortfolioBasketCalculation.module.scss';
import { IconsModules } from '../../constants/icons-module';
import { toastInterceptor } from '../../utils/utils';
import { URL_CONSTANTS } from '../../api-data-mapping/url-constants';
import SubmitBasket from './SubmitBasket';
import UpdateBasket from './UpdateBasket';
import BasketToIndex from './BasketToIndex';
import BulkBasketSubmission from './BulkBasketSubmission';
import './Portfolio.css';

const getBasket = (tokenUser, data) => {
  return axios.get(URL_CONSTANTS.GETLIVEBASKET, {
    headers: { Authorization: `Bearer ${tokenUser}` },
  });
};
const Imagen = IconsModules.MethodologyModule;

const PortfolioBasketCalculatio = () => {
  const [files, setFiles] = React.useState('');
  const token = useSelector(state => state.auth.token);
  const [dataPortfolio, setDataPortfolio] = React.useState({});
  const portfolioBasketCalculation = ConfigYAML.jsonPath('$.live_calculation_basket');
  const [currentView, setCurrentView] = React.useState(null);
  const [widgets, setWidgets] = useState(null);
  const [type, setType] = useState('Basket');
  const [data, setData] = useState();
  const [selectBasket, setSelectBasket] = useState();
  const [listBasket, setListBasket] = useState([]);
  const location = useLocation();

  const cards = Object.values(portfolioBasketCalculation?.components ?? {})?.filter(
    field => field.type === 'Card',
  );

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(CommonActions.resetSubheader());
    dispatch(CommonActions.appendSubheader('Live Calculation / Basket'));
    document.title = `${process.env.REACT_APP_SITE_TITLE} | Live Calculation / Basket`;
    dispatch(ApiDataActions.setBaskets(token));
    getBasket(token)
      .then(rsp => {
        setListBasket(Object.values(rsp?.data?.data));
      })
      .catch(error => {
        toastInterceptor(error);
      });
  }, []);

  useEffect(() => {
    setType('Basket');
    setData(false);
    setSelectBasket(false);
    setFiles(null);
    switch (location.pathname) {
      case '/live-calculation/basket':
        setWidgets(null);
        break;

      case '/live-calculation/basket/submit-basket':
        setWidgets(cards[0].onclick.widgets);
        setCurrentView('Single Basket Submission');
        break;
      case '/live-calculation/basket/update-basket':
        setWidgets(cards[1].onclick.widgets);
        setCurrentView('Basket Update/Edition');
        break;
      case '/live-calculation/basket/baskt-to-index':
        setWidgets(cards[2].onclick.widgets);
        setCurrentView('Basket to Index');
        break;

      case '/live-calculation/basket/bulk-basket-submission':
        setWidgets(cards[3].onclick.widgets);
        setCurrentView('Bulk Basket Submission');
        break;
      default:
        // eslint-disable-next-line
        return;
    }
    // eslint-disable-next-line
  }, [location]);

  const properties = Object.keys(data?.metadata ?? {});

  const symbols = Object.keys(data?.metadata?.shares ?? {});

  return (
    <PageLayout>
      <div className="content" style={{ background: '#2a2d31' }}>
        <div className={styles.pageHeading}>
          <div className={styles.pageTitle}>
            <Imagen />
            BASKET SETUP
          </div>
        </div>
        <div>
          <div className={styles.compositionPorfolio}>
            <div className={styles.compositionPorfolioTitleView}>{currentView}</div>
          </div>
          {currentView === 'Single Basket Submission' ? (
            <SubmitBasket />
          ) : currentView === 'Basket to Index' ? (
            <BasketToIndex />
          ) : currentView === 'Bulk Basket Submission' ? (
            <BulkBasketSubmission />
          ) : (
            <UpdateBasket />
          )}
        </div>
      </div>
    </PageLayout>
  );
};

export default PortfolioBasketCalculatio;
