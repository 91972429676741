import React, { useState, useEffect } from 'react';
import styles from '../../builder.module.scss';
import Switcher from '../../../bitaComponents/Switcher/Switcher';
import BitaCheckbox from '../../../components/BitaCheckbox/BitaCheckbox';
import { ComponentGenerator } from '../Components';

export const BuilderCheckBox = ({
  setterFunction = Function.prototype,
  setterFilter = Function.prototype,
  filterField = '',
  onChange = Function.prototype,
  path,
  options = [],
  id = '',
  widgetData,
  subcomponents_direction,
  disabled = false,
  default_option = false,
  historyAddibleData,
  styling,
  hidden,
}) => {
  const [selected, setSelected] = useState(
    default_option ? Object.values(options).filter(i => i?.title === default_option)[0] : '',
  );
  const localPath = path ? `${path}.${id}` : id;

  useEffect(() => {
    if (typeof options[0] === 'object') {
      Object.values(options).forEach(option => option?.toggled_by_default && setSelected(option));
    }
  }, [options]);

  useEffect(() => {
    if (setterFilter && setterFunction) {
      if (selected && selected) setterFunction(prev => ({ ...prev, RadioGroup: selected }));
      if (setterFilter) setterFilter(prev => ({ field: filterField, filter: selected }));
    }
    if (onChange && selected)
      onChange(selected.id ? `${localPath}.${selected.id}` : localPath, selected.title);
    return () => {
      if (selected && selected.onclick && typeof selected.onclick[0] === 'object') {
        Object.values(selected.onclick).forEach(comp => {
          if (comp.id) {
            onChange(`${path}.${comp.id}`, undefined);
          }
        });
      } else if (selected && selected.onclick && selected.onclick.id) {
        onChange(`${path}.${selected.onclick.id}`, undefined);
      }
    };
  }, [selected]);

  if (typeof options[0] === 'object') {
    return (
      <div
        className={`${styles.radioParentGroup} builderToggleGroup`}
        style={{
          opacity: disabled ? (hidden ? '0' : '0.5') : '1',
          pointerEvents: disabled ? 'none' : '',
          flexFlow: subcomponents_direction === 'horizontal' ? 'row' : 'column',
          flexDirection: 'row-reverse',
        }}
      >
        {Object.values(options).map((option, key) => (
          <div
            key={key}
            style={{ display: 'flex', alignItems: 'center', flexDirection: 'row-reverse' }}
          >
            <div
              className={`${styles.radioHolder} radioHolder`}
              style={
                subcomponents_direction === 'horizontal'
                  ? {
                      width: '120px',
                      marginLeft: 10,
                      opacity: option.disabled ? '0.5' : '1',
                      pointerEvents: option.disabled ? 'none' : '',
                      display: key === 1 ? 'none' : 'row',
                    }
                  : {
                      width: '100px',
                      opacity: option.disabled ? '0.5' : '1',
                      pointerEvents: option.disabled ? 'none' : '',
                      display: key === 1 ? 'none' : '',
                    }
              }
            >
              <BitaCheckbox
                label={option.title}
                checked={option.title === selected.title}
                handleChange={checked => {
                  onChange(path, {});
                  if (checked) {
                    setSelected(option);
                  } else {
                    setSelected(Object.values(options)?.[key === 0 ? 1 : 0]);
                    onChange(
                      localPath,
                      {
                        value: Object.values(options)?.[key === 0 ? 1 : 0].title,
                        id: Object.values(options)?.[key === 0 ? 1 : 0].title,
                      },
                      '',
                    );
                  }
                }}
              />
            </div>
            {selected && option.title === selected.title && option.onclick && (
              <div
                className=" builderRadioGroup "
                style={option.options_text ? { flexDirection: 'column' } : {}}
              >
                {option.options_text && <span>{option.options_text}</span>}
                {typeof option.onclick[0] === 'object' ? (
                  Object.values(option.onclick).map((comp, index) => (
                    <ComponentGenerator
                      {...comp}
                      path={path}
                      onChange={onChange}
                      widgetData={widgetData}
                      key={index + comp.toString()}
                      historyAddibleData={historyAddibleData}
                    />
                  ))
                ) : (
                  <ComponentGenerator
                    {...option.onclick}
                    path={path}
                    onChange={onChange}
                    widgetData={widgetData}
                    historyAddibleData={historyAddibleData}
                  />
                )}
              </div>
            )}
          </div>
        ))}
      </div>
    );
  }

  return Object.values(options).map(option => (
    <div key={option}>
      <div className={`${styles.radioHolder} radioHolder `}>
        <span>{option}</span>
        <Switcher.Switch
          key={option}
          className={styles.customRadio}
          onChange={() => (option === selected ? setSelected('') : setSelected(option))}
          checked={option === selected}
        />
      </div>
    </div>
  ));
};
