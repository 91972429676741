/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import 'simplebar'; // or "import SimpleBar from 'simplebar';" if you want to use it manually.
import 'simplebar/dist/simplebar.css';
import VideoPlayer from './VideoPlayer';

import PageLayout from '../../components/Layout/PageLayout';
import * as CommonActions from '../../state/actions/common-actions';
import TabItem from '../../components/BitaAccordeon/TabItem';
import BitaPreviewVideo from '../../components/BitaPreviewVideo/BitaPreviewVideo';
import style from './tutorials.module.scss';
import BitaInput from '../../components/BitaInput/BitaInput';

const getDataPlayList = (apiKey, dataPlayList, ratioSelect) => {
  const url = `https://youtube.googleapis.com/youtube/v3/playlistItems?part=contentDetails%2Csnippet&maxResults=10&playlistId=${dataPlayList[ratioSelect]}&key=${apiKey}`;
  return axios.get(url);
};

export const RadioGroup = ({ options, optionSelected, onChange }) => {
  return options.map(option => (
    <React.Fragment key={option}>
      <input
        id={`radio-${option}`}
        type="radio"
        name="radio-filter"
        value={option}
        onChange={() => onChange(option)}
        checked={optionSelected === option}
      />
      <label htmlFor={`radio-${option}`}>{option}</label>
    </React.Fragment>
  ));
};

const Tutorials = () => {
  const [ratioSelect, setRatioSelect] = useState('All');
  const [search, setSearch] = useState('');
  const [listView, setListView] = useState([]);
  const [listVideoBottom, setListVideoBottom] = useState([]);
  const [listShowVideo, setListShowVideo] = useState([]);
  const [selectVideo, setSelectVideo] = useState({
    url: 'o_K0xFO2t10',
    description:
      'How to add an additional country and a primary exchange when creating your universe in the Universe Builder module',
  });
  const dispatch = useDispatch();
  const DATA_PLAY_LIST = {
    'Index Builder': 'PLsMtCr5TLEwriTnRhvF6oncVsbBXXRXfr',
    'Universe builder': 'PLsMtCr5TLEwrDPBYXy2sx9B-Z0wQwCu9p',
    'Structured Index Builder': 'PLsMtCr5TLEwrX5gZEwauot4aKpZVA_fXr',
    Analyzer: 'PLsMtCr5TLEwpHYsH8vyfVJmAerr0J21Kq',
  };
  const apiKey = 'AIzaSyAUzXrPWpGuQkL1wPbIetIR9ep-qkVeB-U';

  dispatch(CommonActions.resetSubheader());
  dispatch(CommonActions.appendSubheader('Tutorials'));

  useEffect(() => {
    const setterData = data => {
      setListView(data);
      setListShowVideo(
        search !== ''
          ? data.filter(video => video?.title?.toUpperCase().includes(search.toUpperCase()))
          : data,
      );
    };
    if (ratioSelect === 'all' || ratioSelect === 'All') {
      const flattenedArray = [...listVideoBottom.map(item => item.videos)];
      const data = flattenedArray.flatMap(array => array);
      setterData(data);
    } else {
      getDataPlayList(apiKey, DATA_PLAY_LIST, ratioSelect).then(rsp => {
        const data = rsp.data.items.map(video => video.snippet);
        setterData(data);
      });
    }
  }, [ratioSelect, listVideoBottom]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const requests = Object.keys(DATA_PLAY_LIST).map(item =>
          getDataPlayList(apiKey, DATA_PLAY_LIST, item)
            .then(rsp => rsp.data.items.map(video => video.snippet))
            .catch(error => {
              console.error(`Error fetching data for ${item}:`, error);
              return [];
            }),
        );

        const results = await Promise.all(requests);

        setListVideoBottom(
          results.map((videos, index) => ({
            nameSection: Object.keys(DATA_PLAY_LIST)[index],
            videos,
          })),
        );
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const filteredList =
      search !== ''
        ? listView.filter(video => video?.title?.toUpperCase().includes(search.toUpperCase()))
        : listView;
    setListShowVideo(filteredList);
  }, [search, listView]);

  useEffect(() => {
    document.title = `${process.env.REACT_APP_SITE_TITLE} | Tutorials`;
  }, []);

  return (
    <PageLayout>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className={style.contentVideo}>
          <div>
            <div className={style.contentFrameVideo}>
              <VideoPlayer videoId={selectVideo.url} />
            </div>
            <div className={style.descriptionVideo}>{selectVideo?.description}</div>
          </div>
          <div style={{ margin: '7px' }}>
            {listVideoBottom.map(section => (
              <TabItem name={section.nameSection}>
                {section.videos.map(video => (
                  <div
                    onClick={() => {
                      setSelectVideo({
                        url: video?.resourceId?.videoId,
                        description:
                          video?.description && video?.description !== ''
                            ? video?.description
                            : video?.title,
                      });
                    }}
                    className={`${style.contentInformationVideo} ${
                      video?.resourceId?.videoId === selectVideo.url ? style.activeVideo : ''
                    }`}
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <div>{video.title}</div>
                  </div>
                ))}
              </TabItem>
            ))}
          </div>
        </div>
        <div style={{ width: '400px' }} className={style.contentListVideo}>
          <div>
            <BitaInput
              type="text"
              className="searchInput"
              placeholder="Search by..."
              title="Search by..."
              autoFocus
              id="inputSearchModalCustomField"
              value={search}
              handleChange={val => {
                setSearch(val);
              }}
            />
            <div style={{ padding: '15px' }}>
              <RadioGroup
                options={[
                  'All',
                  'Index Builder',
                  'Universe builder',
                  'Structured Index Builder',
                  'Analyzer',
                ]}
                optionSelected={ratioSelect}
                onChange={value => setRatioSelect(value)}
              />
            </div>
          </div>
          <div data-simplebar style={{ height: '700px', overflow: 'hiden' }}>
            <div>
              {listShowVideo.map(item => (
                <BitaPreviewVideo
                  key={item?.id || item?.resourceId?.resourceId?.videoId}
                  video={item}
                  onClick={video =>
                    setSelectVideo({
                      url: video?.resourceId?.videoId,
                      description:
                        video?.description && video?.description !== ''
                          ? video?.description
                          : video?.title,
                    })
                  }
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default Tutorials;
