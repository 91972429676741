import React from 'react';
// import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import styles from './Layout.module.scss';

// const getSubheader = pathname => {
//   let path = pathname.split('/');
//   path = path[path.length - 1];
//   switch (path) {
//     case 'rebalacing-reconstitution':
//       return 'REBALANCING & RECONSTITUTION';
//     case 'general-rules':
//       return 'GENERAL RULES';
//     case 'fast-exit-entry':
//       return 'FAST EXIT & ENTRY';
//     case 'additional-rules':
//       return 'ADDITIONAL RULES';
//     default:
//       return path;
//   }
// };

const SubTopbar = props => {
  const { subheader } = props;
  return <div className={styles.subTopbar}>{subheader}</div>;
};

const mapStateToProps = state => {
  return {
    subheader: state.common.subheader,
  };
};

export default connect(mapStateToProps)(SubTopbar);
// export default withRouter(SubTopbar);
