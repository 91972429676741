// this file help building correctly the backtest data to send to the api

import * as _ from 'lodash';
import moment from 'moment';

const processMessage = (incommingMessage, { backtests }) => {
  // debugger;

  try {
    const backtestID = _.get(incommingMessage, 'headers.id');
    if (!backtestID) return { backtests };

    const searchTSIndexValue = (metricName, date) => {
      const metric = backtests[backtestID].backtestIndexData[metricName];
      if (metric) {
        return metric.index[date];
      }
      console.info('No se consiguio la metrica con nombre: ', metricName);
      return null;
    };

    // metrics : [[name_metric,{data,id}]]
    const metrics = _.chain(incommingMessage)
      .get('grouped_data', {})
      .entries()
      .value();

    const memData = backtests[backtestID].backtestIndexData || {};
    // eslint-disable-next-line no-restricted-syntax
    for (const [metricName, { data }] of metrics) {
      memData[metricName] = data;
    }

    const memSaveStat = backtests[backtestID].backtestSaveStats || {
      SINGLE: [],
      JSON: [],
      TIMESERIES: [],
      'JSON TIMESERIES': [],
    };

    // backtestSaveStats only use those metrics where the id is an object
    // we ommit the name_metric
    metrics
      .filter(([, { id: metricId }]) => typeof metricId === 'number')
      .map(([, metricDetail]) => metricDetail) // ignoro el elemento 0 que es el nombre de la métrica
      .forEach(({ data, id, type, name }) => {
        const formatedData = {
          m_portfolio_id: null,
          m_stats_id: id,
          stat_value: data.index || null,
        };
        // these metrics includes several data in the index and it's processed independently
        if (['TIMESERIES', 'JSON TIMESERIES'].includes(type)) {
          let entries = {};
          if (type === 'TIMESERIES') {
            entries = _.entries(data.index || {})
              .filter(([date]) => moment(date, 'YYYY-MM-DD', true).isValid())
              .map(([date, { index: indexVal }]) => {
                formatedData.stat_value = indexVal;
                return {
                  ...formatedData,
                  timestamp: date,
                  stat_value:
                    indexVal || searchTSIndexValue(name.toLowerCase().replace(/\s/g, '_'), date),
                };
              });
          } else {
            entries = _.entries(data.index || {})
              .filter(([date]) => moment(date, 'YYYY-MM-DD', true).isValid())
              .map(([date, indexVal]) => {
                return { ...formatedData, timestamp: date, stat_value: indexVal };
              });
          }

          memSaveStat[type].push(...entries);
        } else {
          // else just save the formatedData in the type property
          memSaveStat[type].push(formatedData);
        }
      });

    return {
      backtests: {
        ...backtests,
        [backtestID]: {
          ...backtests[backtestID],
          backtestIndexData: memData,
          backtestSaveStats: memSaveStat,
        },
      },
    };
  } catch (error) {
    console.error('backtest-metrics-helper[x] ERROR ', error);
    return { backtests: { ...backtests } };
  }
};

export default processMessage;
