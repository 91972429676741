import React from 'react';
import styles from '../Summary.module.scss';
import { Icons } from '../../../constants/icons';

const SummaryItem = props => {
  const { title, disabled, handleDelete } = props;
  return (
    <div className={`${styles.summaryItem} ${disabled ? 'disabled-component' : ''}`}>
      <button className={styles.summaryItemRemove} onClick={() => handleDelete()}>
        <Icons.deleteIcon />
      </button>
      <div className={styles.summaryItemData}>
        <div className={styles.subSection}>{title}-</div>
        <div className={styles.itemContent}>{props.children}</div>
      </div>
    </div>
  );
};

export default SummaryItem;
