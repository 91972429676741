import React from 'react';
import Select, { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import { Icons } from '../../constants/icons';
import './BitaSearchBarstyle.scss';

const SingleValue = props => {
  return (
    components.SingleValue && (
      <components.SingleValue {...props}>
        <Icons.search style={{ marginRight: '10px' }} />
        {props.data.label}
      </components.SingleValue>
    )
  );
};

const getPlaceholderText = (placeholder, isasync, value) => {
  // if (placeholder) return 'Live Basket(s)';
  if (isasync) return 'Introduce the name(s) of the Single Instrument(s) you want to apply.';
  return value || placeholder || 'Search Instrument...';
};

const setPlaceholder = (placeholder, isasync, value, props) => {
  return (
    components.Placeholder && (
      <components.Placeholder {...props}>
        <Icons.search style={{ marginRight: '10px' }} />
        {/* {placeholder
          ? 'Live Basket(s)'
          : isasync
          ? 'Introduce the name(s) of the Single Instrument(s) you want to apply.'
          : value || 'Search Instrument...'} */}

        {getPlaceholderText(placeholder, isasync, value)}
      </components.Placeholder>
    )
  );
};

const Input = props => {
  return (
    components.Input && (
      <div style={{ display: 'flex', color: 'white', alignItems: 'center' }}>
        {props.value && <Icons.search />}
        <components.Input {...props} style={{ marginLeft: !props.value ? '20px' : '5px' }} />
      </div>
    )
  );
};

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isFocused ? '#2a2d31' : 'white',
    backgroundColor: state.isFocused && 'white',
  }),

  input: styles => ({ ...styles, color: 'white' }),
  singleValue: styles => ({
    ...styles,
    color: 'white',
    display: 'flex',
    alignItems: 'center',
  }),
  valueContainer: styles => ({ ...styles, color: 'white' }),
};

const BitaSearchBar = ({
  loadOptions,
  searchOptions = [],
  value = undefined,
  onChange = Function.prototype,
  isasync,
  placeholder = '',
  forceStopLoading = false,
}) => {
  const Placeholder = props => {
    return setPlaceholder(placeholder, isasync, value, props);
  };

  return (
    <div className="bitaSearchBar">
      {isasync ? (
        <AsyncSelect
          value={value}
          styles={customStyles}
          cacheOptions
          theme={theme => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              text: 'white',
              neutral90: 'white',
              primary: 'white',
              primary25: '#2a2d31',
              neutral0: '#2a2d31',
            },
          })}
          components={{ SingleValue, Placeholder, Input }}
          classNamePrefix="barsearch"
          loadOptions={loadOptions}
          onChange={onChange}
        />
      ) : (
        <Select
          value={value}
          styles={customStyles}
          onChange={onChange}
          options={searchOptions || []}
          isLoading={!forceStopLoading && searchOptions && searchOptions.length === 0}
          theme={theme => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              text: 'white',
              neutral90: 'white',
              primary: 'white',
              primary25: '#2a2d31',
              neutral0: '#2a2d31',
            },
          })}
          components={{ SingleValue, Placeholder, Input }}
          classNamePrefix="barsearch"
        />
      )}
    </div>
  );
};

export default BitaSearchBar;
