/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/require-default-props */
import React, { useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import style from './BitaCard.module.scss';
import { Icons } from '../../constants/icons';
import { IconsModules } from '../../constants/icons-module';

const BitaCard = props => {
  const [divWidth, setDivWidth] = useState(0);

  const cardRef = React.createRef();

  useEffect(() => {
    const _width = cardRef.current.offsetWidth;
    setDivWidth(_width);
    // eslint-disable-next-line
  }, []);

  let IconElement = props.icon;
  if (props.icon === 'createNewIndex') {
    IconElement = IconsModules.CreateNewIndex;
  }
  if (props.icon === 'createNewMethodology') {
    IconElement = IconsModules.CreateNewMethodology;
  }
  if (props.icon === 'editMethodology') {
    IconElement = IconsModules.EditMethodology;
  }
  if (props.icon === 'baseUniverse') {
    IconElement = IconsModules.BaseUniverse;
  }
  if (props.icon === 'baseMethodology') {
    IconElement = IconsModules.BaseMethodology;
  }
  if (props.icon === 'createFromTemplate') {
    IconElement = IconsModules.CreateFromTemplate;
  }
  if (props.icon === 'whitecanvas') {
    IconElement = IconsModules.IndexWhiteCanvas;
  }

  if (props.icon === 'createNewUniverse') {
    IconElement = IconsModules.CreateNewUniverse;
  }
  if (props.icon === 'editExistingUniverse') {
    IconElement = IconsModules.EditExistingUniverse;
  }

  if (props.icon === 'startFromTemplate') {
    IconElement = IconsModules.StartfromTemplate;
  }
  if (props.icon === 'universeWhiteCanvas') {
    IconElement = IconsModules.UniverseWhiteCanvas;
  }

  const cardTypeClass = useMemo(() => {
    if (props.cardType === 'BITACARD_UNIVERSE') {
      return `${style.bitaCard} ${style.universe} bitaCard`;
    }
    if (props.cardType === 'BITACARD_FILTER') {
      return `${style.bitaCard} ${style.filter} bitaCard`;
    }
    if (props.cardType === 'BITACARD_MODULE') {
      return `${style.bitaCard} ${style.module} bitaCard`;
    }
    return `${style.bitaCard}`;
  }, [props.cardType]);

  return (
    <div
      className={classnames(
        cardTypeClass,
        props.color === 'secondary' ? style.bitaCardPurple : style.bitaCardBlue,
        props.disabled ? style.disabled : '',
        {
          [props.className.toString()]: !!props.className,
        },
      )}
      style={props.styles}
      onClick={props.onClick}
      ref={cardRef}
    >
      <div>
        <div
          className={`${classnames(style.title, {
            [style.titleWithSelected.toString()]: !!props.withSelected,
          })} bitaCardTitle `}
        >
          {props.cardType === 'BITACARD_UNIVERSE' ? props.title : props.title.toUpperCase()}
        </div>
        <p className={style.text}>{props.text}</p>
        {props.withSelected ? (
          <div className={style.withSelectedContent}>{props.withSelected}</div>
        ) : null}
        {props.withSelected ? null : (
          <IconElement
            className={
              (props.cardType !== 'BITACARD_FILTER' &&
                divWidth < 320 &&
                divWidth > 280 &&
                style.svgSmall) ||
              ''
            }
          />
        )}
      </div>
    </div>
  );
};

BitaCard.propTypes = {
  cardType: PropTypes.oneOf(['BITACARD_FILTER', 'BITACARD_UNIVERSE', 'BITACARD_MODULE']),
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
  onClick: PropTypes.func,
  styles: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  className: PropTypes.string,
  withSelected: PropTypes.node,
  color: PropTypes.string,
};

BitaCard.defaultProps = {
  cardType: 'BITACARD_UNIVERSE',
  text: ' ',
  color: 'primary',
  icon: Icons.globe,
  styles: {},
  disabled: false,
  onClick: Function.prototype, // noop
  className: '',
  withSelected: null,
};

BitaCard.FILTER = 'BITACARD_FILTER';
BitaCard.UNIVERSE = 'BITACARD_UNIVERSE';
BitaCard.MODULE = 'BITACARD_MODULE';

export default BitaCard;
