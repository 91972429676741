import { thousandFormat } from '../../../utils/utils';

const defaultTheme = {
  credits: {
    enabled: false,
  },
  chart: {
    type: 'column',
    backgroundColor: 'transparent',
    style: {
      fontFamily: "'Neue Haas Grotesk Display Pro', sans-serif",
    },
  },
  xAxis: {
    labels: {
      style: {
        color: '#E0E0E3',
        fontFamily: "'Neue Haas Grotesk Display Pro', sans-serif",
      },
    },
  },
  yAxis: {
    labels: {
      style: {
        color: '#E0E0E3',
        fontFamily: "'Neue Haas Grotesk Display Pro', sans-serif",
      },
    },
  },
  plotOptions: {
    series: {
      color: {
        linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
        stops: [
          [0, '#0039CB'], // principio del gradiente
          [0.8, '#2962FF'], // final del gradiente
        ],
      },
      turboThreshold: 15000,
      borderWidth: 0,
      showInLegend: false,
    },
  },
};
const invertedTheme = {
  credits: {
    enabled: false,
  },
  chart: {
    type: 'column',
    inverted: true,
    backgroundColor: 'transparent',
    style: {
      fontFamily: "'Neue Haas Grotesk Display Pro', sans-serif",
    },
  },
  plotOptions: {
    series: {
      color: {
        linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
        stops: [
          [0, '#0039CB'], // principio del gradiente
          [0.8, '#2962FF'], // final del gradiente
        ],
      },
      borderWidth: 0,
      showInLegend: false,
      turboThreshold: 15000,
    },
  },
  xAxis: {
    labels: {
      style: {
        color: '#E0E0E3',
        fontFamily: "'Neue Haas Grotesk Display Pro', sans-serif",
      },
    },
  },
  yAxis: {
    labels: {
      formatter() {
        return `${thousandFormat(this.value)}`;
      },
      style: {
        color: '#E0E0E3',
        fontFamily: "'Neue Haas Grotesk Display Pro', sans-serif",
      },
    },
  },
};

const activeBreakdownTheme = {
  credits: {
    enabled: false,
  },
  chart: {
    type: 'column',
    backgroundColor: 'transparent',
    style: {
      fontFamily: "'Neue Haas Grotesk Display Pro', sans-serif",
    },
  },
  title: '',
  plotOptions: {
    series: {
      color: '#1A1BE8',
      borderWidth: 0,
      showInLegend: false,
      turboThreshold: 15000,
    },
  },
  xAxis: {
    labels: {
      style: {
        color: '#E0E0E3',
        fontFamily: "'Neue Haas Grotesk Display Pro', sans-serif",
      },
    },
  },
  yAxis: {
    labels: {
      style: {
        color: '#E0E0E3',
        fontFamily: "'Neue Haas Grotesk Display Pro', sans-serif",
      },
    },
  },
};

const activeFactorTheme = {
  credits: {
    enabled: false,
  },
  chart: {
    type: 'column',
    backgroundColor: 'transparent',
    style: {
      fontFamily: "'Neue Haas Grotesk Display Pro', sans-serif",
    },
  },
  title: '',
  plotOptions: {
    series: {
      color: '#1987E8',
      borderWidth: 0,
      showInLegend: false,
      turboThreshold: 15000,
    },
  },
  xAxis: {
    labels: {
      style: {
        color: '#E0E0E3',
        fontFamily: "'Neue Haas Grotesk Display Pro', sans-serif",
      },
    },
  },
  yAxis: {
    title: {
      text: '',
    },
    labels: {
      style: {
        color: '#E0E0E3',
        fontFamily: "'Neue Haas Grotesk Display Pro', sans-serif",
      },
    },
  },
};

const contributionsTheme = {
  credits: {
    enabled: false,
  },
  chart: {
    type: 'column',
    backgroundColor: 'transparent',
    style: {
      fontFamily: "'Neue Haas Grotesk Display Pro', sans-serif",
    },
  },
  title: '',
  plotOptions: {
    series: {
      color: '#5981ED',
      borderWidth: 0,
      showInLegend: false,
      turboThreshold: 15000,
    },
  },
  lang: {
    noData: "There's no data available for the time frame you've selected",
  },
  noData: {
    style: {
      fontWeight: 'bold',
      fontSize: '12px',
      color: '#ffffff',
    },
  },
  xAxis: {
    labels: {
      step: 1,
      rotation: -45,
      style: {
        color: '#E0E0E3',
        fontFamily: "'Neue Haas Grotesk Display Pro', sans-serif",
        fontSize: 12,
      },
    },
  },
  yAxis: {
    labels: {
      style: {
        color: '#E0E0E3',
        fontFamily: "'Neue Haas Grotesk Display Pro', sans-serif",
      },
    },
  },
};

const activeRiskTheme = {
  credits: {
    enabled: false,
  },
  chart: {
    type: 'column',
    backgroundColor: 'transparent',
    style: {
      fontFamily: "'Neue Haas Grotesk Display Pro', sans-serif",
    },
  },
  tooltip: {
    crosshairs: true,
    shared: true,
    formatter(tooltip) {
      // eslint-disable-next-line prefer-destructuring
      const y = this.y;
      this.points[0].point.y = Number(y.toFixed(4));
      return tooltip.defaultFormatter.call(this, tooltip);
    },
  },
  title: '',
  plotOptions: {
    series: {
      color: '#2962ff',
      borderWidth: 0,
      showInLegend: false,
      turboThreshold: 15000,
    },
  },
  lang: {
    noData: "There's no data available for the time frame you've selected",
  },
  noData: {
    style: {
      fontWeight: 'bold',
      fontSize: '12px',
      color: '#ffffff',
    },
  },
  xAxis: {
    labels: {
      step: 1,
      rotation: 0,
      style: {
        color: '#E0E0E3',
        fontFamily: "'Neue Haas Grotesk Display Pro', sans-serif",
      },
    },
  },
  yAxis: {
    title: {
      text: '',
    },
    labels: {
      style: {
        color: '#E0E0E3',
        fontFamily: "'Neue Haas Grotesk Display Pro', sans-serif",
      },
    },
  },
};
// -----

const anualReturnTheme = {
  credits: {
    enabled: false,
  },

  chart: {
    type: 'column',
    backgroundColor: 'transparent',
    style: {
      fontFamily: "'Neue Haas Grotesk Display Pro', sans-serif",
    },
  },
  colors: ['#2962FF', '#FA5400'],
  title: '',
  plotOptions: {
    series: {
      dataLabels: {
        enabled: true,
        color: 'white',
        y: 2,
        allowOverlap: true,
        formatter() {
          return this.y ? `${this.y}%` : 'N/A';
        },
      },
      animation: {
        duration: 500,
      },
      borderWidth: 0,
      turboThreshold: 15000,
    },
  },
  xAxis: {
    labels: {
      style: {
        color: '#E0E0E3',
        fontFamily: "'Neue Haas Grotesk Display Pro', sans-serif",
      },
    },
  },
  legend: {
    itemStyle: {
      color: 'white',
      fontFamily: "'Neue Haas Grotesk Display Pro', sans-serif",
    },
  },
  yAxis: {
    title: {
      text: '',
    },
    labels: {
      formatter() {
        return `${this.value} %`;
      },
      style: {
        color: '#E0E0E3',
        fontFamily: "'Neue Haas Grotesk Display Pro', sans-serif",
      },
    },
  },
};

const historicalPerformanceTheme = {
  credits: {
    enabled: false,
  },
  colors: ['#2962FF', '#FA5400'],
  chart: {
    type: 'spline',
    backgroundColor: 'transparent',
    style: {
      fontFamily: "'Neue Haas Grotesk Display Pro', sans-serif",
    },
  },
  title: {
    text: '',
  },
  xAxis: {
    labels: {
      style: {
        color: '#E0E0E3',
        fontFamily: "'Neue Haas Grotesk Display Pro', sans-serif",
      },
    },
  },

  yAxis: {
    title: {
      text: '',
    },
    labels: {
      style: {
        color: '#E0E0E3',
        fontFamily: "'Neue Haas Grotesk Display Pro', sans-serif",
      },
    },
  },
  tooltip: {
    crosshairs: true,
    shared: true,
  },
  boost: {
    useGPUTranslations: true,
    usePreAllocated: true,
  },
  plotOptions: {
    series: {
      boostThreshold: 500,
      turboThreshold: 8000,
      animation: {
        duration: 500,
      },
    },
    spline: {
      marker: {
        radius: 0,
        lineColor: '#666666',
        lineWidth: 1,
      },
    },
  },
  legend: {
    itemStyle: {
      color: 'white',
      fontFamily: "'Neue Haas Grotesk Display Pro', sans-serif",
    },
  },
};
const WasteGenerationTheme = {
  credits: {
    enabled: false,
  },
  colors: ['#2962FF', '#FA5400', '#91B7F9', '#013FCD', '#ff884d'],
  chart: {
    backgroundColor: 'transparent',
    style: {
      fontFamily: "'Neue Haas Grotesk Display Pro', sans-serif",
    },
  },

  title: {
    text: '',
  },
  xAxis: {
    labels: {
      style: {
        color: '#E0E0E3',
        fontFamily: "'Neue Haas Grotesk Display Pro', sans-serif",
        fontSize: '10px',
      },
    },
  },

  yAxis: {
    title: {
      text: '',
    },
    tickInterval: 1,
    labels: {
      style: {
        color: '#E0E0E3',
        fontFamily: "'Neue Haas Grotesk Display Pro', sans-serif",
      },
    },
  },
  tooltip: {
    crosshairs: true,
    shared: true,
  },
  boost: {
    useGPUTranslations: true,
    usePreAllocated: true,
  },
  plotOptions: {
    series: {
      marker: {
        enabled: false,
      },
    },
  },
  legend: {
    itemStyle: {
      color: 'white',
      fontFamily: "'Neue Haas Grotesk Display Pro', sans-serif",
      fontSize: '10px',
    },
  },
};

const WasteGrowthTheme = {
  credits: {
    enabled: false,
  },
  colors: ['#2962FF', '#FA5400', '#91B7F9', '#013FCD', '#ff884d'],
  chart: {
    backgroundColor: 'transparent',
    style: {
      fontFamily: "'Neue Haas Grotesk Display Pro', sans-serif",
    },
  },

  title: {
    text: '',
  },
  xAxis: {
    labels: {
      style: {
        color: '#E0E0E3',
        fontFamily: "'Neue Haas Grotesk Display Pro', sans-serif",
        fontSize: '10px',
      },
    },
  },

  yAxis: {
    title: {
      text: '',
    },
    labels: {
      style: {
        color: '#E0E0E3',
        fontFamily: "'Neue Haas Grotesk Display Pro', sans-serif",
      },
    },
  },
  tooltip: {
    crosshairs: true,
    shared: true,
  },
  boost: {
    useGPUTranslations: true,
    usePreAllocated: true,
  },
  plotOptions: {
    series: {
      marker: {
        enabled: false,
      },
    },
  },
  legend: {
    itemStyle: {
      color: 'white',
      fontFamily: "'Neue Haas Grotesk Display Pro', sans-serif",
      fontSize: '10px',
    },
  },
};

const WasteGrowthThemeWaste = {
  credits: {
    enabled: false,
  },
  colors: ['#1A1BE8', '#5981ED', '#C3C3C3'],

  chart: {
    type: 'column',
    backgroundColor: 'transparent',
    style: {
      fontFamily: "'Neue Haas Grotesk Display Pro', sans-serif",
    },
  },

  title: {
    text: '',
  },
  xAxis: {
    labels: {
      style: {
        color: '#E0E0E3',
        fontFamily: "'Neue Haas Grotesk Display Pro', sans-serif",
        fontSize: '10px',
      },
    },
  },

  yAxis: {
    title: {
      text: '',
    },
    labels: {
      style: {
        color: '#E0E0E3',
        fontFamily: "'Neue Haas Grotesk Display Pro', sans-serif",
      },
    },
  },
  boost: {
    useGPUTranslations: true,
    usePreAllocated: true,
  },

  plotOptions: {
    column: {
      borderWidth: 0,
    },
    series: {
      marker: {
        enabled: false,
      },
    },
  },
  legend: {
    itemStyle: {
      color: 'white',
      fontFamily: "'Neue Haas Grotesk Display Pro', sans-serif",
      fontSize: '10px',
    },
  },
};

const ReciclingRatioTheme = {
  credits: {
    enabled: false,
  },
  colors: ['#5981ED', '#1A1BE8', '#FA5400'],

  chart: {
    backgroundColor: 'transparent',
    style: {
      fontFamily: "'Neue Haas Grotesk Display Pro', sans-serif",
    },
  },

  title: {
    text: '',
  },
  xAxis: {
    labels: {
      style: {
        color: '#E0E0E3',
        fontFamily: "'Neue Haas Grotesk Display Pro', sans-serif",
        fontSize: '10px',
      },
    },
  },

  yAxis: [
    {
      id: 'y_axis_0',
      title: {
        text: '',
      },
      stackLabels: {
        style: {
          fontWeight: 'bold',
        },
      },
      labels: {
        align: 'left',
        x: -10,
        y: 10,
        style: {
          color: '#E0E0E3',
          fontFamily: "'Neue Haas Grotesk Display Pro', sans-serif",
          fontSize: '10px',
        },
      },
    },
    {
      // Secondary yAxis
      id: 'y_axis_1',
      gridLineWidth: 0,
      labels: {
        align: 'right',
        x: 10,
        y: 10,
        format: '{value} %',
        formatter() {
          return `${this.value}%`;
        },
        style: {
          color: '#E0E0E3',
          fontFamily: "'Neue Haas Grotesk Display Pro', sans-serif",
          fontSize: '10px',
        },
      },
      opposite: true,
      title: {
        text: '',
      },
    },
  ],

  tooltip: {
    crosshairs: true,
    shared: true,
  },
  boost: {
    useGPUTranslations: true,
    usePreAllocated: true,
  },
  plotOptions: {
    column: {
      grouping: false,
    },
    series: {
      marker: {
        enabled: false,
      },
    },
  },
  legend: {
    itemStyle: {
      color: 'white',
      fontFamily: "'Neue Haas Grotesk Display Pro', sans-serif",
      fontSize: '10px',
    },
  },
};

const TotalRecicledTheme = {
  credits: {
    enabled: false,
  },
  colors: ['#8ab7ff', '#013AD5', '#FA5400', '#2962FF'],
  chart: {
    type: 'spline',
    backgroundColor: 'transparent',
    style: {
      fontFamily: "'Neue Haas Grotesk Display Pro', sans-serif",
    },
  },

  title: {
    text: '',
  },
  xAxis: {
    labels: {
      style: {
        color: '#E0E0E3',
        fontFamily: "'Neue Haas Grotesk Display Pro', sans-serif",
        fontSize: '10px',
      },
    },
  },

  yAxis: {
    title: {
      text: '',
    },
    labels: {
      style: {
        color: '#E0E0E3',
        fontFamily: "'Neue Haas Grotesk Display Pro', sans-serif",
      },
    },
  },
  tooltip: {
    crosshairs: true,
    shared: true,
  },
  boost: {
    useGPUTranslations: true,
    usePreAllocated: true,
  },
  plotOptions: {
    series: {
      marker: {
        enabled: false,
      },
      fillOpacity: 1,
    },
  },
  legend: {
    itemStyle: {
      color: 'white',
      fontFamily: "'Neue Haas Grotesk Display Pro', sans-serif",
      fontSize: '10px',
    },
  },
};
const TopicsTheme = {
  credits: {
    enabled: false,
  },
  colors: ['#2962FF', '#FA5400'],
  chart: {
    type: 'spline',
    backgroundColor: 'transparent',
    style: {
      fontFamily: "'Neue Haas Grotesk Display Pro', sans-serif",
    },
  },
  title: {
    text: '',
  },
  xAxis: {
    labels: {
      style: {
        color: '#E0E0E3',
        fontFamily: "'Neue Haas Grotesk Display Pro', sans-serif",
      },
    },
  },

  yAxis: {
    title: {
      text: '',
    },
    labels: {
      formatter() {
        return `${this.value} %`;
      },
      style: {
        color: '#E0E0E3',
        fontFamily: "'Neue Haas Grotesk Display Pro', sans-serif",
      },
    },
  },
  tooltip: {
    crosshairs: true,
    shared: true,
  },
  boost: {
    useGPUTranslations: true,
    usePreAllocated: true,
  },
  plotOptions: {
    series: {
      boostThreshold: 500,
      turboThreshold: 8000,
      animation: {
        duration: 500,
      },
    },
    spline: {
      marker: {
        radius: 0,
        lineColor: '#666666',
        lineWidth: 1,
      },
    },
  },
  legend: {
    itemStyle: {
      color: 'white',
      fontFamily: "'Neue Haas Grotesk Display Pro', sans-serif",
    },
  },
};
const gicsWeightingTheme = {
  credits: {
    enabled: false,
  },
  chart: {
    height: 200,
    type: 'bar',
    backgroundColor: 'transparent',
    style: {
      fontFamily: "'Neue Haas Grotesk Display Pro', sans-serif",
    },
  },
  title: {
    align: 'left',
    style: {
      color: '#FFF',
      fontSize: '14px',
      fontWeight: '800',
    },
  },
  yAxis: {
    gridLineWidth: 0,
    minorGridLineWidth: 0,
    labels: { enabled: false },
    min: 0,
    title: {
      text: null,
    },
  },
  legend: {
    enabled: false,
  },
  plotOptions: {
    series: {
      color: '#2962FF',
      groupPadding: 0,
      pointPadding: 0.1,
      borderWidth: 0,
      turboThreshold: 15000,
      maxPointWidth: 12,
    },
  },
};

const countryWeightingTheme = {
  credits: {
    enabled: false,
  },
  chart: {
    height: 200,
    type: 'bar',
    backgroundColor: 'transparent',
    style: {
      fontFamily: "'Neue Haas Grotesk Display Pro', sans-serif",
    },
  },
  title: {
    text: 'Country Weighting',
    align: 'left',
    style: {
      color: '#FFF',
      fontSize: '14px',
      fontWeight: '800',
    },
  },
  yAxis: {
    gridLineWidth: 0,
    minorGridLineWidth: 0,
    labels: { enabled: false },
    min: 0,
    title: {
      text: null,
    },
  },
  legend: {
    enabled: false,
  },
  plotOptions: {
    series: {
      color: '#FA5400',
      groupPadding: 0,
      pointPadding: 0.1,
      borderWidth: 0,
      turboThreshold: 15000,
      maxPointWidth: 12,
    },
  },
};

const attributionLeftTheme = {
  colors: ['#1A1BE8', '#C3C3C3'],
  chart: {
    type: 'column',
    inverted: true,
    alignTicks: true,
    backgroundColor: 'transparent',
    style: {
      fontFamily: "'Neue Haas Grotesk Display Pro', sans-serif",
    },
  },
  title: {
    style: {
      color: '#E0E0E3',
      fontSize: '14px',
    },
    text: 'Relative Weight',
    x: -65,
  },
  tooltip: {
    crosshairs: true,
    shared: true,
  },
  legend: {
    symbolHeight: 7,
    symbolWidth: 7,
    symbolRadius: 0,
    itemMarginBottom: 2,
    width: '100%',
    itemStyle: {
      color: '#E0E0E3',
      marginTop: '10px',
    },
  },
  plotOptions: {
    column: {
      borderColor: 'transparent',
    },
  },
  yAxis: {
    title: {
      text: '',
    },
    lineColor: 'transparent',
    gridLineWidth: 0,
    labels: {
      style: {
        color: '#E0E0E3',
        fontFamily: "'Neue Haas Grotesk Display Pro', sans-serif",
      },
      rotation: 0,
      staggerLines: 2,
    },
    lineWidth: 1,
    plotLines: [
      {
        color: '#e7e7e7',
        width: 1,
        value: 0,
      },
    ],
  },
  xAxis: {
    offset: 25,
    opposite: true,
    labels: {
      align: 'center',
      style: {
        color: '#E0E0E3',
        fontFamily: "'Neue Haas Grotesk Display Pro', sans-serif",
      },
    },
    lineColor: 'transparent',
  },
  credits: {
    enabled: false,
  },
};

const attributionRightTheme = {
  colors: ['#1A1BE8', '#5981ED', '#C3C3C3'],
  chart: {
    type: 'column',
    inverted: true,
    alignTicks: true,
    backgroundColor: 'transparent',
    style: {
      fontFamily: "'Neue Haas Grotesk Display Pro', sans-serif",
    },
  },
  tooltip: {
    crosshairs: true,
    shared: true,
  },
  title: {
    style: {
      color: '#E0E0E3',
      fontSize: '14px',
    },
    text: 'Attribution Effect',
  },
  legend: {
    symbolHeight: 7,
    symbolWidth: 7,
    symbolRadius: 0,
    width: '50%',
    itemMarginBottom: 2,
    itemStyle: {
      color: '#E0E0E3',
      fontFamily: "'Neue Haas Grotesk Display Pro', sans-serif",
    },
  },
  plotOptions: {
    column: {
      borderColor: 'transparent',
    },
  },
  yAxis: {
    lineColor: 'transparent',
    gridLineWidth: 0,
    title: {
      text: '',
    },
    lineWidth: 1,
    labels: {
      style: {
        color: '#E0E0E3',
        fontFamily: "'Neue Haas Grotesk Display Pro', sans-serif",
      },
      rotation: 0,
      staggerLines: 2,
    },
    plotLines: [
      {
        color: '#e7e7e7',
        width: 1,
        value: 0,
      },
    ],
  },
  xAxis: {
    visible: false,
    lineColor: 'transparent',
    minorLineWidth: 0,
    labels: {
      style: {
        color: '#E0E0E3',
        fontFamily: "'Neue Haas Grotesk Display Pro', sans-serif",
      },
    },
  },
  credits: {
    enabled: false,
  },
};

const historicalRatingsTheme = {
  colors: ['#2962FF', '#FA5400'],
  chart: {
    type: 'column',
    alignTicks: true,
    backgroundColor: 'transparent',
    style: {
      fontFamily: "'Neue Haas Grotesk Display Pro', sans-serif",
    },
  },
  title: {
    style: {
      color: '#E0E0E3',
      fontSize: '14px',
    },
    text: ' ',
  },
  legend: {
    symbolHeight: 7,
    symbolWidth: 7,
    symbolRadius: 0,
    itemMarginBottom: 2,
    itemStyle: {
      color: '#E0E0E3',
      fontFamily: "'Neue Haas Grotesk Display Pro', sans-serif",
    },
  },
  plotOptions: {
    column: {
      borderColor: 'transparent',
    },
  },

  xAxis: {
    labels: {
      style: {
        color: '#E0E0E3',
        fontFamily: "'Neue Haas Grotesk Display Pro', sans-serif",
      },
    },
  },
  yAxis: {
    title: {
      text: '',
    },
    labels: {
      style: {
        color: '#E0E0E3',
        fontFamily: "'Neue Haas Grotesk Display Pro', sans-serif",
      },
    },
  },
  credits: {
    enabled: false,
  },
};
export {
  defaultTheme,
  historicalPerformanceTheme,
  anualReturnTheme,
  invertedTheme,
  activeRiskTheme,
  activeFactorTheme,
  contributionsTheme,
  activeBreakdownTheme,
  countryWeightingTheme,
  gicsWeightingTheme,
  attributionLeftTheme,
  attributionRightTheme,
  WasteGrowthThemeWaste,
  TopicsTheme,
  WasteGenerationTheme,
  WasteGrowthTheme,
  ReciclingRatioTheme,
  TotalRecicledTheme,
  historicalRatingsTheme,
};
