import React from 'react';
import PulseLoader from 'react-spinners/PulseLoader';
import './BitaSpinner.scss';

const BitaSpinner = props => {
  if (props.pointers) {
    return (
      <PulseLoader
        size={5}
        color={props.color || '#fff'}
        loading={props.loading}
        css={props.style}
      />
    );
  }
  return (
    <div className="spinner bita_spinner">
      <svg id="triangle" width="50px" height="50px" viewBox="-3 -4 39 39">
        <polygon fill="#2962FF" stroke="#FFFFFF" strokeWidth="2" points="16,0 32,32 0,32" />
      </svg>
    </div>
  );
};

export default BitaSpinner;
