/*
this component creates the filter submenu inside UNIVERSE BUILDER

 */
/* eslint-disable react/no-array-index-key */
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Nav } from 'react-sidenav';
import React, { useMemo } from 'react';
import * as lodash from 'lodash';
import ConfigYAML from '../../../config/ConfigYAML';
import NavItem from '../../../bitaComponents/SideNavbar/lib/NavItem';
import { Icons } from '../../../constants/icons';
import SideNavWrapper from '../SideNavWrapper';

const mapStateToProps = state => ({
  filterGroups: state.universeBuilderOperations.filterGroups,
  filterGroupSummaryData: lodash.get(state, ['summary', 'Universe Builder', 'Filter Group'], []),
});

const UniverseFiltersBuilder = connect(mapStateToProps)(({ filterGroups }) => {
  const location = useLocation();
  const cards = useMemo(() => {
    const result = ConfigYAML.jsonPath([
      'universe_builder',
      "sections[?(@.name == 'Available Filters')]",
      'components',
      'components',
    ]).filter(card => {
      return card.disabled === false || card.disabled === undefined;
    });
    if (!result) {
      return [];
    }

    return Object.values(result).map((card, cardindex) => ({
      ...card,
      to: card.onclick ? `filter-${cardindex}` : '',
    }));
  }, []);

  return (
    <div className="universe-builder-filters">
      {filterGroups.map((filterObj, groupindex) => (
        <SideNavWrapper
          defaultPath="/universe-builder/universe-builder"
          id={`/universe-builder/universe-builder/filtergroup-${groupindex}`}
          key={`nav-filtrgroup-${groupindex}`}
          hideFilter={false}
        >
          <NavItem
            key={`filtergroup-${groupindex + 1}`}
            title={`Filter Group #${groupindex + 1}`}
            to={`/universe-builder/universe-builder/filtergroup-${groupindex}`}
            icon={Icons.filters}
          />
          {cards.length && (
            <Nav id={`filternsav-${groupindex + 1}-cards`} key={groupindex}>
              {cards.map((card, cardindex) => (
                <NavItem
                  selected={
                    location.pathname ===
                    `/universe-builder/universe-builder/filtergroup-${groupindex}/${card.to}`
                  }
                  key={`filtergroup-${cardindex + 1}-card-${cardindex}`}
                  title={card.title}
                  to={`/universe-builder/universe-builder/filtergroup-${groupindex}/${card.to}`}
                />
              ))}
            </Nav>
          )}
        </SideNavWrapper>
      ))}
    </div>
  );
});

UniverseFiltersBuilder.displayName = 'UniverseBuilderFiltersBuilder';

export default UniverseFiltersBuilder;
