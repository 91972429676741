const chartData = (xAxisDates, data) => {
  const maxValue = data ? Math.max(...data) + 5 : 5;
  return {
    title: {
      text: ' ',
    },

    xAxis: {
      categories: xAxisDates,
      title: {
        text: '',
      },
    },
    yAxis: {
      tickInterval: maxValue,
      max: maxValue,
    },
    tooltip: {
      pointFormat:
        '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
      shared: true,
    },
    series: [{ data }],
  };
};

export default chartData;
